import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';
import { User } from 'src/app/models/user';
// import { AbstractControl, ValidationErrors, Validators, FormGroup, EmailValidator, FormBuilder } from '@angular/forms';
import { take, publish } from 'rxjs/operators';
import { ConnectableObservable } from 'rxjs';
import { WaitDialogComponent } from '../../util/wait-dialog/wait-dialog.component';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {

  userEmail: any;
  userName:any;
  userActive: boolean = true;
  validEmail: boolean = true;
  emailRegEx: RegExp = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
  emailExists: boolean = true;
  IfMandatory: boolean = false;
  errorMessage: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog,
    private router: Router,
    private userService: UserService,
  ) { }

  ngOnInit() {

  }

  clearMessage() {
    this.errorMessage = "";
  }


  checkAllFieldsAreEmpty() {

    if ((this.userEmail == null || this.userEmail == undefined) || (this.userName == null || this.userName == undefined)) {
      this.IfMandatory = true;
    }
    else {
      this.IfMandatory = false;
    }
  }

  userSaveIfTrue() {
    let user = new User();
    let self = this;
    user.UserName = this.userName + "_1R";
    user.UserEmail = this.userEmail;
    user.UserActive = this.userActive;
    user.CreatedBy = localStorage.getItem("loggedInUserName");
    user.ModifiedBy = localStorage.getItem("loggedInUserName");

    var userExists = this.userService.userExists(user.UserEmail,user.UserName).pipe(take(1));
    var createUser = this.userService.createUser(user).pipe(take(1), publish()) as ConnectableObservable<any>;

    userExists.subscribe(res => {
        this.emailExists = true;
    }, error => {
        this.emailExists = false;
        this.errorMessage = "User already exists";
    }, createUser.connect.bind(createUser));
    let className = localStorage.getItem("themeName");
    const dialogRef = this.dialog.open(WaitDialogComponent, {data: {caption: 'Saving...'},panelClass:className});

    createUser.subscribe(function (data) {
        dialogRef.close();
        if (data && self.emailExists) {
            Swal.fire({
                text: "User Created Successfully",
                icon: 'success',
                confirmButtonText: 'OK',
            }).then(() => {
                self.dialog.closeAll();
            })
        }
        else {
            Swal.fire({
                text: "User Create failed",
                icon: 'error',
                confirmButtonText: 'OK',
            }).then(() => {
                self.dialog.closeAll();
            })
        }

    }, error => {
        Swal.fire({
            text: "User Create failed. Server Error",
            icon: 'error',
            confirmButtonText: 'OK',
        }).then(() => {
            self.dialog.closeAll();
        })
    });
  
   
  }



  saveUser() {
    this.checkAllFieldsAreEmpty();
    if (!this.IfMandatory) {
      let userEmail = this.userEmail.toLowerCase();
      this.validEmail = this.emailRegEx.test(userEmail);
      if (!this.validEmail) {
        this.errorMessage = "Not a valid email";
      }
      else {
        this.userSaveIfTrue();

      }
    }
    else
    {
      this.errorMessage="All fields are mandatory"
    }


  }


  cancelUser() {
    this.dialog.closeAll();
    this.router.navigate(['userAdmin']);
  }

}
