<div style="height: 600px; width: 500px;">
  <h1 mat-dialog-title>RECOMMENDATIONS</h1>
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <br>
    <mat-card *ngIf="searching">
      <table style="width: 100%;">
        <tr>
          <td width="80%">
            Saving Eligibility Questions
          </td>
          <td matTooltip="Check">
            <div id="loading" *ngIf="saving"></div>
            <mat-icon color="primary" *ngIf="!saving">check</mat-icon>
          </td>
        </tr>
        <tr>
          <td width="80%">
            Calculating Enrollment Recommendations
          </td>
          <td>
            <div id="loading"></div>
          </td>
        </tr>
      </table>
    </mat-card>
    <div *ngIf="!searching">
      <mat-list>
        <mat-list-item *ngFor="let rec of recs;">
          <mat-icon mat-list-icon style="color: red;margin-right: 30px;" matTooltip="Ineligible"
            *ngIf="rec.RECOMMEND === 0">error
          </mat-icon>
          <mat-icon mat-list-icon style="color: yellow;margin-right: 30px;" matTooltip="Moderate"
            *ngIf="rec.RECOMMEND === 2">warning
          </mat-icon>
          <mat-icon mat-list-icon style="color: green;margin-right: 30px;" matTooltip="Eligible"
            *ngIf="rec.RECOMMEND === 1">check_circle
          </mat-icon>
          <h4 mat-line *ngIf="rec.RECOMMEND === 0">{{rec.PROGRAMNAME}}</h4>
          <button mat-raised-button color="primary" (click)="handleEnroll(rec)" *ngIf="rec.RECOMMEND !== 0">Enroll in
            {{rec.WORKORDERTYPE}}</button>
        </mat-list-item>
      </mat-list>
    </div>
    <br>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="handleCancel()">CANCEL</button>
  </div>
</div>