<h5 mat-dialog-title  style="padding:0.2vw; color:black">Role Detail : {{roleData.RoleId}}</h5>
<mat-divider></mat-divider>
<div mat-dialog-content>

  <mat-card class="user-card" style="width: 90%;">
    <mat-card-content>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" [(ngModel)]="updatedName" (focusin)="clearMessage()" maxlength="50">
      </mat-form-field>
      <!--   <br>
      <br> -->
      <mat-slide-toggle [(ngModel)]="updatedActive">Active</mat-slide-toggle>
      <br>
      <label style="color: red; font-size: 0.9vw">{{errorMessage}}</label>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="saveRole();">SAVE</button>
      <button mat-raised-button color="primary" (click)="cancelRole();">CANCEL</button>
    </mat-card-actions>
  </mat-card>
</div>
<!-- <div style="margin-bottom: 75px;"></div> -->