<mat-card>
    <mat-tab-group #searchTabs color="accent" backgroundColor="primary" style="margin:1vw">
      <mat-tab label="Master Configuration">
        <br>

      <div style="margin: 1vw;">

        <form  style="width:25% !important;float: left;" >
          <mat-form-field appearance="outline" style="padding-right: 2.2vw;width:17.6vw;font-size: 1vw;">
            <mat-label>Select Utility</mat-label>
            <mat-select  style="font-size: 11px;" [(value)]="selected" (selectionChange)="onSelection($event)" >
              <mat-option *ngFor="let util of utilityList" [value]="util.utilid" [id]="util.utilid"   style="font-size: 11px;">{{util.utiltitle}}</mat-option>
            </mat-select>
          </mat-form-field>
        </form>

        <button style="margin-left:1vw; margin-top:1vw" mat-raised-button color="primary" (click)="viewUtilConfigurations()">View/Edit</button>
        <button style="margin-left:1vw; margin-top:1vw" mat-raised-button color="primary" >Add</button>
        <button style="margin-left:1vw; margin-top:1vw" mat-raised-button color="primary" >Copy</button>
        <br>
        <br>       
      </div> 
      </mat-tab>
         <mat-tab label="Style Configuration">
      </mat-tab>
    </mat-tab-group>
  

    
  
  
  
  </mat-card>

  