import { Component, OnInit } from '@angular/core';
import { RoutingService } from '../services/route.service';
import * as moment from 'moment';
import { DataTransferService } from '../services/data-transfer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-route-optimization',
  templateUrl: './route-optimization.component.html',
  styleUrls: ['./route-optimization.component.scss']
})
export class RouteOptimizationComponent implements OnInit {
  searching =false;
  selectedDate:any = new Date();
  showTableData = false;
  //minDate :any = new Date();
  dataSource:any;
  displayedColumns: string[] = ['ro_id', 'timerun', 'targetday'];

  constructor(private routingService:RoutingService,
     private dataTransfer:DataTransferService,
     private router: Router) { }

  ngOnInit(): void {
    this.dataTransfer.myMethod(true);   
    this.showTableData = false; 
  }
  handleClick(row){
    console.log(row.ro_id);
    localStorage.setItem("RO_ID",row.ro_id);
    localStorage.setItem("RO_DATE",row.targetday);    
    this.router.navigated = false;
    this.router.navigate(['routeOptimizationDetail']);
  }

  onDate(e){

  }
  routeMe()
  {
    this.router.navigated = false;
    this.router.navigate(['routeOptimizationDetail']);
  }
  optimizeRoute(selectedDate){
    this.searching =true;
    this.showTableData = false;
    let stringDate  =  moment(selectedDate).format("MM/DD/YYYY");   
     this.routingService.getTargetRoute(stringDate).subscribe(res=>{  
      this.dataSource= res.ROMaster;     
      this.searching =false;
      this.showTableData = true;
    }) 
  }

}
