import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { WorkorderExceptions } from '../models/workorderexceptions';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../services/utility.service';
@Injectable({
  providedIn: 'root'
})
export class WorkorderExceptionsService {
  private woSearchWsUrl1 = environment.apiUrl + 'workorder/';
  constructor(private http: HttpClient, private utilityService: UtilityService) { }
  public getHeaders() {
    const email = localStorage.getItem('loggedInUserEmail');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-email': email,
        'x-programsponsorid': this.utilityService.currentUtility.ProgramsponsorId
      })
    };
    return httpOptions;
  }

  getWorkorderExceptions(workorderID): Observable<WorkorderExceptions> {
    const searchUrl = this.woSearchWsUrl1 + this.utilityService.currentUtility.OfficeId + "/exceptions" + '/' + workorderID + "/role/" + this.utilityService.currentUtility.LoggedInUserRole
      + '?code=' + environment.apiCode;

    return this.http.get<WorkorderExceptions>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  Clearworkorderexceptions(workorderID, exceptioncode): Observable<any[]> {
    const searchUrl = this.woSearchWsUrl1 + this.utilityService.currentUtility.OfficeId + "/clearexception" + '/' + workorderID + '/' + exceptioncode + "/user/" + this.utilityService.currentUtility.BBCSUserId
      + '?code=' + environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }


  ClearAllworkorderexceptions(workorderID): Observable<any[]> {
    const searchUrl = this.woSearchWsUrl1 + this.utilityService.currentUtility.OfficeId + "/ClearClearableExceptions" + '/' + workorderID + "/user/" + this.utilityService.currentUtility.BBCSUserId
      + '?code=' + environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }
}
