import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilityService } from './utility.service';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  mapData: any;
  httpRequest: any[];
  count ;

  constructor(private http: HttpClient, private utilityService: UtilityService) { }

  public getHeaders() {
    const email = localStorage.getItem('loggedInUserEmail');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-email': email,
        'x-programsponsorid': this.utilityService.currentUtility.ProgramsponsorId
      })
    };
    return httpOptions;
  }

  getTargetRoute(startDate) {

    let infoObj = {
        targetDate: startDate    
    }

    const searchUrl = environment.apiUrl + "routeoptimization/romasterlist/" + this.utilityService.currentUtility.OfficeId +  "?code=" + environment.apiCode;
    console.log(searchUrl);
    return this.http.post<any[]>(searchUrl, infoObj, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );

  }
 
  getRoute(ro) {
    console.log("searchUrl");
    const searchUrl = environment.apiUrl + "routeoptimization/rodetaillist/" + ro +  "?code=" + environment.apiCode;
    console.log(searchUrl);
    return this.http.get<any[]>(searchUrl, this.getHeaders())
    .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
    );
}




  getLatLng(address, index) {

    console.log("index : " + index+ "address: "+ address);
    this.http
    .get(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
      address +
        "&key=AIzaSyDEJpepas8zDP-C_bqqnZhXI93nxqBJKk4"
    )
    .subscribe((result: any) => {   
              console.log(result);             
            if (result.status == "OK") {
              var lats = result.results[0].geometry.location.lat;
              var lngs = result.results[0].geometry.location.lng;
              console.log("lat :" + lats+ " lng: "+ lngs); 
              this.mapData[index].lat= lats;
              this.mapData[index].lngs= lngs; 
              console.log(this.mapData);
              this.count++;
              } 
            });  
  }

  GetMapData() {
      for (var i = 0; i < this.mapData.length; i++) {     
                                    
          var fulladdress=this.mapData[i].address+ ", "+ this.mapData[i].city+ ", "+
          this.mapData[i].stateprovince+ ", "+ this.mapData[i].zip;
  
          this.mapData[i].customerfulladdress=fulladdress;
  
          let customerAddress=  fulladdress.replace(/ /g, "+");                
          console.log("calling for "+ i);
          this.getLatLng(customerAddress, i);  
          console.log("ending for "+ i); 
      }

    }
    public requestDataFromMultipleSources(): Observable<any[]> {
      this.httpRequest=[];
      console.log("requestDataFromMultipleSources start")
      for (var i = 0; i < this.mapData.length; i++) {     
                                    
        var fulladdress=this.mapData[i].address+ ", "+ this.mapData[i].city+ ", "+
        this.mapData[i].stateprovince+ ", "+ this.mapData[i].zip;
        this.mapData[i].customerfulladdress=fulladdress;      

        let customerAddress=  fulladdress.replace(/ /g, "+");    
        console.log(customerAddress);         
       var req= this.http
        .get(
          "https://maps.googleapis.com/maps/api/geocode/json?address=" +
          customerAddress +
           "&key=AIzaSyDEJpepas8zDP-C_bqqnZhXI93nxqBJKk4"         
        );
       this.httpRequest.push(req);       
    }   
      console.log("requestDataFromMultipleSources end")
      return forkJoin(this.httpRequest);
    } 
}
