import { Component, OnInit, OnChanges, ViewChild,Input, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Customer } from '../models/customer';
import { Router } from '@angular/router';
import { CustomerService } from '../services/customer.service';
import { MatTableDataSource } from '@angular/material/table';
import { InteractionsService } from '../services/interactions.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Interaction } from '../models/interaction';
import { Observable, throwError, of } from 'rxjs';
import { WorkorderService } from '../services/workorder.service';
import { Workorder, Enrollment, BBCSWorkorder } from '../models/workorder';
import { MatDialog, MatDialogRef, MatDialogConfig, MatDialogClose } from '@angular/material/dialog';
// import { AddinteractionDialogComponent } from '../util/addinteraction-dialog/addinteraction-dialog.component';
import { WorkorderHistoryDialogComponent } from '../util/workorder-history-dialog/workorder-history-dialog.component';
import { WaitDialogComponent } from '../util/wait-dialog/wait-dialog.component';
import { DataTransferService } from '../services/data-transfer.service';
import Swal from 'sweetalert2';
import { Attachments } from '../models/attachments';
import { environment } from 'src/environments/environment';
// import { element } from 'protractor';
import { MeasureClass } from '../workorder-detail/workorder-detail.component';
import { MatAccordion } from '@angular/material/expansion';
// import { MatTab } from '@angular/material/tabs';
import { fromMatPaginator, paginateRows } from '../util/datasource-utils';
import { map } from 'rxjs/operators';
import { trigger, state, transition, style, animate } from '@angular/animations';
// import { stringify } from 'querystring';
import { UtilityService } from '../services/utility.service';
import { EnrollmentService } from '../services/enrollment.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { AddWorkorderComponent } from '../util/add-workorder/add-workorder.component';
import { Subscription, interval } from 'rxjs';
import { MatCheckbox } from '@angular/material/checkbox';
import * as moment from 'moment';
import { MatTabGroup, MatTabChangeEvent } from '@angular/material/tabs';
import { CdkDetailRowDirective } from './cdk-detail-row.directive';
import { ProgramenrollmentComponent } from '../programenrollment/programenrollment.component';
import { EditaddressComponent } from './dialogs/editaddress/editaddress.component';
import { EditCustomerNameComponent } from './dialogs/edit-customer-name/edit-customer-name.component';
import { UsageDialogComponent } from './dialogs/usage-dialog/usage-dialog.component';
export interface Dropdown {
  id: string;
  value: string;
}

@Component({
  selector: 'app-detailedoutput',
  templateUrl: './detailedoutput.component.html',
  styleUrls: ['./detailedoutput.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('void', style({height: '0px', minHeight: '0', visibility: 'hidden'})),
      state('*', style({height: '*', visibility: 'visible'})),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  // animations: [
  //   trigger('detailExpand', [
  //     state('collapsed', style({ height: '0px', minHeight: '0' })),
  //     state('expanded', style({ height: '*' })),
  //     transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  //   ]),
  // ],
})
export class DetailedoutputComponent implements OnInit, OnChanges {
  loadFile:boolean;
  showInteraction = false;
  showWorkorder = false;
  selectedInteraction: any;
  selectedWorkorder: any;
  customer: Customer;
  customers: Customer[];
  interactions: Interaction[];
  workorders: any[];
  displayedColumns: string[] = ['provider', 'accno', 'startdate', 'enddate', 'ratecode', 'externalid', 'premiseid'];
  dataSource = new MatTableDataSource<Customer>();
  displayedColumns1: string[] = ['type', 'name', 'date'];
  contextMenu: MatMenuTrigger;
  displayedColumns2: string[] = ['type', 'startdate', 'enddate', 'user',
    'reason', 'donedate', 'complete', 'marketing', 'notes'];
  /* displayedColumns3: string[] = ['name', 'date', 'status']; */
  expandedElement: Enrollment | Workorder;
  /* displayedColumns3: string[] = ['Id', 'StartDate', 'Status']; */ // for enrollment
  displayedColumns3: string[] = ['Name', 'StatusDate', 'Status'];
  displayedColumns5: string[] = ['Name', 'Description', 'StatusDate', 'Status', 'ExpandCollapse'];// for enrollment
  displayedColumns4: string[] = ['name', 'date'];
  attachmentMessage: string;
  dataSource1 = new MatTableDataSource<Interaction>();
  dataSource2 = new MatTableDataSource<Enrollment>();
  dataSource3 = new MatTableDataSource<Attachments>();

  dataSource4 = new MatTableDataSource<any>();
  workorderHistory: any;
  dialogRef: MatDialogRef<WaitDialogComponent>;
  workorderDisplay: boolean;
  interactionDisplay: boolean;
  editing = false;
  editCust: Customer;
  emailFC: UntypedFormControl;
  disableSelect: boolean = true;
  workorderMessage: any;
  interactionMessage: any;
  loadInteraction: boolean = false;
  loadWorkorder: boolean = false;
  loadAttachments: boolean = true;
  showAttachMessage: boolean = false;
  showWorkorderLoad: boolean = false;
  showInteractionLoad: boolean = false;
  measures: MeasureClass = new MeasureClass();
  measuresSearching: boolean;
  interactionIsComplete: boolean;
  enrollments: EnrollmentClass = new EnrollmentClass();
  enrollmentActions = [];

  showAddInteractions: boolean = false;
  selectedIndex = 0;

  exceptionSearching = true;
  contextMenuPosition = { x: '0px', y: '0px' };

  @ViewChild('detailTabs', { static: true }) detailTabs: MatTabGroup;

/*   @ViewChild('paginator', { static: true }) paginator: MatPaginator;
 */  @ViewChild('wopaginator', { static: true }) wopaginator: MatPaginator;
  @ViewChild('attachpaginator', { static: true }) attachpaginator: MatPaginator;
  @ViewChild('MeasurePaginator', { static: true }) MeasurePaginator: MatPaginator;
  @ViewChild('EnrollPaginator', { static: true }) EnrollPaginator: MatPaginator;
  @ViewChild('accordion', { static: true }) accordion: MatAccordion;
  @ViewChild('enrollAccordion', { static: true }) enrollAccordion: MatAccordion;
  displayedRows$: Observable<MeasureClass[]>;
  totalRows$: Observable<number>;
  displayedEnrollmentRows$: Observable<EnrollmentClass[]>;
  totalEnrollmentRows$: Observable<number>;


  // interactions code
  ifSave:boolean ;
  types: Dropdown[] = [];
  marketing: Dropdown[] = [];
  whys: Dropdown[] = [];
  reasons: Dropdown[] = [];
  selectedTypeCode: any;
  selectedReason: any;
  selectedWhy: any;
  selectedMarketing: any;
  isCompleteChecked: boolean;
  notesInput: any;
  timerMinutes: any;
  timerSeconds: any;
  customerId: any;
  officeId: any;
  programSponsorId: any;
  savedInteraction: Interaction = new Interaction();
  startTime = new Date();
  timerInterval: Subscription;
  timersource = interval(1000);
  timer = '0:00';
  statusMessage: string;
  checkIfComplete: boolean = false;
  className: any;
  adding = false;

  @Input() singleChildRowDetail: boolean;

  @ViewChild('completeCheckbox', { static: true }) private completeCheckbox: MatCheckbox;

  constructor(
    public customerService: CustomerService,
    private router: Router,
    private interactionService: InteractionsService,
    private workorderService: WorkorderService,
    private dialog: MatDialog,
    private dataTransfer: DataTransferService, 
    private utilityService: UtilityService,
    private enrollmentService:EnrollmentService,
  ) {
    this.customer = this.customerService.currentCustomer;
    this.customers = [this.customer];
    this.dataSource.data = this.customers;
    this.className = localStorage.getItem("themeName");
  }

  ngOnChanges() {
    this.dataTransfer.myMethod(true);
  }

  ngOnInit() {
    this.dataTransfer.myMethod(true);
    this.getInteractions(false);
    this.getWorkorders();
    //this.getOriginalValues();
    if (this.customer === undefined) {
      this.router.navigate(['/search']);
    }
    this.editCust = new Customer();
    this.editCust.DoNotCall = this.customer.DoNotCall;
    this.editCust.DoNotEmail = this.customer.DoNotEmail;
    this.editCust.DoNotMail = this.customer.DoNotMail;
    this.emailFC = new UntypedFormControl(this.customer.Email, [Validators.email]);
    this.onLoad();
  }

  private openedRow: CdkDetailRowDirective
  onToggleChange(cdkDetailRow: CdkDetailRowDirective,row) : void {
    if (this.singleChildRowDetail && this.openedRow && this.openedRow.expended) {
      this.openedRow.toggle();      
    }

    if(!row.close)
    {
        row.close = true;
    } else
    { 
        row.close = false;
    }
    this.openedRow = cdkDetailRow.expended ? cdkDetailRow : undefined;
  }

  addAtWorkorderLevel(enrollmentId){
    let className = localStorage.getItem("themeName");
    const dialogRef = this.dialog.open(AddWorkorderComponent,{
      data : enrollmentId,panelClass:className
    })
  }

  doSave() {
    let className = localStorage.getItem("themeName");
    this.dialogRef = this.dialog.open(WaitDialogComponent,
      {
        data:
        {
          caption: 'Please Wait! Updating Customer Record'
        },panelClass:className
      });

    this.editCust.Email = this.emailFC.value;
    this.customerService.updateCustomer(this.editCust).subscribe(() => this.finishSave(), err => {
      Swal.fire({
        text: err,
        icon: 'error',
        confirmButtonText: 'OK',
      });
      this.editing = false;
      this.dialogRef.close();
    });
  }

  private finishSave() {
    this.updateOriginalValuesAfterSave();
    this.editing = false;
    this.dialogRef.close();
  }

  private getOriginalValues() {
    this.editCust.setUpFromWS(this.customer);
  }

  private updateOriginalValuesAfterSave() {
    this.customer.DoNotCall = this.editCust.DoNotCall;
    this.customer.DoNotEmail = this.editCust.DoNotEmail;
    this.customer.DoNotMail = this.editCust.DoNotMail;
    this.customer.PrimaryPhone = this.editCust.PrimaryPhone;
    this.customer.AlternatePhone = this.editCust.AlternatePhone;
    this.customer.Email = this.editCust.Email;
  }

  public startEditing() {
    this.getOriginalValues();
    this.editing = true;
  }
  editRow() {
    this.editing = true;
  }
  public startEditingAddress(){
    let className = localStorage.getItem("themeName");
    const dialogRef = this.dialog.open(EditaddressComponent, {data: { editMode: true},panelClass:className,
      autoFocus: false,
      restoreFocus: false});

    dialogRef.afterClosed().subscribe((res) => {

    });
  }
  public startEditingName(){
    let className = localStorage.getItem("themeName");
    const dialogRef = this.dialog.open(EditCustomerNameComponent, {data: { editMode: true},panelClass:className,
      autoFocus: false,
      restoreFocus: false});

    dialogRef.afterClosed().subscribe((res) => {

    });
  }
  public cancelEditing() {
    this.editing = false;
  }

  addNewInteraction() {
    let className = localStorage.getItem("themeName");
    this.showAddInteractions = true;
    this.detailTabs.selectedIndex = 3;
    this.onLoad();

    // const dialogRef = this.dialog.open(AddinteractionDialogComponent, { panelClass: className });
    // dialogRef.afterClosed().subscribe(result => {
    //   this.getInteractions();
    // });
  }


  getInteractions(val) {
    this.loadInteraction = true;
    this.interactionService.getInteractionsByCustomerId(this.customerService.currentCustomer.Id)
      .subscribe((data) => {
        this.setInteractions(data, val)
      }, err => {
        this.loadInteraction = false;
        this.interactionMessage = err;
      }

      );
  }

  getWorkorders() {
    if (this.customerService.isBbcs()) {
      this.loadWorkorder = true;

      this.workorderService.getEnrollmentAndWorkorderByPersonCompanyId(this.customerService.currentCustomer.PersonCompanyId)
        .subscribe((data) => {
          this.setBbcsEnrollmentsAndWorkorder(data);
        }, err => {
          this.workorderMessage = err;
          this.loadWorkorder = false;
        });
    } else {
      this.loadWorkorder = true;
      /*console.log(this.customerService.currentCustomer.Id);*/
      this.workorderService.getWorkorderByCustomerId(this.customerService.currentCustomer.Id)
        .subscribe((data) => {
          this.setWorkorder(data);
        }, err => {
          this.workorderMessage = err;
          this.loadWorkorder = false;
        });
    }
  }

  getAttachments(selectedWorkorder) {

    this.workorderService.getWorkorderAttachments(selectedWorkorder)
      .subscribe((data) => {
        this.setAttachments(data);
      }, err => {
        this.showAttachMessage = true;
        this.attachmentMessage = 'No attachments found';
        this.loadAttachments = false;
      });

  }



  setInteractions(data, val) {
    
    this.loadInteraction = false;
    if (data.Interactions != null && data.Interactions.length > 0) {
      this.interactionDisplay = true;
      this.interactions = data.Interactions;
     // this.dataSource1.paginator = this.paginator;
      this.dataSource1.data = data.Interactions;
      if(val)this.handleInteractionClick( data.Interactions[0])
    }
    else {
      this.interactionDisplay = false;
      this.interactionMessage = 'No interactions for the current user';
    }

  }

  setAttachments(data) {

    this.loadAttachments = false;
    if (data.length === 0 || data === null) {
      this.showAttachMessage = true;
      this.attachmentMessage = 'No attachments found';
    } else {
      this.showAttachMessage = false;
      this.dataSource3.paginator = this.attachpaginator;
      this.dataSource3.data = data;
    }
  }

  setWorkorder(data) {

    this.loadWorkorder = false;

    if (data.WorkordersList != null && data.WorkordersList.length > 0) {
      this.workorderDisplay = true;
      this.workorders = data.WorkordersList;
      this.dataSource2.paginator = this.wopaginator;
      this.dataSource2.data = data.WorkordersList;
    }
    else {

      this.workorderDisplay = false;
      this.workorderMessage = 'No workorders for the current user';

    }

  }

  setBbcsEnrollmentsAndWorkorder(data) {
  
    this.loadWorkorder = false;


    if (data.EnrollmentActions != null && data.EnrollmentActions.length > 0) {
      this.enrollmentActions = data.EnrollmentActions;
    }

    if (data.Enrollments != null && data.Enrollments.length > 0) {
      this.workorderDisplay = true;

      // Sorting Enrollments by the latest Enrollment ID before displaying it in the UI
      data.Enrollments.sort((a, b) => {
        const firstEnrollment = a.EnrollmentId.toUpperCase();
        const secondEnrollment = b.EnrollmentId.toUpperCase();

        if (firstEnrollment < secondEnrollment) {
          return 1;
        }
        if (firstEnrollment > secondEnrollment) {
          return -1;          
        }

        return 0;        
      });

      // Sorting Enrollments' Workorders by the latest Workorder ID before displaying it in the UI
      data.Enrollments.forEach(enrollment => {
        enrollment.Workorders.sort((a, b) => {
          const firstWorkOrder = a.Workorder.toUpperCase();
          const secondWorkOrder = b.Workorder.toUpperCase();

          if (firstWorkOrder < secondWorkOrder) {
            return 1;
          }
          if (firstWorkOrder > secondWorkOrder) {
            return -1;
          }

          return 0;
        });        
      });

      this.enrollments.EnrollmentArray = data.Enrollments;

      
      const pageEnrollEvents$: Observable<PageEvent> = fromMatPaginator(this.EnrollPaginator);

      const enrollmentRows$ = of(this.enrollments.EnrollmentArray);

      this.totalEnrollmentRows$ = enrollmentRows$.pipe(map(rows => rows.length));
      this.displayedEnrollmentRows$ = enrollmentRows$.pipe(paginateRows(pageEnrollEvents$))


    } else {

      this.workorderDisplay = false;
      this.workorderMessage = 'No workorders for the current user';
    }


  }

  handleInteractionClick(data) {
   
    this.showInteraction = true;
    this.showWorkorder = false;
    this.showInteractionLoad = true;

    if (data != null) {
      if (data.CallDurationSeconds == null) {
        data.EndDate = data.StartDate;
      }
      else {
        let start = new Date(data.StartDate);
        let end = start.setSeconds(start.getSeconds() + data.CallDurationSeconds);
        data.EndDate = new Date(end);
      }

      if (data.StartDate == null) {
        data.StartDate = data.CreatedDate;
        data.EndDate = data.CompletedDateTime;
      }

      if (data.Status == "Completed") {
        this.interactionIsComplete = true;
      }
      else {
        this.interactionIsComplete = false;
      }
    }

    this.selectedInteraction = data;



    this.showInteractionLoad = false;
  }

  handleWorkorderClick(data) {
    this.showWorkorder = true;
    this.showInteraction = false;
    this.showWorkorderLoad = true;

    this.workorderService.getWorkorderDetailsByWorkorderId(data.Id, this.customerService.currentCustomer.Id)
      .subscribe((res) => {
        this.setWorkorderDetails(res);
        console.log(res);
      }
        , err => {
          this.handleError(err);
          this.showWorkorderLoad = false;
        });
  }

  handleEnrollmentWorkorderClick(data, enrollmentId) {
  
    const body = { Id: data.Workorder };
    this.workorderService.currentWorkorderID = data.Workorder;
    this.enrollmentService.currentEnrollmentID = enrollmentId;
    this.router.navigate(['/workorderGetSave',data.Group,data.SubGroup,data.Workorder,data?.SubGroup1 ?? "ALL"]);
    /* this.handleWorkorderClick(body); */
  }

  handleAttachmentClick(data) {
    let fileName = data.FileName.toLowerCase();
    let fileExtension = fileName.substr(fileName.lastIndexOf('.') + 1);
    let packageID = data.PackageId;
    let fileID = data.Id;
    if (environment.imageFileTypes.includes(fileExtension)) {
      this.workorderService.getImageAttachmentDetail(packageID, fileID, fileExtension, fileName);

    }
    else if (environment.pdfType.includes(fileExtension)) {
      this.workorderService.getPdfAttachmentDetail(packageID, fileID, fileName);
    }
    else if (environment.textTypes.includes(fileExtension)) {
      this.workorderService.getDocAttachmentDetail(packageID, fileID, fileName);
    }
    else if (environment.docTypes.includes(fileExtension)) {
      this.workorderService.getDocAttachmentDetail(packageID, fileID, fileName);
    }
    else if (environment.excelTypes.includes(fileExtension)) {
      if (fileExtension.toLowerCase() === ("csv").toLowerCase()) {
        this.workorderService.getCsvAttachmentDetail(packageID, fileID, fileName)
      }
      else {
        this.workorderService.getExcelAttachmentDetail(packageID, fileID, fileName);
      }

    }
    else {
      Swal.fire({
        text: "File Type Not Supported",
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }




  }

  setWorkorderDetails(data) {

    this.showWorkorderLoad = false;
    this.selectedWorkorder = data;

    this.getWorkorderMeasures(data.WorkorderId, data.WorkorderName);
    // this.getWorkorderExceptions(data.WorkorderId);
    this.getAttachments(data);
  }

  getWorkorderExceptions(workorderId) {
      this.workorderService.getWorkorderExceptions(workorderId).subscribe(data => {console.log("DATA: " + JSON.stringify(data, null, 2));});
  }

  getWorkorderMeasures(workorderId, workorderName) {


    this.measuresSearching = true;
    this.measures.MeasuresArray = [];
    this.workorderService.getWorkorderMeasures(workorderId, workorderName).subscribe(res => {

      if (Array.isArray(res) && res.length > 0) {
        res.forEach(element => {

          this.measures.MeasuresArray.push(element);
        });
        const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.MeasurePaginator);

        const rows$ = of(this.measures.MeasuresArray);

        this.totalRows$ = rows$.pipe(map(rows => rows.length));
        this.displayedRows$ = rows$.pipe(paginateRows(pageEvents$))
      }
      this.measuresSearching = false;
    }, err => {

      this.measuresSearching = false;
    });

  }

  closeAll() {
    this.accordion.closeAll();
  }

  showWorkorderHistory(data) {
    this.workorderService.getWorkorderHistory(data).subscribe(res => {
      try {
        let className = localStorage.getItem("themeName");
        const dialogRef = this.dialog.open(WorkorderHistoryDialogComponent, {
          data: {
            dataKey: res
          }
          , panelClass: className
        });
      }
      catch{
        this.handleError('Server Side Error')
      }

    }, err => {
      this.handleError(err);
    })


    return false;
  }

  handleError(mesg: string) {
    Swal.fire({
      title: 'Error',
      text: mesg,
      icon: 'error',
      confirmButtonText: 'OK',
    })
  }

  handleEnrollment(enaction) {
    
    let className = localStorage.getItem("themeName");
    if (enaction.Id === 'EnergyAdvisor') {
      this.router.navigate(['/eligibility']);
      
    } else  if (enaction.Id === 'EligQual') {
      this.loadFile = true;
        const dialogRef = this.dialog.open(ProgramenrollmentComponent,{
          closeOnNavigation: true,      
          panelClass:className , maxHeight: '60vh'});
          this.router.events
          .subscribe(() => {
          dialogRef.close();
           });
    } else if (enaction.Id === 'DRActions'){
      this.router.navigate(['/dractions']);
    }

    else 
    {
      const dialogRef = this.dialog.open(AddWorkorderComponent,{panelClass:className})
     /*  Swal.fire({
        title: 'NOT IMPLEMENTED',
        text: 'Add Workorder is not implemented.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok'
      }); */
    }
  }

  onLoad() {
    /*  this.ifSave=true; */
     this.whys = [];
     this.reasons = [];
     this.types = [];
     this.populateTypeDropdown();
     this.populateMarketingDropdown();
     this.selectedMarketing = "";
     this.notesInput = "";
     this.isCompleteChecked = true;
     if (!this.isCompleteChecked) {
         this.checkIfComplete = false;
     }

     this.checkIfComplete = false;
     this.customerId = this.customerService.currentCustomer.Id;
     this.programSponsorId = this.utilityService.currentUtility.ProgramsponsorId;
     this.officeId = this.utilityService.currentUtility.OfficeId;

     this.startTime = new Date();
     this.timerInterval = this.timersource.subscribe(() => this.interactionTimer());
     this.adding = false;
     //this.completeCheckbox.checked = true;
     localStorage.setItem('personcompanyid', this.customerService.currentCustomer.PersonCompanyId)
 }


 onCompleteClick(event) {
     if (event.checked) {
         this.isCompleteChecked = true;
         this.checkIfComplete = true;
         this.selectedReason = "";
         this.completeCheckbox.checked = true;
     }
     else{
         this.isCompleteChecked = false;
         this.checkIfComplete = false;

         if (this.selectedTypeCode != null || this.selectedTypeCode != undefined || this.selectedTypeCode != "") {
             this.populateReasonDropdown();
         }
         this.completeCheckbox.checked = false;
     }
 }

//  ngOnDestroy() {
//      this.timerInterval.unsubscribe();
//  }

 private interactionTimer() {
     this.timerMinutes = 0;
     this.timerSeconds = 0;
     const elapsed = (new Date().getTime() - this.startTime.getTime()) / 1000;  // secs
     const sec = elapsed % 60;
     const min = Math.floor(elapsed / 60);
     this.timerMinutes = min;
     this.timerSeconds = sec;
     this.timer = this.timerMinutes + ':' + (this.timerSeconds < 10 ? '0' : '') + this.timerSeconds.toFixed(0);
 }


 private populateTypeDropdown() {
     this.interactionService.getInteractionType().subscribe(res => {
         if (res.InteractionTypes != null && Array.isArray(res.InteractionTypes)) {
             res.InteractionTypes.forEach(element => {
                 this.types.push({
                     id: element['Name'], value: element['Description']
                 });
             });

             this.selectedTypeCode = this.types[0];
             this.changeType(this.selectedTypeCode);

         }
     }, err => {
         Swal.fire({
             text: err,
             icon: 'error',
             confirmButtonText: 'OK',
         })
     });
 }

 checkGoodDropForm(): boolean {
     if (this.selectedTypeCode === undefined
         //|| this.selectedTypeCode.value === 'General Query'
         || this.selectedWhy === undefined
         || (!this.isCompleteChecked && (this.selectedReason === undefined || this.selectedReason === ''))) {
         return true;
     } else {
         return false;
     }
 }

 addInteraction() {
     this.adding = true;

     try {
         // start date and end date same
         const doneDate = moment();
         const formattedDoneDate = doneDate.toJSON();

         const endDate = moment();
         const formattedEndDate = endDate.toJSON();

         const startDate = doneDate;
         startDate.subtract(this.timerMinutes, 'minutes');
         startDate.subtract(this.timerSeconds, 'seconds')
         const formattedStartDate = startDate.toJSON();

         this.savedInteraction.CustomerId = this.customerId === null ? '' : this.customerId;
         if (this.selectedMarketing != undefined) {
             this.savedInteraction.MarketingCode = this.selectedMarketing.id == null ? '' : this.selectedMarketing.id;
             this.savedInteraction.MarketingDescription = this.selectedMarketing.value == null ? '' : this.selectedMarketing.value;
         }
         this.savedInteraction.Notes = this.notesInput === null ? '' : this.notesInput;
         if (this.selectedReason != undefined) {
             this.savedInteraction.ReasonCode = this.selectedReason.id === null ? '' : this.selectedReason.id;
             this.savedInteraction.ReasonDescription = this.selectedReason.value === null ? '' : this.selectedReason.value;
         }
         if (this.selectedWhy != undefined) {
             this.savedInteraction.WhyCode = this.selectedWhy.id === null ? '' : this.selectedWhy.id;
             this.savedInteraction.WhyDescription = this.selectedWhy.value === null ? '' : this.selectedWhy.value;
         }
         this.savedInteraction.TypeCode = this.selectedTypeCode.id === null ? '' : this.selectedTypeCode.id;
         this.savedInteraction.TypeDescription = this.selectedTypeCode.value === null ? '' : this.selectedTypeCode.value;
         this.savedInteraction.IsComplete = this.isCompleteChecked === false ? false : this.isCompleteChecked;
         if(this.utilityService.currentProgramSponsor.SourceId === 'BBCS'){
             this.savedInteraction.User = this.utilityService.currentUtility.BBCSUserId === null ? '' :  this.utilityService.currentUtility.BBCSUserId;
         }
         else
         {
             this.savedInteraction.User = localStorage.getItem('loggedInUserName') === null ? '' : localStorage.getItem('loggedInUserName');
         }
         
       
         this.savedInteraction.StartDate = (new Date(formattedStartDate)).toJSON();
         this.savedInteraction.EndDate = (new Date(formattedEndDate)).toJSON();
         this.savedInteraction.DoneDate = (new Date(formattedDoneDate)).toJSON();
         //this.savedInteraction.WorkorderId = (this.workorderService.currentWorkorderID == null || this.workorderService.currentWorkorderID == undefined) ? null : this.workorderService.currentWorkorderID;
         this.savedInteraction.EnrollmentId = (this.enrollmentService.currentEnrollmentID == null || this.enrollmentService.currentEnrollmentID == undefined) ? null : this.enrollmentService.currentEnrollmentID;

         this.savedInteraction.ProgramSponsorId = this.programSponsorId === null ? '' : this.programSponsorId;
         if (this.savedInteraction != null) {
             this.ifSave = false;
             this.interactionService.addInteractions(this.savedInteraction).subscribe(res => {
                 try {
                  this.getInteractions(true);
                     this.ifSave = true;
                     this.showAddInteractions = false;
                     this.detailTabs.selectedIndex = 0;
                     Swal.fire({
                         text: 'Saved Successfully!',
                         icon: 'success',
                         confirmButtonText: 'OK',
                     }).then((result) => {
                         this.dialog.closeAll();
                         if (result.value) {
                             this.onLoad();
                         }
                     });

                 }
                 catch{
                     Swal.fire({
                         text: 'Server side error! Please try again',
                         icon: 'error',
                         confirmButtonText: 'OK',
                     }).then((result) => {
                         this.dialog.closeAll();
                         if (result.value) {
                             this.onLoad();
                         }
                     });

                 }
             }, err => {
                 this.dialog.closeAll();
                 Swal.fire({
                     text: err,
                     icon: 'error',
                     confirmButtonText: 'OK',
                 });

             });


         }
     }
     catch{
         Swal.fire({
             text: 'Please check the entered data',
             icon: 'error',
             confirmButtonText: 'OK',
         });
     }


 }

 closeInteractions() {
  this.showAddInteractions = false;
  this.detailTabs.selectedIndex = 0;
 }


 changeType(value) {
     if (value != null && value !== "") {
         this.whys = [];
         this.selectedWhy = undefined;
         this.reasons = [];
         this.populateWhyDropdown();
         if (this.checkIfComplete === false) {
             this.populateReasonDropdown();
         }
     }
 }

 private populateWhyDropdown() {
  this.whys = [];
     this.interactionService.getInteractionWhys(this.selectedTypeCode.id).subscribe(res => {
         if (res.InteractionWhys != null && Array.isArray(res.InteractionWhys)) {
             res.InteractionWhys.forEach(element => {
                 this.whys.push({
                     id: element['Name'], value: element['Description']
                 })
             });

         }
     }, err => {
         Swal.fire({
             text: err,
             icon: 'error',
             confirmButtonText: 'OK',
         })
     });
 }

 private populateReasonDropdown() {
  this.reasons = [];
     this.interactionService.getInteractionReasons(this.selectedTypeCode.id).subscribe(res => {
         if (res.InteractionReasons != null && Array.isArray(res.InteractionReasons)) {
             res.InteractionReasons.forEach(element => {
                 this.reasons.push({
                     id: element['Name'], value: element['Description']
                 })
             });

         }
     }, err => {
         Swal.fire({
             text: err,
             icon: 'error',
             confirmButtonText: 'OK',
         })
     });
 }

 private populateMarketingDropdown() {
  this.marketing = [];
     this.interactionService.getInteractionMarketing().subscribe(res => {
         if (res.InteractionMarketing != null && Array.isArray(res.InteractionMarketing)) {
             res.InteractionMarketing.forEach(element => {
                 this.marketing.push({
                     id: element['Name'], value: element['Description']
                 })
             });

         }
     }, err => {
         Swal.fire({
             text: err,
             icon: 'error',
             confirmButtonText: 'OK',
         });
     });
 }

 showUsageDialog (event: Event) {
  event.stopPropagation();

  this.dialog.open(UsageDialogComponent, 
    {disableClose: true, width: '73%'});
 }

}

export class EnrollmentClass {
  EnrollmentArray: any = [];
  WorkorderArray: any = [];
}