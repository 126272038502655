<div *ngIf="measuresLength != 0 && (response.Lineitemmasterid == selectedMeasureType) && myIndex == currentIndex"
    (click)="clearFilterText()">
    <ng-container *ngTemplateOutlet="tmpl;">
    </ng-container>
</div>

<ng-container *ngTemplateOutlet="tmplgrid;">
</ng-container>

<div *ngIf="response?.SectionInfo?.length > 0" class="sectionLength">
    <mat-accordion #Woaccordion="matAccordion" displayMode="flat" multi="true" [togglePosition]="'after'">

        <mat-expansion-panel *ngFor="let item of response.SectionInfo; let i=index" [expanded]="true">
            <mat-expansion-panel-header class="mat-header-work-qa">
                <mat-panel-title class="mat-header-color panel-title whitesmoke">
                    {{ item.SectionName | titlecase }}
                </mat-panel-title>
                <mat-panel-description>
                    <button mat-button *ngIf="item.ShowAddMeasure == 1" mat-button style="color: #0000008A;"
                        [disabled]="!workorderResponse.SaveEnabled || item.ShowAddMeasure == 0" (click)="$event.stopPropagation();
                                          openAddMeasure(response?.ShowAddMeasure, i, item.Lineitemmasterid);">
                        <mat-icon matTooltip="Add {{ item.SectionName  | titlecase }}">add</mat-icon>
                    </button>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div *ngIf="measuresLength != 0 && selectedSection == item?.Lineitemmasterid && myIndex == currentIndex">
                <ng-container *ngTemplateOutlet="tmpl;">
                </ng-container>
            </div>

            <table *ngIf="response?.SectionInfo[i]?.['SUBMEASUREGRID']?.length > 0" class="mat-elevation-z8 table">
                <caption class="mat-header-color caption whitesmoke">
                    <th>
                        {{ response?.SectionInfo[i]?.SectionName}} MEASURE INFORMATION
                    </th>
                </caption>
                <thead>
                    <tr>
                        <ng-container
                            *ngFor="let head of response?.SectionInfo[i]?.['SUBMEASUREGRID'][0] |  keyvalue : originalOrder">
                            <th class="mat-header-color table-header whitesmoke"
                                *ngIf="head.key != 'Record Id' && head.key !='Measure Id'">{{head.key}}</th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let sub of response?.SectionInfo[i]?.['SUBMEASUREGRID']" class="align">
                        <ng-container *ngFor="let list of sub | keyvalue : originalOrder; let i=index">
                            <td class="table-data" *ngIf="list.key!= 'Record Id' && list.key !='Measure Id'">
                                <span *ngIf="sub[list?.key] != 'Edit,Delete'"> {{sub[list.key]}}</span>
                                <span *ngIf="sub[list?.key] == 'Edit,Delete'">
                                    <mat-icon (click)="updateMeasure(true, sub['Record Id'], item?.Lineitemmasterid);"
                                        class="cursor">edit</mat-icon> &nbsp;&nbsp;&nbsp;
                                    <mat-icon (click)="deleteMeasure(sub['Record Id'])" class="cursor">delete</mat-icon>
                                </span>
                            </td>
                        </ng-container>
                    </tr>

                </tbody>
            </table>

        </mat-expansion-panel>
    </mat-accordion>
</div>

<ng-template #tmpl>
    <div *ngIf="measuresLength != 0">
        <mat-card class="height-width">
            <mat-grid-list cols="12" rowHeight="35px" class="gridItems">
                <ng-container *ngFor="let subResponse of measureResponse?.DetailedInfo[0]?.Attributes">
                    <mat-grid-tile class="work-get-tile"
                        [ngStyle]="{'text-align': subResponse.DataType=='RadioList' ? 'center' : 'inherit' }"
                        colspan="{{subResponse.DataType=='RadioList' ? '12' : '4'}}" *ngIf="subResponse.Visible != '0'">
                        <mat-label *ngIf="subResponse.DataType=='RadioList'" matTooltip="{{subResponse.Name}}"
                            class="label">{{subResponse.Name}}
                            <span class="symbolStyle" *ngIf="subResponse.IsMandatory == 1">*</span>
                        </mat-label>
                        <mat-label
                            *ngIf="subResponse.DataType!='RadioList' && subResponse.Name != 'Cooling Capacity(Tons)' && subResponse.Name != 'Cooling Capacity Per Unit' &&
                            (subResponse.DataType!='RadioList' && subResponse.AttributeId != 'existmodel_' + officeId) &&
                            (subResponse.DataType!='RadioList' && subResponse.AttributeId != 'existmanuf_' + officeId) &&
                            (subResponse.DataType!='RadioList' && subResponse.AttributeId != 'model_id_' + officeId)"
                            matTooltip="{{subResponse.Name}}" class="label">{{subResponse.Name}}
                            <span class="symbolStyle" *ngIf="subResponse.IsMandatory == 1">*</span>
                        </mat-label>
                        <mat-label
                            *ngIf="subResponse.DataType!='RadioList' && (subResponse.Name == 'Cooling Capacity(Tons)')"
                            matTooltip="Calculation <=20: $90 / >=21: $75 per ton" class="label">{{subResponse.Name}}
                            <span class="symbolStyle" *ngIf="subResponse.IsMandatory == 1">*</span>
                        </mat-label>
                        <mat-label
                            *ngIf="subResponse.DataType!='RadioList' && (subResponse.Name == 'Cooling Capacity Per Unit')"
                            matTooltip="Calculation >=12: $50 / <12: $60 per ton" class="label">{{subResponse.Name}}
                            <span class="symbolStyle" *ngIf="subResponse.IsMandatory == 1">*</span>
                        </mat-label>
                        <mat-label
                            *ngIf="subResponse.DataType!='RadioList' && subResponse.AttributeId == 'existmodel_' + officeId"
                            class="label">{{subResponse.Name}}
                            <span class="symbolStyle" *ngIf="subResponse.IsMandatory == 1">*</span>
                        </mat-label>
                        <mat-label
                            *ngIf="subResponse.DataType!='RadioList' && subResponse.AttributeId == 'existmanuf_' + officeId"
                            class="label">{{subResponse.Name}}
                            <span class="symbolStyle" *ngIf="subResponse.IsMandatory == 1">*</span>
                        </mat-label>
                        <mat-label
                            *ngIf="subResponse.DataType!='RadioList' && subResponse.AttributeId == 'model_id_' + officeId"
                            class="label">{{subResponse.Name}}
                            <span class="symbolStyle" *ngIf="subResponse.IsMandatory == 1">*</span>
                        </mat-label>

                        <mat-form-field appearance="outline" class="work-get-form font form-field"
                            *ngIf="subResponse.DataType=='List' 
                                            && subResponse.Name != 'New Fixture Type' && subResponse.Name != 'Existing Fixture Type' && subResponse.Name != 'Reason for Work Done'
                                            && (subResponse.AttributeId != 'model_id_' + officeId)
                                            && (subResponse.AttributeId != 'replmanuf_' + officeId)
                                            && (subResponse.AttributeId != 'test_eq_serialno_' + officeId)
                                            && (subResponse.AttributeId != 'replmanufhvac_' + officeId)
                                            && (subResponse.AttributeId != 'replmodelhvac_' + officeId)">
                            <input [name]="subResponse.AttributeId" type="hidden" />
                            <mat-select [(ngModel)]="subResponse.SelectedValue"
                                (selectionChange)="updateMeasure(subResponse.Name =='Measure')"
                                (ngInit)="oninitialize(subResponse.SelectedValue,subResponse.AttributeId,subResponse.IsEditable)"
                                (ngModelChange)="dataChanged_customize($event,i,d,subResponse.AttributeId);"
                                [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                                [name]="subResponse.AttributeId">
                                <mat-option *ngFor="let optionValues of subResponse.Values" [value]="optionValues.Id"
                                    style="font-size: 11px;">
                                    {{optionValues.Value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div appearance="outline" class="width-20 font" *ngIf="subResponse.DataType=='RadioList'">
                            <mat-radio-group aria-labelledby="work-get-radio-group-label" class="work-get-radio-group"
                                [(ngModel)]="subResponse.SelectedValue" (ngModelChange)="dataChanged($event,i,d)"
                                [name]="subResponse.AttributeId">
                                <mat-radio-button class="work-get-radio-button" *ngFor="let val of subResponse.Values"
                                    [value]="val.Id" [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                                    [name]="subResponse.AttributeId">
                                    {{val.Value}}</mat-radio-button>
                            </mat-radio-group>
                        </div>

                        <div appearance="outline" class="work-get-form font form-field" *ngIf="subResponse.DataType=='Radio'">
                            <mat-radio-group style="display: grid;" aria-labelledby="work-get-radio-group-label" class="work-get-radio-group"
                                [(ngModel)]="subResponse.SelectedValue" (ngModelChange)="dataChanged($event,i,d)"
                                [name]="subResponse.AttributeId">
                                <mat-radio-button class="work-get-radio-button" *ngFor="let val of subResponse.Values" [value]="val.Id"
                                    [disabled]="(subResponse.IsEditable == 'Yes'? false : true)" [name]="subResponse.AttributeId">
                                    {{val.Value}}</mat-radio-button>
                            </mat-radio-group>
                        </div>

                        <mat-form-field appearance="outline" class="form-field font"
                            *ngIf="subResponse.DataType=='String'">
                            <ng-container *ngIf="subResponse.Size <=50">
                                <input autocomplete="off" appInputlengthFilter matInput
                                    [(ngModel)]="subResponse.SelectedValue" (ngModelChange)="dataChanged($event,i,d)"
                                    [disabled]="(subResponse.IsEditable == 'Yes' ||
                                                    (psegdisable == 'Yes' &&
                                                      response.SectionName == 'Owner Information' )? false : true)"
                                    [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''"
                                    [maxlength]="subResponse.Size" [name]="subResponse.AttributeId">
                            </ng-container>
                            <ng-container *ngIf="subResponse.Size >50">
                                <textarea appInputlengthFilter [(ngModel)]="subResponse.SelectedValue" rows="1"
                                    cols="15" matInput [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                                    [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''"
                                    [maxlength]="subResponse.Size" (ngModelChange)="dataChanged($event,i,d)"
                                    [name]="subResponse.AttributeId"></textarea>
                            </ng-container>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="form-field font"
                            *ngIf="(subResponse.DataType=='String' && subResponse.AttributeId == 'existmodel_' + officeId) &&
                                                          (subResponse.DataType=='String' && subResponse.AttributeId == 'existmanuf' + officeId)">
                            <ng-container *ngIf="subResponse.Size <=50">
                                <input autocomplete="off" appInputlengthFilter matInput [(ngModel)]="subResponse.SelectedValue"
                                    (ngModelChange)="dataChanged($event,i,d)" [disabled]="(subResponse.IsEditable == 'Yes' ||
                                                                            (psegdisable == 'Yes' &&
                                                                              response.SectionName == 'Owner Information' )? false : true)"
                                    [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''" [maxlength]="subResponse.Size"
                                    [name]="subResponse.AttributeId">
                            </ng-container>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="form-field font"
                            *ngIf="subResponse.DataType=='Integer'">
                            <input appInputlengthFilter [(ngModel)]="subResponse.SelectedValue" matInput appNumeric
                                (ngModelChange)="dataChanged($event,i,d)" [min]="subResponse.Min" minlength="0"
                                [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                                [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''"
                                type="number" [max]="subResponse.Max" [name]="subResponse.AttributeId"
                                [maxlength]="subResponse.Size">
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="form-field font"
                            *ngIf="subResponse.DataType=='Decimal' && subResponse.Name != 'Watts/sq ft for New Constr.,'">
                            <input appInputlengthFilter [(ngModel)]="subResponse.SelectedValue" matInput
                                appDecimalnumber [min]="subResponse.Min" (ngModelChange)="dataChanged($event,i,d);"
                                minlength="0" [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                                [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''"
                                type="number" [max]="subResponse.Max" [name]="subResponse.AttributeId"
                                [maxlength]="subResponse.Size">
                        </mat-form-field>

                        <mat-checkbox class="width-50" *ngIf="subResponse.DataType=='Bit'"
                            [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                            [(ngModel)]="subResponse.SelectedValue" (ngModelChange)="dataChanged($event,i,d)"
                            [name]="subResponse.AttributeId">
                        </mat-checkbox>

                        <!-- New Fixture type code -->
                        <mat-form-field appearance="outline" class="work-get-form form-field font"
                            *ngIf="subResponse.DataType=='List' && subResponse.Name == 'New Fixture Type'">
                            <input [name]="subResponse.AttributeId" type="hidden" />
                            <mat-select class="dropdown" [(ngModel)]="subResponse.SelectedValue"
                                (selectionChange)="changeFixture($event)"
                                (ngInit)="oninitialize(subResponse.SelectedValue,subResponse.AttributeId,subResponse.IsEditable)"
                                (ngModelChange)="dataChanged_customize($event,i,d,subResponse.AttributeId);"
                                [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                                [name]="subResponse.AttributeId">
                                <mat-icon class="searchIcon" aria-hidden="false" aria-label="Example search icon"
                                    fontIcon="search"></mat-icon>
                                <input class="searchInput" matInput [(ngModel)]="filteredText" placeholder="Search..."
                                    appNumeric>
                                <mat-option *ngFor="let optionValues of filterNewFixture" [value]="optionValues.ItemNo"
                                    style="font-size: 11px; ">
                                    {{optionValues.Value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="work-get-form form-field font"
                            *ngIf="subResponse.DataType=='List' && subResponse.Name == 'Existing Fixture Type'">
                            <input [name]="subResponse.AttributeId" type="hidden" />
                            <mat-select [(ngModel)]="subResponse.SelectedValue"
                                (ngInit)="oninitialize(subResponse.SelectedValue,subResponse.AttributeId,subResponse.IsEditable)"
                                (ngModelChange)="dataChanged_customize($event,i,d,subResponse.AttributeId);"
                                [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                                [name]="subResponse.AttributeId">
                                <mat-option *ngFor="let optionValues of existingFixtureArray"
                                    [value]="optionValues.ItemNo" style="font-size: 11px;">
                                    {{optionValues.Value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- End of new Fixture type -->

                        <!-- reason for work done -->
                        <mat-form-field appearance="outline" class="work-get-form form-field font"
                            *ngIf="subResponse.DataType=='List' && subResponse.Name == 'Reason for Work Done'">
                            <input [name]="subResponse.AttributeId" type="hidden" />
                            <mat-select [(ngModel)]="subResponse.SelectedValue" (selectionChange)="changeReason($event)"
                                (ngInit)="oninitialize(subResponse.SelectedValue,subResponse.AttributeId,subResponse.IsEditable)"
                                (ngModelChange)="dataChanged_customize($event,i,d,subResponse.AttributeId);"
                                [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                                [name]="subResponse.AttributeId">

                                <mat-option *ngFor="let optionValues of subResponse.Values" [value]="optionValues.Id"
                                    style="font-size: 11px;">
                                    {{optionValues.Value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="form-field font"
                            *ngIf="subResponse.DataType=='Decimal' && subResponse.Name == 'Watts/sq ft for New Constr.,'">
                            <input autocomplete="off" appInputlengthFilter matInput
                                [(ngModel)]="subResponse.SelectedValue" appDecimalnumber
                                (ngModelChange)="dataChanged($event,i,d)" [disabled]="isDisabled"
                                [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''"
                                type="number" [min]="subResponse.Min" [max]="subResponse.Max"
                                [maxlength]="subResponse.Size" [name]="subResponse.AttributeId">
                        </mat-form-field>
                        <!-- reason for work done end-->

                        <mat-form-field appearance="outline" class="work-get-form form-field font"
                            *ngIf="subResponse.DataType=='Date'">
                            <mat-label></mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="subResponse.SelectedValue"
                                [readonly]="inputReadOnly"
                                (ngInit)="oninitialize(subResponse.SelectedValue,subResponse.AttributeId,subResponse.IsEditable)"
                                (ngModelChange)="dataChanged_customize($event,i,d,subResponse.AttributeId)"
                                [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                                [name]="subResponse.SelectedValue" [value]="subResponse.SelectedValue" [min]="minDate">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>

                        <!---- Manufacturer & Model Number & ES Model (HPWH)------>
                        <mat-form-field appearance="outline" class="work-get-form form-field font"
                            *ngIf="subResponse.DataType=='List' && subResponse.AttributeId == 'test_eq_serialno_' + officeId && subResponse.IsESModel != undefined">
                            <input [name]="subResponse.AttributeId" type="hidden" />
                            <mat-select [(ngModel)]="subResponse.SelectedValue" (openedChange)="focus()" (selectionChange)="onESModelChange($event)"
                                (ngInit)="oninitialize(subResponse.SelectedValue,subResponse.AttributeId,subResponse.IsEditable)"
                                (ngModelChange)="dataChanged_customize($event,i,d,subResponse.AttributeId);"
                                [disabled]="(subResponse.IsEditable == 'Yes'? false : true)" [name]="subResponse.AttributeId">
                                <mat-icon class="searchIcon" aria-hidden="false" aria-label="Example search icon" fontIcon="search"></mat-icon>
                                <input class="searchInput" matInput [(ngModel)]="filteredText" placeholder="Search..." #searchInput>
                                <mat-option *ngFor="let optionValues of esmodelArray" [value]="optionValues.ItemNo" style="font-size: 11px;">
                                    {{optionValues.Value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="work-get-form form-field font"
                            *ngIf="subResponse.DataType=='List' && subResponse.AttributeId == 'replmanuf_' + this.officeId && subResponse.IsManufacturer != undefined">
                            <input [name]="subResponse.AttributeId" type="hidden" />
                            <mat-select class="dropdown" [(ngModel)]="subResponse.SelectedValue"
                                (ngInit)="oninitialize(subResponse.SelectedValue,subResponse.AttributeId,subResponse.IsEditable)"
                                (ngModelChange)="dataChanged_customize($event,i,d,subResponse.AttributeId);"
                                [disabled]="(subResponse.IsEditable == 'Yes'? false : true)" [name]="subResponse.AttributeId">
                                <mat-option *ngFor="let optionValues of manufacturerArray" [value]="optionValues.ItemNo" style="font-size: 11px;">
                                    {{optionValues.Value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" class="work-get-form form-field font"
                            *ngIf="subResponse.DataType=='List' && subResponse.AttributeId == 'model_id_' + this.officeId && subResponse.IsModelNo != undefined">
                            <input [name]="subResponse.AttributeId" type="hidden" />
                            <mat-select [(ngModel)]="subResponse.SelectedValue"
                                (ngInit)="oninitialize(subResponse.SelectedValue,subResponse.AttributeId,subResponse.IsEditable)"
                                (ngModelChange)="dataChanged_customize($event,i,d,subResponse.AttributeId);"
                                [disabled]="(subResponse.IsEditable == 'Yes'? false : true)" [name]="subResponse.AttributeId">
                                <mat-option *ngFor="let optionValues of energyStarModelArray" [value]="optionValues.ItemNo" style="font-size: 11px;">
                                    {{optionValues.Value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!----------------------HVAC Distributor------------------>
                        <mat-form-field appearance="outline" class="work-get-form form-field font"
                            *ngIf="subResponse.DataType=='List' && subResponse.AttributeId == 'replmanufhvac_' + officeId">
                            <input [name]="subResponse.AttributeId" type="hidden" />
                            <mat-select class="dropdown" [(ngModel)]="subResponse.SelectedValue" (selectionChange)="onManufacturerchange($event)"
                                (ngInit)="oninitialize(subResponse.SelectedValue,subResponse.AttributeId,subResponse.IsEditable)"
                                (ngModelChange)="dataChanged_customize($event,i,d,subResponse.AttributeId);"
                                [disabled]="(subResponse.IsEditable == 'Yes'? false : true)" [name]="subResponse.AttributeId">
                                <mat-option *ngFor="let optionValues of manufacturerhvacArray" [value]="optionValues.Id"
                                    style="font-size: 11px;">
                                    {{optionValues.Value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" class="work-get-form form-field font"
                            *ngIf="subResponse.DataType=='List' && subResponse.AttributeId == 'replmodelhvac_' + officeId">
                            <input [name]="subResponse.AttributeId" type="hidden" />
                            <mat-select [(ngModel)]="subResponse.SelectedValue"
                                (ngInit)="oninitialize(subResponse.SelectedValue,subResponse.AttributeId,subResponse.IsEditable)"
                                (ngModelChange)="dataChanged_customize($event,i,d,subResponse.AttributeId);"
                                [disabled]="(subResponse.IsEditable == 'Yes'? false : true)" [name]="subResponse.AttributeId">
                                <mat-option *ngFor="let optionValues of modelhvacArray" [value]="optionValues.Id"
                                    style="font-size: 11px;">
                                    {{optionValues.Value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-grid-tile>
                </ng-container>
            </mat-grid-list>

            <div class="align">
                <mat-card-actions>
                    <button mat-button color="primary" class="save-cancel-btn" (click)="saveMeasure();" *ngIf="measureResponse?.ViewEnabled != 1">Save</button>
                    <button mat-button color="primary" class="save-cancel-btn"
                        (click)="addMeasureClose();">Cancel</button>
                </mat-card-actions>
            </div>
        </mat-card>
    </div>
</ng-template>

<ng-template #tmplgrid>
    <table *ngIf="response?.['MEASUREGRID']?.length > 0" class="mat-elevation-z8 table">
        <caption class="mat-header-color caption whitesmoke">
            <th>
                {{ response?.SectionName | uppercase}} MEASURE INFORMATION
            </th>
        </caption>
        <thead>
            <tr>
                <ng-container *ngFor="let head of response?.['MEASUREGRID'][0] |  keyvalue : originalOrder">
                    <th class="mat-header-color table-header whitesmoke" *ngIf="head.key != 'Record Id' && head.key !='Measure Id' && 
                        head.key !='Lineitemmaster Id' && head.key!= 'Rebateitem Id'">{{head.key}}</th>
                </ng-container>
            </tr>
        </thead>
        <tbody>

            <tr *ngFor="let item of response?.['MEASUREGRID']" class="align">
                <ng-container *ngFor="let list of item | keyvalue : originalOrder">
                    <td class="table-data" *ngIf="list.key!= 'Record Id' && list.key !='Measure Id' &&
                    list.key !='Lineitemmaster Id' && list.key!= 'Rebateitem Id'">
                        <span *ngIf="item[list.key] != 'Edit,Delete' && item[list.key] != 'Edit' && item[list.key] != 'View'"> {{item[list.key]}}</span>
                        <span *ngIf="item[list.key] == 'Edit,Delete'">
                            <mat-icon (click)="updateMeasure(true, item['Record Id'], response.Lineitemmasterid);"
                                class="cursor">edit</mat-icon> &nbsp;&nbsp;&nbsp;
                            <mat-icon (click)="deleteMeasure(item['Record Id'])" class="cursor">delete</mat-icon>&nbsp;&nbsp;&nbsp;
                            <mat-icon *ngIf="response.SectionName == 'HVAC System Tune-Up'" class="cursor"
                                (click)="uploadDoc(item['Record Id']);">attach_file</mat-icon> &nbsp;&nbsp;&nbsp;
                            <mat-icon *ngIf="response.SectionName == 'HVAC System Tune-Up' && item['File Count'] != 0"
                                class="cursor" (click)="viewDoc(item['Record Id']);">visibility</mat-icon>
                        </span>
                        <span *ngIf="item[list.key] == 'Edit'">
                            <mat-icon (click)="updateMeasure(true, item['Record Id'], response.Lineitemmasterid);"
                                class="cursor">edit</mat-icon>
                        </span>
                        <span *ngIf="item[list.key] == 'View'">
                            <mat-icon (click)="updateMeasure(true, item['Record Id'], response.Lineitemmasterid);"
                                class="cursor">visibility</mat-icon>
                        </span>
                    </td>
                </ng-container>
            </tr>

        </tbody>
    </table>
</ng-template>