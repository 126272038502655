<h5 mat-dialog-title style="padding:0.2vw; color:black; margin:0 !important">Create Office</h5>
<mat-divider></mat-divider>
<div mat-dialog-content style="height: 57vh; width: 100vh;">
    <mat-card class="user-card" style="width: 93%;">
        <mat-card-content>
            <mat-form-field appearance="outline" class="formfield-half" style="width:50% !important">
                <mat-label>Office ID</mat-label>
                <input matInput placeholder="Name" [(ngModel)]="officeId" (focusin)="clearMessage()" maxlength="20 ">
            </mat-form-field>
            <mat-form-field appearance="outline" class="formfield-half" style="width:50% !important">
                <mat-label>Office Name</mat-label>
                <input matInput placeholder="Name" [(ngModel)]="officeDescription" (focusin)="clearMessage()" maxlength="50">
            </mat-form-field>
            <br>
            <mat-form-field appearance="outline" class="formfield-half">
                <mat-label>ProgramSponsor</mat-label>
                <mat-select [(ngModel)]="selectedProgramsponsor">
                    <mat-option *ngFor="let pSponsor of pSponsors" [value]="pSponsor.ProgramSponsorId" style="font-size: 11px;">
                       {{pSponsor.ProgramSponsorId}} - {{pSponsor.ProgramSponsorDescription}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-slide-toggle class="formfield-half" [(ngModel)]="officeActive">Active</mat-slide-toggle>
            <br>
            <br>
            <label  style="color: red; font-size: 0.9vw">{{errorMessage}}</label>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="primary" (click)="saveOffice();">SAVE</button>
            <button mat-raised-button color="primary" (click)="cancelOffice();">CANCEL</button>
        </mat-card-actions>
    </mat-card>
</div>