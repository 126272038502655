<div id="loading" *ngIf="loadFile" class="loading-indicator">
    <mat-spinner [diameter]="30" color="primary"></mat-spinner>
</div>

<div style="float:right;top:-5px;right:-5px;color:white">
    <button mat-icon-button [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div *ngIf="action">
    <h1 mat-dialog-title class="header" style="text-align:center;padding:0.5vw;font-size:1.2vw;color:white;">{{message}}
    </h1>
</div>


<div *ngIf="programCount > 0">


    <div>
        <h1 mat-dialog-title class="header" style="text-align:center;padding:0.5vw;font-size:1.2vw;color:white;">Select
            Program</h1>
    </div>


    <div>
        <mat-card style="width: 45vw; height:max-content">
            <mat-card-content>
                <div>
                    <mat-list style="cursor: pointer" #programsData [multiple]="false" *ngFor="let prog of programList"
                        dense>
                        <button mat-list-item style="width:100% !important;cursor: pointer;" [disableRipple]="false"
                            class="mat-list-btn" (click)="startEnrollment(prog)">{{prog.ProgramName}}</button>
                    </mat-list>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div *ngIf="programCount == 0">


    <div>
        <h1 mat-dialog-title class="header"
            style="width: 45vw; text-align:center;padding:0.5vw;font-size:1.2vw;color:white;">User is already enrolled
            into the Program</h1>
    </div>

</div>