<mat-card>
  <mat-card-header>
    <mat-card-title style="font-size: 16px">
      Energy Star Data
    </mat-card-title>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="ExportTOExcel()" [disabled]="count == 0">
        Export As Excel
      </button>
    </mat-card-actions>
  </mat-card-header>
  <mat-divider></mat-divider>
  <br />
  <mat-card-content>
    <div class="mat-elevation-z8" #TABLE>
      <table mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="WorkOrderType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Work Order Type</th>
          <td mat-cell *matCellDef="let report">
            {{ report.WorkOrderType }}
          </td>
        </ng-container>
        <ng-container matColumnDef="ImprovementType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Improvement Type</th>
          <td mat-cell *matCellDef="let report">
            {{ report.ImprovementType }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Manufacturer">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacturer</th>
          <td mat-cell *matCellDef="let report">
            {{ report.Manufacturer }}
          </td>
        </ng-container>
        <ng-container matColumnDef="ModelNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Model Number</th>
          <td mat-cell *matCellDef="let report">
            {{ report.ModelNumber }}
          </td>
        </ng-container>
        <ng-container matColumnDef="ESID">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>ES ID</th>
          <td mat-cell *matCellDef="let report">
            {{ report.ES_ID }}
          </td>
        </ng-container>
        <ng-container matColumnDef="ExternalKey">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>External Key</th>
          <td mat-cell *matCellDef="let report">
            {{ report.ExternalKey }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Capacity">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Capacity</th>
          <td mat-cell *matCellDef="let report">
            {{ report.Capacity }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <br /><br />
      <mat-paginator [pageSizeOptions]="[10,20,30]" showFirstLastButtons></mat-paginator>
    </div>
    <ng-container *ngIf="count == 0">
      <span style="text-align: center !important; font-size: larger">No records found.</span>
    </ng-container>
  </mat-card-content>
</mat-card>