import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from '../../../services/utility.service';
import { IndividualService } from '../../../services/individual.service';
import * as moment from 'moment';

@Component({
  selector: 'app-bucket-scheduling',
  templateUrl: './bucket-scheduling.component.html',
  styleUrls: ['./bucket-scheduling.component.scss']
})
export class BucketSchedulingComponent implements OnInit {

    webtimeslotsetup_id =null;
    workorderType;
    gridType;
    timeslot: string;
    apptQty: 1;
    startDate;
    endDate;
    apptstarttime;
    apptendtime;

    // weekdays by default set to true
    monday = true;
    tuesday = true;
    wednesday = true;
    thursday = true;
    friday = true;

    saturday = false;
    sunday = false;

    modifiable = false;
    deactivated = false;
    deactivatedDate;
    wasDeactivated = false;

    constructor(
        public dialogRef: MatDialogRef<BucketSchedulingComponent>,
        public indService: IndividualService,
        public utilityService: UtilityService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        if (this.data.editMode) {
            this.timeslot = this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].TIMESLOT_NAME;
            this.apptQty = this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].TIMESLOT_QTY;
            this.startDate = this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].STARTDATE;
            this.endDate = this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].ENDDATE;
            this.apptstarttime = this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].APPTSTARTTIME;
            this.apptendtime = this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].APPTENDTIME;
            this.monday = this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].MONDAY;
            this.tuesday = this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].TUESDAY;
            this.wednesday = this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].WEDNESDAY;
            this.thursday = this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].THURSDAY;
            this.friday = this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].FRIDAY;
            this.saturday = this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].SATURDAY;
            this.sunday = this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].SUNDAY;
            this.modifiable = this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].MODIFIABLE;
            this.deactivated = this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].DEACTIVATEROW;
            this.wasDeactivated = this.deactivated;
            this.deactivatedDate = this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].DEACTIVATEDATE;

            this.webtimeslotsetup_id = this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].WEBTIMESLOTSETUP_ID;
            
            if (this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].GRID_CD != null) {
                for (let i = 0; i < this.indService.indDetail.BucketSchedulingTab.GRIDLIST.length; i++) {
                    if (this.indService.indDetail.BucketSchedulingTab.GRIDLIST[i].CODE
                        === this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].GRID_CD) {
                        this.gridType = this.indService.indDetail.BucketSchedulingTab.GRIDLIST[i];
                        break;
                    }
                }
            } else {
                this.gridType = this.indService.indDetail.BucketSchedulingTab.GRIDLIST[0];
            }

            if (this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].SELECTEDWOTYPECODE != null) {
                for (let i = 0; i < this.indService.indDetail.BucketSchedulingTab.WORKORDERTYPELIST.length; i++) {
                    if (this.indService.indDetail.BucketSchedulingTab.WORKORDERTYPELIST[i].CODE
                        === this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].SELECTEDWOTYPECODE) {
                        this.workorderType = this.indService.indDetail.BucketSchedulingTab.WORKORDERTYPELIST[i];
                        break;
                    }
                }
            }
        }
        else
        {
            var today = new Date();
            var year = today.getFullYear();
            var month = today.getMonth();
            var day = today.getDate();
            var startHour = 8;
            var endHour = 17;

            this.startDate= new Date(year, month, day);
            this.endDate = new Date(year + 5, month, day);

            this.apptstarttime = new Date(year, month, day,startHour);
            this.apptendtime=new Date(year + 5, month, day,endHour);

            this.gridType = this.indService.indDetail.BucketSchedulingTab.GRIDLIST[0];
            this.timeslot ="All Day";
        }
    }

    saveBucket() {
        
        if (this.data.editMode) {
            
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].TIMESLOT_NAME = this.timeslot;
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].TIMESLOT_QTY = this.apptQty;
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].STARTDATE =  moment(this.startDate).format('MM/DD/YYYY HH:mm:ss');
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].ENDDATE = moment(this.endDate).format('MM/DD/YYYY HH:mm:ss');
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].APPTSTARTTIME = this.apptstarttime;
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].APPTENDTIME = this.apptendtime;
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].MONDAY = Number(this.monday);
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].TUESDAY = Number(this.tuesday);
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].WEDNESDAY = Number(this.wednesday);
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].THURSDAY = Number(this.thursday);
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].FRIDAY = Number(this.friday);
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].SATURDAY = Number(this.saturday);
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].SUNDAY = Number(this.sunday);
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].MODIFIABLE = Number(this.modifiable);
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].DEACTIVATEROW = Number(this.deactivated);
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].ACTIVE = Number(!this.deactivated);

            // console.log("deactivated = " + this.deactivated + " and wasDeactivated = " + this.wasDeactivated);
            if (this.deactivated && !this.wasDeactivated) {
                this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].DEACTIVATEDATE = moment().format('MM/DD/YYYY HH:mm:ss');;
                this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].DEACTIVATEUSER=this.utilityService.currentUtility.BBCSUserId;
            } else {
                this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].DEACTIVATEUSER = null;
            }

            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].GRID_CD = this.gridType.CODE;
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].SELECTEDWOTYPEDESC= this.workorderType.DESCRIPTION;
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].SELECTEDWOTYPECODE = this.workorderType.CODE;
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].SELECTEDGRIDDESC = this.gridType.DESCRIPTION;
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index].SELECTEDGRIDCODE = this.gridType.CODE;

            // console.log("this.deactivated=" + this.deactivated);
            // console.log("bucket: " + JSON.stringify(this.indService.indDetail.BucketSchedulingTab.BucketScheduling[this.data.index], null, 2));
        } else {
            const bucket = {
                WEBTIMESLOTSETUP_ID: null,
                TIMESLOT_NAME: this.timeslot,
                TIMESLOT_QTY: this.apptQty,
                PROGRAMDETAIL_ID: null,
                STARTDATE:  moment(this.startDate).format('MM/DD/YYYY HH:mm:ss'),
                ENDDATE:  moment(this.endDate).format('MM/DD/YYYY HH:mm:ss'),
                APPTSTARTTIME:  moment(this.apptstarttime).format('MM/DD/YYYY HH:mm:ss'),
                APPTENDTIME:  moment(this.apptendtime).format('MM/DD/YYYY HH:mm:ss'),
                WORKRESOURCE_ID: null,
                GRID_CD: this.gridType.CODE,
                PROVIDER_ID: null,
                SUNDAY: Number(this.sunday),
                MONDAY: Number(this.monday),
                TUESDAY: Number(this.tuesday),
                WEDNESDAY: Number(this.wednesday),
                THURSDAY: Number(this.thursday),
                FRIDAY: Number(this.friday),
                SATURDAY: Number(this.saturday),
                ACTIVE: Number(!this.deactivated),
                ACTIVEDATE: new Date(),
                ACTIVEUSER: this.utilityService.currentUtility.BBCSUserId,  // localStorage.setItem('loggedInUserName', 'Test User'),
                DEACTIVATEDATE: null,
                DEACTIVATEUSER: this.deactivated === true ? this.utilityService.currentUtility.BBCSUserId : null,
                DEACTIVATEROW: Number(this.deactivated),
                MODIFIABLE: Number(this.modifiable),
                SELECTEDWOTYPEDESC: this.workorderType.DESCRIPTION,
                SELECTEDWOTYPECODE: this.workorderType.CODE,
                SELECTEDGRIDDESC: this.gridType.DESCRIPTION,
                SELECTEDGRIDCODE: this.gridType.CODE
                
            };

            this.indService.indDetail.BucketSchedulingTab.BucketScheduling.push(bucket);
        }

        this.dialogRef.close('1');
    }

}
