<ngx-spinner bdColor = "rgba(128, 128, 128, 0.5)" size = "medium" color = "#ff4081" type = "timer" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner> 
<div mat-dialog-container style="height: 33vw !important;">
<div mat-dialog-title style="margin: 0 !important">
    <h1 mat-dialog-title class="mat-header-color" style="margin:0 !important; font-size:1vw; width:90% !important;float:left; color:whitesmoke">  Add Event :
        {{eventName}}</h1>
    <button matTooltip="Close" mat-button style="height: 2.5vw !important;"  class="close-icon" [mat-dialog-close]="hasSaved">
        <mat-icon style="margin-bottom: 0.5vw;">close</mat-icon>
    </button>
</div>

<div mat-dialog-content style="height: 100vw; width:60vw;">
    <mat-card style="margin-top: 0.5vw;">
        <mat-card-content style="height:26vw !important">
            <table style="table-layout: fixed;width:100%">
                <tr>
                    <td colspan="2">
                        <mat-form-field appearance="outline" style="width:100%;font-size: 0.9vw;padding-bottom: 0.2vw !important;">
                            <mat-label>Assigned Contractor</mat-label>
                            <mat-select [(ngModel)]="resourceName">
                                <mat-option *ngFor="let resource of resources" [value]="resource.personcompanyid"
                                style="font-size: 11px;">
                                    {{resource.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>
                        <input placeholder="Event Start"
                            style="border: 1px solid black; border-radius: 5px;  height: 2.5vw;padding:0.75em;width:94.5%;font-size: 0.9vw;"
                            [owlDateTimeTrigger]="dt"  [owlDateTime]="dt" [(ngModel)]="selectedStartTime">
                        <owl-date-time #dt [hour12Timer] = "true"></owl-date-time>
                    </td>
                    <td>
                        <input placeholder="Event End"
                            style="border: 1px solid black; border-radius: 5px; height: 2.5vw;padding:0.75em;width:94.5%;font-size: 0.9vw;"
                            [owlDateTimeTrigger]="dt1"  [owlDateTime]="dt1" [(ngModel)]="selectedEndTime">
                        <owl-date-time #dt1 [hour12Timer] = "true"></owl-date-time>
                    </td>
                </tr>
                <tr>
                    <td>
                        <mat-form-field appearance="outline" style="width:100%;font-size: 0.9vw;">
                            <mat-label>Email 1</mat-label>
                            <input matInput placeholder="Email 1" [(ngModel)]="email1" style="width:90%">
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field appearance="outline" style="width:100%;font-size: 0.9vw;">
                            <mat-label>Email 2</mat-label>
                            <input matInput placeholder="Email 2" [(ngModel)]="email2" style="width:90%">
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td >
                        <mat-form-field appearance="outline" style="width:100%;font-size: 0.9vw;">
                            <mat-label>Workorder Notes</mat-label>
                            <textarea rows="1" cols="30" matInput [(ngModel)]="WonotesObj"></textarea>
                        </mat-form-field>
                    </td>
                    <td >
                        <mat-form-field appearance="outline" style="width:100%;font-size: 0.9vw;">
                            <mat-label>Appointment Notes</mat-label>
                            <textarea rows="1" cols="30" matInput [(ngModel)]="ApptnotesObj"></textarea>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <mat-form-field appearance="outline" style="width:100%;font-size: 0.9vw;">
                            <mat-label>Reason</mat-label>
                            <mat-select [(ngModel)]="reasonName" [disabled]="!disableReasons">
                                <mat-option *ngFor="let reason of reasons" [value]="reason.ReasonCode"
                                style="font-size: 11px;">
                                    {{reason.Description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>

                </tr>
                <tr>
                    <button mat-raised-button color="primary" style="font-size: 12px !important"  (click)="onSave()">Save</button>
                </tr>
            </table>

        </mat-card-content>
       <!--  <mat-card-actions>
            
        </mat-card-actions> -->
    </mat-card>
</div>

<br>
</div>