export class Maps {
  Appointments: BBCSAppointments[];
}
export class BBCSAppointments {
  Id: any;
  Name: any;
  StartDate: any;
  EndTime: any;
  ApptDate: any;
  WorkorderId: any;
  Address: any;
  CityStZip: any;
  Grid: any;
}
export class Maps_address {
  Name: any;
  WorkorderId: any;
  Address: any;
  cusomeraddress: any;
  lats: any;
  lngs: any;
  ApptDate: any;
  Id: any;
  Grid: any;
  icon: any;

  public setFromWeb(
    Name,
    Workorder,
    Address,
    lats,
    lngs,
    ApptDate,
    Id,
    Grid,
    icon
  ) {
    this.Name = Name;
    this.WorkorderId = Workorder;
    this.Address = Address;
    this.lats = lats;
    this.lngs = lngs;
    this.ApptDate = ApptDate;
    this.Id = Id;
    this.Grid = Grid;
    this.icon = icon;
  }
}
