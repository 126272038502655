<div mat-dialog-container style="height: 33vw !important;">
    <div mat-dialog-title style="margin: 0 !important">
        <h1 mat-dialog-title class="mat-header-color"
            style="margin:0 !important; font-size:1vw; width:100% !important;float:left; color:whitesmoke">Schedule Appointment</h1>
        <!-- <button mat-button style="height: 2.5vw !important;" class="close-icon" [mat-dialog-close]="hasSaved">
            <mat-icon style="margin-bottom: 0.5vw;">close</mat-icon>
        </button> -->
    </div>

    <div mat-dialog-content style="height: 100vw; width:30vw;float:right;">
        <mat-card style="margin-top: 0.5vw;">
            <mat-card-content style="height:26vw !important">
                <table style="table-layout: fixed;width:100%">

                    <tr>
                        <td colspan="2">
                            <mat-form-field appearance="outline" style="width:100%;font-size: 0.9vw;">
                                <mat-label>Appoinment Notes</mat-label>
                                <textarea rows="4" cols="30" matInput [(ngModel)]="ApptnotesObj"></textarea>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field appearance="outline" style="width:100%;font-size: 0.9vw;">
                                <mat-label>Primary Email</mat-label>
                                <input matInput placeholder="Primary Email" [(ngModel)]="email1" style="width:90%">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" style="width:100%;font-size: 0.9vw;">
                                <mat-label>Secondary Email</mat-label>
                                <input matInput placeholder="Secondary Email" [(ngModel)]="email2" style="width:90%">
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2"> 
                            <mat-form-field appearance="outline" style="width:100%;font-size: 0.9vw;">
                                <mat-label>Workorder Notes</mat-label>
                                <textarea rows="4" cols="30" matInput [(ngModel)]="WonotesObj"></textarea>
                            </mat-form-field>
                        </td>
                    </tr>

                    <tr>
                        <button mat-raised-button color="primary" (click)="onSchedule()">Schedule</button>
                    </tr>
                </table>

            </mat-card-content>
            <!--  <mat-card-actions>
                
            </mat-card-actions> -->
        </mat-card>
    </div>
    <div mat-dialog-content style=" width:30vw;float:left;margin-top:10vw;text-align: center;">
        <pre>{{bucketObj.SlotStart | date:'fullDate'}}</pre>
     <pre>{{address}}</pre>   
       <pre> {{phone}}</pre>
    </div>

    <br>
</div>