import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { UtilityService } from './utility.service';

@Injectable({
    providedIn: 'root'
})
export class IndividualService {

    // for editing in detail UI
    indDetail;
    orgId: string;
    personcompanyId: string;
    officeId: string;
    editMode = true;
    indName:any;

    orgList = [];
    indvList = [];
    selectedOrg;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    private getHeaders() {
        const email = localStorage.getItem('loggedInUserEmail');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-email': email,
                'x-programsponsorid': this.utilityService.currentUtility.ProgramsponsorId
            })
        };
        return httpOptions;
    }

    public getOrganizationList(): Observable<any[]> {
        const searchUrl = environment.apiUrl
            + 'individualmaintenance/'
            + this.utilityService.currentUtility.OfficeId
            + '/organizations/ALL?code=' + environment.apiCode;

        return this.http.get<any[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    public getIndividualsForOrganization(orgId: string,indName:any): Observable<any[]> {
        if (indName === undefined){
            indName = '';
        }
        if (orgId === undefined){
            orgId = '';
        }
        const searchUrl = environment.apiUrl
            + 'individualmaintenance/'
            + this.utilityService.currentUtility.OfficeId
            + '/individual/search/ALL?orgCode=' + orgId + '&orgName=' + indName + '&code=' + environment.apiCode;

        return this.http.get<any[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    public getDetail(orgId: string, personcompanyId: string) {
        this.personcompanyId = personcompanyId;
        this.orgId = orgId;
        this.officeId = this.utilityService.currentUtility.OfficeId;

        const searchUrl = environment.apiUrl
            + 'individualmaintenance/'
            + this.utilityService.currentUtility.OfficeId
            + '/individual/detail/' + orgId + '/personcompany/' + personcompanyId  +  '?code=' + environment.apiCode;

        return this.http.get<any[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    public newDetail(orgId: string) {
        this.orgId = orgId;
        this.officeId = this.utilityService.currentUtility.OfficeId;

        const searchUrl = environment.apiUrl
            + 'individualmaintenance/'
            + this.utilityService.currentUtility.OfficeId
            + '/individual/new/detail/' + orgId +  '?code=' + environment.apiCode;

        return this.http.get<any[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    public save() {
        if (this.editMode) {
            return this.saveIndividual();
        } else {
            return this.saveNewIndividual();
        }
    }

    private saveIndividual() {
        const saveUrl =  environment.apiUrl
        + 'individualmaintenance/'
        + this.utilityService.currentUtility.OfficeId
        + '/individual/save/' + this.indDetail.IndividualTab.Individual.SELECTEDORGCODE
        + '/personcompany/' + this.personcompanyId  +  '?code=' + environment.apiCode;

        return this.http.post<any[]>(saveUrl, this.indDetail, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    private saveNewIndividual() {
        const saveUrl =  environment.apiUrl
        + 'individualmaintenance/'
        + this.utilityService.currentUtility.OfficeId
        + '/individual/create/' + this.indDetail.IndividualTab.Individual.SELECTEDORGCODE
        +  '?code=' + environment.apiCode;

        return this.http.post<any[]>(saveUrl, this.indDetail, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }
}
