import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-enrollmentportaladmin',
  templateUrl: './enrollmentportaladmin.component.html',
  styleUrls: ['./enrollmentportaladmin.component.scss']
})
export class EnrollmentportaladminComponent implements OnInit {
  utilityList: any;
  selectedUtil: any;
  searchResultList:any;
  searchResult: any = true;
  searching: any = false;
  selected:any;
  firstFormGroup: UntypedFormGroup;
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['MainIntro', 'MainDescription', 'edit'];
  constructor(private userService: UserService,private _formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
      secondCtrl: ['', Validators.required],
    });


    this.getMenuList();
  }

  //menuChange(e) {
//console.log('change', e);
    //this.getOffices(e.value.MenuId);
 // }



  getMenuList() {
    
    this.userService.getAllUtilityList().subscribe(res => {
      this.utilityList = res.SettingsList || []
      console.log('utilityList',this.utilityList);
    })
  }
  

  onSelection(ev: any){

    let optionText = ev.source.selected.id;
   console.log('selected value:',optionText);
    localStorage.setItem('utilityId', optionText);
    let selectedUtil = localStorage.getItem('utilityId');
    console.log('selected Utility Id:',selectedUtil);
  }

  viewUtilConfigurations() {
    this.searchResult = false;
    this.searchResultList = [];
    this.searching = true;
       
    let selectedUtil = localStorage.getItem('utilityId');
    
    console.log('selected utilid',selectedUtil);
    this.userService.getAllUtilDetailList(this.selectedUtil).subscribe(data => {
      console.log('selected utilid:',selectedUtil);
      this.searchResultList = data;
      console.log('search results:',data);
      if (data.length > 0) {
        this.searching = false;
   //     this.dataSource.paginator = this.firstpaginator;
        this.dataSource.data = this.searchResultList;

      }
      else {
        this.searching = false;

      }

    });

  }


 

  

}
