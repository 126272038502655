import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { WorkorderService } from 'src/app/services/workorder.service';
import { UtilityService } from 'src/app/services/utility.service';
import { SchedulingService } from 'src/app/services/scheduling.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-cancelschedule-dialog',
  templateUrl: './cancelschedule-dialog.component.html',
  styleUrls: ['./cancelschedule-dialog.component.scss']
})
export class CancelscheduleDialogComponent implements OnInit {

  reasons: any = [];
  dialogTitle: any;
  workorderId: any;
  user: any;
  alertText: any;
  loadReasons: any = true;
  compassJobId:any;
  compassEnabled:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<CancelscheduleDialogComponent>, private scheduleService: SchedulingService,
    private utilityService: UtilityService,private spinner:NgxSpinnerService) { }


  ngOnInit(): void {
  
    this.workorderId = this.data.workorderID;
    this.user = this.utilityService.currentUtility.BBCSUserId;
    this.compassEnabled=localStorage.getItem('compassEnabled');
    this.compassJobId=localStorage.getItem('compassJobId');
    //this.compassJobId=localStorage.getItem('compassJobId');
    console.log('compassenabled:',this.compassEnabled);
    this.getReasons();
  }

  getReasons() {
  
    this.loadReasons = true;
    this.scheduleService.getCancelScheduleReasons().subscribe(res => {
      this.reasons = res;
      this.loadReasons = false;
    })
  }

  cancelSchedule(reason) {

    Swal.fire({
      title: 'Save Changes?',
      text: "Are you sure you want to cancel appointment?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Save Changes'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.doCancelSchedule(reason);
      }
    });

  }
  doCancelSchedule(reason) {
  
    if(this.workorderId == undefined){
      this.scheduleService.cancelOpenAppointments(this.data.scheduleDate.toLocaleDateString(),this.data.personcompany,reason.ReasonCode,'ALL').subscribe(res=>{
        this.spinner.hide();
      
        if (!res.Action || (res.Action==0)) {
          Swal.fire({
            text: "Appointment cancellation is successful",
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            this.dialog.close({data:true});
          })
        } else {
          Swal.fire({
            text: 'Appointment cancellation is not successful.',
            icon: 'error',
            confirmButtonText: 'OK',
          }).then(() => {
            this.dialog.close({data:false});
          })
        }
      })
    }
    else
    {
      this.scheduleService.cancelSchedule(this.workorderId, reason.ReasonCode).subscribe(res => 
      {
       
        this.spinner.hide();
        //Compass integration - start
        if (res.Action == "0" && this.compassEnabled == "1")
        {
          this.scheduleService.getCompassCancelScheduleAppointment(this.workorderId,this.user,this.compassJobId).subscribe(res1 => 
            {
              if(res1.Action)
              {
                Swal.fire({
                  text: ' Appointment cancellation is not successful. Reason: '+ res1.Message,
                  icon: 'error',
                  confirmButtonText: 'OK',
                }).then((result) => {
                  if (result.value) {
                     this.dialog.close({data:true});
                  }
                }); 
              }
              else
              {
                this.spinner.hide();
                Swal.fire({
                  text: 'Appointment cancellation is successful',
                  icon: 'success',
                  confirmButtonText: 'OK',
              }).then(() => {
                this.dialog.close({data:true});
               })
              }

            });

          
        }
          if (res.Action == "0" && this.compassEnabled == "0")
          { 
              Swal.fire({
                text: 'Appointment cancellation is successful',
                icon: 'success',
                confirmButtonText: 'OK',
            }).then(() => {
              this.dialog.close({data:true});
            })
  
          }
        //compass integration -end
      
      if (res.Action == "0" && this.compassEnabled == "undefined")
      { 
            Swal.fire({
              text: 'Appointment cancellation is successful',
              icon: 'success',
              confirmButtonText: 'OK',
          }).then(() => {
            this.dialog.close({data:true});
          })

      }

        if (res.Action != "0" && this.compassEnabled == "0") {
          Swal.fire({
            text: res.Message,
            icon: 'error',
            confirmButtonText: 'OK',
          }).then((result) => {
    
            if (result.value) {
              this.dialog.close({data:true});
            }
          });
        }

      });
      
    }
   

  }

}
