import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appDecimalnumber]'
})
export class DecimalnumberDirective {

   // Allow decimal numbers and negative values
   // Allow key codes for special events. Reflect :
   // Backspace, tab, end, home
   private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
   var input = this.el.nativeElement;
   var oldVal = this.el.nativeElement.value;
   var regex = new RegExp(/^\d+(\.\d{0,2})?$/g);

   setTimeout(function () {
     var newVal = input.value;

     if (!regex.test(newVal)) {
       input.value = oldVal;
     }
   });
  }
}
