<mat-spinner [diameter]="80" color="primary" *ngIf="searching" style="margin:0 auto; width:50% !important; margin-top: 2vw;"></mat-spinner>
<div [hidden]="searching">
    <mat-card class="user-card" >
        <mat-card-header>
          <mat-card-title style="font-size: 16px;">
            <span matBadge="{{ users.length }}" matBadgeOverlap="false">Users</span>
          </mat-card-title>
          <div style="width: 100% !important;">
            <button mat-button (click)="addNewUser()"
              style="background: transparent;float:right !important; height: 100% !important">
              <mat-icon matTooltip="Add User" aria-hidden="false" color="primary" 
              style="text-align: center;
              width: 98%;float:right;">
                add
              </mat-icon>
            </button>
          </div>
          <!--  <mat-card-subtitle>
            Double Click row to edit.
          </mat-card-subtitle> -->
        </mat-card-header>
        <mat-divider></mat-divider>
       
        <mat-card-content style="margin-bottom: 0 !important">
        
          <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
          </mat-form-field>
          <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="table-layout: fixed">
            <ng-container matColumnDef="UserEmail">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 33%;">User Email</th>
              <td mat-cell *matCellDef="let user" matTooltip="{{user.UserEmail}}"> {{ user.UserEmail }} </td>
            </ng-container>
      
            <ng-container matColumnDef="UserActive">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
              <td mat-cell *matCellDef="let user">
                <mat-icon matTooltip="Check" *ngIf="user.UserActive" color="primary">check_box</mat-icon>
                <mat-icon matTooltip="Cancel" *ngIf="!user.UserActive" style="color: red !important">cancel</mat-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="UserName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Username</th>
              <td mat-cell *matCellDef="let user" matTooltip="{{user.UserName}}"> {{ user.UserName }} </td>
            </ng-container>
      
            <ng-container matColumnDef="CreatedDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</th>
              <td mat-cell *matCellDef="let user"> {{user.CreatedDate  | date:'shortDate' }} </td>
            </ng-container>
      
            <ng-container matColumnDef="ModifiedBy">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Modified By</th>
              <td mat-cell *matCellDef="let user" matTooltip="{{user.ModifiedBy}}"> {{user.ModifiedBy}}</td>
            </ng-container>
      
      
      
      
      
            <ng-container matColumnDef="edit" stickyEnd>
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10% !important"></th>
              <td mat-cell *matCellDef="let user">
                <button mat-button (click)="handleClick(user)">
                  <mat-icon matTooltip="Edit" color="primary">create</mat-icon>
                </button>
      
              </td>
            </ng-container>
      
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[5,10,20]" showFirstLastButtons></mat-paginator>
        </mat-card-content>
          <!--<mat-card-actions>
        <button mat-raised-button color="primary" routerLink="/search">DONE</button>
        </mat-card-actions>-->
      </mat-card>
</div>

<div style="margin-bottom: 75px;"></div>