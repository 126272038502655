<h1 mat-dialog-title class="header" style="padding:1vw;color:whitesmoke">Workorder Status History</h1>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <table style="width: 100% !important" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Workorder Status Date</th>
      <td mat-cell *matCellDef="let workorder"> {{workorder.WorkorderStatusDate | date:'M/dd/yy, h:mm:ss a'}} </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Workorder Status</th>
      <td mat-cell *matCellDef="let workorder"> {{workorder.WorkorderStatus}} </td>
    </ng-container>
    <ng-container matColumnDef="createdDate">
      <th mat-header-cell *matHeaderCellDef>Created Date</th>
      <td mat-cell *matCellDef="let workorder"> {{workorder.CreatedDate | date:'M/dd/yy'}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Assigned By</th>
      <td mat-cell *matCellDef="let workorder"> {{workorder.AssignedBy}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="curr-workorder"></tr>
  </table>
</div>