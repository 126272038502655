import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';

import { UserService } from '../../services/user.service';

import { Module } from '../../models/module';
import { ModuleDetailComponent } from '../module-detail/module-detail.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ModuleCreateComponent } from '../module-create/module-create.component';

@Component({
    selector: 'app-module',
    templateUrl: './module.component.html',
    styleUrls: ['./module.component.scss']
})
export class ModuleComponent implements OnInit {
    dialogRef: MatDialogRef<ModuleDetailComponent>;


    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    modules = [];
    Roles: any;
    displayedColumns: string[] = ['ModuleId', 'ModuleName', 'ModuleItemName', 'Roles', 'IsActive', 'CreatedDate', 'ModifiedBy', 'edit'];
    dataSource = new MatTableDataSource<Module>();
    searching = true;
    className:any;
    constructor(
        private userService: UserService,
        private router: Router,
        private dialog: MatDialog
    ) { 
        
    }

    ngOnInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.getModules();
    }

    private getModules() {
        this.userService.getModules().subscribe(data => {

            data.forEach(element => {
                element.RoleString = '';
                element.Rolemodules = [];
                element.RoleModule.forEach((roleElement: any) => {
                    element.Rolemodules.push(roleElement.RoleId);
                    element.RoleString = element.Rolemodules.toString();
                });
            });
            this.modules = data;
            this.dataSource.data = this.modules;

            this.searching = false;

        });
    }

    addNewModule() {
        this.className=localStorage.getItem("themeName");
        const dialogCreate = this.dialog.open(ModuleCreateComponent,{panelClass:this.className});
        dialogCreate.afterClosed().subscribe(result => {
            this.getModules();
        });
    }

    public handleClick(moduleModel: Module) {
        this.className=localStorage.getItem("themeName");
        this.dialogRef = this.dialog.open(ModuleDetailComponent,
            {
                data: {
                    dataKey: moduleModel
                },panelClass:this.className
            });

        this.dialogRef.afterClosed().subscribe(result => {
            this.getModules();
        });
    }
}
