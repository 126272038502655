import { Component, OnInit, Inject, DebugEventListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ProgramSponsor } from 'src/app/models/programsponsor';
import { take, publish } from 'rxjs/operators';
import { ConnectableObservable } from 'rxjs';
import { WaitDialogComponent } from '../../util/wait-dialog/wait-dialog.component';

@Component({
  selector: 'app-program-sponsor-create',
  templateUrl: './program-sponsor-create.component.html',
  styleUrls: ['./program-sponsor-create.component.scss']
})
export class ProgramSponsorCreateComponent implements OnInit {

  programSponsorId: any;
  programSponsorName: any;
  compassEmail: any;
  compassPassword: any;
  emailRegEx: RegExp = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
  // URL_REGEXP = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
  emailValid: boolean = true;
  urlValid: boolean = true;
  IfMandatory: boolean = false;
  programSponsorActive: boolean = true;
  ifPSponsorExists: boolean = false;
  errorMessage: any;
  compassUri: any;
  salesforceUsername: string;
  salesforcePassword: string;
  selectedSource: any;
  sources: [] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog,
    private router: Router,
    private userService: UserService) { }

  ngOnInit() {
    this.loadSourceList();
  }

  loadSourceList() {
    this.userService.getSourceList().subscribe(res => {
      this.sources = res;
    })
  }


  savePSponsor() {
    this.checkAllFieldsAreEmpty();
    if (!this.IfMandatory) {
      this.pSponsorSaveIfTrue();
    }
    else {
      this.errorMessage = "All fields are mandatory";
    }
  }

  checkAllFieldsAreEmpty() {

    if ((this.compassEmail == null || this.compassEmail == undefined || this.compassEmail == "")
      || (this.compassPassword == null || this.compassPassword == undefined || this.compassPassword == "")
      || (this.compassUri == null || this.compassUri == undefined || this.compassUri == "")
      || (this.salesforceUsername === null || this.salesforceUsername === undefined || this.salesforceUsername === "")
      || (this.salesforcePassword === null || this.salesforcePassword === undefined || this.salesforcePassword === "")
      || (this.programSponsorId == null || this.programSponsorId == undefined || this.programSponsorId == "")
      || (this.programSponsorName == null || this.programSponsorName == undefined || this.programSponsorName == "")
      || (this.selectedSource == null || this.selectedSource == undefined || this.selectedSource == "")) {
      this.IfMandatory = true;
    }
    else {
      this.IfMandatory = false;
    }
  }

  clearMessage() {
    this.errorMessage = "";
  }

  pSponsorSaveIfTrue() {

    if (this.compassEmail != null && this.compassEmail != "" && this.compassEmail != undefined) {
      //  let compassEmailID = this.compassEmail.toLowerCase();
      this.compassEmail = this.compassEmail.toLowerCase();
      this.emailValid = this.emailRegEx.test(this.compassEmail);
    }
    if ((this.compassUri != null && this.compassUri != "" && this.compassUri != undefined)) {
      let url = this.compassUri.toLowerCase();
      //this.urlValid = this.URL_REGEXP.test(url);
    }

    if (!this.emailValid) {
      this.errorMessage = "Please enter a valid email";
    }
    /* if (this.urlValid) {
      this.errorMessage = "Please enter a valid url";
    } */

    if (this.emailValid) {
      let className = localStorage.getItem("themeName");
      const dialogRef = this.dialog.open(WaitDialogComponent, { data: { caption: 'Saving...' },panelClass:className });
      let pSponsor = new ProgramSponsor();
      let self = this;
      pSponsor.ProgramSponsorId = this.programSponsorId.toUpperCase();
      pSponsor.ProgramSponsorDescription = this.programSponsorName;
      pSponsor.CompassUri = this.compassUri;
      pSponsor.CompassAuthorization = this.compassEmail + ":" + this.compassPassword;
      pSponsor.ProgramSponsorActive = this.programSponsorActive;
      pSponsor.CreatedBy = localStorage.getItem("loggedInUserName");
      pSponsor.ModifiedBy = localStorage.getItem("loggedInUserName");
      pSponsor.SalesforceUsername = this.salesforceUsername;
      pSponsor.SalesforcePassword = this.salesforcePassword;
      pSponsor.SourceId = this.selectedSource;

      var pSponsorExists = this.userService.pSponsorExists(pSponsor.ProgramSponsorId, pSponsor.ProgramSponsorDescription).pipe(take(1));
      var createPSponsor = this.userService.createPSponsor(pSponsor).pipe(take(1), publish()) as ConnectableObservable<any>;

      pSponsorExists.subscribe(res => {
        this.ifPSponsorExists = true;
      }, error => {
        this.ifPSponsorExists = false;
        this.errorMessage = "ProgramSponsor already exists";
      }, createPSponsor.connect.bind(createPSponsor));


      createPSponsor.subscribe(function (data) {
        dialogRef.close();
        if (data && self.ifPSponsorExists) {
          Swal.fire({
            text: "ProgramSponsor Created Successfully",
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            self.dialog.closeAll();
          })
        }
        else {
          Swal.fire({
            text: "ProgramSponsor Create failed",
            icon: 'error',
            confirmButtonText: 'OK',
          }).then(() => {
            self.dialog.closeAll();
          })
        }

      }, error => {
        Swal.fire({
          text: "ProgramSponsor Create failed. Server Error",
          icon: 'error',
          confirmButtonText: 'OK',
        }).then(() => {
          self.dialog.closeAll();
        })
      });
    }

  }




  cancelPSponsor() {
    this.dialog.closeAll();
    this.router.navigate(['/programSponsorAdmin']);

  }

}
