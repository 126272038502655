import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataTransferService {

  myMethod$: Observable<any>;
  private myMethodSubject = new Subject<any>();

  constructor() {

    this.myMethod$ = this.myMethodSubject.asObservable();
  }

  myMethod(data) {
    this.myMethodSubject.next(data);
  }
}




