<mat-spinner diameter="100" style="text-align: center;margin:0 auto; margin-top:5vw" color="primary" *ngIf="response == null || response == undefined"></mat-spinner>
<div class="bucket-page">
  <div *ngIf="response != null" class="page-div">
    <div>
      <br>
      <br>
      <!-- <h2 class="top-header">Available Appointments for {{selectedWorkorder}}</h2>
      <pre class="main-header">{{response.Address}}</pre>
      <pre class="main-header">{{response.Phone}}</pre>
      <pre class="main-header">{{response.Email}}</pre> -->
      <h2 class="top-header">Available Appointments for {{response.WorkorderType}}</h2>
      <pre class="main-header">{{response.Street}}</pre>
      <pre class="main-header">{{response.City}},{{response.State}} {{response.Zip}}</pre>
      <pre class="main-header">{{formatPhoneNumber(response.Phone)}}</pre>
      <pre class="main-header">{{response.Email}}</pre>
    <br>
    <mat-form-field appearance="outline" style="padding-right: 2.2vw; width:17.6vw">
      <mat-label>Select Date</mat-label>
      <input matInput [matDatepicker]="datePicker" placeholder="Choose a date" 
        [(ngModel)]="selectedDate" (dateInput)="onDate($event)" [min]="minDate">
      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker #datePicker (selectedChanged)="onDate($event)"></mat-datepicker>
    </mat-form-field>
    </div>
  
    
      <div class="card-div mat-primary">
        <mat-card *ngFor="let day of days" class="schedule-card">
          <mat-card-header>
            <mat-card-title style="font-size: 16px">{{ day.format("dddd, MMMM DD, YYYY") }}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
          <!--    -->
    
            <h3 *ngIf="response.AvailableSlotsResources.length == 0">No available slots.</h3>
    
            <button *ngFor="let bucket of response.AvailableSlotsResources" 
            mat-raised-button color="primary" class="schedule-button" matTooltip="Resource Availability : {{bucket.AvailableResources}}" (click)="openTimeSlotDialog(bucket,response.WorkorderNotes,response.Email,response.Address,response.Phone)">
              {{ slotlabel(bucket.SlotStart, bucket.SlotEnd) }}
            </button>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <button mat-raised-button class="buck-back" color="primary" (click)="back()">Back</button>
</div>

  <br>
  <br>
  