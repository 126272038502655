<mat-card class="user-card" style="width: 95%;">  
    <mat-card-header style="margin-bottom: 20px;">      
      Route Optimization  - {{rodate}}     
    </mat-card-header>   
    <mat-card-content>
        <mat-divider></mat-divider>  
        <br>
        <mat-tab-group [disableRipple]="false" color="accent" backgroundColor="primary"  [(selectedIndex)]="tabIndex" style="width: 100%; height: 100%;">
       <br>  
       <mat-tab label="Map View" style="width: 95%; height: 100%;" >
        <div style="height: 35px"  *ngIf="mapSearching" >
          <mat-spinner style="margin:0 auto; margin-top:50px" ></mat-spinner>
          </div>        
           <div id="map"></div>
       </mat-tab>
       <br/>
        <mat-tab label="Tabular View" style="width: 95%; height: 100%;" selected>          
            <br>
            <div style="height: 35px; top:-100px" *ngIf="searching" >
              <mat-spinner style="margin:0 auto;" ></mat-spinner>
              </div> 
                <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100% !important;">

                <ng-container matColumnDef="workorder_id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Workorder</th>
                  <td mat-cell *matCellDef="let element"> {{element.workorder_id}} </td>
                </ng-container>
              
                <ng-container matColumnDef="address">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
                  <td mat-cell *matCellDef="let element"> {{element.address | titlecase}} </td>
                </ng-container>
              
                <ng-container matColumnDef="city">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
                  <td mat-cell *matCellDef="let element"> {{element.city  | titlecase}} </td>
                </ng-container> 

                <ng-container matColumnDef="stateprovince">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>State Province</th>
                    <td mat-cell *matCellDef="let element"> {{element.stateprovince}} </td>
                  </ng-container> 

                  <ng-container matColumnDef="zip">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Zip</th>
                    <td mat-cell *matCellDef="let element"> {{element.zip}} </td>
                  </ng-container> 

                  <ng-container matColumnDef="status_cd">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                    <td mat-cell *matCellDef="let element"> {{element.status_cd | titlecase}}  </td>
                  </ng-container> 
  
                  <ng-container matColumnDef="apptstart">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Appt Start</th>
                      <td mat-cell *matCellDef="let element"> {{element.apptstart}} </td>
                    </ng-container> 
  
                    <ng-container matColumnDef="apptwindow">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Appt Window</th>
                      <td mat-cell *matCellDef="let element"> {{element.apptwindow}} </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="resource">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Technician</th>
                      <td mat-cell *matCellDef="let element"> {{element.resource | titlecase}} </td>
                    </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="curr-cust"></tr>
              </table>       
        </mat-tab> 
     </mat-tab-group>
    </mat-card-content>
  </mat-card>

