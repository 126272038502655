import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Workorder } from 'src/app/models/workorder';
import { WorkorderService } from 'src/app/services/workorder.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-workorder-status-dialog',
  templateUrl: './workorder-status-dialog.component.html',
  styleUrls: ['./workorder-status-dialog.component.scss']
})
export class WorkorderStatusDialogComponent implements OnInit {

  className: any;
  displayedColumns: string[] = ['STATUS_DESC', 'STATUSDATE', 'STATUSDATESYS', 'REASON_DESC', 'USER_ID', 'EXPORTDATE'];
  dataSource = new MatTableDataSource<Workorder>();
  statusData: any;
  constructor(private workorderService: WorkorderService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.className = localStorage.getItem("themeName");
  }

  ngOnInit() {
    this.statusData = this.data;
    this.dataSource.data = this.data;

  }

  radioStatusChange(data) {
    if (data == 'ST') {
      this.dataSource.data = this.statusData.filter((status) => {
        return status.TYPE_CD == 'ST';
      })
    } else if (data == 'MS') {
      this.dataSource.data = this.statusData.filter((status) => {
        return status.TYPE_CD == 'MS';
      })
    } else {
      this.dataSource.data = this.statusData;
    }
  }

}
