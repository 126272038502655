<mat-spinner [diameter]="80" color="primary" *ngIf="searching"
  style="margin:0 auto; width:50% !important; margin-top: 2vw;"></mat-spinner>
<div [hidden]="searching">
  <mat-card class="user-card">
    <mat-card-header style="width: 100% !important;">
      <mat-card-title style="width: 50% !important;margin-top: 0.2vw !important;
          margin-bottom: 0.5vw;font-size: 16px;">
        <!--  <div> -->
        <span matBadge="{{ roles.length }}" matBadgeOverlap="false" style="width: 50% !important; ">Roles</span>
        <!--  </div> -->


      </mat-card-title>
      <div style="width: 100% !important;">
        <button mat-button (click)="addNewRole()" matTooltip="Add"
          style="background: transparent;float:right !important; height: 100% !important">
          <mat-icon matTooltip="Add Role" aria-hidden="false" color="primary" 
          style="text-align: center;
              width: 98%;float:right;">
            add
          </mat-icon>
        </button>
      </div>

    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content style="margin-bottom: 0 !important">
      <br>
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8"
        style="table-layout: fixed; border-spacing: 1">
        <ng-container matColumnDef="RoleId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Role ID</th>
          <td mat-cell *matCellDef="let role" matTooltip="{{ role.RoleId }}"> {{ role.RoleId }} </td>
        </ng-container>

        <ng-container matColumnDef="RoleName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 25%;">Name</th>
          <td mat-cell *matCellDef="let role" matTooltip="{{ role.RoleId }}"> {{role.RoleName}} </td>
        </ng-container>

        <ng-container matColumnDef="RoleActive">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10% !important;">Active</th>
          <td mat-cell *matCellDef="let role">
            <mat-icon matTooltip="Check" *ngIf="role.RoleActive" color="primary">check_box</mat-icon>
            <mat-icon matTooltip="Cancel" *ngIf="!role.RoleActive" style="color: red !important">cancel</mat-icon>
          </td>
        </ng-container>


        <ng-container matColumnDef="CreatedDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</th>
          <td mat-cell *matCellDef="let role"> {{role.CreatedDate  | date:'shortDate' }} </td>
        </ng-container>

        <ng-container matColumnDef="ModifiedBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Modified By</th>
          <td mat-cell *matCellDef="let role" matTooltip="{{ role.ModifiedBy }}"> {{role.ModifiedBy}} </td>
        </ng-container>


        <ng-container matColumnDef="edit" stickyEnd>
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:9% !important"></th>
          <td mat-cell *matCellDef="let role">
            <button mat-button (click)="handleClick(role)" matTooltip="Create">
              <mat-icon color="primary" matTooltip="Edit">create</mat-icon>
            </button>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5,10,20]" showFirstLastButtons></mat-paginator>
    </mat-card-content>
    <!--   <mat-card-actions>
          <button mat-raised-button color="primary" routerLink="/search">DONE</button>
        </mat-card-actions> -->
  </mat-card>
</div>

<div style="margin-bottom: 75px;"></div>