import { Component, OnInit, ViewChild } from '@angular/core';
import { WorkorderService } from '../services/workorder.service';
import { Workorder } from '../models/workorder';
import { DataTransferService } from '../services/data-transfer.service';
import { CustomerService } from '../services/customer.service';
import { Customer } from '../models/customer';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { Attachments } from '../models/attachments';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { WorkorderHistoryDialogComponent } from '../util/workorder-history-dialog/workorder-history-dialog.component';
import { Observable, of } from 'rxjs';
import { fromMatSort, fromMatPaginator, sortRows, paginateRows } from '../util/datasource-utils';
import { map } from 'rxjs/operators';
import { MatAccordion } from '@angular/material/expansion';


@Component({
  selector: 'app-workorder-detail',
  templateUrl: './workorder-detail.component.html',
  styleUrls: ['./workorder-detail.component.scss']
})


export class WorkorderDetailComponent implements OnInit {

  @ViewChild('attpaginator', { static: true }) attpaginator: MatPaginator;
  workorder: Workorder;
  workorderArray: Workorder[];
  showAttachMessage: boolean = false;
  attachmentMessage: any;
  dataSource3 = new MatTableDataSource<Attachments>();
  displayedColumns4: string[] = ['name', 'date'];
  measures: MeasureClass = new MeasureClass();
  customerSearching: boolean = false;

  @ViewChild('MeasurePaginator', { static: true }) paginator: MatPaginator;
  @ViewChild('accordion', { static: true }) accordion: MatAccordion;
  displayedRows$: Observable<MeasureClass[]>;
  totalRows$: Observable<number>;
  measuresSearching: boolean;


  constructor(private workorderService: WorkorderService,
    private dataTransfer: DataTransferService,
    private customerService: CustomerService,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.workorder = this.workorderService.currentWorkorder;
  
    this.workorderArray = [this.workorder];
  }

  ngOnChanges() {
    this.dataTransfer.myMethod(true);
  }

  ngOnInit() {
    this.dataTransfer.myMethod(true);
    this.getWorkorderMeasures(this.workorder.WorkorderId, this.workorder.WorkorderName);


  }

  ngAfterViewInit() {
    this.getAttachments(this.workorder);
  }


  customerClick(customerId) {
    this.customerSearching = true;
    this.customerService.getCustomerById(customerId).subscribe((cust: Customer[]) => {
      this.navigateToCustomer(cust);
    })

    return false;
  }

  navigateToCustomer(cust) {
    const tempcust: Customer = new Customer();
    tempcust.setUpFromWS(cust);
    this.customerService.currentCustomer = tempcust;
    this.customerService.storedCustomers(tempcust);
    this.customerSearching = false;
    this.router.navigate(['/detail']);
  }

  getAttachments(selectedWorkorder) {

    this.workorderService.getWorkorderAttachments(selectedWorkorder)
      .subscribe((data) => {
        this.setAttachments(data);
      }, err => {
        this.showAttachMessage = true;
        this.attachmentMessage = 'No attachments found';

      });

  }

  setAttachments(data) {
    if (data.length === 0 || data === null) {
      this.showAttachMessage = true;
      this.attachmentMessage = 'No attachments found';
    } else {
      this.showAttachMessage = false;
      this.dataSource3.paginator = this.attpaginator;
      this.dataSource3.data = data;
    }
  }

  getWorkorderMeasures(workorderId, workorderName) {
    this.measuresSearching = true;
    this.measures.MeasuresArray = [];
    this.workorderService.getWorkorderMeasures(workorderId, workorderName).subscribe(res => {

      if (Array.isArray(res) && res.length > 0) {
        res.forEach(element => {

          this.measures.MeasuresArray.push(element);


        });
      
        const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);

        const rows$ = of(this.measures.MeasuresArray);

        this.totalRows$ = rows$.pipe(map(rows => rows.length));
        this.displayedRows$ = rows$.pipe(paginateRows(pageEvents$))
      }
      this.measuresSearching = false;
    }, err => {
      this.measuresSearching = false;
    });

  }

  closeAll(){
    this.accordion.closeAll();
  }

  workorderStatus(workorderId) {
    let className = localStorage.getItem("themeName");
    this.workorderService.getWorkorderHistory(workorderId).subscribe(res => {
      try {
        const dialogRef = this.dialog.open(WorkorderHistoryDialogComponent, {
          data: {
            dataKey: res
          },panelClass:className
        });
      }
      catch{
        Swal.fire({
          title: 'Error',
          text: 'Server Side Error',
          icon: 'error',
          confirmButtonText: 'OK',
        })
      }

    }, err => {
      Swal.fire({
        title: 'Error',
        text: err,
        icon: 'error',
        confirmButtonText: 'OK',
      })

    })


    return false;
  }

  handleAttachmentClick(data) {
    let fileName = data.FileName.toLowerCase();
    let fileExtension = fileName.substr(fileName.lastIndexOf('.') + 1);
    let packageID = data.PackageId;
    let fileID = data.Id;
    if (environment.imageFileTypes.includes(fileExtension)) {
      this.workorderService.getImageAttachmentDetail(packageID, fileID, fileExtension, fileName);

    }
    else if (environment.pdfType.includes(fileExtension)) {
      this.workorderService.getPdfAttachmentDetail(packageID, fileID, fileName);
    }
    else if (environment.docTypes.includes(fileExtension)) {
      this.workorderService.getDocAttachmentDetail(packageID, fileID, fileName);
    }
    else if (environment.textTypes.includes(fileExtension)) {
      this.workorderService.getDocAttachmentDetail(packageID, fileID, fileName);
    }
    else if (environment.excelTypes.includes(fileExtension)) {
      if (fileExtension.toLowerCase() === ("csv").toLowerCase()) {
        this.workorderService.getCsvAttachmentDetail(packageID, fileID, fileName)
      }
      else {
        this.workorderService.getExcelAttachmentDetail(packageID, fileID, fileName);
      }

    }
    else {
      Swal.fire({
        text: "File Type Not Supported",
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }




  }
}


export class MeasureClass {

  MeasuresArray: any = [];
  attributeDisplay: any = [];

}