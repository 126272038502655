import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EnrollVerifyComponent } from '../enroll-verify/enroll-verify.component';
import { EnrollmentService } from '../services/enrollment.service';
import { UtilityService } from '../services/utility.service';

@Component({
  selector: 'app-enroll-qualify',
  templateUrl: './enroll-qualify.component.html',
  styleUrls: ['./enroll-qualify.component.scss']
})
export class EnrollQualifyComponent implements OnInit {

  constructor(public enrollService: EnrollmentService,public utilityService: UtilityService, public dialog: MatDialog) { }
  loadFile:boolean=true;
  confirmData: any = {};
  personcompanyid: any;
  questions = [];
  ind: any = -1;
  indCurrentValue: any = '';
  quesCount = 0;
  answeredQues = {};
  answeredQuesCount = 0;
  answered: any = 0;
  countArray: any = [];
  programDetail:string;
  programName:string;
  ownandrent: any;
  ownOrRent: any = {};
  ownorrent: any;
  ownorrentLength: any;
  count: {
    index: Number,
    count: Number
};

  ngOnInit(): void {
    this.confirmData = history.state.data;
    console.log("confirm data",this.confirmData);
    this.personcompanyid = localStorage.getItem('personcompanyid') || this.confirmData.PersonCompanyId;
    this.loadQuestions(this.personcompanyid,  history.state.program.ProgramId);
    localStorage.setItem('programdetailid', history.state.program.ProgramId);
    this.programName=history.state.program.ProgramName;

}
onAnswering(index, count, currentValue) {
    //    console.log(index)
    //    console.log(count)
    //    console.log(currentValue)
    if (this.questions[index] && this.questions[index].QUESTION_REQUIRED) {
        this.answeredQues[index] = true;
    }

   this.answeredQuesCount = Object.keys(this.answeredQues).length;

    if (this.countArray != undefined) {
        if (this.countArray.length > 0) {
            this.ind = this.countArray.findIndex(i => i.index == index)
            this.indCurrentValue = this.countArray.find(function (el) {
                return el.index === index;
            }) || {};
        }
        if (this.ind >= 0 && (currentValue != "")) {
            //this.countArray.push({index:index,currentValue:currentValue});

            this.countArray.forEach((element) => {
                if (element.index === index) {
                    if (!element.currentValue) {
                        if (this.answered <= this.questions.length) {
                            this.answered++;
                        }
                    }
                    element.currentValue = currentValue;
                }
            });

        } else if (currentValue == "") {
            this.answered--;
            this.countArray.forEach((element) => {
                if (element.index === index) {
                    element.currentValue = currentValue;
                }
            });

        }

    }

}

loadQuestions(percompanyid, ProgramdetailId) {
  this.answered = 0;
  this.quesCount = 0;
  this.answeredQues = {};
  this.answeredQuesCount = 0;
  //this.spinner.show();
  this.questions = [];
  
      this.enrollService.getIndividualQuestions(percompanyid, ProgramdetailId).subscribe(res => {
          this.enhanceQuestions(res, percompanyid)
          this.loadFile=false;
          this.ownandrent = res.OwnOrRent;
          console.log("ownandrent", this.ownandrent);
          this.ownOrRent = res.OwnOrRent.Values;
          this.ownorrentLength = res.OwnOrRent.Values.length;
          this.ownorrent = res.OwnOrRent.SelectedValue;
          
          console.log("enrollqualification", res);
      },
      err => {
         // this.spinner.hide();
          console.log(err);
      })

}


enhanceQuestions(res, percompanyid){
    //this.spinner.hide();
    this.questions = res[percompanyid];
    this.questions.map(element => {
        if (element.QUESTION_REQUIRED) {
            this.quesCount++;
        }
    });


    this.questions.forEach((ques, index) => {

        if (ques.QUESTION_TYPE === 'TEXT') {
            if (ques.QUESTION_RESPONSES !== undefined && ques.QUESTION_RESPONSES.length > 0
                && ques.QUESTION_RESPONSES[0].ELIGIBILITY_ID !== undefined) {
                ques.currentValue = ques.QUESTION_RESPONSES[0].RESPONSE_TEXT;
                this.answered++;
                if (this.questions[index].QUESTION_REQUIRED) {
                    this.answeredQues[index] = true;
                }
            } else {
                ques.currentValue = '';
            }
        }
        if (ques.QUESTION_TYPE === 'LABEL') {
            if (ques.QUESTION_RESPONSES !== undefined && ques.QUESTION_RESPONSES.length > 0
                && ques.QUESTION_RESPONSES[0].ELIGIBILITY_ID !== undefined) {
                ques.currentValue = ques.QUESTION_RESPONSES[0].RESPONSE_TEXT;
                this.answered++;
                if (this.questions[index].QUESTION_REQUIRED) {
                    this.answeredQues[index] = true;
                }
            } else {
                ques.currentValue = '';
            }
        }
        if (ques.QUESTION_TYPE === 'NUMBER' || ques.QUESTION_TYPE === 'VALUE') {
            if (ques.QUESTION_RESPONSES !== undefined && ques.QUESTION_RESPONSES.length > 0
                && ques.QUESTION_RESPONSES[0].ELIGIBILITY_ID !== undefined) {
                ques.currentValue = ques.QUESTION_RESPONSES[0].RESPONSE_TEXT;
                this.answered++;
                if (this.questions[index].QUESTION_REQUIRED) {
                    this.answeredQues[index] = true;
                }
            } else {
                ques.currentValue = '0';
            }
        }
        if (ques.QUESTION_TYPE === 'LIST') {
            ques.currentValue = '';
            if (ques.QUESTION_RESPONSES !== undefined && ques.QUESTION_RESPONSES.length > 0) {
                ques.QUESTION_RESPONSES.forEach(resp => {
                    if (resp.ELIGIBILITY_ID !== undefined) {
                        ques.currentValue = resp.RESPONSE_ID;
                        this.answered++;
                        if (this.questions[index].QUESTION_REQUIRED) {
                            this.answeredQues[index] = true;
                        }
                    }
                });
            }
        }
       this.answeredQuesCount = Object.keys(this.answeredQues).length;
        this.countArray.push({ index: index, currentValue: ques.currentValue });
    });
    console.log(this.questions);
}

private buildEligilityList(): any[] {

    const elList = [];

    this.questions.forEach(ques => {
        let respId;
        let respText;
        let eligibility_Id;

        if (ques.QUESTION_TYPE === 'LIST') {
            respId = ques.currentValue;

            const index = ques.QUESTION_RESPONSES.findIndex(val => val.RESPONSE_ID === ques.currentValue);
            const eligibilityIndex = ques.QUESTION_RESPONSES.findIndex(val => val.ELIGIBILITY_ID != null);
            if (index === -1) {
                respText = '';
            } else {
                respText = ques.QUESTION_RESPONSES[index].RESPONSE_TEXT;

            }
            if (eligibilityIndex === -1) {
                eligibility_Id = ques.ELIGIBILITY_ID;
            }
            else {
                eligibility_Id = ques.QUESTION_RESPONSES[eligibilityIndex].ELIGIBILITY_ID;
            }
        } else {
            respId = ques.QUESTION_RESPONSES[0].RESPONSE_ID;
            respText = ques.currentValue;
            eligibility_Id = ques.QUESTION_RESPONSES[0].ELIGIBILITY_ID;
        }

        const el = {
            QUESTION_ID: ques.QUESTION_ID,
            RESPONSE_ID: respId,
            RESPONSE_TEXT: respText,
            ELIGIBILITY_ID: (eligibility_Id != null || eligibility_Id != undefined || eligibility_Id != "") ? eligibility_Id : null
        };

        elList.push(el);
    });

    return elList;
}


public getRecommendations() {
    let className = localStorage.getItem("themeName");

 //ownorrent

 let bodyContent = {
    Email: "",
    PrimaryPhone: "",
    AlternatePhone: "",
    DoNotCall: "false",
    DoNotEmail: "false",
    DoNotMail: "false",
    License: "false",
    HomeType: "",
    Preferredphone: "",
    HomeOwnRent: this.ownorrent,
};

this.enrollService.ownorrent(this.utilityService.currentUtility.OfficeId, this.personcompanyid, bodyContent).subscribe((res: any) => {

})


//ownorrent


    const dialogRef = this.dialog.open(EnrollVerifyComponent,
        {
            data: {
                personcompId: this.personcompanyid,
                elList: this.buildEligilityList(),
                programId: history.state.program.ProgramId,
            }, panelClass: className
        }
    );
    
    dialogRef.afterClosed().subscribe((res) => {
        console.log('inside question close', res)
        this.loadQuestions(this.personcompanyid,  localStorage.getItem('programdetailid'));
        
    });
}


}



