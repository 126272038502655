import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';

import { UserService } from '../../services/user.service';

import { ProgramSponsor } from '../../models/programsponsor';
import { ProgramSponsorDetailComponent } from '../program-sponsor-detail/program-sponsor-detail.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ProgramSponsorCreateComponent } from '../program-sponsor-create/program-sponsor-create.component';

@Component({
    selector: 'app-program-sponsor',
    templateUrl: './program-sponsor.component.html',
    styleUrls: ['./program-sponsor.component.scss']
})
export class ProgramSponsorComponent implements OnInit {

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    programSponsors = [];
    displayedColumns: string[] = ['ProgramSponsorId', 'ProgramSponsorDescription','CompassUri','CompassAuthorization', 'ProgramSponsorActive', 'CreatedDate', 'ModifiedBy', 'edit'];
    dataSource = new MatTableDataSource<ProgramSponsor>();
    dialogRef: MatDialogRef<ProgramSponsorDetailComponent>;
    searching = true;

    constructor(
        private userService: UserService,
        private router: Router, private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.getProgramSponsors();
    }

    private getProgramSponsors() {
        this.userService.getProgramSponsors().subscribe(data => {
            this.programSponsors = data;
            this.dataSource.data = this.programSponsors;
            this.searching = false;
        });
    }

    addNewPSponsor() {
        let className=localStorage.getItem("themeName");
        const dialogCreate = this.dialog.open(ProgramSponsorCreateComponent,{panelClass:className});
        dialogCreate.afterClosed().subscribe(result => {
            this.getProgramSponsors();
        });
    }

    public handleClick(programSponsor: ProgramSponsor) {
        /*   this.router.navigate(['/programSponsorDetail']); */
        let className=localStorage.getItem("themeName");
        this.dialogRef = this.dialog.open(ProgramSponsorDetailComponent,
            {
                data: {
                    dataKey: programSponsor
                },panelClass:className
            });

        this.dialogRef.afterClosed().subscribe(result => {
            this.getProgramSponsors();
        });
    }
}
