<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#ff4081" type = "timer" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
<div [ngClass]="className">
  <div class="mat-app-background">
    <mat-toolbar color="primary"
      style="position:sticky;top:0;z-index:1000; width: 100% !important;height:46px !important;">
      <div style="width:12.9vw">
        <img src="../../assets/honeywell_logo.png" class="mainlogo" style="width: 8.5vw;
      padding-left: 0.5vw;
      padding-right: 0.5vw;
      padding-top: 0.2vw;">
        <span style="font-size: 2.5vw; padding-left: 1vw; margin-bottom: 0.5vw;color: black !important;"> | </span>
      </div>

      <a href="" (click)="goHome();" matTooltip="Home">
        <mat-icon style="font-size:2.5vw; margin-top: 0.5vw;color: whitesmoke; margin-left:1.5vw" aria-hidden="false"
          aria-label="home icon" [inline]="true">home
        </mat-icon>
      </a>
      <a href="" (click)="goHome();" style=" text-decoration: none; cursor: pointer"><span
          style="font-size: 1.5vw; padding-left: 1vw; color: whitesmoke">{{environmentType}}</span></a>
      <div style="margin: 0 auto; vertical-align:middle;text-align:center">
        <span *ngIf="defaultUtility" class="utilSelector">
          <mat-form-field class="utilSelector"
            style="width:22vw;font-size: 1.3vw; padding-right: 5vw;color: whitesmoke">
            <mat-select style="font-size: 1.3vw; color: whitesmoke" [(value)]="defaultUtility"
              [disabled]="disableSelect" (selectionChange)="utilityUpdated(defaultUtility)">
              <mat-option style="font-size: 1.3vw;color: black;" *ngFor="let util of utilList" [value]="util">

                {{ util.ProgramsponsorDescription }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
      </div>

      <button mat-button matTooltip="Branding" [matMenuTriggerFor]="themeMenu" style="font-size:0.5vw;color: whitesmoke;margin-right: 2vw;">
        <mat-icon style="font-size: 2.1vw;
      margin-right: 0.5vw;" [inline]="true">invert_colors</mat-icon>
      </button>



      <mat-menu #themeMenu="matMenu" class="themeMatMenu">
        <button mat-menu-item class="buttonMatMenu" (click)="changeTheme('Indigo', '#3F51B5')"
          style="background: #3F51B5;"></button>
        <button mat-menu-item class="buttonMatMenu" (click)="changeTheme('Grey', '#37474F')" style="background: #37474F;"></button>
        <button mat-menu-item class="buttonMatMenu" (click)="changeTheme('Blue', '#2196F3')" style="background: #2196F3;"></button>
        <button mat-menu-item class="buttonMatMenu" (click)="changeTheme('DeepPurple', '#673AB7')"
          style="background: #673AB7"></button>
        <button mat-menu-item class="buttonMatMenu" (click)="changeTheme('Teal', '#009688')" style="background: #009688"></button>
        <button mat-menu-item class="buttonMatMenu" (click)="changeTheme('Pink', '#880E4F')" style="background: #880E4F"></button>
        <button mat-menu-item class="buttonMatMenu" (click)="changeTheme('Cyan', '#00ACC1')" style="background: #00ACC1"></button>
        <button mat-menu-item class="buttonMatMenu" (click)="changeTheme('Brown', '#795548')" style="background: #795548"></button>
      </mat-menu>


      <div style="margin-top: 0.5vw" matTooltip="Person Pin">
        <mat-icon style="font-size:2.2vw;color: whitesmoke;" aria-hidden="false" aria-label="Example home icon" [inline]="true">
          person_pin
        </mat-icon>
      </div>
      <span style="margin-left: 1vw; font-size:1.2vw;color: whitesmoke">{{loggedInUserName}}</span>

    </mat-toolbar>
    <mat-drawer-container class="flex" color="primary" [autosize]="false">
      <mat-drawer mode="side" opened style="width:6%; overflow:hidden;height: 100% !important;" (mouseenter)="matSelectClick(null)" >
        <div class="table-header-color" style="overflow: hidden; " >
          
          
          <button mat-button  (mouseenter)="matSelectClick('first')" matTooltip="Access Time" *ngIf="resourceRole != 'contractor' && resourceRole != 'distributor' && this.userRole != 'CLIENT' && this.userRole != 'UTILRO'"
            style="font-size:0.5vw;color: whitesmoke;margin-right: 2vw;width: 100% !important;">
            <mat-icon style=" margin-right: 0.5vw !important; pointer-events:all; 
            font-size: 2vw;" aria-hidden="false" aria-label="launch icon" *ngIf="resourceRole != 'contractor' && resourceRole != 'distributor' && this.userRole != 'CLIENT' && this.userRole != 'UTILRO'"  [inline]="true">
              access_time
            </mat-icon>
            <mat-icon style="font-size:2vw" *ngIf="resourceRole != 'contractor' && resourceRole != 'distributor' && this.userRole != 'CLIENT' && this.userRole != 'UTILRO'" [inline]="true">arrow_right</mat-icon>
           <div  #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="lastVisited"></div>
          </button>
          <mat-menu  #lastVisited="matMenu" [hasBackdrop]="false" >
            <span *ngIf="storedCustomers.length > 0" (mouseleave)="menuTrigger.closeMenu()" >
              <button mat-menu-item style="font-size: 0.8vw;" *ngFor="let customer of storedCustomers"
                (click)="goToCustomerDetails(customer);"> {{customer.Name | uppercase}}</button>
            </span>
            <span *ngIf="storedCustomers.length == 0" (mouseleave)="menuTrigger.closeMenu()" >
              No recent items
            </span>
          </mat-menu>
          
          

          <button mat-button (mouseenter)="matSelectClick('second')" matTooltip="Supervisor Account" *ngIf="resourceRole != 'contractor' && resourceRole != 'distributor' && this.userRole != 'CLIENT' && this.userRole != 'UTILRO'"
            style="font-size:0.5vw;color: whitesmoke;margin-right: 2vw;width: 100% !important;">
            <mat-icon style=" margin-right: 0.5vw !important; pointer-events:all;font-size: 2vw;" aria-hidden="false" 
            aria-label="launch icon" *ngIf="resourceRole != 'contractor' && resourceRole != 'distributor' && this.userRole != 'CLIENT' && this.userRole != 'UTILRO'" [inline]="true">
              supervisor_account
            </mat-icon>
            <mat-icon style="font-size:2vw" *ngIf="resourceRole != 'contractor' && resourceRole != 'distributor' && this.userRole != 'CLIENT' && this.userRole != 'UTILRO'" [inline]="true">arrow_right</mat-icon>
            <div #menuTrigger1="matMenuTrigger" [matMenuTriggerFor]="admin"></div>
          </button>

          <mat-menu #admin="matMenu" [hasBackdrop]="false">
            <span (mouseleave)="menuTrigger1.closeMenu()">
              <button mat-menu-item style="font-size: 0.8vw;" *ngFor="let listitem of adminViewList"
                (click)="Navigate(listitem.rl,false);"> {{listitem.name | uppercase}}</button>
            </span>
          </mat-menu>
          
          

          <button mat-button (mouseenter)="matSelectClick('third')" matTooltip="Assessment" *ngIf="resourceRole != 'contractor' && resourceRole != 'distributor'"
            style="font-size:0.5vw;color: whitesmoke;margin-right: 2vw; width: 100%;">
            <mat-icon style=" margin-right: 0.5vw !important; pointer-events:all; 
            font-size: 2vw;" aria-hidden="false" aria-label="launch icon" [inline]="true"
            *ngIf="resourceRole != 'contractor' && resourceRole != 'distributor'"
            >
              assessment
            </mat-icon>
            <mat-icon style="font-size:2vw" [inline]="true" *ngIf="resourceRole != 'contractor' && resourceRole != 'distributor'">arrow_right</mat-icon>
            <div #menuTrigger2="matMenuTrigger" [matMenuTriggerFor]="reportsList"></div>
          </button>
          <mat-menu #reportsList="matMenu" [hasBackdrop]="false">
            <span (mouseleave)="menuTrigger2.closeMenu()">
              <button mat-menu-item style="font-size: 0.8vw;"  *ngFor="let  listitem of reportsViewList"
                (click)="Navigate(listitem.rl,false);"> {{listitem.name | uppercase}}</button>
            </span>
          </mat-menu>
          

          <button mat-button (mouseenter)="matSelectClick('fourth')" matTooltip="Build" *ngIf="resourceRole != 'contractor' && resourceRole != 'distributor' && this.userRole != 'CLIENT' && this.userRole != 'UTILRO'"
            style="font-size:0.5vw;color: whitesmoke;margin-right: 2vw;width: 100%;">
            <mat-icon style=" margin-right: 0.5vw !important; pointer-events:all; 
          font-size: 2vw;" aria-hidden="false" aria-label="launch icon" *ngIf="resourceRole != 'contractor' && resourceRole != 'distributor' && this.userRole != 'CLIENT' && this.userRole != 'UTILRO'" [inline]="true">
              build
            </mat-icon>
            <mat-icon style="font-size:2vw" *ngIf="resourceRole != 'contractor' && resourceRole != 'distributor' && this.userRole != 'CLIENT' && this.userRole != 'UTILRO'" [inline]="true">arrow_right</mat-icon>
            <div #menuTrigger3="matMenuTrigger" [matMenuTriggerFor]="setupList"></div>
          </button>

          <mat-menu #setupList="matMenu" [hasBackdrop]="false">
            <span (mouseleave)="menuTrigger3.closeMenu()">
              <button mat-menu-item style="font-size: 0.8vw;" *ngFor="let  listitem of setupViewList"
                (click)="Navigate(listitem.rl,listitem?.ifDataLoad);"> {{listitem.name | uppercase}}</button>
            </span>
          </mat-menu>
          

          

          <button mat-button (mouseenter)="matSelectClick('fifth')" *ngIf="resourceRole != 'contractor' && resourceRole != 'distributor' && this.userRole != 'CLIENT' && this.userRole != 'UTILRO'"
            style="font-size:0.5vw;color: whitesmoke;margin-right: 2vw;width: 100%;">
            <mat-icon style=" margin-right: 0.5vw !important; pointer-events:all; 
            font-size: 2vw;" aria-hidden="false" aria-label="launch icon" matTooltip="Help Outline"
              *ngIf="resourceRole != 'contractor' && resourceRole != 'distributor' && this.userRole != 'CLIENT' && this.userRole != 'UTILRO'" [inline]="true">
              help_outline
            </mat-icon>
            <mat-icon style="font-size:2vw" *ngIf="resourceRole != 'contractor' && resourceRole != 'distributor' && this.userRole != 'CLIENT' && this.userRole != 'UTILRO'"
              [inline]="true">arrow_right</mat-icon>
            <div #menuTrigger4="matMenuTrigger" [matMenuTriggerFor]="helpList"></div>
          </button>

          <mat-menu #helpList="matMenu" [hasBackdrop]="false">
            <span (mouseleave)="menuTrigger4.closeMenu()">
              <button mat-menu-item style="font-size: 0.8vw;"> HELP</button>
            </span>
          </mat-menu>

          

          

          <button mat-button (mouseenter)="matSelectClick('seven')"
            style="font-size:0.5vw;color: whitesmoke;margin-right: 2vw;width: 100%;">
            <mat-icon style=" margin-right: 0.5vw !important; pointer-events:all; font-size: 2vw;" aria-hidden="false"
              aria-label="launch icon" matTooltip="Dashboard" [inline]="true"
              *ngIf="setupDashboardList.length > 0 && userService.orgCd != null">
              dashboard
            </mat-icon>
            <mat-icon style="font-size:2vw" [inline]="true"
              *ngIf="setupDashboardList.length > 0 && userService.orgCd != null">arrow_right</mat-icon>
            <div #menuTrigger6="matMenuTrigger" [matMenuTriggerFor]="dashboardList"></div>
          </button>

          <mat-menu #dashboardList="matMenu" [hasBackdrop]="false">
            <span (mouseleave)="menuTrigger6.closeMenu()">
              <button mat-menu-item style="font-size: 0.8vw;" *ngFor="let  listitem of setupDashboardList"
                (click)="Navigate(listitem.rl,listitem?.ifDataLoad);"> {{listitem.name | uppercase}}</button>
            </span>
          </mat-menu>

           
          <button mat-button (mouseenter)="matSelectClick('sixth')"
            style="font-size:0.5vw;color: whitesmoke;margin-right: 2vw;width: 100%;">
            <mat-icon style=" margin-right: 0.5vw !important; pointer-events:all; 
          font-size: 2vw;" aria-hidden="false" aria-label="launch icon" matTooltip="Import/Export"
              *ngIf="(importExportMenu | json) != '{}' && importExportMenu != 0"
              [inline]="true">
              import_export
            </mat-icon>
            <mat-icon style="font-size:2vw"
              *ngIf="(importExportMenu | json) != '{}' && importExportMenu != 0"
              [inline]="true">
              arrow_right</mat-icon>
            <div #menuTrigger5="matMenuTrigger" [matMenuTriggerFor]="main_menu"></div>
          </button>

          <mat-menu #main_menu="matMenu" [hasBackdrop]="false">
            <ng-container *ngFor="let mainItem of objectKeys(importExportMenu)">
              <button mat-menu-item style="font-size: 0.8vw;" [matMenuTriggerFor]="sub_menu">{{ mainItem | uppercase
                }}</button>
              <mat-menu #sub_menu="matMenu" [hasBackdrop]="false">
                <button *ngFor="let subItem of importExportMenu[mainItem]" style="font-size: 0.8vw;" mat-menu-item
                  (click)="subMenuImportExportClick(subItem);">{{ subItem | uppercase }}</button>
              </mat-menu>
            </ng-container>
          </mat-menu>

          
          

          <button mat-button (mouseenter)="matSelectClick('eight')"
            style="font-size:0.5vw;color: whitesmoke;margin-right: 2vw;width: 100%;">
            <mat-icon style=" margin-right: 0.5vw !important; pointer-events:all; font-size: 2vw;" aria-hidden="false"
              aria-label="launch icon" matTooltip="Search" [inline]="true" *ngIf="resourceRole == 'contractor' && userService.orgCd != null">
              access_time
            </mat-icon>
            <mat-icon style="font-size:2vw" [inline]="true" *ngIf="resourceRole == 'contractor' && userService.orgCd != null">arrow_right</mat-icon>
            <div #menuTrigger7="matMenuTrigger" [matMenuTriggerFor]="searchListMenu"></div>
          </button>

          <mat-menu #searchListMenu="matMenu" [hasBackdrop]="false">
            <span (mouseleave)="menuTrigger7.closeMenu()">
              <button mat-menu-item style="font-size: 0.8vw;" *ngFor="let  listitem of searchList"
                (click)="Navigate(listitem.rl,listitem?.ifDataLoad);"> {{listitem.name | uppercase}}</button>
            </span>
          </mat-menu>

          
          
        </div>
        <div (mouseenter)="matSelectClick(null)">
        </div>
      </mat-drawer>
      <mat-drawer-content style="overflow: hidden;" (mouseenter)="matSelectClick(null)" >

        <div *ngIf="loadHome" style="min-height: 85.5vh !important;">
          <mat-spinner  style="text-align: center;margin:0 auto; margin-top:5vw"></mat-spinner>
        </div>

        <mat-toolbar-row style="height: 35px;" *ngIf="userService?.orgDesc">
          {{userService?.orgDesc}}
        </mat-toolbar-row>
        
        <div *ngIf="utilList" style="height: 100% !important;min-height: 85.5vh !important; float:right;width:99.5%">

          <router-outlet style="float: right"></router-outlet>

        </div> 
        <mat-toolbar
          style="bottom:0; width: 93% !important; height:38px !important; background: transparent; margin-top: 3vw;">

          <mat-toolbar-row style="height: 17px !important;font-size: 0.9vw; color: red;justify-content: center;
        margin: 0 auto;">
            <span style="text-align: center;">Copyright &copy; {{year}} Honeywell International Inc.</span> 
            
            <!-- <span style="text-align: right;"></span> -->
          </mat-toolbar-row>
          <mat-toolbar-row style="height: 17px !important;font-size: 0.9vw; color: red;margin: 0 auto;">
            <span style="text-align: center; flex: 1 1 auto">Version {{ versionNum }}</span>
            <span style="text-align: right;" matTooltip="BBCSUserId" *ngIf="userService?.dbBBCSId">
              {{userService?.dbBBCSId}}
            </span>
          </mat-toolbar-row>
        </mat-toolbar>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>




