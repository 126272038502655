<mat-card class="user-card" style="width:97%">
  <mat-card-header style="width: 100% !important;">
    <mat-card-title style="width: 50% !important;margin-top: 0.2vw !important;
      margin-bottom: 0.5vw;font-size: 16px;">
      <span matBadge="{{ dataSource.data.length }}" matBadgeOverlap="false"
        style="width: 50% !important; ">Reports</span>
    </mat-card-title>
    <!--    <div style="width: 100% !important;">
        <button mat-button (click)="addNewRole()"
          style="background: transparent;float:right !important; height: 100% !important">
          <mat-icon matTooltip="Add Role" aria-hidden="false" color="primary" style="font-size: 2.4vw; 
          text-align: center;
          width: 98%;float:right; margin-bottom: 0.5vw">
            add
          </mat-icon>
        </button>
      </div> -->

  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content style="margin-bottom: 0 !important">
    <br>
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8"
      style="table-layout: fixed; border-spacing: 1;width: 100%;">


      <ng-container matColumnDef="ReportName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 0.8vw;">Name</th>
        <td mat-cell style="font-size: 0.8vw;" *matCellDef="let reportConfig" matTooltip="{{ reportConfig.ReportName }}">
          {{reportConfig.ReportName}} </td>
      </ng-container>

      <ng-container matColumnDef="ReportDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 0.8vw;">Description</th>
        <td mat-cell style="font-size: 0.8vw;" *matCellDef="let reportConfig"
          matTooltip="{{ reportConfig.ReportDescription }}">
          {{reportConfig.ReportDescription}} </td>
      </ng-container>

      <ng-container matColumnDef="StartDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 0.8vw;">Start Date (mm/dd/yyyy)</th>
        <td mat-cell style="font-size: 0.8vw;" *matCellDef="let reportConfig" >
          <mat-form-field>
            <input matInput [matDatepicker]="fromPicker"  [(ngModel)]="reportConfig.StartDate">

            <!--
            <input matInput [matDatepicker]="fromPicker" *ngIf="reportConfig.StartDate != null"
              [(ngModel)]="reportConfig.StartDate">
            <input matInput [matDatepicker]="fromPicker" *ngIf="reportConfig.StartDate == null"
              [disabled]="reportConfig.StartDate == null">
              -->
            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker></mat-datepicker>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="EndDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 0.8vw;">End Date (mm/dd/yyyy)</th>
        <td mat-cell style="font-size: 0.8vw;" *matCellDef="let reportConfig" >
          <mat-form-field>

            <input matInput [matDatepicker]="toPicker" [(ngModel)]="reportConfig.EndDate">
              

            <!--
            <input matInput [matDatepicker]="toPicker" *ngIf="reportConfig.EndDate != null"
              [(ngModel)]="reportConfig.EndDate">
              <input matInput [matDatepicker]="fromPicker" *ngIf="reportConfig.EndDate == null"
               [disabled]="reportConfig.StartDate == null">
              -->
            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
            <mat-datepicker #toPicker></mat-datepicker>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="extract">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;font-weight: bold;width: 20% !important;"></th>
        <td mat-cell *matCellDef="let reportConfig">
          <button matTooltip="Insights" mat-button (click)="reportExtractClick(reportConfig)">
            <mat-icon color="primary" matTooltip="View Report">insights</mat-icon>
          </button>

        </td>
      </ng-container>




      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5,10,20]" showFirstLastButtons></mat-paginator>
  </mat-card-content>
</mat-card>
<div style="margin-bottom: 75px;"></div>