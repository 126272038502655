import { Component, OnInit, ViewChild, Output, EventEmitter, HostListener, Input } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import {CommonModule} from '@angular/common';
import { Location } from '@angular/common'

import { BucketschedulingService } from '../services/bucketscheduling.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TimeslotDialogComponent } from '../util/timeslot-dialog/timeslot-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DataTransferService } from '../services/data-transfer.service';
@Component({
  selector: 'app-bucket-schedule',
  templateUrl: './bucket-scheduling.component.html',
  styleUrls: ['./bucket-scheduling.component.scss']
})
export class BucketScheduleComponent implements OnInit {

  selectedDay:any = new Date();
  selectedDate:any;
  title = 'bucketscheduling';
  days = [];
  minDay:any = new Date();
  minDate:any;
  selectedWorkorder:any;
  stringDate:any;
  response:any;
  className:any;

  constructor(public bucketService: BucketschedulingService,public router:Router, private location: Location,
    public route:ActivatedRoute,public dialog:MatDialog,public dataTransfer:DataTransferService) {
      this.className = localStorage.getItem("themeName");
  }

  ngOnInit(): void {
    this.className = localStorage.getItem("themeName");
    this.dataTransfer.myMethod(true);
    this.selectedWorkorder =  this.route.snapshot.paramMap.get("workorderID");
    this.selectedDate = new Date(this.selectedDay);
    this.selectedDate.setDate(this.selectedDate.getDate()+1)
  //  this.stringDate = moment(this.selectedDate).format("MM/DD/YYYY");
    this.minDate = new Date(this.minDay);
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.loadDays();
  }

  loadDays(): void {
    this.days = [];
    this.response = null;
    this.stringDate = moment(this.selectedDate).format("MM/DD/YYYY");
   
    const now = moment(this.selectedDate);
   
      this.days.push(moment([now.year(), now.month(), now.date()]));


   this.slotsForDay(this.selectedWorkorder);
  }

  onDate(e){
    this.loadDays();
  }

  back(): void {
    this.location.back()
  }

  slotsForDay(selectedWO){
  
    this.bucketService.getSlotsForDay(this.stringDate,selectedWO).subscribe(res=>{
 
      this.response = res;
      console.log(res);
    })
  }

  formatPhoneNumber(phoneNumberString) {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  openTimeSlotDialog(bucket,workorderNotes,email,address,phone){
    let className = localStorage.getItem("themeName");
    const dialogRef = this.dialog.open(TimeslotDialogComponent, {
      data: {
        bucketObj: bucket,
        workorderNotes: workorderNotes,
        email: email,
        address:address,
        phone:phone,
        workorderId:this.selectedWorkorder
      },
      panelClass: className,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if(res){
    //    this.loadDays();
    this.router.navigate(['/search']);
      }
    });
  }
  

  slotlabel(start: string, end: string): string {
   
    const date1 = new Date(start);
    const date2 = new Date(end);

    let h1 = date1.getHours().toString();
    let m1 = date1.getMinutes().toString();
    let ampm = 'am';

    if (date1.getHours() > 11) {
      ampm = 'pm';
    }

    if (date1.getHours() > 12) {
      h1 = (date1.getHours() - 12).toString();
    }

    if (date1.getMinutes() < 10) {
      m1 = '0' + m1;
    }

    let h2 = date2.getHours().toString();
    let m2 = date2.getMinutes().toString();


    if (date2.getHours() > 12) {
      h2 = (date2.getHours() - 12).toString();
    }

    if (date2.getMinutes() < 10) {
      m2 = '0' + m2;
    }

    let res = h1 + ":" + m1 + " to " + h2 + ":" + m2+ " "  + ampm;

    return res;
  }

}
