import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../services/utility.service';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { WorkorderExceptions } from '../models/workorderexceptions';
import { LocationSearch } from '../models/location';
import { url } from 'inspector';
@Injectable({
  providedIn: 'root'
})
export class LocationsearchService {
  private woSearchWsUrl1 = environment.apiUrl + 'workorder/';
  public scheduledate: any;
  public grid: any;
  public workorderID:any;
  public personcompany:any;
  public schdate:any;
  constructor(private http: HttpClient, private utilityService: UtilityService) { }
  public getHeaders() {
    const email = localStorage.getItem('loggedInUserEmail');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-email': email,
        'x-programsponsorid': this.utilityService.currentUtility.ProgramsponsorId
      })
    };
    return httpOptions;
  }
  setlocationparameters(scheduledate, grid) {
    this.scheduledate = scheduledate;
    this.grid=grid;
  }

  getlocationScheduledate() {
    return this.scheduledate;
}
getlocationgrid() {
  return this.grid;
}

individualAuthorization(personcompany,workorderId,scheduleDate,Organiztion_cd){
  const searchUrl = environment.apiUrl +"bbcs/schedule/individual/authorized/"+
  this.utilityService.currentUtility.OfficeId+"/organization/"+Organiztion_cd 
  + '?code=' + environment.apiCode;

  const body = {
    workorderId:workorderId,
    personcompanyId:personcompany,
    scheduleDate:scheduleDate

  }
  console.log(workorderId);
  return this.http.post<LocationSearch>(searchUrl, body, this.getHeaders())
    .pipe(map(response => response),
      catchError(err => this.utilityService.handleError(err, this, []))
    );
}

setschedulingparameters(personcompany,grid,workorderID,schdate) {
  this.personcompany=personcompany;
  this.grid=grid;
  this.workorderID=workorderID;
  this.schdate=schdate;

}
  getLocation(response, Organiztion_cd): Observable<LocationSearch> {

    const searchUrl = this.woSearchWsUrl1 + "shedule/locationsearch/"+ this.utilityService.currentUtility.OfficeId + "/organization/" + Organiztion_cd
    + '?code=' + environment.apiCode;

    const body = response;
    return this.http.post<LocationSearch>(searchUrl, body, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }
}
