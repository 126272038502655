<mat-card>
  <mat-tab-group #searchTabs color="accent" backgroundColor="primary" style="margin:1vw"
    (selectedTabChange)="tabChanged($event)">
    <mat-tab label="Associate Menu Office">
      <br>
      <div style="margin: 1vw;">
        <form [formGroup]="firstFormGroup" style="width:52% !important;float: left;">
          <mat-form-field appearance="outline" style="padding-right: 2.2vw;width:17.6vw;font-size: 1vw;">
            <mat-label>Select Menu Item</mat-label>
            <mat-select formControlName="firstCtrl" style="font-size: 11px;" (selectionChange)="menuChange($event)">
              <mat-option *ngFor="let menu of menuList" [value]="menu" style="font-size: 11px;">{{menu.MenuName}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" style="padding-right: 2.2vw;width:17.6vw;font-size: 1vw;">
            <mat-label>Select Office</mat-label>
            <mat-select formControlName="secondCtrl"style="font-size: 11px;">
              <mat-option *ngFor="let office of officeList" [value]="office" style="font-size: 11px;">{{office.OfficeDescription}}</mat-option>
            </mat-select>
          </mat-form-field>


        </form>


        <mat-slide-toggle [(ngModel)]="menuOfficeActive" style="font-size: 1vw;">Active
        </mat-slide-toggle>

        <button style="margin-left:1vw; margin-top:1vw" mat-raised-button color="primary" (click)="associateMenuOffice()">Save</button>
        <br>
        <br>
        <table *ngIf="menuOfficeList.length > 0" mat-table [dataSource]="dataSource1" matSort (matSortChange)="sortMenuOfficeData($event)" class="mat-elevation-z8" style="margin-top:1vw">
          <ng-container matColumnDef="MenuName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 18%">Menu</th>
            <td mat-cell *matCellDef="let menuOffice" matTooltip="{{ menuOffice.MenuName }}">
              {{ menuOffice.MenuName }} </td>
          </ng-container>

          <ng-container matColumnDef="MenuDescription">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 17%">Office</th>
            <td mat-cell *matCellDef="let menuOffice" matTooltip="{{ menuOffice.MenuDescription }}">
              {{ menuOffice.MenuDescription }} </td>
          </ng-container>
          <ng-container matColumnDef="edit" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 6% !important"></th>
            <td mat-cell *matCellDef="let menuOffice">
              <mat-slide-toggle (toggleChange)="deactivateAssociation(menuOffice)" [(ngModel)]="menuOffice.MenuActive" style="font-size: 1vw;">Active
              </mat-slide-toggle>
              <!-- <button mat-icon-button color="accent" (click)="deactivateAssociation(menuOffice)">
                <mat-icon  matTooltip="Off" *ngIf="!menuOffice.MenuActive" matTooltip="Activate" color="primary">toggle_off</mat-icon>
                <mat-icon  matTooltip="On" *ngIf="menuOffice.MenuActive" matTooltip="Deactivate">toggle_on</mat-icon>
              </button> -->

            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns1;" class="curr-cust"></tr>
        </table>
        <mat-paginator #paginator [pageSizeOptions]="[5,10,20]" showFirstLastButtons></mat-paginator>

      </div>
    </mat-tab>


    <mat-tab label="Create Report">
      <br>
      <div style="margin: 1vw;">
        <form [formGroup]="secondFormGroup">
          <mat-form-field appearance="outline" style="padding-right: 2.2vw;width:17.6vw;font-size: 1vw;">
            <mat-label>Report ID</mat-label>
            <input matInput formControlName="firstCtrl" placeholder="ID" required>
          </mat-form-field>
          <mat-form-field appearance="outline" style="padding-right: 2.2vw;width:17.6vw;font-size: 1vw;">
            <mat-label>Report Name</mat-label>
            <input matInput formControlName="secondCtrl" placeholder="Name" required>
          </mat-form-field>
          <mat-form-field appearance="outline" style="padding-right: 2.2vw;width:17.6vw;font-size: 1vw;">
            <mat-label>Report Description</mat-label>
            <input matInput formControlName="thirdCtrl" placeholder="Description" required>
          </mat-form-field>
          <mat-form-field appearance="outline" style="padding-right: 2.2vw;width:17.6vw;font-size: 1vw;">
            <mat-label>Report Internal Path</mat-label>
            <input matInput formControlName="fourthCtrl" placeholder="Path" required>
          </mat-form-field>
          <mat-form-field appearance="outline" style="padding-right: 2.2vw;width:17.6vw;font-size: 1vw;">
            <mat-label>Report External Path</mat-label>
            <input matInput formControlName="fifthCtrl" placeholder="Path" required>
          </mat-form-field>
          <mat-form-field appearance="outline" style="padding-right: 2.2vw;width:17.6vw;font-size: 1vw;">
            <mat-label>Select Menu Item</mat-label>
            <mat-select formControlName="MenuCtrl" style="font-size: 11px;">
              <mat-option *ngFor="let menu of menuList" [value]="menu" style="font-size: 11px;">{{menu.MenuName}}</mat-option>
            </mat-select>
          </mat-form-field>
          <br>
        </form>
        <mat-slide-toggle class="formfield-half" style="font-size: 1vw;" [(ngModel)]="dateControl">Date
          Control</mat-slide-toggle>
        <mat-slide-toggle class="formfield-half" style="font-size: 1vw;" [(ngModel)]="activeReport">Active
        </mat-slide-toggle>
        <br>
        <div style="margin-top: 1vw;">
          <button mat-raised-button color="primary" (click)="createReport()">Save</button>
        </div>

      </div>
    </mat-tab>

    <mat-tab label="Report Configuration">
      <div style="width: 100%;">
      <div style="margin-top: 1vw;">
        <mat-form-field appearance="outline" style="padding-right: 2.2vw;width:17.6vw;font-size: 1vw;">
          <mat-label>Select Menu Item</mat-label>
          <mat-select [(ngModel)]="selectedMenu" style="font-size: 11px;">
            <mat-option *ngFor="let menu of menuList" [value]="menu" style="font-size: 11px;">{{menu.MenuName}}</mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="viewReportConfigurations()">View</button>
        
      </div>
      <br>

      <div *ngIf="searching">
        <mat-spinner [diameter]="25" color="primary"></mat-spinner>
      </div>
      <div [hidden]="searchResult">
        <table *ngIf="searchResultList.length > 0 && !searching" mat-table [dataSource]="dataSource" matSort (matSortChange)="sortReportData($event)"
          class="mat-elevation-z8">
          <ng-container matColumnDef="ReportId">
            <th mat-header-cell *matHeaderCellDef style="font-size: 0.8vw; width: 17% !important;" mat-sort-header>ID</th>
            <td mat-cell style="font-size: 0.8vw;" *matCellDef="let reportConfig"
              matTooltip="{{ reportConfig.ReportId }}">
              {{ reportConfig.ReportId }} </td>
          </ng-container>

          <ng-container matColumnDef="ReportName">
            <th mat-header-cell *matHeaderCellDef style="font-size: 0.8vw;" mat-sort-header >Name</th>
            <td mat-cell style="font-size: 0.8vw;" *matCellDef="let reportConfig"
              matTooltip="{{ reportConfig.ReportName }}">
              {{reportConfig.ReportName}} </td>
          </ng-container>

          <ng-container matColumnDef="ReportDescription">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 0.8vw;">Description</th>
            <td mat-cell style="font-size: 0.8vw;" *matCellDef="let reportConfig"
              matTooltip="{{ reportConfig.ReportDescription }}">
              {{reportConfig.ReportDescription}} </td>
          </ng-container>

          <ng-container matColumnDef="ReportMenuName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 0.8vw;">Menu</th>
            <td mat-cell style="font-size: 0.8vw;" *matCellDef="let reportConfig"
              matTooltip="{{ reportConfig.ReportMenuName }}">
              {{reportConfig.ReportMenuName}} </td>
          </ng-container>

          <ng-container matColumnDef="ReportPath">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 0.8vw;  
            " >Path</th>
            <td mat-cell style="font-size: 0.8vw;word-wrap: break-word !important;" *matCellDef="let reportConfig"
              matTooltip="{{ reportConfig.ReportPath }}">
              {{reportConfig.ReportPath}} </td>
          </ng-container>

          <ng-container matColumnDef="ExternalReportPath">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 0.8vw;">External Path</th>
            <td mat-cell style="font-size: 0.8vw;  word-wrap: break-word !important;
            " *matCellDef="let reportConfig"
              matTooltip="{{ reportConfig.ExternalReportPath }}">
              {{reportConfig.ExternalReportPath}} </td>
          </ng-container>

          <ng-container matColumnDef="DateControlParameter">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 0.8vw;">Date Control</th>
            <td mat-cell style="font-size: 0.8vw;" *matCellDef="let reportConfig" >
              <mat-icon matTooltip="Check" *ngIf="reportConfig.DateControlParameter" color="primary">check_box</mat-icon>
              <mat-icon matTooltip="Cancel" *ngIf="!reportConfig.DateControlParameter" style="color: red !important">cancel</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="ReportActive">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 0.8vw;">Active</th>
            <td mat-cell style="font-size: 0.8vw;" *matCellDef="let reportConfig" >
              <mat-icon matTooltip="Check" *ngIf="reportConfig.ReportActive" color="primary">check_box</mat-icon>
              <mat-icon matTooltip="Cancel" *ngIf="!reportConfig.ReportActive" style="color: red !important">cancel</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td style="font-size: 1vw;" mat-cell *matCellDef="let reportConfig">
              <button mat-button (click)="editReport(reportConfig)" matTooltip="Create">
                <mat-icon matTooltip="Edit" color="primary">create</mat-icon>
              </button>

            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="curr-cust"></tr>
        </table>
        <!-- <mat-paginator #firstpaginator [pageSizeOptions]="[5,10,20]" showFirstLastButtons></mat-paginator> -->

        <label *ngIf="searchResultList.length == 0 && !searching">No search results</label>

      </div>
    </div>
    </mat-tab>

  </mat-tab-group>
</mat-card>