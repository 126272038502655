import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { WorkorderService } from 'src/app/services/workorder.service';
import { UtilityService } from 'src/app/services/utility.service';
import Swal from 'sweetalert2';

@Component({
  selector: "app-upload-dialog",
  templateUrl: "./upload-dialog.component.html",
  styleUrls: ["./upload-dialog.component.scss"],
})
export class UploadDialogComponent implements OnInit {

  fileTypes: any = [];
  file: any;
  fileObj: any;
  result: any;
  selectedImageCode: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private workorderService: WorkorderService,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.workorderService.getFileTypesForUpload().subscribe((res) => {
      this.fileTypes = res;
    });
  }

  handleFiles(e) {
    this.file = e.target.files[0];

  

    let fileReader = new FileReader();
    fileReader.onload = (e) => {

    
      this.result = fileReader.result;
    };

    let objContent = fileReader.readAsDataURL(this.file);
  }

  uploadDoc() {

    if (this.fileObj != null && this.selectedImageCode != null) {
      this.callUpload();
    } else {
      Swal.fire({
        text: "File upload error. Please enter all the fields.",
        icon: "error",
        confirmButtonText: "OK",
      }); /* .then(() => {
      
      }) */
    }


  }

  callUpload() {
    this.result = this.result.split(",")[1];
    let File = this.data.lineItemID != null ? this.data.lineItemID + '_' + this.file.name : this.file.name;
    let body =
    {
      File: File,
      ImageCode: this.selectedImageCode,
      User: this.utilityService.currentUtility.BBCSUserId,
      Content: this.result,
    }
    
    this.workorderService
      .uploadFile(this.file.fileName, body, this.data.workOrderID)
      .subscribe((res: any) => {
      if (res.Success) {
        Swal.fire({
          text: "File uploaded successfully!",
            icon: "success",
            confirmButtonText: "OK",
        }).then(() => {
          this.dialog.closeAll();
          });
        } else {
        Swal.fire({
          text: res.ErrorMessage,
            icon: "error",
            confirmButtonText: "OK",
        }).then(() => {
          this.dialog.closeAll();
          });
      }
      });
  }

}
