import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Workorder } from 'src/app/models/workorder';
import { WorkorderService } from 'src/app/services/workorder.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-workorder-history-dialog',
  templateUrl: './workorder-history-dialog.component.html',
  styleUrls: ['./workorder-history-dialog.component.scss']
})
export class WorkorderHistoryDialogComponent implements OnInit {

  className: any;
  displayedColumns: string[] = ['date', 'status', 'createdDate', 'name'];
  dataSource = new MatTableDataSource<Workorder>();
  constructor(private workorderService: WorkorderService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.className = localStorage.getItem("themeName");
  }



  ngOnInit() {

    this.dataSource.data = this.data.dataKey.WorkorderStatusHistory;

  }

}
