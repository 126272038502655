import { Component, OnInit, ViewChild } from "@angular/core";
import { WorkorderService } from "../../services/workorder.service";
import { NgxSpinnerService } from "ngx-spinner";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UtilityService } from "src/app/services/utility.service";
import { MatAccordion } from "@angular/material/expansion";
import { Inject } from "@angular/core";
import { KeyValue } from "@angular/common";

@Component({
  selector: "app-comparison-screen-dialog",
  templateUrl: "./comparison-screen-dialog.component.html",
  styleUrls: ["./comparison-screen-dialog.component.scss"],
})
export class ComparisonScreenDialogComponent implements OnInit {
  constructor(
    private workorderService: WorkorderService,
    private utilityService: UtilityService,
    private dialog: MatDialogRef<ComparisonScreenDialogComponent>,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.className = localStorage.getItem("themeName");
  }
  loggedinuserrole: any = this.utilityService.currentUtility.LoggedInUserRole;
  response: any = [];
  workorderID: any = this.workorderService.currentWorkorderID;
  IAWorkorder: any = this.workorderService.currentIAWorkorderID;

  @ViewChild("Woaccordion", { static: false }) Woaccordion: MatAccordion;
  className: any;
  message: any;
  ngOnInit() {
    this.loadComparisonWorkorder();
  }

  loadComparisonWorkorder() {
    this.workorderService
      .getComparisonDetail(
        this.workorderID,
        this.IAWorkorder,
        localStorage.getItem("subGroup"),
        localStorage.getItem("subGroup1")
      )
      .subscribe((res) => {
        this.response = res;
        if (this.response.ComparisonGridValue != null) {
          if (Object.keys(this.response).length > 0) {
            this.spinner.hide();
          }
        }
        else {
          this.spinner.hide();
          this.message = "IA and Rebate measures are same. Click Submit to continue.";
        }
      });
  }

  handleCancel(resp) {
    this.dialog.close({ data: resp });
  }

  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };
}