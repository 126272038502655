import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from 'src/app/services/utility.service';
import { WorkorderService } from 'src/app/services/workorder.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-workorder',
  templateUrl: './add-workorder.component.html',
  styleUrls: ['./add-workorder.component.scss']
})
export class AddWorkorderComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog, private workorderService: WorkorderService,
    private utilityService: UtilityService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private userService: UserService) { }

  programs: any = [];
  selectedQaWorkOrder: any;
  ngOnInit(): void {
    if (this.data != null && this.data != undefined) {
      this.workorderService.getProgramsAtEnrollmentLevel(this.data).subscribe((res: any) => {
        
        this.programs = res.Programs;
        //select program automatically if there is only one option avalible
        if (this.programs.length == 1){
          this.selectedQaWorkOrder = this.programs[0].ProgramId;        
        }
      })
    }
    else {
      this.workorderService.getAllProgramsForAllEnrollments().subscribe((res: any) => {
       
        this.programs = res.Programs;
      })
    }
  }

  openQaWorkOrder() {
    const selProgram = this.programs.find(item => item.ProgramId == this.selectedQaWorkOrder)
    const cust = JSON.parse(localStorage.getItem('storedCustomers'))
    const selPer = cust[0].PersonCompanyId;
    const data = {
      "PersoncompanyId": selPer,
      "EnrollmentId": this.data ? this.data : 'All',
      "ProgramdetailId": selProgram.ProgramId,
      "UserId": this.utilityService.currentUtility.BBCSUserId,
      "OrgCode": this.userService.orgCd,
      "UserRole": this.utilityService.currentUtility.LoggedInUserRole
    };
    this.workorderService.createWorkorder(data, selProgram.Group, selProgram.SubGroup).subscribe((res: any) => {
      
      this.dialog.closeAll();
	  this.spinner.hide();
      if(res.Action)
      {
         Swal.fire(res.Message);
      }
      else
      {
      
      const category = selProgram.Group;
      const subCategory = selProgram.SubGroup;
      const WorkorderId = res.WorkorderId;
      this.workorderService.currentWorkorderID= WorkorderId;
      this.router.navigate(['workorderGetSave', category, subCategory, WorkorderId,selProgram?.SubGroup1 ?? "ALL" ]);
      }
    },
    err => {
      this.spinner.hide();
      Swal.fire({
        text: 'Error',
        icon: 'error',
        confirmButtonText: 'OK',
      })
    })

  }

}
