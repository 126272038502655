import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { Role } from 'src/app/models/role';
import { _MatSlideToggleRequiredValidatorModule } from '@angular/material/slide-toggle';
import Swal from 'sweetalert2';
import { WaitDialogComponent } from '../../util/wait-dialog/wait-dialog.component';

@Component({
    selector: 'app-role-detail',
    templateUrl: './role-detail.component.html',
    styleUrls: ['./role-detail.component.scss']
})
export class RoleDetailComponent implements OnInit {

    roleData: any;
    updatedName: any;
    updatedActive: boolean;
    IfMandatory: boolean = false;
    errorMessage: any;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog,
        private router: Router,
        private userService: UserService) { }

    ngOnInit() {
        this.roleData = this.data.dataKey;
        this.updatedName = this.roleData.RoleName;
        this.updatedActive = this.roleData.RoleActive;
    }

    checkAllFieldsAreEmpty() {

        if ((this.updatedName == null || this.updatedName == undefined || this.updatedName == "")) {
            this.IfMandatory = true;
        }
        else {
            this.IfMandatory = false;
        }
    }

    clearMessage(){
        this.errorMessage="";
    }

    saveRole() {
        Swal.fire({
            title: 'Save Changes?',
            text: "Changing this update your configuration.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Save Changes'
        }).then((result) => {
            if (result.value) {
                this.doSaveRole();
            }
        });
      }
    
      private doSaveRole() {
        this.checkAllFieldsAreEmpty();
        if (!this.IfMandatory) {
            let className = localStorage.getItem("themeName");
            const dialogRef = this.dialog.open(WaitDialogComponent, {data: {caption: 'Saving...'},panelClass:className});
            let role = new Role();
            role.RoleId = this.roleData.RoleId;
            role.RoleName = this.updatedName;
            role.RoleActive = this.updatedActive;
            role.ModifiedBy = localStorage.getItem("loggedInUserName");
            this.userService.updateRoles(role).subscribe(res => {
                dialogRef.close();
                if (res == true) {
                    Swal.fire({
                        text: "Updated Successfully",
                        icon: 'success',
                        confirmButtonText: 'OK',
                    }).then(() => {
                        this.dialog.closeAll();
                    })
                }
                else {
                    Swal.fire({
                        text: "Update failed",
                        icon: 'error',
                        confirmButtonText: 'OK',
                    }).then(() => {
                        this.dialog.closeAll();
                    })
                }
            }, err => {
                Swal.fire({
                    text: "Update failed",
                    icon: 'error',
                    confirmButtonText: 'OK',
                }).then(() => {
                    this.dialog.closeAll();
                })
            })

        }
        else {
            this.errorMessage = "All fields are mandatory";
        }
    }

    cancelRole() {

        this.dialog.closeAll();
        this.router.navigate(['roleAdmin']);
    }

}
