<mat-dialog-content class="comparison-wrapper">
  <table
    *ngIf="response.ComparisonGridValue?.length > 0"
    class="mat-elevation-z8 table comparison-table"
  >
    <thead>
      <tr>
        <th
          class="comparsionTableHeader"
          *ngFor="
            let head of response.ComparisonGridValue[0] | keyvalue
          "
        >
           {{ head.key =='CurrentInitialAssesment' ? 'Current Initial Assessment' : 'Just Entered' }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr colspan="2" *ngFor="let item of response.ComparisonGridValue">
        <td class="table-text">
          <span
            *ngFor="
              let CurrentIA of item.CurrentInitialAssesment[0]
                | keyvalue: originalOrder
            "
          >
          
            <b *ngIf="CurrentIA.key == 'Measure Name'" class="measureName">{{
              CurrentIA.value
            }}</b>

            <ul class="textLeftAlign">
              <li *ngIf="CurrentIA.key != 'Measure Name'">
                <b>{{ CurrentIA.key }}</b> : {{ CurrentIA.value }}
              </li>
            </ul>
          </span>
        </td>
        <td class="table-text">
          <span
            *ngFor="
              let JustEntered of item.JustEntered[0] | keyvalue : originalOrder
            "
          >
            <center>
            <b *ngIf="JustEntered.key == 'Measure Name'" class="measureName">{{
              JustEntered.value
            }}</b>
            </center>
            <ul class="textLeftAlign">
              <li *ngIf="JustEntered.key != 'Measure Name'">
                <b>{{ JustEntered.key }}</b> : {{ JustEntered.value }}
              </li>
            </ul>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
  <ng-container *ngIf="response.ComparisonGridValue == null">
   {{message}}
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>Back</button>
  <button
    mat-raised-button
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial
    (click)="handleCancel(data?.workOrderResponseData)"
    class="submit-button"
  >
    Submit
  </button>
</mat-dialog-actions>
