<mat-card class="user-card" style="width: 95%">
  <mat-card-header>
    <mat-card-title style="font-size: 16px">
      <div style="display: inline-block">
        Utility Details:
        {{
        distributorService?.indDetail?.Individuals?.selectedUtilityId ??
        distributorService?.selectedUtility
        }}
      </div>
      <div style="display: inline-block; padding-left: 40px">
        Distributor Details:
        {{
        distributorService?.indDetail?.DistributorDetail?.DistributorName ??
        distributorName.value
        }}
      </div>
    </mat-card-title>
    <mat-card-subtitle>
      Click Save when you are done making all your changes.
    </mat-card-subtitle>
    <div class="tp-button">
      <button mat-raised-button color="primary" routerLink="/distributormaintenance/1">
        Back
      </button>
    </div>
  </mat-card-header>
  <mat-divider></mat-divider>
  <br />
  <mat-card-content>
    <mat-tab-group color="accent" backgroundColor="primary" (selectedTabChange)="SKUdetails($event)">
      <mat-tab label="Distributors">
        <br />
        <div class="center" style="width: 90%">
          <mat-card-content>
            <fieldset>
              <legend>Distributor Details</legend>
              <br />
              <mat-form-field appearance="outline" class="mat-field-width32">
                <mat-label>Name</mat-label>
                <input oninput="this.value = this.value.toUpperCase()" matInput required [formControl]="distributorName"
                  [ngModel]="
                    distributorService?.indDetail?.DistributorDetail
                      ?.DistributorName
                  " />
                <mat-error *ngIf="distributorName.invalid">Distributor Name is required</mat-error>
                <mat-error *ngIf="distributorName?.value?.length > 40">Max length is 40 characters</mat-error>
                <mat-hint align="end" style="margin-top: 2px">{{ distributorName?.value?.length || 0 }}/40</mat-hint>
              </mat-form-field>

              <mat-form-field appearance="outline" class="mat-field-width32">
                <mat-label>Short Name</mat-label>
                <input oninput="this.value = this.value.toUpperCase()" matInput [formControl]="shortName" [ngModel]="
                    distributorService?.indDetail?.DistributorDetail
                      ?.DistributorShortName
                  " />
                <mat-error *ngIf="shortName.invalid">Distributor Short Name is required</mat-error>
                <mat-error *ngIf="shortName?.value?.length > 20">Max length is 20 characters</mat-error>
                <mat-hint align="end" style="margin-top: 2px">{{ shortName?.value?.length || 0 }}/20</mat-hint>
              </mat-form-field>

              <mat-form-field appearance="outline" class="mat-field-width32">
                <mat-label>Code</mat-label>
                <input oninput="this.value = this.value.toUpperCase()" matInput required maxlength="6"
                  [formControl]="code" [ngModel]="
                    distributorService?.indDetail?.DistributorDetail
                      ?.DistributorCode
                  " />

                <mat-error *ngIf="code?.value?.length > 6">Max length is 6 characters</mat-error>
                <mat-error *ngIf="code?.value?.length < 6">Min length is 6 characters</mat-error>
                <mat-error *ngIf="code?.value?.invalid">Distributor Code is Required. No spaces</mat-error>
              </mat-form-field>
            </fieldset>
            <br />
            <fieldset>
              <legend>Account/Company Details</legend>
              <br />
              <mat-form-field appearance="outline" class="mat-field-width32">
                <mat-label>Company Name</mat-label>
                <input matInput oninput="this.value = this.value.toUpperCase()" required [formControl]="companyName"
                  [ngModel]="
                    distributorService?.indDetail?.DistributorDetail
                      ?.CompanyName
                  " />
              </mat-form-field>
              <mat-form-field appearance="outline" class="mat-field-width32">
                <mat-label>Account Number</mat-label>
                <input matInput oninput="this.value = this.value.toUpperCase()" [formControl]="accountNumber" required
                  [ngModel]="
                    distributorService?.indDetail?.DistributorDetail
                      ?.accountNumber
                  " />
                <mat-error *ngIf="stNo.invalid">Account number is required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="mat-field-width32">
                <mat-label>Rate Code</mat-label>
                <input matInput oninput="this.value = this.value.toUpperCase()" [formControl]="rateCode" [ngModel]="
                    distributorService?.indDetail?.DistributorDetail?.rateCode
                  " />
              </mat-form-field>
            </fieldset>
            <br />

            <fieldset>
              <legend>Address Details</legend>
              <br />
              <mat-form-field appearance="outline" class="mat-field-width32">
                <mat-label>Street Number</mat-label>
                <input matInput required [formControl]="stNo" [ngModel]="
                    distributorService?.indDetail?.DistributorDetail
                      ?.StreetNumber
                  " />
                <mat-error *ngIf="stNo.invalid">A number is required</mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" class="mat-field-width32">
                <mat-label>Street Name</mat-label>
                <input oninput="this.value = this.value.toUpperCase()" matInput required [formControl]="stName"
                  [ngModel]="
                    distributorService?.indDetail?.DistributorDetail?.StreetName
                  " />
                <mat-error *ngIf="stName.invalid">Street Name is Required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="mat-field-width32">
                <mat-label>Street Type</mat-label>
                <input oninput="this.value = this.value.toUpperCase()" matInput required [formControl]="stType"
                  [ngModel]="
                    distributorService?.indDetail?.DistributorDetail?.StreetType
                  " />
                <mat-error *ngIf="code?.value?.length < 10">Min length is 10 characters</mat-error>
              </mat-form-field>

              <br />
              <mat-form-field appearance="outline" class="mat-field-width32">
                <mat-label>City</mat-label>
                <input oninput="this.value = this.value.toUpperCase()" matInput required [formControl]="stCity"
                  [ngModel]="
                    distributorService?.indDetail?.DistributorDetail?.City
                  " />
                <mat-error *ngIf="stCity.invalid">City is Required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="mat-field-width32">
                <mat-label>State</mat-label>
                <input oninput="this.value = this.value.toUpperCase()" matInput required [formControl]="stState"
                  maxlength="2" [ngModel]="
                    distributorService?.indDetail?.DistributorDetail?.State
                  " />
                <mat-error *ngIf="stState.invalid">A 2 letter state abbreviation is required</mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" class="mat-field-width32">
                <mat-label>Zip Code</mat-label>
                <input oninput="this.value = this.value.toUpperCase()" matInput required [formControl]="stZip"
                  [ngModel]="
                    distributorService?.indDetail?.DistributorDetail?.Zip
                  " />
                <mat-error *ngIf="stZip.invalid">A numeric ZIP is required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="mat-field-width32">
                <mat-label>address2</mat-label>
                <input oninput="this.value = this.value.toUpperCase()" matInput [formControl]="address2" [ngModel]="
                    distributorService?.indDetail?.DistributorDetail?.address2
                  " />
              </mat-form-field>
            </fieldset>
            <br />
            <fieldset>
              <legend>Contact Details</legend>
              <br />
              <mat-form-field appearance="outline" class="mat-field-width32">
                <mat-label>Title</mat-label>
                <mat-select [(ngModel)]="
                      distributorService?.indDetail?.Individuals.selectedTitle" [disabled]="!this.isSaveEnabled">
                  <mat-option *ngFor="
                        let title of distributorService?.indDetail?.Individuals
                          .ContactTitle
                      " [value]="title.Id" style="font-size: 11px">
                    {{ title.Value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="mat-field-width32">
                <mat-label>First Name</mat-label>
                <input oninput="this.value = this.value.toUpperCase()" matInput required [formControl]="contactFName"
                  [ngModel]="
                    distributorService?.indDetail?.DistributorDetail
                      ?.ContactFirstName
                  " />
                <!-- <mat-error *ngIf="stZip.invalid">Contact First Name is required</mat-error> -->
              </mat-form-field>
              <mat-form-field appearance="outline" class="mat-field-width32">
                <mat-label>Last Name</mat-label>
                <input oninput="this.value = this.value.toUpperCase()" matInput required [formControl]="contactLName"
                  [ngModel]="
                    distributorService?.indDetail?.DistributorDetail
                      ?.ContactLastName
                  " />
                <!-- <mat-error *ngIf="stZip.invalid">Contact First Name is required</mat-error> -->
              </mat-form-field>

              <br />
              <mat-form-field appearance="outline" class="mat-field-width32">
                <mat-label>Phone Number</mat-label>
                <input matInput maxlength="14" mask="(000) 000-0000" required [formControl]="phoneNoVal" [ngModel]="
                    distributorService?.indDetail?.DistributorDetail
                      ?.ContactPhoneNumber
                  " />
                <mat-error *ngIf="phoneNoVal.invalid || phoneNoVal.hasError">Phone No is required in format (987)
                  654-3210</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="mat-field-width32">
                <mat-label>Alternate Phone Number</mat-label>
                <input matInput maxlength="14" mask="(000) 000-0000" [formControl]="alternatephoneNoVal" [ngModel]="
                    distributorService?.indDetail?.DistributorDetail
                      ?.alternatePhoneNumber
                  " />
                <mat-error *ngIf="
                    alternatephoneNoVal.invalid || alternatephoneNoVal.hasError
                  ">Enter Valid Phone No in format (987) 654-3210</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="mat-field-width32">
                <mat-label>Email Address</mat-label>
                <input oninput="this.value = this.value.toUpperCase()" matInput required [formControl]="email"
                  [ngModel]="
                    distributorService?.indDetail?.DistributorDetail
                      ?.ContactEmail
                  " />
                <mat-error *ngIf="email.invalid">Email address is invalid</mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" class="mat-field-width32">
                <mat-label>Program</mat-label>
                <mat-select [disabled]="
                    distributorService?.indDetail?.Individuals
                      .selectedProgramId &&
                    distributorService?.indDetail?.DistributorDetail
                      ?.DistributorCode
                  " [(ngModel)]="
                    distributorService?.indDetail?.Individuals.selectedProgramId
                  ">
                  <mat-option *ngFor="
                      let prg of distributorService?.indDetail?.Individuals
                        .ProgramsList
                    " [value]="prg.Id" style="font-size: 11px">
                    {{ prg.Value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </fieldset>
            <br />
          </mat-card-content>
          <mat-card-actions>
            <button mat-raised-button color="primary" [disabled]="badData() || !this.isSaveEnabled"
              (click)="saveUpdateDistributor()">
              SAVE
            </button>
            <button mat-raised-button color="primary" [disabled]="badData() || this.isSaveEnabled"
              (click)="enableControls()">
              EDIT
            </button>
            <button mat-raised-button color="primary" [disabled]="this.distributorService.editMode == true"
              (click)="resetDistributor()">
              CLEAR
            </button>
          </mat-card-actions>
        </div>
      </mat-tab>
      <!-- Stores --------------------------------------------------------------------- -->
      <mat-tab label="Company/Stores">
        <br />
        <div class="center" style="width: 90%">
          <mat-card>
            <mat-card-header>
              <mat-card-title style="font-size: 16px"> Company </mat-card-title>
              <mat-card-actions>
                <button mat-raised-button color="primary" (click)="addCompany()">
                  Add Company
                </button>

                <button mat-raised-button color="primary" (click)="resetCompanySelection()">
                  Reset
                </button>
              </mat-card-actions>
            </mat-card-header>
            <mat-divider></mat-divider>
            <br />
            <mat-card-content>
              <div class="example-container mat-elevation-z8">
                <table matSort mat-table [dataSource]="dataSourceCompany" class="mat-elevation-z8">
                  <ng-container matColumnDef="CompanyName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Company Name
                    </th>
                    <td mat-cell *matCellDef="let store">
                      {{ store.CompanyName }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="CompanyAbbr">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Company Abbr.
                    </th>
                    <td mat-cell *matCellDef="let store">
                      {{ store.CompanyAbbr }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="CompanyType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Company Type
                    </th>
                    <td mat-cell *matCellDef="let store">
                      {{ store.CompanyType }}
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsCompany"></tr>
                  <tr mat-row [class.curr-cust]="index == cindex" *matRowDef="
                      let row;
                      columns: displayedColumnsCompany;
                      let index = index
                    " (click)="companySelection(row, index)"></tr>
                </table>
              </div>
              <br />
            </mat-card-content>
          </mat-card>
          <mat-divider></mat-divider>
          <br />
          <mat-card>
            <mat-card-header>
              <mat-card-title style="font-size: 16px"> Stores </mat-card-title>
              <div style="text-align: center; flex: auto">
                <label style="font-size: 16px">Distributor Details:
                  {{
                  distributorService?.indDetail?.DistributorDetail
                  ?.DistributorName ?? distributorName.value
                  }}
                </label>
              </div>
              <mat-card-actions>
                <button mat-raised-button color="primary" (click)="addStore()"
                  [disabled]="cindex == null || cindex == undefined">
                  Add Store
                </button>
                <button mat-raised-button color="primary" (click)="bulkUpload(true)"
                  [disabled]="cindex == null || cindex == undefined">
                  Bulk Upload
                </button>
              </mat-card-actions>
            </mat-card-header>
            <mat-divider></mat-divider>
            <br />
            <mat-card-content>
              <div class="example-container mat-elevation-z8">
                <table mat-table matSort [dataSource]="dataSourceStores" class="mat-elevation-z8">
                  <ng-container matColumnDef="Utility">
                    <th mat-header-cell *matHeaderCellDef>Utility</th>
                    <td mat-cell *matCellDef="let store">
                      {{ store.Utility }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="CompanyName">
                    <th mat-header-cell *matHeaderCellDef>Company Name</th>
                    <td mat-cell *matCellDef="let store">
                      {{ store.CompanyName }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="StoreNumber">
                    <th mat-header-cell *matHeaderCellDef>Store Number</th>
                    <td mat-cell *matCellDef="let store">
                      {{ store.StoreNumber }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="City">
                    <th mat-header-cell *matHeaderCellDef>City</th>
                    <td mat-cell *matCellDef="let store">
                      {{ store.City }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="State">
                    <th mat-header-cell *matHeaderCellDef>State</th>
                    <td mat-cell *matCellDef="let store">
                      {{ store.State }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="District">
                    <th mat-header-cell *matHeaderCellDef>District</th>
                    <td mat-cell *matCellDef="let store">
                      {{ store.District }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Edit">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center">
                      Edit
                    </th>
                    <td mat-cell *matCellDef="let store; let i = index" align="center">
                      <button mat-icon-button matTooltip="Edit" (click)="editStore(i)">
                        <mat-icon>edit</mat-icon>
                      </button>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsStores"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsStores"></tr>
                </table>
              </div>
              <br />
            </mat-card-content>
          </mat-card>
        </div>
      </mat-tab>
      <!-- SKUs --------------------------------------------------------------------- -->
      <mat-tab label="SKUs">
        <br />
        <div class="center">
          <mat-card>
            <mat-card-header>
              <mat-card-title style="font-size: 16px"> SKUs </mat-card-title>
              <mat-card-actions>
                <button mat-raised-button color="primary" (click)="bulkUpload(false)">
                  Bulk Upload SKU
                </button>
                <!-- <button mat-raised-button color="primary" (click)="download()">
                  Download
                </button> -->
              </mat-card-actions>
            </mat-card-header>
            <mat-divider></mat-divider>
            <br />
            <mat-card-content>
              <table mat-table [dataSource]="dataSourceSKUs" class="mat-elevation-z8">
                <ng-container matColumnDef="Distributor">
                  <th mat-header-cell *matHeaderCellDef>Distributor</th>
                  <td mat-cell *matCellDef="let sku">
                    {{ sku.Distributor }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Company">
                  <th mat-header-cell *matHeaderCellDef>Company</th>
                  <td mat-cell *matCellDef="let sku">
                    {{ sku.Company }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="SKU">
                  <th mat-header-cell *matHeaderCellDef>SKU</th>
                  <td mat-cell *matCellDef="let sku">
                    {{ sku.SKU }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="ESModel">
                  <th mat-header-cell *matHeaderCellDef>ESModel</th>
                  <td mat-cell *matCellDef="let sku">
                    {{ sku.ESModel }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Lumens">
                  <th mat-header-cell *matHeaderCellDef>Lumens</th>
                  <td mat-cell *matCellDef="let sku">
                    {{ sku.Lumens }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="RebateAmount">
                  <th mat-header-cell *matHeaderCellDef>Rebate Amount</th>
                  <td mat-cell *matCellDef="let sku">
                    {{ sku.RebateAmount }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="ExternalKey">
                  <th mat-header-cell *matHeaderCellDef>External Key</th>
                  <td mat-cell *matCellDef="let sku">
                    {{ sku.ExternalKey }}
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsSKUs"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsSKUs"></tr>
              </table>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>