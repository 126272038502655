import { Component, OnInit, ViewChild } from "@angular/core";
import { GoogleMapsService } from "../services/google-maps.service";
import { Maps } from "../models/Maps";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import Swal from "sweetalert2";
import { Maps_address } from "../models/Maps";
import { WorkorderService } from "../services/workorder.service";
import { LocationsearchService } from "../services/locationsearch.service";
import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import {} from 'google.maps';

@Component({
  selector: "app-google-maps",
  templateUrl: "./google-maps.component.html",
  styleUrls: ["./google-maps.component.scss"],
})
export class GoogleMapsComponent implements OnInit {
  @ViewChild("map") mapElement: any;
  map: google.maps.Map;
  myData: any;
  stringifiedData: any;
  response: any = [];
  currentMaps: Maps;
  tabledata: any[] = [];
  Address: any;
  workorder: any;
  workorderID: any = this.workorderService.currentWorkorderID;
  Name: any;
  customerAddress: any;
  iconurl: any;
  lat: number = 40.7390026;
  lng: number = -74.226053;
  zoom: number = 10;
  lats: number;
  lngs: number;
  loadWorkorder: any = true;
  workorderAddress: any;
  actualaddress: any;
  draggable: boolean;
  latSource: any;
  longSource: any;
  latDest: any;
  longDest: any;
  latSourceRadians: any;
  longSourceRadians: any;
  latDestRadians: any;
  longDestRadians: any;
  distance: any;
  dist: any;
  nearestapp: any;
  scheduleDate: any;
  latest_date: any;
  selectedGrid: any;
  ApptDate: any;
  Id: any;
  Grid: any;
  icon: any;
  result: any;
  URL: any;
  error: any;
  Organiztion_cd: any = "ALL";
  cnt1 : any=0;
  cnt2 : any=0;
  
  constructor(
    private googleMapsService: GoogleMapsService,
    private locationsearchService: LocationsearchService,
    private datepipe: DatePipe,
    public dialogRef: MatDialogRef<GoogleMapsComponent>,
    public http: HttpClient,
    private workorderService: WorkorderService,
    private router: Router
  ) {}

  ngOnInit(): void {
   
  }

  ngAfterViewInit() {
    this.Map();
  }

   async Map() {
    await this.GoogleMaps();  
  }
 
  clickedMarker(marker) {
    var personcompany=marker.get("personcompany");
    var grid=marker.get("Grid");
    console.log("GRID" + marker.get("Grid"));
    var scheduledate=marker.get("scheduledate");    
    console.log(personcompany + ' '+grid+' '+ scheduledate);
       
    this.locationsearchService
      .individualAuthorization(
        personcompany,
        this.workorderID,
        scheduledate,
        this.Organiztion_cd
      )
      .subscribe(
        (res) => {
          console.log("RESPONSE");
          console.log(res);
          if (res.Action == 0) {
            if (
              this.workorderID !== null &&
              typeof this.workorderID !== "undefined" &&
              personcompany !== null &&
              grid !== null &&
              typeof personcompany !== "undefined" &&
              typeof grid !== "undefined"
            ) {
              this.locationsearchService.setschedulingparameters(
                personcompany,
                grid,
                this.workorderID,
                scheduledate
              );
              if (this.workorderService.workorderStatusCode == "schedule") {
                console.log("schedule888")
                this.router.navigate([
                  "scheduling",
                  this.workorderService.currentWorkorder.Group,
                  this.workorderService.currentWorkorder.SubGroup,
                  this.workorderID,
                  "schedule",
                ]);
              } else {
                console.log("reshedule");
                this.router.navigate([
                  "scheduling",
                  this.workorderService.currentWorkorder.Group,
                  this.workorderService.currentWorkorder.SubGroup,
                  this.workorderID,
                  "reschedule",
                ]);
              }

              this.dialogRef.close();
            }
          } else {
            Swal.fire({
              title: "Error",
              text: res.Message,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        },
        (err) => {
          Swal.fire({
            title: "Error",
            text: err,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      );
  }

   async GoogleMaps() {

    this.loadWorkorder = true;  
    this.iconurl = null;
    this.scheduleDate = this.locationsearchService.getlocationScheduledate();

    this.selectedGrid = this.locationsearchService.getlocationgrid();
    this.latest_date = this.datepipe.transform(
      new Date(this.scheduleDate),
      "MM/dd/yyyy"
    );
    this.myData = {
      scheduledate: this.latest_date,
    };
    this.stringifiedData = JSON.stringify(this.myData);
    this.customerAddress =
      this.googleMapsService.getcustomeraddress() + " " + "USA";
    this.workorderAddress = this.googleMapsService.getcustomeraddress();
    if (this.workorderAddress !== null && this.workorderAddress !== undefined) {
      let customerAddress = this.customerAddress.replace(/ /g, "+");
      this.http
        .get(
          "https://maps.googleapis.com/maps/api/geocode/json?address=" +
            customerAddress +
            "&key=AIzaSyDEJpepas8zDP-C_bqqnZhXI93nxqBJKk4"
        )
        .subscribe((value: any) => {
          this.result = value;

          if (this.result.status == "OK") {
            this.lats = this.result.results[0].geometry.location.lat;

            this.lngs = this.result.results[0].geometry.location.lng;
            this.latSource = this.lats;
            this.longSource = this.lngs;
          }
          //if (this.Name === null) {
          this.Name = this.workorderAddress;
          this.icon = 'red';
          //}
          const cr = new Maps_address();
          cr.setFromWeb(
            this.Name,
            this.workorder,
            this.customerAddress,
            this.lats,
            this.lngs,
            this.ApptDate,
            this.Id,
            this.Grid,
            this.icon
          );
          this.tabledata.push(cr);
        });
    }

    this.googleMapsService
    .getnearestappointment(this.stringifiedData)
    .subscribe(
      (data) => {
        this.response = data;
        this.loadWorkorder = false;        
        console.log (data);

        if (this.response.Appointments.length ==0) {        
          this.DrawMap();
        }

        this.response.Appointments.forEach((element) => {

          this.cnt1++;
          this.latDest = null;
          this.longDest = null;
          this.distance = null;
          this.lats = null;

          this.lngs = null;
          this.result = null;
          this.dist = null;
          this.distance = null;
          this.nearestapp = false;
          this.Address = element.Address + " " + element.CityStCountry;

          this.ApptDate = element.ApptDate;
          this.Id = element.Id;
          this.Grid = element.Grid;
          this.icon = 'green';// "http://maps.google.com/mapfiles/ms/micons/green.png";
          if (
            element.Address !== null &&
            typeof element.Address !== "undefined"
          ) {
            this.Address = this.Address.replace(" ", "+");
            console.log ("Address call "+this.Address );
            this.http
              .get(
                "https://maps.googleapis.com/maps/api/geocode/json?address=" +
                  this.Address +
                  "&key=AIzaSyDEJpepas8zDP-C_bqqnZhXI93nxqBJKk4"
              )
              .subscribe((value: any) => {              
                this.result = value;

                if (this.result.status == "OK") {
                  this.cnt2++;
                  this.lats = this.result.results[0].geometry.location.lat;

                  this.lngs = this.result.results[0].geometry.location.lng;
                  this.latDest = this.lats;
                  this.longDest = this.lngs;

                  this.Name = element.Name;
                  this.workorder = element.WorkorderId;
                  this.ApptDate = element.ApptDate;
                  this.Id = element.Id;
                  this.Grid = element.Grid;
                  this.icon ='green'
                   // "http://maps.google.com/mapfiles/ms/micons/green.png";
                    console.log("distance calc");

                  this.calculateDist(
                    this.latSource,
                    this.longSource,
                    this.latDest,
                    this.longDest,
                    this.Name,
                    this.workorder,
                    this.Address,
                    this.ApptDate,
                    this.Id,
                    this.Grid,
                    this.icon
                  )                 

                  if(this.cnt1==this.cnt2) {                   
                    this.DrawMap();
                  }
                }
              });            
          }
        });      
      },
      (err) => {
        Swal.fire({
          title: "Error",
          text: err,
          icon: "error",
          confirmButtonText: "OK",
        });
        this.dialogRef.close("1");
      }
    );

  }
  calculateDist(
    latSource,
    longSource,
    latDest,
    longDest,
    Name,
    workorder,
    address,
    apptdate,
    id,
    grid,
    icon
  ) {
    this.nearestapp = false;
    const srcLocation = new google.maps.LatLng(latSource, longSource);
    const dstLocation = new google.maps.LatLng(latDest, longDest);
    var distance = google.maps.geometry.spherical.computeDistanceBetween(
    srcLocation,
    dstLocation
    );
    this.dist = distance / 1000;
    console.log("DISTANCE->" +this.dist);
    if (this.dist <= 150) {
    this.nearestapp = true;
    }   

    if (this.nearestapp == true) {
      const c = new Maps_address();

      c.setFromWeb(
        Name,
        workorder,
        address,
        latDest,
        longDest,
        apptdate,
        id,
        grid,
        icon
      );
      this.tabledata.push(c);     
    }
  }

  DrawMap()
  {
     var map = new google.maps.Map(document.getElementById('map'), {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      //zoom:12,
      styles: [
        {
            featureType: "poi",
            elementType: "labels",
            stylers: [
                  { visibility: "off" }
            ]
        }
    ]
  });

  var infowindow = new google.maps.InfoWindow();
  var bounds = new google.maps.LatLngBounds();    
  var icon = {
      path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z", //SVG path of awesomefont marker
      fillColor: 'white', //color of the marker
      fillOpacity: 1,
      strokeWeight: 0,
      scale: 0.09, //size of the marker, careful! this scale also affects anchor and labelOrigin
      anchor: new google.maps.Point(200,510), //position of the icon, careful! this is affected by scale
      labelOrigin: new google.maps.Point(205,190) //position of the label, careful! this is affected by scale
  }

  for (let i = 0; i < this.tabledata.length; i++)  {
      const cusomerData = this.tabledata[i]; 
        icon.fillColor=  cusomerData.icon;

      const infowindow = new google.maps.InfoWindow({
      content:'infoString'       
    });
    console.log("lat: "+ cusomerData.lats+ ", lng: "+cusomerData.lngs);
    var marker =  new google.maps.Marker({   
      position: { lat: cusomerData.lats, lng: cusomerData.lngs },     
      map,
      icon: icon,          
      label: {
        fontFamily: "'Font Awesome 5 Free'",
        text:cusomerData.Name ,            
        fontWeight: '900',
       //color:(cusomerData.WorkorderId== undefined)? 'red':'green',
       color:'black',     
      } 
    },   
    ); 
    marker.set("personcompany", cusomerData.Id);
    marker.set("Grid", cusomerData.Grid);
    marker.set("scheduledate", cusomerData.ApptDate);

    marker.addListener("click", () => {
      //infowindow.open(map, marker); 
      this.clickedMarker(marker);
    });  

  // draw line
  bounds.extend(marker.getPosition());  
  map.fitBounds(bounds); 
}
}
}
