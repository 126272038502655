<ngx-spinner bdColor = "rgba(128, 128, 128, 0.5)" size = "medium" color = "#ff4081" type = "timer" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner> 

<div style="float:right;top:-5px;right:-5px;color:white">
    <button mat-icon-button [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
      </button>  
</div> 

<h1 mat-dialog-title class="header" style="padding:0.5vw;font-size:1.2vw; color:white">Select Reason for OnHold</h1>
<div mat-dialog-content>

    <mat-card style="width: 40vw;">
        <mat-card-content>

            <mat-spinner *ngIf="loadReasons" [diameter]="25" color="primary"></mat-spinner>
            <!--   <table style="font-size: 1vw;width: 100% !important;">
                <tbody> -->
            <div *ngIf="!loadReasons">
                <mat-list style="cursor: pointer ;" *ngFor="let reason of reasons" dense>
                    <button mat-list-item style="width:100% !important;cursor: pointer;" [disableRipple]="false" class="mat-list-btn"
                        (dblclick)="onHold(reason)"> {{reason.ReasonDescription}}</button>
                </mat-list>
            </div>

            <!--   </tbody>
            </table> -->
        </mat-card-content>
    </mat-card>
</div>