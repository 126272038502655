import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IndividualService } from '../../../services/individual.service';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {

    resourcegrid_id=null;    
    gridType;
    endDate;

    constructor(
        public dialogRef: MatDialogRef<GridComponent>,
        public indService: IndividualService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        if (this.data.editMode) {
            this.endDate = this.indService.indDetail.GridsTab.Grids[this.data.index].ENDDATE;

            for (var i = 0; i < this.indService.indDetail.GridsTab.GridTypeList.length; i++) {
                if (this.indService.indDetail.GridsTab.GridTypeList[i].CODE
                    === this.indService.indDetail.GridsTab.Grids[this.data.index].GRID_CD) {
                    this.gridType = this.indService.indDetail.GridsTab.GridTypeList[i];
                    break;
                }
            }

            this.resourcegrid_id = this.indService.indDetail.GridsTab.Grids[this.data.index].RESOURCEGRID_ID;
        }
        else
        {
            var today = new Date();
            var year = today.getFullYear();
            var month = today.getMonth();
            var day = today.getDate();
            this.endDate = new Date(year + 5, month, day);
        }
    }

    saveGrid() {
        let datestr = null;
        if (this.endDate !== undefined) {
            datestr = this.endDate.toString();
        }

        if (this.data.editMode) {
            this.indService.indDetail.GridsTab.Grids[this.data.index].ENDDATE = this.endDate;

            this.indService.indDetail.GridsTab.Grids[this.data.index].GRID_CD = this.gridType.CODE;
            this.indService.indDetail.GridsTab.Grids[this.data.index].SELECTEDGRIDDESCRIPTION = this.gridType.DESCRIPTION;
            this.indService.indDetail.GridsTab.Grids[this.data.index].SELECTEDGRIDCODE = this.gridType.CODE;
        } else {
            const grid = {
                RESOURCEGRID_ID: null,
                PERSONCOMPANY_ID: this.indService.personcompanyId,
                GRID_CD: this.gridType.CODE,
                ENDDATE: datestr,
                OLDDATE: null,
                ITEMCHANGED: 0,
                SELECTEDGRIDDESCRIPTION: this.gridType.DESCRIPTION,
                SELECTEDGRIDCODE: this.gridType.CODE
              };
    
            this.indService.indDetail.GridsTab.Grids.push(grid);
        }

        this.dialogRef.close('1');
    }

}
