<h1 mat-dialog-title class="mat-header-color" style="padding:0.5vw;font-size:1.2vw; color: whitesmoke">Add Workorder</h1>
<div class="mat-header-color" mat-dialog-content>

    <mat-card style="width: 40vw;">
        <mat-card-content>
            <mat-form-field style="width: 100%;" appearance="outline">
                <mat-label>Programs</mat-label>
                <mat-select [(ngModel)]="selectedQaWorkOrder">
                  <mat-option *ngFor="let program of programs" [value]="program.ProgramId" style="font-size: 11px;">{{program.ProgramName}}</mat-option>
                </mat-select>
              </mat-form-field>
          
        </mat-card-content>
        <mat-card-actions>
            <button mat-button (click)='openQaWorkOrder()'>Create Workorder</button>
        </mat-card-actions>
    </mat-card>
</div>