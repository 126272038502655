import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';

import { UserService } from '../../services/user.service';

import { Office } from '../../models/office';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { OfficeDetailComponent } from '../office-detail/office-detail.component';
import { UserDetailComponent } from '../user-detail/user-detail.component';
import { OfficeCreateComponent } from '../office-create/office-create.component';

@Component({
    selector: 'app-office',
    templateUrl: './office.component.html',
    styleUrls: ['./office.component.scss']
})
export class OfficeComponent implements OnInit {

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    offices = [];
    displayedColumns: string[] = ['OfficeId', 'OfficeDescription', 'ProgramSponsorId','OfficeActive' ,'CreatedDate','ModifiedBy','edit'];
    dataSource = new MatTableDataSource<Office>();
    dialogRef: MatDialogRef<OfficeDetailComponent>;
    searching = true;

    constructor(
        private userService: UserService,
        private router: Router, private dialog: MatDialog
    ) { }

    ngOnInit() {
      
        this.getOffices();
    }

    ngAfterViewInit(){
       
    }

    private getOffices() {
        this.userService.getOffices().subscribe(data => {
         
            this.offices = data;
            this.dataSource.data = this.offices;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            this.searching = false;
        },err=>{
            console.log(err);
        });
    }

    addNewOffice() {
        let className=localStorage.getItem("themeName");
        const dialogCreate = this.dialog.open(OfficeCreateComponent,{panelClass:className});
        dialogCreate.afterClosed().subscribe(result => {
            this.getOffices();
        });
    }

    public handleClick(office: Office) {
        let className=localStorage.getItem("themeName");
       this.dialogRef = this.dialog.open(OfficeDetailComponent,
            {
                data: {
                    dataKey: office
                },panelClass:className
            });

        this.dialogRef.afterClosed().subscribe(result => {
            this.getOffices();
        });
    }
}
