import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { UntypedFormControl, Validators } from "@angular/forms";
import { StoresComponent } from "../dialogs/stores/stores.component";
import { DistributorService } from "src/app/services/distributor.service";
import { WhiteSpaceValidator } from "src/app/services/whitespace.validator";
import { UploadExcelComponent } from "../dialogs/upload-excel/upload-excel.component";
import { NgxSpinnerService } from "ngx-spinner";
import { CompanyComponent } from "../dialogs/company/company.component";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: "app-distributor-detail",
  templateUrl: "./distributor-detail.component.html",
  styleUrls: ["./distributor-detail.component.scss"],
})
export class DistributorDetailComponent implements OnInit {
  //#region Distributor
  distributorName = new UntypedFormControl("", [
    Validators.required,
    Validators.maxLength(40),
  ]);
  shortName = new UntypedFormControl("", [
    Validators.required,
    Validators.maxLength(20),
  ]);
  code = new UntypedFormControl("", [
    Validators.required,
    Validators.maxLength(6),
    Validators.minLength(3),
    WhiteSpaceValidator.noWhiteSpace,
  ]);
  stNo = new UntypedFormControl("", [
    Validators.required,
    Validators.pattern("[0-9]*"),
  ]);
  stName = new UntypedFormControl("", [
    Validators.required,
    Validators.maxLength(40),
  ]);
  stType = new UntypedFormControl("", [
    Validators.required,
    Validators.maxLength(10),
  ]);
  stCity = new UntypedFormControl("", [Validators.required]);
  stState = new UntypedFormControl("", [
    Validators.required,
    Validators.maxLength(2),
    Validators.pattern("[a-zA-Z ]*"),
  ]);
  stZip = new UntypedFormControl("", [
    Validators.required,
    Validators.pattern("[0-9]*"),
  ]);
  contactFName = new UntypedFormControl("");
  contactLName = new UntypedFormControl("");
  // contactLTitle = new UntypedFormControl("");
  phoneNoVal = new UntypedFormControl("", [
    //Validators.pattern("^\(\d{3}\)\s\d{3}-\d{4}$"),
    Validators.required,
  ]);
  alternatephoneNoVal = new UntypedFormControl("", [
    //Validators.pattern("^(\\([0-9]{3}\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"),
  ]);
  address2 = new UntypedFormControl("");
  email = new UntypedFormControl("", [Validators.email, Validators.required]);
  companyName = new UntypedFormControl("", [Validators.required]);
  rateCode = new UntypedFormControl("");
  accountNumber = new UntypedFormControl("", [Validators.required]);
  isSaveEnabled: boolean = false;
  //#endregion Distributor

  //#region Company
  dataSourceCompany = new MatTableDataSource<any>();
  displayedColumnsCompany: string[] = [
    "CompanyName",
    "CompanyAbbr",
    "CompanyType",
  ];
  //#endregion Company

  //#region Store
  dataSourceStores = new MatTableDataSource<any>();
  displayedColumnsStores: string[] = [
    "Utility",
    "CompanyName",
    "StoreNumber",
    "City",
    "State",
    "District",
    "Edit",
  ];
  //#endregion Store

  //#region SKU
  dataSourceSKUs = new MatTableDataSource<any>();
  displayedColumnsSKUs: string[] = [
    "Distributor",
    "Company",
    "SKU",
    "ESModel",
    "Lumens",
    "RebateAmount",
    "ExternalKey",
  ];
  //#endregion SKU
  cindex;

  constructor(
    private dialog: MatDialog,
    public distributorService: DistributorService,
    private spinner: NgxSpinnerService,
    private utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    this.enableDisableControls();
    this.distributorService.editMode == false ? this.enableControls() : this.disableControls();
    this.dataSourceCompany = this.distributorService?.indDetail?.CompanyDetails;
  }

  enableDisableControls() {
    this.distributorService.editMode == true
      ? this.code.disable()
      : this.code.enable();
    this.distributorService.editMode == true
      ? this.accountNumber.disable()
      : this.accountNumber.enable();
  }

  //#region Distributor
  resetDistributor() {
    this.distributorName.reset();
    this.code.reset();
    this.contactFName.reset();
    this.contactLName.reset();
    //this.contactLTitle.reset();
    this.phoneNoVal.reset();
    this.shortName.reset();
    this.stCity.reset();
    this.stName.reset();
    this.stZip.reset();
    this.stType.reset();
    this.stState.reset();
    this.stNo.reset();
    this.companyName.reset();
    this.email.reset();
    this.accountNumber.reset();
    this.address2.reset();
    this.rateCode.reset();
    this.alternatephoneNoVal.reset();
  }

  enableControls() {
    this.isSaveEnabled = true;
    this.stCity.enable();
    this.companyName.enable();
    this.email.enable();
    this.contactFName.enable();
    this.contactLName.enable();
    this.phoneNoVal.enable();
    //this.contactLTitle.enable();
    this.distributorName.enable();
    this.shortName.enable();
    this.stState.enable();
    this.stName.enable();
    this.stNo.enable();
    this.stType.enable();
    this.stZip.enable();
    this.address2.enable();
    this.alternatephoneNoVal.enable();
    this.rateCode.enable();
  }

  disableControls() {
    this.isSaveEnabled = false;
    this.stCity.disable();
    this.companyName.disable();
    this.email.disable();
    this.contactFName.disable();
    this.contactLName.disable();
    this.phoneNoVal.disable();
    //this.contactLTitle.disable();
    this.distributorName.disable();
    this.shortName.disable();
    this.stState.disable();
    this.stName.disable();
    this.stNo.disable();
    this.stType.disable();
    this.stZip.disable();
    this.address2.disable();
    this.alternatephoneNoVal.disable();
    this.rateCode.disable();
    this.accountNumber.disable();
    this.code.disable();
  }

  saveUpdateDistributor() {
    const distributor = {
      City: this.stCity.value,
      CompanyName: this.companyName.value,
      ContactEmail: this.email.value,
      ContactFirstName: this.contactFName.value,
      ContactLastName: this.contactLName.value,
      ContactPhoneNumber: this.phoneNoVal.value.replace(/[^a-zA-Z0-9 ]/g, ""),
      ContactTitle: this.distributorService?.indDetail?.Individuals.selectedTitle,
      DistributorCode: this.code.value.length <= 6 ? this.utilityService.currentUtility.OfficeId + this.code.value : this.code.value,
      DistributorName: this.distributorName.value,
      DistributorShortName: this.shortName.value,
      Program:
        this.distributorService?.indDetail?.Individuals.selectedProgramId,
      State: this.stState.value,
      StreetName: this.stName.value,
      StreetNumber: this.stNo.value,
      StreetType: this.stType.value,
      Utility:
        this.distributorService?.indDetail?.Individuals.selectedUtilityId ??
        this.distributorService?.selectedUtility,
      Zip: this.stZip.value,

      accountNumber: this.accountNumber.value,
      accountid: this.distributorService.indDetail.DistributorDetail.accountid,
      address2: this.address2.value,
      address_id:
        this.distributorService.indDetail.DistributorDetail.address_id,
      alternatePhoneNumber: this.alternatephoneNoVal.value != null ? this.alternatephoneNoVal.value.replace(/[^a-zA-Z0-9 ]/g, "") : this.alternatephoneNoVal.value,
      personcompanyid:
        this.distributorService.indDetail.DistributorDetail.personcompanyid,
      phoneid: this.distributorService.indDetail.DistributorDetail.phoneid,
      rateCode: this.rateCode.value,
    };
    this.spinner.show();
    this.distributorService.saveDistributor(distributor).subscribe((data) => {
      this.spinner.hide();
      if (data.Action) {
        Swal.fire({
          text: data.Message,
          icon: "success",
          confirmButtonText: "OK",
        });
        this.distributorService.selectedDistributorCode = this.utilityService.currentUtility.OfficeId + this.code.value;
        this.distributorService.indDetail.DistributorDetail = "";
        this.distributorService.indDetail.DistributorDetail = distributor;
        this.distributorService
          .search(this.distributorService.selectedUtility?.ProviderId, "")
          .subscribe((data) => {
            this.distributorService.indvList = data;
          });
        this.disableControls();
      } else
        Swal.fire({
          title: "Error",
          text: data.Message,
          icon: "error",
          confirmButtonText: "OK",
        });
    });
  }
  //#endregion Distributor

  //#region Company
  companySelection(row, index) {
    this.resetCompanySelection();
    this.cindex = this.distributorName.value != null ? index : null;
    if (this.cindex != null) {
      this.distributorService.selectedCompany = row.CompanyName;
      this.distributorService.selectedCompanyAbbr = row.CompanyAbbr;
      this.spinner.show();
      this.distributorService.getStoresOnCompany().subscribe((data) => {
        this.dataSourceStores =
          data.StoresDetails[0]?.Addressid != null ? data.StoresDetails : null;
        this.distributorService.indDetail.StoresDetails = "";
        this.distributorService.indDetail.StoresDetails = data.StoresDetails;
        this.spinner.hide();
      });
    }
    else {
      Swal.fire({
        title: "Error",
        text: "Please fill and save distributor details",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }

  addCompany() {
    let className = localStorage.getItem("themeName");
    const dialogRef = this.dialog.open(CompanyComponent, {
      data: { caption: "Add Company", editMode: false },
      panelClass: className,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dataSourceCompany = result.CompanyDetails;
        this.distributorService.indDetail.CompanyDetails = "";
        this.distributorService.indDetail.CompanyDetails =
          result.CompanyDetails;
      }
    });
  }

  resetCompanySelection() {
    this.cindex = null;
    this.dataSourceStores = new MatTableDataSource<any>();
  }
  //#endregion Company

  //#region Store
  addStore() {
    let className = localStorage.getItem("themeName");
    const dialogRef = this.dialog.open(StoresComponent, {
      data: { caption: "Add Store", editMode: false },
      panelClass: className,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.distributorService.getStoresOnCompany().subscribe((data) => {
          this.dataSourceStores = data.StoresDetails;
          this.distributorService.indDetail.StoresDetails = "";
          this.distributorService.indDetail.StoresDetails = data.StoresDetails;
        });
      }
    });
  }

  editStore(idx) {
    let className = localStorage.getItem("themeName");
    const dialogRef = this.dialog.open(StoresComponent, {
      data: { caption: "View Store", editMode: true, index: idx },
      panelClass: className,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.distributorService.getStoresOnCompany().subscribe((data) => {
          this.dataSourceStores = data.StoresDetails;
          this.distributorService.indDetail.StoresDetails = data.StoresDetails;
        });
      }
    });
  }
  //#endregion Store

  //#region SKU
  SKUdetails(event) {
    if (event.tab.textLabel == "SKUs") {
      this.spinner.show();
      this.distributorService.getSKUOnDistributor().subscribe((data) => {
        this.dataSourceSKUs = data.SKUDetails;
      });
      this.spinner.hide();
    }
    if (event.tab.textLabel == "Company/Stores") {
      this.dataSourceCompany =
        this.distributorService?.indDetail?.CompanyDetails;
    }
  }

  download() {
    this.distributorService.getSKUOnDistributor().subscribe((data) => {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
        data.SKUDetails
      );
      console.log("worksheet", worksheet);
      const workbook: XLSX.WorkBook = {
        Sheets: { data: worksheet },
        SheetNames: ["data"],
      };
      const excelBuffer: any = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, "testdownload");
    });
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + ".xlsx"
    );
  }
  //#endregion SKU

  bulkUpload(isStore) {
    let className = localStorage.getItem("themeName");
    const dialogRef = this.dialog.open(UploadExcelComponent, {
      data: {
        caption: isStore == true ? "Bulk Upload Store" : "Bulk Upload SKUs",
      },
      panelClass: className,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && isStore) {
        this.dataSourceStores = result?.StoresDetails;
      } else {
        this.dataSourceSKUs = result?.SKUDetails;
      }
    });
  }

  badData() {
    return (
      this.stCity.invalid ||
      this.companyName.invalid ||
      this.email.invalid ||
      this.phoneNoVal.invalid ||
      this.code.invalid ||
      this.distributorName.invalid ||
      this.shortName.invalid ||
      this.stState.invalid ||
      this.stName.invalid ||
      this.stNo.invalid ||
      this.stType.invalid ||
      this.stZip.invalid ||
      this.alternatephoneNoVal.invalid ||
      this.accountNumber.invalid
    );
  }
}
