import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { UtilityService } from './utility.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InventoryMaintenanceService {

  public warehouseList: any;
  public WarehouseDetail: any;
  public selectedWarehouse: any;
  public serialnoList: any;
  public searching = true;
  
    constructor(
      private http: HttpClient,
      private utilityService: UtilityService
  ) { }

private getHeaders() {
  const email = localStorage.getItem('loggedInUserEmail');
  const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'x-email': email,
          'x-programsponsorid': this.utilityService.currentUtility.ProgramsponsorId
      })
  };
  return httpOptions;
}

public getWarehouseList(): Observable<any[]> {
  const searchUrl = environment.apiUrl
      + 'inventory/inventorymaintenance/warehouse/'
      + this.utilityService.currentUtility.OfficeId
     //+'CEN2'
      + '/ALL?code=' + environment.apiCode;
      
  return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
          catchError(err => this.utilityService.handleError(err, this, []))
      );
}

public getWarehouseTable (workresource): Observable<any[]> {
  const searchUrl = environment.apiUrl
      + 'inventory/inventorymaintenance/detail/'
     + this.utilityService.currentUtility.OfficeId
     //+'CEN2'
      + '/'+ workresource+'?code=' + environment.apiCode;
      
  return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
          catchError(err => this.utilityService.handleError(err, this, []))
      );
}

  getPastTransaction(workresource, productId) {
    let infoObj = {
        targetDate: null
    }
    console.log(this.utilityService.currentUtility.OfficeId);
    console.log(productId);
    console.log(productId);
    let searchUrl = environment.apiUrl + "inventory/inventorymaintenance/detail/"
     + this.utilityService.currentUtility.OfficeId 
     + '/'+ workresource+ '/'
     + productId+"?code=" + environment.apiCode;
  
    console.log("searchUrl->");
    console.log(searchUrl);
    //searchUrl="https://onering-api.azurewebsites.net/api/inventory/inventorymaintenance/detail/APC1/D000651068/APC1CHA1?code=ZHlne4CWtAzZKColEDYImUFvNHkdqekpkuLVMJG20LS7WprTI3xIrA==";
    return this.http.post<any[]>(searchUrl, infoObj, this.getHeaders())
      .pipe(map(response => response),
        catchError(err =>this.handleError())
      );

  }

public handleError(): Observable<any> { 
  this.searching=false;
  return;
}

public getSerialnoList(serialno): Observable<any[]> {  

  const searchUrl = environment.apiUrl
      + 'inventory/inventorymaintenance/serialno/'
     + this.utilityService.currentUtility.OfficeId
    // +'CEN2'
      + '/E000001162/CEN26200?code=' + environment.apiCode +'&serialno=' + serialno;
      
  return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
          catchError(err => this.utilityService.handleError(err, this, []))
      );
}

}
