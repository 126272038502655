import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

import Swal from 'sweetalert2';

import { UtilityService } from '../../services/utility.service';
import { IndividualService } from '../../services/individual.service';
import * as moment from 'moment';

import { PhoneComponent } from '../dialogs/phone/phone.component';
import { AddressComponent } from '../dialogs/address/address.component';
import { IndividualRoleComponent } from '../dialogs/individual-role/individual-role.component';
import { GridComponent } from '../dialogs/grid/grid.component';
import { DailyHoursComponent } from '../dialogs/daily-hours/daily-hours.component';
import { TimeOffComponent } from '../dialogs/time-off/time-off.component';
import { AdditionalHoursComponent } from '../dialogs/additional-hours/additional-hours.component';
import { BucketSchedulingComponent } from '../dialogs/bucket-scheduling/bucket-scheduling.component';

import { WaitDialogComponent } from '../../util/wait-dialog/wait-dialog.component';
import { DataTransferService } from 'src/app/services/data-transfer.service';

@Component({
    selector: 'app-individual-detail',
    templateUrl: './individual-detail.component.html',
    styleUrls: ['./individual-detail.component.scss']
})
export class IndividualDetailComponent implements OnInit {

    // Individual Tab
    dataSourcePhone = new MatTableDataSource<any>();
    displayedColumnsPhone: string[] = ['Type', 'Number', 'extension', 'Edit', 'Delete'];

    dataSourceEmail = new MatTableDataSource<any>();
    displayedColumnsEmail: string[] = ['Email', 'Edit', 'Delete'];

    dataSourceAddress = new MatTableDataSource<any>();
    displayedColumnsAddress: string[] = ['Type', 'Address', 'Edit', 'Delete'];

    // Role Tab
    dataSourceRoles = new MatTableDataSource<any>();
    displayedColumnsRoles: string[] = ['Role', 'EndDate', 'Inventory', 'Active', 'Certification', 'ViewKeys', 'Edit', 'Delete'];

    // Grids Tab
    dataSourceGrids = new MatTableDataSource<any>();
    displayedColumnsGrids: string[] = ['Grid', 'EndDate', 'Edit', 'Delete'];

    // DailyHours Tab
    dataSourceDailyHours = new MatTableDataSource<any>();
    displayedColumnsDailyHours: string[] = ['dotw', 'Start', 'End', 'Edit', 'Delete'];

    // TimeOff Tab
    dataSourceTimeOff = new MatTableDataSource<any>();
    displayedColumnsTimeOff: string[] = ['Start', 'End', 'Reason', 'User', 'DateEntered', 'Edit', 'Delete'];

    // AdditionalHours Tab
    dataSourceAdditionalHours = new MatTableDataSource<any>();
    displayedColumnsAdditionalHours: string[] = ['Start', 'End', 'Edit', 'Delete'];

    // BucketScheduling Tab
    dataSourceBucketScheduling = new MatTableDataSource<any>();
    displayedColumnsBucketScheduling: string[] = ['workorder', 'grid', 'timeslot', 'apptqty',
        'startdate', 'enddate', 'apptstart', 'apptend',
        'sun', 'mon', 'tues', 'wed', 'thur', 'fri', 'sat', 'modifiable', 'Edit'];

    isBucketEnabled = true;

    constructor(
        private dialog: MatDialog,
        public indService: IndividualService,
        private utilityService: UtilityService,
        private dataTransfer: DataTransferService
    ) { }

    ngOnInit(): void {
        this.dataTransfer.myMethod(true);
        this.updateDataSources();
    }

    private updateDataSources() {
        this.dataSourcePhone.data = this.indService.indDetail.IndividualTab.Phone;
        this.dataSourceEmail.data = this.indService.indDetail.IndividualTab.Email;
        this.dataSourceAddress.data = this.indService.indDetail.IndividualTab.Address;

        this.dataSourceRoles.data = this.indService.indDetail.RolesTab.Roles;
        this.dataSourceGrids.data = this.indService.indDetail.GridsTab.Grids;

        this.indService.indDetail.DailyHoursTab.DailyHours.forEach(element => {
           const starttime = new Date(element.STARTTIME);
           const endtime = new Date(element.ENDTIME);

           const startdate = new Date(element.STARTDATE);
           const enddate = new Date(element.ENDDATE);

           startdate.setHours(starttime.getHours());
           startdate.setMinutes(starttime.getMinutes());
           element.STARTDATE = new Date(startdate);


           enddate.setHours(endtime.getHours());
           enddate.setMinutes(endtime.getMinutes());

           if(element.ENDDATE != null)
           element.ENDDATE = new Date(enddate);
           else
           element.ENDDATE = new Date(endtime);
        });
        this.dataSourceDailyHours.data = this.indService.indDetail.DailyHoursTab.DailyHours;

        if (this.indService.indDetail.TimeOffTab === undefined || this.indService.indDetail.TimeOffTab == null) {
            this.indService.indDetail.TimeOffTab = {TimeOff: []};
        }
        this.dataSourceTimeOff.data = this.indService.indDetail.TimeOffTab.TimeOff;

        if (this.indService.indDetail.AdditionalHoursTab === undefined || this.indService.indDetail.AdditionalHoursTab == null) {
            this.indService.indDetail.AdditionalHoursTab = {AdditionalHours: []};
        }

        
        this.dataSourceAdditionalHours.data = this.indService.indDetail.AdditionalHoursTab.AdditionalHours;

        if (this.indService.indDetail.BucketSchedulingTab !== undefined
            && this.indService.indDetail.BucketSchedulingTab != null) {
            this.dataSourceBucketScheduling.data = this.indService.indDetail.BucketSchedulingTab.BucketScheduling;
            this.isBucketEnabled = true;
        } else {
            this.dataSourceBucketScheduling.data = [];
            this.isBucketEnabled = false;
        }
    }

    // Individual Tab /////////////////////////////////////////////////////////////////////
    addPhone() {
        let className = localStorage.getItem("themeName");
        const dialogRef = this.dialog.open(PhoneComponent, {data: {caption: 'Add Phone', editMode: false},panelClass:className});
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dataSourcePhone.data = this.indService.indDetail.IndividualTab.Phone;
            }
        });
    }

    editPhone(idx) {
        let className = localStorage.getItem("themeName");
        const dialogRef = this.dialog.open(PhoneComponent, {data: {caption: 'Edit Phone', editMode: true, index: idx},panelClass:className});
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dataSourcePhone.data = this.indService.indDetail.IndividualTab.Phone;
            }
        });
    }

    deletePhone(index) {
        this.indService.indDetail.IndividualTab.Phone.splice(index, 1);
        this.dataSourcePhone.data = this.indService.indDetail.IndividualTab.Phone;
    }

    addEmail() {
        Swal.fire({
            title: 'Enter New Email Address',
            input: 'email',
            showCancelButton: true,
            inputPlaceholder: 'Enter email address'
        }).then(email => {
            if (email.value !== undefined) {
                const val = {
                    EMAIL_ID: null,
                    EMAILADDRESS: email.value
                };

                this.indService.indDetail.IndividualTab.Email.push(val);
                this.dataSourceEmail.data = this.indService.indDetail.IndividualTab.Email;
            }
        });
    }

    editEmail(index) {
        Swal.fire({
            title: 'Enter New Email Address',
            input: 'email',
            showCancelButton: true,
            inputPlaceholder: 'Enter email address',
            inputValue: this.indService.indDetail.IndividualTab.Email[index].EMAILADDRESS
        }).then(email => {
            this.indService.indDetail.IndividualTab.Email[index].EMAILADDRESS = email.value;
            this.dataSourceEmail.data = this.indService.indDetail.IndividualTab.Email;
        });
    }

    deleteEmail(index) {
        this.indService.indDetail.IndividualTab.Email.splice(index, 1);
        this.dataSourceEmail.data = this.indService.indDetail.IndividualTab.Email;
    }

    addAddress() {
        let className = localStorage.getItem("themeName");
        const dialogRef = this.dialog.open(AddressComponent, {data: {caption: 'Add Address', editMode: false},panelClass:className});
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dataSourceAddress.data = this.indService.indDetail.IndividualTab.Address;
            }
        });
    }

    editAddress(idx) {
        let className = localStorage.getItem("themeName");
        const dialogRef = this.dialog.open(AddressComponent, {data: {caption: 'Edit Address', editMode: true, index: idx},panelClass:className});
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dataSourceAddress.data = this.indService.indDetail.IndividualTab.Address;
            }
        });
    }

    deleteAddress(index) {
        this.indService.indDetail.IndividualTab.Address.splice(index, 1);
        this.dataSourceAddress.data = this.indService.indDetail.IndividualTab.Address;
    }

    // Role Tab //////////////////////////////////////////////////////////////////////////////////////////
    addRoles() {
        let className = localStorage.getItem("themeName");
        const dialogRef = this.dialog.open(IndividualRoleComponent, {data: {caption: 'Add Role', editMode: false},panelClass:className});
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dataSourceRoles.data = this.indService.indDetail.RolesTab.Roles;
            }
        });
    }

    editRoles(idx) {
        let className = localStorage.getItem("themeName");
        const dialogRef = this.dialog.open(IndividualRoleComponent, {data: {caption: 'Edit Roles', editMode: true, index: idx},panelClass:className});
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dataSourceAddress.data = this.indService.indDetail.RolesTab.Roles;
            }
        });
    }

    deleteRoles(index) {
        this.indService.indDetail.RolesTab.Roles.splice(index, 1);
        this.dataSourceRoles.data = this.indService.indDetail.RolesTab.Roles;
    }

    public viewClientKeys(role) {
        let msg = '';
        if (role.CLIENTKEY1 !== undefined && role.CLIENTKEY1 != null) {
            msg += role.CLIENTKEY1;
        }
        if (role.CLIENTKEY2 !== undefined && role.CLIENTKEY2 != null) {
            if (msg.length > 0) {
                msg += ', ';
            }
            msg += role.CLIENTKEY2;
        }
        if (role.CLIENTKEY3 !== undefined && role.CLIENTKEY3 != null) {
            if (msg.length > 0) {
                msg += ', ';
            }
            msg += role.CLIENTKEY3;
        }
        if (role.CLIENTKEY4 !== undefined && role.CLIENTKEY4 != null) {
            if (msg.length > 0) {
                msg += ', ';
            }
            msg += role.CLIENTKEY4;
        }
        if (role.CLIENTKEY5 !== undefined && role.CLIENTKEY5 != null) {
            if (msg.length > 0) {
                msg += ', ';
            }
            msg += role.CLIENTKEY5;
        }
        if (role.CLIENTKEY6 !== undefined && role.CLIENTKEY6 != null) {
            if (msg.length > 0) {
                msg += ', ';
            }
            msg += role.CLIENTKEY6;
        }

        if (msg.length < 1) {
            msg = 'No Client Keys';
        }

        Swal.fire(
            'Client Keys',
            msg,
            'info'
          );
    }

    // Grids Tab /////////////////////////////////////////////////////////////////////////////////////////////
    addGrids() {
        let className = localStorage.getItem("themeName");
        const dialogRef = this.dialog.open(GridComponent, {data: {caption: 'Add Grid', editMode: false},panelClass:className});
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dataSourceGrids.data = this.indService.indDetail.GridsTab.Grids;
            }
        });
    }

    editGrids(idx) {
        let className = localStorage.getItem("themeName");
        const dialogRef = this.dialog.open(GridComponent, {data: {caption: 'Edit Grid', editMode: true, index: idx},panelClass:className});
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dataSourceGrids.data = this.indService.indDetail.GridsTab.Grids;
            }
        });
    }

    deleteGrids(index) {
        this.indService.indDetail.GridsTab.Grids.splice(index, 1);
        this.dataSourceGrids.data = this.indService.indDetail.GridsTab.Grids;
    }

    // DailyHours Tab //////////////////////////////////////////////////////////////////////////////////////////
    addDailyHours() {
        let className = localStorage.getItem("themeName");
        const dialogRef = this.dialog.open(DailyHoursComponent, {data: {caption: 'Add Hours', editMode: false},panelClass:className});
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dataSourceDailyHours.data = this.indService.indDetail.DailyHoursTab.DailyHours;
            }
        });
    }

    editDailyHours(idx) {
        let className = localStorage.getItem("themeName");
        const dialogRef = this.dialog.open(DailyHoursComponent, {data: {caption: 'Edit Hours', editMode: true, index: idx},panelClass:className});
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dataSourceDailyHours.data = this.indService.indDetail.DailyHoursTab.DailyHours;
            }
        });
    }

    deleteDailyHours(index) {
        this.indService.indDetail.DailyHoursTab.DailyHours.splice(index, 1);
        this.dataSourceDailyHours.data = this.indService.indDetail.DailyHoursTab.DailyHours;
    }

    // TimeOff Tab //////////////////////////////////////////////////////////////////////////////////////////
    addTimeOff() {
        let className = localStorage.getItem("themeName");
        const dialogRef = this.dialog.open(TimeOffComponent, {data: {caption: 'Add Time Off', editMode: false},panelClass:className});
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dataSourceTimeOff.data = this.indService.indDetail.TimeOffTab.TimeOff;
            }
        });
    }

    editTimeOff(idx) {
        let className = localStorage.getItem("themeName");
        const dialogRef = this.dialog.open(TimeOffComponent, {data: {caption: 'Edit Time Off', editMode: true, index: idx},panelClass:className});
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dataSourceTimeOff.data = this.indService.indDetail.TimeOffTab.TimeOff;
            }
        });
    }

    deleteTimeOff(index) {
        this.indService.indDetail.TimeOffTab.TimeOff.splice(index, 1);
        this.dataSourceTimeOff.data = this.indService.indDetail.TimeOffTab.TimeOff;
    }

    // AdditionalHours Tab ////////////////////////////////////////////////////////////////////////////////////////
    addAdditionalHours() {
        let className = localStorage.getItem("themeName");
        const dialogRef = this.dialog.open(AdditionalHoursComponent, {data: {caption: 'Add Additional Hours', editMode: false},panelClass:className});
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dataSourceAdditionalHours.data = this.indService.indDetail.AdditionalHoursTab.AdditionalHours;
            }
        });
    }

    editAdditionalHours(idx) {
        let className = localStorage.getItem("themeName");
        const dialogRef = this.dialog.open(AdditionalHoursComponent,
            {data: {caption: 'Edit Additional Hours', editMode: true, index: idx},panelClass:className});
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dataSourceAdditionalHours.data = this.indService.indDetail.AdditionalHoursTab.AdditionalHours;
            }
        });
    }

    deleteAdditionalHours(index) {
        this.indService.indDetail.AdditionalHoursTab.AdditionalHours.splice(index, 1);
        this.dataSourceAdditionalHours.data = this.indService.indDetail.AdditionalHoursTab.AdditionalHours;
    }

    // BucketScheduling Tab ///////////////////////////////////////////////////////////////////////////////////
    addBucketScheduling() {
        if (this.indService.indDetail.BucketSchedulingTab.GRIDLIST.length < 1
            || this.indService.indDetail.BucketSchedulingTab.WORKORDERTYPELIST.length < 1) {
                Swal.fire(
                    'This organization is not configured.',
                    'Goto Organization Maintenance and configure grid list and workorder types.',
                    'error'
                );
        } else {
            let className = localStorage.getItem("themeName");
            const dialogRef = this.dialog.open(BucketSchedulingComponent, {data: {caption: 'Add Bucket Schedule', editMode: false},panelClass:className});
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.dataSourceBucketScheduling.data = this.indService.indDetail.BucketSchedulingTab.BucketScheduling;
                }
            });
        }
    }

    deactivateBucketScheduling(idx) {
        if (this.indService.indDetail.BucketSchedulingTab.BucketScheduling[idx].DEACTIVATEROW) {
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[idx].DEACTIVATEROW = 0;
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[idx].ACTIVE = 1;
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[idx].DEACTIVATEDATE = null;
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[idx].DEACTIVATEUSER = null;
        } else {
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[idx].DEACTIVATEROW = 1;
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[idx].ACTIVE = 0;
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[idx].DEACTIVATEDATE = moment().format('MM/DD/YYYY HH:mm:ss');
            this.indService.indDetail.BucketSchedulingTab.BucketScheduling[idx].DEACTIVATEUSER = this.utilityService.currentUtility.BBCSUserId;
        }
        this.dataSourceBucketScheduling.data = this.indService.indDetail.BucketSchedulingTab.BucketScheduling;
    }

    editBucketScheduling(idx) {
        let className = localStorage.getItem("themeName");
        const dialogRef = this.dialog.open(BucketSchedulingComponent,
            {data: {caption: 'Edit Bucket Schedule', editMode: true, index: idx},panelClass:className});
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dataSourceBucketScheduling.data = this.indService.indDetail.BucketSchedulingTab.BucketScheduling;
            }
        });
    }

    deleteBucketScheduling(index) {
        this.indService.indDetail.BucketSchedulingTab.BucketScheduling.splice(index, 1);
        this.dataSourceBucketScheduling.data = this.indService.indDetail.BucketSchedulingTab.BucketScheduling;
    }

    // save individual /////////////////////////////////////////////////////////////////////////////////////////
    saveIndividual() {
       
        if (this.indService.indDetail.RolesTab.Roles.length < 1 || this.indService.indDetail.IndividualTab.Individual.FULLNAME === '') {
            Swal.fire(
                'Data Not Saved. There was an issue.',
                'You need at least one Role and a valid name to save.',
                'error'
            );
        } else {
            let className = localStorage.getItem("themeName");
            const dialogRef = this.dialog.open(WaitDialogComponent, {data: {caption: 'Saving...'},panelClass:className});
            this.indService.indDetail.IndividualTab.Individual.FULLNAME =
            this.indService.indDetail.IndividualTab.Individual.FULLNAME.toUpperCase();

            this.indService.save().subscribe(data => {
                if (this.indService.editMode && data.Action) {
                    dialogRef.close();
                    Swal.fire(
                        'Data Not Saved. There was an issue.',
                        data.Message,
                        'error'
                    );
                } else if (!this.indService.editMode && data.Action !== 'False') {
                    dialogRef.close();
                    Swal.fire(
                        'There was an issue saving new individual.',
                        data.Message,
                        'error'
                    );
                } else {
                    let percompId = this.indService.personcompanyId;
                    let organId = this.indService.orgId;
                    dialogRef.close();

                    if (!this.indService.editMode) {
                        percompId = data.PersoncompanyId;
                        organId = this.indService.selectedOrg.VALUE;
                        dialogRef.close();
                    }

                    // re-read individual
                    this.indService.getDetail(organId, percompId).subscribe(data2 => {
                        this.indService.indDetail = data2;
                        this.indService.editMode = true;
                        this.updateDataSources();
                    });

                    // update cache
                    this.indService.getIndividualsForOrganization(this.indService.selectedOrg.VALUE,this.indService.indName).subscribe(data3 => {
                        this.indService.indvList = data3;
                    });

                    Swal.fire(
                        'Success',
                        'Individual Saved',
                        'success'
                    );
                }

                dialogRef.close();
                
                    
            });
        }
    }

    // reset data /////////////////////////////////////
    public reset() {
        let className = localStorage.getItem("themeName");
        const dialogRef = this.dialog.open(WaitDialogComponent, {data: {caption: 'Reloading...'},panelClass:className});
        // read new individual
        this.indService.getDetail(this.indService.orgId, this.indService.personcompanyId).subscribe(data2 => {
            this.indService.indDetail = data2;
            this.updateDataSources();
            dialogRef.close();
        });
    }
}
