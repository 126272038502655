<mat-card>
  <mat-card-header>
    <mat-card-title style="font-size: 16px">
      Manufacturers And Distributors
    </mat-card-title>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="ExportTOExcel()" [disabled]="count == 0">
        Export As Excel
      </button>
    </mat-card-actions>
  </mat-card-header>
  <mat-divider></mat-divider>
  <br />
  <mat-card-content>
    <div class="mat-elevation-z8" #TABLE>
      <table mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="ManufacturerName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacturer Name</th>
          <td mat-cell *matCellDef="let report">
            {{ report.ManufacturerName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="DistributorName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Distributor Name</th>
          <td mat-cell *matCellDef="let report">
            {{ report.DistributorName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="ProgramType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Program Type</th>
          <td mat-cell *matCellDef="let report">
            {{ report.ProgramType }}
          </td>
        </ng-container>
        <ng-container matColumnDef="ManufacturerCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacturer Code</th>
          <td mat-cell *matCellDef="let report">
            {{ report.ManufacturerCode }}
          </td>
        </ng-container>
        <ng-container matColumnDef="DistributorCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Distributor Code</th>
          <td mat-cell *matCellDef="let report">
            {{ report.DistributorCode }}
          </td>
        </ng-container>
        <ng-container matColumnDef="LastModifiedDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>LastModified Date</th>
          <td mat-cell *matCellDef="let report">
            {{ report.LastModifiedDate }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <br /><br />
      <mat-paginator [pageSizeOptions]="[10,20,30]" showFirstLastButtons></mat-paginator>
    </div>
    <ng-container *ngIf="count == 0">
      <span style="text-align: center !important; font-size: larger">No records found.</span>
    </ng-container>
  </mat-card-content>
</mat-card>