import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';

import { UserService } from '../../services/user.service';

import { Role } from '../../models/role';
import { MatDialog, MatDialogRef, MatDialogConfig, MatDialogClose } from '@angular/material/dialog';
import { RoleDetailComponent } from '../role-detail/role-detail.component';
import { RoleCreateComponent } from '../role-create/role-create.component';

@Component({
    selector: 'app-role',
    templateUrl: './role.component.html',
    styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {
    dialogRef: MatDialogRef<RoleDetailComponent>;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    roles = [];
    displayedColumns: string[] = ['RoleId', 'RoleName', 'RoleActive', 'CreatedDate', 'ModifiedBy',  'edit'];
    dataSource = new MatTableDataSource<Role>();
    searching = true;

    constructor(
        private userService: UserService,
        private router: Router,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.getRoles();
    }

    private getRoles() {
        this.userService.getRoles().subscribe(data => {
            this.roles = data;
            this.dataSource.data = this.roles;
            this.searching = false;
        });
    }


    addNewRole() {
        let className=localStorage.getItem("themeName");
        const dialogCreate = this.dialog.open(RoleCreateComponent,{panelClass:className});
        dialogCreate.afterClosed().subscribe(result => {
            this.getRoles();
        });
    }

    public handleClick(role: Role) {
        let className=localStorage.getItem("themeName");
        this.dialogRef = this.dialog.open(RoleDetailComponent,
            {
                data: {
                    dataKey: role
                },panelClass:className
            });

            this.dialogRef.afterClosed().subscribe(result => {
            this.getRoles();
        });
        /* this.router.navigate(['/roleDetail']); */
    }
}
