<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn" matTooltip="Close">close</mat-icon>
</button> 

<div id="loading" *ngIf="loadWorkorder" style="margin: 0 auto; align-items: center">
  <mat-spinner [diameter]="100" color="primary"></mat-spinner>
</div>
<div *ngIf="!loadWorkorder">
  <div class="container">
    <header>
      <h1 class="page-header">Near by Appointments</h1>
      <ul class="nav-menu">
        <li>Customer Address: {{ customerAddress }}</li>
      </ul>
    </header>
  </div>
</div> 

<div id="map"></div> 
