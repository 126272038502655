<h5 mat-dialog-title>Add Address</h5>
<mat-divider></mat-divider>
<div mat-dialog-content style="width: 100vh;">
  <mat-card class="user-card">
    <mat-card-content>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Address Type</mat-label>
        <mat-select [(ngModel)]="addressType" [disabled]="address_id !==null">
          <mat-option *ngFor="let atype of indService.indDetail.IndividualTab.AddressTypeList" [value]="atype" style="font-size: 11px;">
            {{atype.DESCRIPTION}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <br />

      <mat-form-field appearance="outline" style="margin-right: 10px;">
        <mat-label>Street Number</mat-label>
        <input matInput placeholder="Number" required [formControl]="stNo">
        <mat-error *ngIf="stNo.invalid">A number is required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" style="margin-right: 10px;">
        <mat-label>Street Name</mat-label>
        <input matInput placeholder="Street" required [formControl]="stName">
        <mat-error *ngIf="stName.invalid">Required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Street Type</mat-label>
        <input matInput placeholder="Type" required [formControl]="stType">
        <!-- <mat-error *ngIf="stType.invalid">Required</mat-error> -->
      </mat-form-field>
      <br />
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>City</mat-label>
        <input matInput placeholder="City" required [formControl]="stCity">
        <mat-error *ngIf="stCity.invalid">Required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>State</mat-label>
        <input matInput placeholder="State" required [formControl]="stState" maxlength="2">
        <mat-error *ngIf="stState.invalid">A 2 letter state abbreviation is required</mat-error>
      </mat-form-field>
      <br />
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Zip Code</mat-label>
        <input matInput placeholder="Zip" required [formControl]="stZip">
        <mat-error *ngIf="stZip.invalid">A numeric ZIP is required</mat-error>
      </mat-form-field>
      <br>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="saveAddress()" [disabled]="badData()">SAVE</button>
      <button mat-raised-button color="primary" [mat-dialog-close]>CANCEL</button>
    </mat-card-actions>
  </mat-card>
</div>
