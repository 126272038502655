<button mat-icon-button matTooltip="Close" class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>

<div *ngIf="currentWorkorderexceptions != undefined && !loadWorkorder">
  <h1 mat-dialog-title style="color:black;" >Workorder Exceptions </h1>
</div>

<!-- <div > -->
<mat-spinner *ngIf="loadWorkorder" style="margin:0 auto;" [diameter]="40"></mat-spinner>
<!-- </div> -->

<div *ngIf="currentWorkorderexceptions != undefined && !loadWorkorder">
  <mat-card class="list-card" style="margin-right: 1vw !important; margin-bottom: 1vw;">
    <table *ngIf="currentWorkorderexceptions.Exceptions.length >0"
      style="width: 100% !important;background-color:#f6f5f3" mat-table [dataSource]="dataSource"
      class="mat-elevation-z8">

      <ng-container matColumnDef="Exception">
        <th mat-header-cell *matHeaderCellDef>
          Exception</th>
        <td mat-cell *matCellDef="let response" matTooltip="{{response.ExceptionDesc}}">
          {{response.ExceptionDesc}} </td>
      </ng-container>


      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef style="width:7vw !important">Status
        </th>
        <td mat-cell [ngClass]="response.WorkorderStatusCode" *matCellDef="let response">
          {{response.WorkorderStatusCode}} </td>
      </ng-container>

        <ng-container matColumnDef="Message">
          <th mat-header-cell *matHeaderCellDef>Message
          </th>
          <td matTooltip="{{response.ExceptionMessage}}" mat-cell *matCellDef="let response">
          {{ truncateAtWord(response.ExceptionMessage,40) }}</td>
        </ng-container>
        <ng-container matColumnDef="Clearable">
          <th mat-header-cell *matHeaderCellDef>Clearable
          </th>
          <td mat-cell *matCellDef="let response">
            {{response.ExceptionClearable}} </td>
        </ng-container>



      <ng-container matColumnDef="Clear">
        <th mat-header-cell *matHeaderCellDef>Clear</th>
        <td mat-cell *matCellDef="let response">
          <mat-checkbox style=" width:60% !important;"
            [disabled]="(response.ExceptionClearable =='Yes' && response.WorkorderStatusCode!='cleared'? false : true)"
            [(ngModel)]="response.ClearFlag">
          </mat-checkbox>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="curr-cust"></tr>
    </table>
    <div *ngIf="currentWorkorderexceptions.Exceptions.length <=0" style="text-align: center; margin: 0 auto">

      <span style="font-size: 1.2vw; color: #88B04B">No Exceptions to Show</span>

    </div>

    <mat-card-actions style="margin-top: 1vw;">
      <div class="tp-button">
        <!-- <td colspan="3" style="text-align: center"> -->
        <button mat-raised-button color="primary" [disabled]="(response.ShowClearException ==1? false : true)"
          (click)="doClearExceptions()">Clear
          Checked</button>
        <button mat-raised-button color="primary" [disabled]="(response.ShowClearException ==1? false : true)"
          (click)="doClearAllExceptions()">Clear All
          Exceptions</button>
        <!--  </td> -->
      </div>
    </mat-card-actions>



  </mat-card>
</div>