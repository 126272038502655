import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
// import { Observable, of } from 'rxjs';
// import { map } from 'rxjs/operators';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { UserService } from 'src/app/services/user.service';
import { UtilityService } from 'src/app/services/utility.service';
// import { fromMatPaginator, paginateRows } from 'src/app/util/datasource-utils';
import Swal from 'sweetalert2';
import { ReportConfigEditComponent } from '../../report-config-edit/report-config-edit.component';

@Component({
  selector: 'app-report-config',
  templateUrl: './report-config.component.html',
  styleUrls: ['./report-config.component.scss']
})
export class ReportConfigComponent implements OnInit {

  constructor(private utilityService: UtilityService,
    private userService: UserService,
    private _formBuilder: UntypedFormBuilder, private dialog: MatDialog, private dataTransfer:DataTransferService) { }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
/*   @ViewChild(MatPaginator, { static: true }) firstpaginator: MatPaginator;
 */
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  selectedOffice: any;
  selectedMenu: any;
  officeList: any;
  menuList: any;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  searchResult: any = true;
  searching: any = false;
  activeStatus: any;
  searchResultList: any = [];
  //menuOfficeActive:any = true;
  menuOfficeList: any = [];
  user: any;
  menuItem: any;
  officeId: any;
  menuOfficeActive: any = true;
  activeReport: any = true;
  dateControl: any = true;
  displayedColumns: string[] = ['ReportId', 'ReportName', 'ReportDescription', 'ReportMenuName', 'ReportPath', 'ExternalReportPath', 'DateControlParameter', 'ReportActive', 'edit'];
  displayedColumns1: string[] = ['MenuName', 'MenuDescription', 'edit'];

  dataSource = new MatTableDataSource<any>();
  dataSource1 = new MatTableDataSource<any>();


  ngOnInit(): void {
    this.dataTransfer.myMethod(true);
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
      secondCtrl: ['', Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
      secondCtrl: ['', Validators.required],
      thirdCtrl: ['', Validators.required],
      fourthCtrl: ['', Validators.required],
      fifthCtrl: ['', Validators.required],
      MenuCtrl: ['', Validators.required],
      /*   reportActive: [this.activeReport, Validators.required],
        dateControlActive: [this.dateControl, Validators.required], */
    });

    this.getMenuList();
    this.displayMenuAssociation();
  }

  menuChange(e) {

    this.getOffices(e.value.MenuId);
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    this.firstFormGroup.reset();
    this.secondFormGroup.reset();
    if (tabChangeEvent.index == 0) {

      this.displayMenuAssociation();
    }


  }



  getOffices(selectedMenu) {
    this.userService.getOfficesForMenu(selectedMenu).subscribe(res => {
      this.officeList = res;
    })
  }

  deactivateAssociation(menuOffice) {

    this.menuItem = menuOffice.MenuId;
    this.officeId = menuOffice.OfficeId;
    let menuOfficeActive = !menuOffice.MenuActive;
    this.user = this.utilityService.currentUtility.BBCSUserId;

    // if (!this.firstFormGroup.invalid) {

    this.userService.associateMenuOffice(this.officeId, this.menuItem, this.user, menuOfficeActive).subscribe(res => {
      if (res) {

        this.displayMenuAssociation();
      }
    })
  }

  getMenuList() {
    this.userService.getAllMenuList().subscribe(res => {
      this.menuList = res;
    })
  }

  associateMenuOffice() {

    this.menuItem = this.firstFormGroup.value.firstCtrl.MenuId;
    this.officeId = this.firstFormGroup.value.secondCtrl.OfficeId;
    let menuOfficeActive = this.menuOfficeActive;
    this.user = this.utilityService.currentUtility.BBCSUserId;
    //if (!this.firstFormGroup.invalid) {

    this.userService.associateMenuOffice(this.officeId, this.menuItem, this.user, menuOfficeActive).subscribe(res => {
      if (res) {
        Swal.fire({
          title: 'Success',
          text: "Saved successfully",
          icon: 'success',
          confirmButtonText: 'OK',
        }).then((result) => {
          if (result) {
            this.firstFormGroup.reset();
            this.secondFormGroup.reset();
            this.displayMenuAssociation()
          }

        }

        )
      }
      else {
        Swal.fire({
          title: 'Error',
          text: "Could not be saved! Please try again.",
          icon: 'error',
          confirmButtonText: 'OK',
        })
      }
    }, err => {
      Swal.fire({
        title: 'Error',
        text: "Server Error",
        icon: 'error',
        confirmButtonText: 'OK',
      })
    })
  }

  //  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  sortMenuOfficeData(sort: Sort) {
    const data = this.menuOfficeList;
    if (!sort.active || sort.direction === '') {
      this.dataSource1.data = data;
      return;
    }


    this.dataSource1.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {

        case 'MenuName': return this.compare(a.MenuName, b.MenuName, isAsc);
        case 'MenuDescription': return this.compare(a.MenuDescription, b.MenuDescription, isAsc);

        default: return 0;
      }
    });
  }


  sortReportData(sort: Sort) {
    const data = this.searchResultList;
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }


    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {

        case 'ReportId': return this.compare(a.ReportId, b.ReportId, isAsc);
        case 'ReportName': return this.compare(a.ReportName, b.ReportName, isAsc);
        case 'ReportDescription': return this.compare(a.ReportDescription, b.ReportDescription, isAsc);
        case 'ReportMenuName': return this.compare(a.ReportMenuName, b.ReportMenuName, isAsc);
        case 'ReportPath': return this.compare(a.ReportPath, b.ReportPath, isAsc);
        default: return 0;
      }
    });
  }

  displayMenuAssociation() {
    this.userService.displayMenuOfficeList().subscribe(res => {
      console.log(res);
      if (res.length > 0) {
        this.menuOfficeList = res;
        this.dataSource1.paginator = this.paginator;
        this.dataSource1.data = res;
      }
    })
  }
  viewReportConfigurations() {
    this.searchResult = false;
    this.searchResultList = [];
    this.searching = true;

    this.userService.getAllReportList(this.selectedMenu.MenuId).subscribe(data => {
     
      this.searchResultList = data;
      console.log(data);
      if (data.length > 0) {
        this.searching = false;
   //     this.dataSource.paginator = this.firstpaginator;
        this.dataSource.data = this.searchResultList;

      }
      else {
        this.searching = false;

      }


    });

  }

  editReport(reportObj) {
    let className = localStorage.getItem("themeName");
    const dialogRef = this.dialog.open(ReportConfigEditComponent, { data: reportObj, panelClass: className });
    dialogRef.afterClosed().subscribe(result => {
      this.viewReportConfigurations();
      this.firstFormGroup.reset();
      this.secondFormGroup.reset();
    });
  }


  createReport() {

    let ReportID = this.secondFormGroup.value.firstCtrl;
    let ReportName = this.secondFormGroup.value.secondCtrl;
    let ReportDescription = this.secondFormGroup.value.thirdCtrl;
    let Path = this.secondFormGroup.value.fourthCtrl;
    let ExternalPath = this.secondFormGroup.value.fifthCtrl;
    let Active = this.activeReport;
    let MenuItem = this.secondFormGroup.value.MenuCtrl.MenuId;
    let OfficeId = this.utilityService.currentUtility.OfficeId;
    let user = this.utilityService.currentUtility.BBCSUserId;
    let dataControl = this.dateControl;

    if (!this.secondFormGroup.invalid) {
      this.userService.createReportConfig(ReportID, ReportName, ReportDescription, Path, ExternalPath, dataControl, MenuItem, Active, user, OfficeId).subscribe(res => {
        if (res) {
          Swal.fire({
            title: 'Success',
            text: "Saved successfully",
            icon: 'success',
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result) {
              this.firstFormGroup.reset();
              this.secondFormGroup.reset();
            }

          }

          )
        }
        else {
          Swal.fire({
            title: 'Error',
            text: "Could not be saved! Please try again.",
            icon: 'error',
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result) {
              this.firstFormGroup.reset();
              this.secondFormGroup.reset();

            }

          }

          )
        }
      }, err => {
        Swal.fire({
          title: 'Error',
          text: "Server Error",
          icon: 'error',
          confirmButtonText: 'OK',
        }).then((result) => {
          if (result) {
            this.firstFormGroup.reset();
            this.secondFormGroup.reset();

          }

        }

        )
      })
    }
  }
}
