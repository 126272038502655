import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IndividualService } from '../../../services/individual.service';

@Component({
    selector: 'app-phone',
    templateUrl: './phone.component.html',
    styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements OnInit {
    phoneType;
    phoneAreaCode: string;
    phoneExchange: string;
    phoneNo: string;
    phoneExt: string;
    phone_id = null;

    phoneAreaCodeVal: UntypedFormControl;
    phoneExchangeVal: UntypedFormControl;
    phoneNoVal: UntypedFormControl;

    constructor(
        public dialogRef: MatDialogRef<PhoneComponent>,
        public indService: IndividualService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        if (this.data.editMode) {
            this.phoneAreaCodeVal = new UntypedFormControl(this.indService.indDetail.IndividualTab.Phone[this.data.index].AREACODE,
                [Validators.required, Validators.pattern('[0-9]*')]);
            this.phoneExchangeVal = new UntypedFormControl(this.indService.indDetail.IndividualTab.Phone[this.data.index].EXCHANGE,
                [Validators.required, Validators.pattern('[0-9]*')]);
            this.phoneNoVal = new UntypedFormControl(this.indService.indDetail.IndividualTab.Phone[this.data.index].PNUMBER,
                [Validators.required, Validators.pattern('[0-9]*')]);
            this.phoneExt = this.indService.indDetail.IndividualTab.Phone[this.data.index].EXTENSION;

            this.phone_id = this.indService.indDetail.IndividualTab.Phone[this.data.index].PHONE_ID;
            for (var i = 0; i < this.indService.indDetail.IndividualTab.PhoneTypeList.length; i++) {
                if (this.indService.indDetail.IndividualTab.PhoneTypeList[i].CODE
                    === this.indService.indDetail.IndividualTab.Phone[this.data.index].TYPE_CD) {
                    this.phoneType = this.indService.indDetail.IndividualTab.PhoneTypeList[i];
                    break;
                }
            }
        } else {
            this.phoneAreaCodeVal = new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]);
            this.phoneExchangeVal = new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]);
            this.phoneNoVal = new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]);
        }
    }

    getAreaCodeErrorMessage() {
        if (this.phoneAreaCodeVal.hasError('required')) {
            return 'Area code is required';
        }

        return this.phoneAreaCodeVal.hasError('pattern') ? 'Must be a number' : '';
    }

    getExchangeErrorMessage() {
        if (this.phoneExchangeVal.hasError('required')) {
            return 'Exchange is required';
        }

        return this.phoneExchangeVal.hasError('pattern') ? 'Must be a number' : '';
    }

    getNoErrorMessage() {
        if (this.phoneNoVal.hasError('required')) {
            return 'Number is required';
        }

        return this.phoneNoVal.hasError('pattern') ? 'Must be a number' : '';
    }

    badData() {
        return this.phoneType === undefined
            || this.phoneAreaCodeVal.invalid
            || this.phoneExchangeVal.invalid
            || this.phoneNoVal.invalid;
    }

    savePhone() {

        this.phoneAreaCode = this.phoneAreaCodeVal.value;
        this.phoneExchange = this.phoneExchangeVal.value;
        this.phoneNo = this.phoneNoVal.value;

        if (this.phoneExt === undefined) {
            this.phoneExt = null;
        }

        if (this.data.editMode) {
            this.indService.indDetail.IndividualTab.Phone[this.data.index].TYPE_CD = this.phoneType.CODE
            this.indService.indDetail.IndividualTab.Phone[this.data.index].SELECTEDPHONETYPEDESCRIPTION = this.phoneType.DESCRIPTION;
            this.indService.indDetail.IndividualTab.Phone[this.data.index].SELECTEDPHONECODE = this.phoneType.CODE;

            this.indService.indDetail.IndividualTab.Phone[this.data.index].AREACODE = this.phoneAreaCode;
            this.indService.indDetail.IndividualTab.Phone[this.data.index].EXCHANGE = this.phoneExchange;
            this.indService.indDetail.IndividualTab.Phone[this.data.index].PNUMBER = this.phoneNo;
            this.indService.indDetail.IndividualTab.Phone[this.data.index].EXTENSION = this.phoneExt;
        } else {
            const phone = {
                PHONE_ID: null,
                TYPE_CD: this.phoneType.CODE,
                AREACODE: this.phoneAreaCode,
                EXCHANGE: this.phoneExchange,
                PNUMBER: this.phoneNo,
                EXTENSION: this.phoneExt,
                SELECTEDPHONETYPEDESCRIPTION: this.phoneType.DESCRIPTION,
                SELECTEDPHONECODE: this.phoneType.CODE,
                PRIMARY_NUMBER: null
            };

            this.indService.indDetail.IndividualTab.Phone.push(phone);
        }

        this.dialogRef.close('1');
    }
}
