import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
import { WorkorderService } from "../services/workorder.service";
import { SectionArray } from "../workorder-getsave/workorder-getsave.component";
import { KeyValue } from "@angular/common";
import { MatAccordion } from "@angular/material/expansion";
import moment from "moment";
import { UtilityService } from "../services/utility.service";

@Component({
  selector: "app-measures",
  templateUrl: "./measures.component.html",
  styleUrls: ["./measures.component.scss"],
})
export class MeasuresComponent implements OnInit {
  @Input() response: any = [];
  @Input() workorderResponse;
  @Input() measuresLength = 0;
  @Input() measureResponse: any = [];
  @Input() selectedMeasureType: any;
  @Input() myIndex = -1;
  @Input() currentIndex = -1;

  @Output() notifyHideMeasures: EventEmitter<any> = new EventEmitter();
  @Output() selectedTypeNotDUCTHVAC: EventEmitter<any> = new EventEmitter();
  @Output() notifyLoadWorkorder: EventEmitter<any> = new EventEmitter();
  @Output() notifyUploadDoc: EventEmitter<any> = new EventEmitter();
  @Output() notifyViewDoc: EventEmitter<any> = new EventEmitter();
  @Output() saveWorkorder: EventEmitter<any> = new EventEmitter();
  clickCount() { }
  @ViewChild("Woaccordion", { static: false }) Woaccordion: MatAccordion;

  //---------Measures-----------
  lineItemId: any;
  showAddMeasure = false;
  //----------------------------
  sectionObj: SectionArray[] = [];
  psegdisable = "No";
  sectionEdited: any;
  selectedSection: any;
  expandedItem: any;

  @Input() filterNewFixture: any = [];
  @Input() measureData;
  isDisabled: boolean = true;
  @Input() existingFixtureArray: any = [];
  storeMeaureData: any = [];
  searchText: any;

  @Input() esmodelArray: any = [];
  @Input() manufacturerArray: any = [];
  @Input() energyStarModelArray: any = [];

  @Input() manufacturerhvacArray: any = [];
  @Input() modelhvacArray: any = [];
  officeId = this.utilityService.currentUtility.OfficeId.toLowerCase();
  @ViewChild('searchInput') searchElement: ElementRef;
  constructor(
    private workorderService: WorkorderService,
    private spinner: NgxSpinnerService,
    private utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    this.expandedItem = null;
  }

  //------------------Measures----------------------
  saveMeasure() {
    this.saveWorkorder.emit(this.measureResponse);
  }

  openAddMeasure(toggleAddMeasure, index, Lineitemmasterid) {
    this.selectedMeasureType = Lineitemmasterid;
    this.selectedSection = Lineitemmasterid;
    this.expandedItem = index;
    this.showAddMeasure = toggleAddMeasure;
    this.addMeasures(Lineitemmasterid);
  }

  addMeasures(Lineitemmasterid) {
    this.selectedTypeNotDUCTHVAC.emit(Lineitemmasterid);
    this.spinner.show();
    this.workorderService
      .addMeasures(
        this.workorderResponse.WorkorderId,
        "addMeasure",
        localStorage.getItem("subGroup"),
        "ALL",
        Lineitemmasterid ?? "ALL",
        localStorage.getItem("subGroup1"),
      )
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          this.measuresLength = res?.DetailedInfo[0]?.Attributes?.length;
          this.lineItemId = res.DetailedInfo[0].Attributes[0].SelectedValue;
          this.measureResponse = res;
        },
        () => {
          const err = "Error in Adding Measures";
        }
      );
  }

  goToMeasureScroll(id) {
    let el = document.getElementById(id);
    el.scrollIntoView();
  }

  updateMeasure(isMeasure, lineID = null, Lineitemmasterid = null) {
    if (!isMeasure) return;
    this.selectedSection = Lineitemmasterid;
    this.selectedMeasureType = Lineitemmasterid;
    this.selectedTypeNotDUCTHVAC.emit(null);
    this.currentIndex = this.myIndex;
    this.spinner.show();
    this.workorderService
      .addMeasures(
        this.workorderResponse.WorkorderId,
        "addMeasure",
        localStorage.getItem("subGroup"),
        lineID ?? this.lineItemId,
        Lineitemmasterid,
        localStorage.getItem("subGroup1")
      )
      .subscribe(
        (data: any) => {
          this.measureResponse = data;
          this.measuresLength = data?.DetailedInfo[0]?.Attributes?.length;
          this.lineItemId = data?.DetailedInfo[0]?.Attributes[0]?.SelectedValue;
         
          this.measureResponse?.DetailedInfo[0]?.Attributes.forEach(element => {
            if (element.AttributeId == 'repltype_' + this.officeId && element.Name == "New Fixture Type") {
              this.measureData = element.Values;
              this.filterNewFixture = element.Values;
            }
            if (element.AttributeId == 'existtype_' + this.officeId && element.Name == "Existing Fixture Type") {
              element.SelectedValue != null ? this.changeFixture(element.SelectedValue) : '';
            }
            if (element.AttributeId == 'test_eq_serialno_' + this.officeId && element.IsESModel != undefined) {
              this.measureData = element.Values;
              this.esmodelArray = element.Values;
            }
            if (element.AttributeId == 'replmanuf_' + this.officeId && element.IsManufacturer != undefined) {
              element.SelectedValue != null ? this.changeManufacture(element.SelectedValue) : '';
            }
            if (element.AttributeId == 'model_id_' + this.officeId && element.IsModelNo != undefined) {
              element.SelectedValue != null ? this.changeModel(element.SelectedValue) : '';
            }
            if (element.AttributeId == 'replmanufhvac_' + this.officeId) {
              this.measureData = element.Values;
              this.manufacturerhvacArray = element.Values;
            }
            if(element.AttributeId == 'replmodelhvac_' + this.officeId) {
              this.modelhvacArray = element.Values;
            }
          });
          this.updateChangeReason();
          this.spinner.hide();
          this.datepickerWork(this.measureResponse);
        }
      );
  }

  //-----------------Date picker implementation------------
  datepickerWork(resp) {
    let reponseArrays = Object?.keys(resp);
    if (reponseArrays.length > 0) {
      for (let resps of resp?.DetailedInfo[0]?.Attributes) {
        if (resps.DataType == 'Date') {
          if (
            resps.SelectedValue != null ||
            resps.SelectedValue != undefined
          ) {
            let dt = moment(resps.SelectedValue).toLocaleString();
            if (dt.indexOf("0530") != -1) {
              resps.SelectedValue = moment(dt).add(1, "day").toDate();
            } else {
              resps.SelectedValue = moment(dt).toDate();
            }
          }

        }
      }
    }
  }

  deleteMeasure(lineItem) {
    this.spinner.show();
    if (lineItem) {
      this.spinner.hide();
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result: any) => {
        // if line item is old then call delete API.
        if (result.value) {
          this.spinner.show();
          this.workorderService
            .deleteMeasure(
              this.workorderResponse.WorkorderId,
              lineItem,
              localStorage.getItem("subGroup"),
              localStorage.getItem("subGroup1")
            )
            .subscribe(
              (res) => {
                if (!res.Message) {
                  this.spinner.hide();
                  Swal.fire({
                    text: "Successfully Deleted",
                    icon: "success",
                    confirmButtonText: "OK",
                  }).then(() => {
                    this.notifyLoadWorkorder.emit("show");
                    this.addMeasureClose();
                  });
                } else {
                  this.spinner.hide();
                  Swal.fire({
                    text: res.Message,
                    icon: "error",
                    confirmButtonText: "OK",
                  }).then(() => {
                    this.notifyLoadWorkorder.emit("show");
                    this.addMeasureClose();
                  });
                }
              },
              () => {
                this.spinner.hide();
                Swal.fire({
                  text: "Error While Deleting",
                  icon: "error",
                  confirmButtonText: "OK",
                });
              }
            );
        }
      });
    }
  }

  addMeasureClose() {
    this.showAddMeasure = false;
    this.measuresLength = 0;
    this.isDisabled = true;
    this.existingFixtureArray = [];
    this.energyStarModelArray = [];
    this.modelhvacArray = [];
    localStorage.removeItem("mData");
  }
  //------------------End of Measures----------------------

  //--------File Upload & View------------------
  uploadDoc(lineItemID) {
    this.notifyUploadDoc.emit(lineItemID);
  }

  viewDoc(lineItemID) {
    this.notifyViewDoc.emit(lineItemID);
  }
  //--------End of File Upload & View------------------

  oninitialize(selectvalue: any, attribute: any, editable: any) {
    if (attribute == "REFOWN1") {
      if (selectvalue == "2" && editable == "Yes") {
        this.psegdisable = "Yes";
      } else if (selectvalue == "1" || editable != "Yes") {
        this.psegdisable = "No";
      }
    }
  }
  dataChanged_customize($event, sectionId, rowId, attribute: any) {
    if (attribute == "REFOWN1") {
      this.onchangedropdown($event);
    }
    this.sectionEdited = true;

    this.sectionObj.push({ sectionId: sectionId, rowId: rowId });
    localStorage.setItem("mData", JSON.stringify(this.measureResponse));
  }
  dataChanged(sectionId, rowId) {
    this.sectionEdited = true;

    this.sectionObj.push({ sectionId: sectionId, rowId: rowId });
    localStorage.setItem("mData", JSON.stringify(this.measureResponse));
  }
  onchangedropdown(value: any) {
    if (value == "2") {
      this.psegdisable = "Yes";
    } else if (value == "1") {
      this.psegdisable = "No";
    }
  }
  psgDataChanged($event, sectionId, rIndex, cIndex, nIndex) {
    let index = nIndex ? nIndex : rIndex;
    this.workorderResponse.SectionInfo.forEach((element) => {
      if (sectionId == element.SectionId) {
        element.IsSectionModified = "true";
        element.Tabular[index].ShowAddMeasures = 1;
        element.Tabular[index].Attributes[cIndex]["SelectedValue"] = $event;
        const selValList =
          element.Tabular[index].Attributes[cIndex]["Values"] || [];
      }
    });
  }

  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };

  //--------------added for CL--------------
  changeFixture(event) {
    this.measureResponse?.DetailedInfo[0]?.Attributes.forEach((element) => {
      if (element.AttributeId == 'existtype_' + this.officeId && element.Name == "Existing Fixture Type") {
        this.existingFixtureArray = element.Values?.filter((ele) => ele.ItemNo == event.value || ele.ItemNo == event);
      }
    });
  }

  changeFilter(event, isModel) {
    if (this.measureData?.length == 0 || this.filteredText == "") {
      return this.measureData;
    } else {
      if (!isModel) {
        return this.measureData?.filter((ele) => {
          return ele.ItemNo == event;
        });
      } else {
        return this.measureData?.filter((ele) => {
          return ele.Value.toLocaleLowerCase().indexOf(event) > -1;;
        });
      }
    }
  }

  changeReason(eve) {
    let reasonId = this.utilityService?.currentUtility?.OfficeId + "LRFWD3";
    if (eve.value.includes(reasonId)) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
      this.measureResponse?.DetailedInfo[0]?.Attributes.forEach((ele) => {
        if (ele.Name == "Watts/sq ft for New Constr.,") {
          ele.SelectedValue = "";
        }
      });
    }
  }

  updateChangeReason() {
    this.storeMeaureData = this.measureResponse?.DetailedInfo[0]?.Attributes;
    if (this.storeMeaureData != undefined) {
      for (const element of this.storeMeaureData) {
        let upReasonId = this.utilityService.currentUtility.OfficeId + "LRFWD3";
        if (
          element.Name == "Reason for Work Done" &&
          element.SelectedValue == upReasonId
        ) {
          this.isDisabled = false;
          break;
        } else {
          this.isDisabled = true;
        }
      }
    }
  }

  get filteredText() {
    return this.searchText;
  }

  set filteredText(value: any) {
    this.searchText = value;
    this.filterNewFixture = this.changeFilter(value, false);
    this.esmodelArray = this.changeFilter(value, true);
  }

  clearFilterText(): void {
    this.filteredText = "";
  }
  //--------------added for CL--------------

  onESModelChange($event) {
    let pOptionName = "1";
      this.measureResponse?.DetailedInfo[0]?.Attributes?.filter(
        (ele) => {
          if (ele.AttributeId == 'test_eq_serialno_' + this.officeId && ele.IsESModel != undefined && $event.value == pOptionName) {
            this.measureResponse?.DetailedInfo[0]?.Attributes?.filter(eleText => {
              if (eleText.AttributeId == 'existmanuf_' + this.officeId && eleText.IsOtherManufacturer != undefined) {
                eleText.SelectedValue = '';
                eleText.Visible = "1";
              }
              if (eleText.AttributeId == 'test_eq_serialno_Others_' + this.officeId && eleText.IsOtherESModel != undefined) {
                eleText.SelectedValue = '';
                eleText.Visible = "1";
              }
              if (eleText.AttributeId == 'replmanuf_' + this.officeId && eleText.IsManufacturer != undefined) {
                eleText.SelectedValue = '';
                eleText.Visible = "0";
                eleText.IsMandatory = "No";
              }
              if (eleText.AttributeId == 'model_id_' + this.officeId && eleText.IsModelNo != undefined) {
                eleText.SelectedValue = '';
                eleText.Visible = "0";
                eleText.IsMandatory = "No";
              }
              if (eleText.AttributeId == 'existmodel_' + this.officeId && eleText.IsOtherModelNo != undefined) {
                eleText.SelectedValue = '';
                eleText.Visible = "1";
              }
            });
          }
          if (ele.AttributeId == 'test_eq_serialno_' + this.officeId && ele.IsESModel != undefined && $event.value != pOptionName) {
            this.measureResponse?.DetailedInfo[0]?.Attributes.filter(eleText => {
              if (eleText.AttributeId == 'existmanuf_' + this.officeId && eleText.IsOtherManufacturer != undefined) {
                eleText.SelectedValue = '';
                eleText.Visible = "0";
              }
              if (eleText.AttributeId == 'test_eq_serialno_Others_' + this.officeId && eleText.IsOtherESModel != undefined) {
                eleText.SelectedValue = '';
                eleText.Visible = "0";
              }
              if (eleText.AttributeId == 'replmanuf_' + this.officeId && eleText.IsManufacturer != undefined) {
                this.changeManufacture($event);
                this.energyStarModelArray = [];
                eleText.SelectedValue = '';
                eleText.Visible = "1";
                eleText.IsMandatory = "1";
              }
              if (eleText.AttributeId == 'model_id_' + this.officeId && eleText.IsModelNo != undefined) {
                this.changeModel($event);
                eleText.SelectedValue = '';
                eleText.Visible = "1";
                eleText.IsMandatory = "1";
              }
              if (eleText.AttributeId == 'existmodel_' + this.officeId && eleText.IsOtherModelNo != undefined) {
                eleText.SelectedValue = '';
                eleText.Visible = "0";
              }
            });
          }
      });
    
  }

  changeModel($event) {
    this.measureResponse?.DetailedInfo[0]?.Attributes?.filter((ele) => {
      if (ele.AttributeId == 'model_id_' + this.officeId && ele.IsModelNo != undefined) {
          this.energyStarModelArray = ele.Values?.filter((ele) => ele?.Id == $event?.value || ele?.Id == $event);
        }
    });
  }

  changeManufacture($event) {
    this.measureResponse?.DetailedInfo[0]?.Attributes?.filter((ele) => {
      if (ele.AttributeId == 'replmanuf_' + this.officeId && ele.IsManufacturer != undefined) {
        this.manufacturerArray = ele.Values?.filter((ele) => ele?.Id == $event?.value || ele?.Id == $event);
      }
    });
  }

  onManufacturerchange($event) {
    this.measureResponse?.DetailedInfo[0]?.Attributes?.filter((ele) => {
      if (ele.AttributeId == 'replmodelhvac_' + this.officeId) {
        ele.SelectedValue = '';
        ele.Visible = "1";
        ele.IsMandatory = "1";
        this.modelhvacArray = ele.Values?.filter((ele) => ele?.ItemNo == $event?.value);
      }
    });
  }

  focus() {
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 0);
  }
}
