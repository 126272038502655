import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilityService } from '../services/utility.service';
import { UserService } from '../services/user.service';
import { EnrollmentService } from '../services/enrollment.service';
import { WorkorderService } from '../services/workorder.service';
import { Router } from '@angular/router';
import { KeyValue } from '@angular/common';
import { ChartDataset, ChartOptions } from 'chart.js';

@Component({
  selector: 'app-workorder-aging',
  templateUrl: './workorder-aging.component.html',
  styleUrls: ['./workorder-aging.component.scss']
})
export class WorkorderAgingComponent implements OnInit {
  dataAging: any = [];
  detailAging: any = [];
  selectedRecord;
  dataExceptionSummary: any = [];
  detailSummary: any = [];
  installerProd: any = [];
  selectedException;
  dataHoldReasonSummary: any = [];
  detailHoldReasonSummary: any = [];
  selectedReason;
  selectedRowIndex: number | null = null;
  public barChartData: ChartDataset[] = [];
  public barChartLabels: string[];

  public barChartDataSummary: ChartDataset[] = [];
  public barChartLabelSummary: string[];

  public barChartInstaller: ChartDataset[] = [];
  public barChartLabelInstaller: string[];
  @ViewChild('tableContainer') tableContainer: ElementRef | undefined;
  @ViewChild('tableContainerReason') tableContainerReason: ElementRef | undefined;

  public barChartDataReason: ChartDataset[] = [];
  public barChartLabelReason: string[];

  constructor(public utilityService: UtilityService,
    private spinner: NgxSpinnerService,
    private workorderService: WorkorderService,
    private enrollmentService: EnrollmentService,
    private router: Router,
    public userService: UserService) { }

  ngOnInit(): void {
    this.getWorkorderAging();
    this.getWorkorderExceptionSummary();
    this.getInstallerProduction();
    this.getHoldReasonSummary();
  }

  getWorkorderAging() {
    this.spinner.show();
    this.userService.getWorkorderAging(this.utilityService.currentUtility.OfficeId).subscribe((data) => {
      this.dataAging = data["AgingData"];
      this.processData(this.dataAging);
      this.spinner.hide();
    });
  }

  getAgingDetail(row) {
    this.spinner.show();
    this.selectedRecord = row["Workorder"] + " - " + row["Status"];
    this.userService.getWorkorderAgingDetail(this.utilityService.currentUtility.OfficeId, row["Programdetail_Id"], row["Status_Cd"]).subscribe((data) => {
      this.detailAging = data["DetailAgingData"];
      this.spinner.hide();
    });
  }

  getWorkorderExceptionSummary() {
    this.spinner.show();
    this.userService.getWorkorderSummary(this.utilityService.currentUtility.OfficeId).subscribe((data) => {
      this.dataExceptionSummary = data["SummeryData"];
      this.spinner.hide();
      this.barChartLabelSummary = this.dataExceptionSummary.map(data => data?.Exception?.length > 25 ? data?.Exception?.substring(0, 25) + '...' : data?.Exception);
      this.barChartDataSummary = [
        { data: this.dataExceptionSummary.map(data => data.AverageDays), label: 'Average Days' }
      ];
    });
  }

  getSummaryDetail(row) {
    this.spinner.show();
    this.selectedException = row["Exception"];
    this.userService.getWorkorderSummaryDetail(this.utilityService.currentUtility.OfficeId, row["ExceptionCd"]).subscribe((data) => {
      this.detailSummary = data["DetailWorkorderSummery"];
      this.spinner.hide();
    });
  }

  refresh() {
    this.getWorkorderAging();
    this.detailAging = [];
    this.selectedRecord = "";
  }

  refreshSummary() {
    this.getWorkorderExceptionSummary();
    this.detailSummary = [];
    this.selectedException = "";
  }

  handleEnrollmentWorkorderClick(data) {
    const body = { Id: data.Workorderid };
    this.workorderService.currentWorkorderID = data.Workorderid ?? data.WorkorderId;
    this.enrollmentService.currentEnrollmentID = data.EnrollmentId;
    this.router.navigate(['/workorderGetSave', data?.GroupId ?? "DEFAULT", data?.SubGroupId, data?.Workorderid ?? data.WorkorderId, data?.SubGroup1 ?? "ALL"]);
  }

  processData(data: any[]): void {
    if (data.length === 0) return;

    const firstItem = data[0];
    const keys = Object.keys(firstItem).filter(key => key !== 'Workorder' && key !== 'Status' && key !== 'Status_Cd' && key !== 'Programdetail_Id');
    this.barChartLabels = keys;

    this.barChartData = data.map(item => {
      const values = keys.map(key => item[key]);
      return {
        label: `${item.Workorder} - ${item.Status}`,
        data: values,
      };
    });
  }

  chartOptions: ChartOptions = {
    responsive: true,
  };

  chartClicked(e: any) {
    this.selectedRowIndex = e.active[0].index;
    this.setFocusOnRow(e.active[0].index, this.tableContainer);
  }

  setFocusOnRow(index: number, table) {
    if (table && table.nativeElement) {
      const rows = table.nativeElement.querySelectorAll('tr');
      if (rows && rows.length > index) {
        const row = rows[index] as HTMLElement;
        row.scrollIntoView({ behavior: 'smooth', block: 'start' });
        row.focus();
      }
    }
  }

  getInstallerProduction() {
    this.spinner.show();
    this.userService.getInstallerProduction(this.utilityService.currentUtility.OfficeId).subscribe((data) => {
      this.installerProd = data["ProductionData"];
      this.spinner.hide();
      if (data["ProductionData"].length > 0) {
        const firstItem = data["ProductionData"][0];
        const keys = Object.keys(firstItem).filter(key => key !== 'Org_cd' && key !== 'Personcompany_Id' && key !== 'Organization');
        this.barChartLabelInstaller = keys;
        this.barChartInstaller = data["ProductionData"].map(item => {
          const values = keys.map(key => item[key]);
          return {
            label: `${item.Organization}`,
            data: values,
          };
        });
      }
    });
  }

  getHoldReasonSummary() {
    this.spinner.show();
    this.userService.getHoldReasonSummary(this.utilityService.currentUtility.OfficeId).subscribe((data) => {
      this.dataHoldReasonSummary = data["HoldSummaryReason"];
      this.spinner.hide();
      if (data["HoldSummaryReason"].length > 0) {
        this.barChartLabelReason = this.dataHoldReasonSummary
          .map(item => item.ReasonDesc?.length > 25 ? item.ReasonDesc?.substring(0, 25) + '...' : item?.ReasonDesc);

        this.barChartDataReason = [{
          data: this.dataHoldReasonSummary.map(item => item.Count || 0),
          label: 'Count'
        }];
      }

    });
  }

  getHoldReasonDetail(row) {
    this.spinner.show();
    this.selectedReason = row["ReasonDesc"];
    this.userService.getHoldReasonDetail(this.utilityService.currentUtility.OfficeId, row["Reason_cd"]).subscribe((data) => {
      this.detailHoldReasonSummary = data["HoldDetailReason"];
      this.spinner.hide();
    });
  }

  refreshReasonSummary() {
    this.getHoldReasonSummary();
    this.detailHoldReasonSummary = [];
    this.selectedReason = "";
  }

  chartReasonClicked(e: any) {
    this.selectedRowIndex = e.active[0].index;
    this.setFocusOnRow(e.active[0].index, this.tableContainerReason);
  }

  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };
}
