<button matTooltip="Close" mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title  style="color:black;" >Location Search </h1>
<br>
<br>
<table style="float: right;top:400px;right: 200px;">

  <tr >
      <td style="background-color: #84bd3a;font-size:0.9vw;color:black;">Same Grid </td>
      &nbsp;
      <td style="background-color: #ffe600d8;font-size:0.9vw;color:black;border: 1.5px solid #999900;">Adjacent Grid </td>
      &nbsp;
      <td style="background-color: #ec4141;font-size:0.9vw;color:whitesmoke;border: 1.5px solid darkred;">Nearby Grid</td>
  </tr>
</table>


<form class="example-form">
  <mat-form-field style="padding-right: 2.2vw;width:10vw;">
    <input type="text"
           placeholder="GRIDS"
           aria-label="Number"
           matInput
           [formControl]="myControl"
           [matAutocomplete]="auto" [(ngModel)]="selectedGrid" >
           <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)='Gridselection($event)'>

      <mat-option   *ngFor="let option of filteredOptions | async" [value]="option" style="font-size: 12px;">
        {{option}}
      </mat-option>

    </mat-autocomplete>
  </mat-form-field>
</form>




<div mat-dialog-content>

  <div id="loading" *ngIf="loadWorkorder" style="margin:0 auto;align-items: center;">
    <mat-spinner [diameter]="100" color="primary"></mat-spinner>
  </div>


<div *ngIf="!loadWorkorder">

 <mat-card class="list-card" style="margin-right: 1vw !important; margin-bottom: 1vw;">
 <!--  <button (click)="Closeall()" style="margin-top: 1px;margin-right: 2px;position:absolute;top:0;right:0" mat-icon-button class="close-button" >
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button> -->





  <table class="table-header-color" style="width:100% !important;  color:whitesmoke;">
    <thead>
      <tr>
<div class="center">
        <th>
          <button matTooltip="Fast Rewind" style="color:whitesmoke;width:75px;font-size: 0.7vw;" mat-button color="primary"  (click)="previousWeek()">
            <mat-icon >fast_rewind</mat-icon>
          </button>
    </th>


<th>
          <button matTooltip="Fast Forward" style="color:whitesmoke;width: 75px;font-size: 0.7vw;" mat-button color="primary" (click)="NextWeek()">
            <mat-icon >fast_forward</mat-icon>
          </button>
    </th>
  </div>
      <th style="text-align:center;font-size: 0.9vw;width: 12% !important; border: 1px solid black;">{{mondayOfWeek | date:'EEE, MMM / d'}}</th>
      <th style="text-align:center;font-size: 0.9vw;width: 12% !important; border: 1px solid black;"> {{tuesdayOfWeek | date:'EEE, MMM / d'}} </th>
      <th style="text-align:center;font-size: 0.9vw;width: 12% !important; border: 1px solid black;"> {{wednesdayOfWeek | date:'EEE, MMM / d'}} </th>
      <th style="text-align:center;font-size: 0.9vw;width: 12% !important; border: 1px solid black; "> {{thursdayOfWeek | date:'EEE, MMM / d'}} </th>
      <th style="text-align:center;font-size: 0.9vw;width: 12% !important; border: 1px solid black;"> {{fridayOfWeek | date:'EEE, MMM / d'}} </th>
      <th style="text-align:center;font-size: 0.9vw;width: 12% !important; border: 1px solid black; "> {{satdayOfWeek | date:'EEE, MMM / d'}} </th>
      <th style="text-align:center;font-size: 0.9vw;width: 11.9% !important; border: 1px solid black; "> {{sundayOfWeek | date:'EEE, MMM / d'}} </th>




    </tr>
      </thead>




  </table>
  <table class="table-header-color" style="width:100% !important;  color:whitesmoke;">


    <thead>

      <tr>

        <th style="text-align:center;font-size: 0.9vw;width:16.6% !important;border: 1px solid black;">Resources</th>

        <th style="text-align:center;font-size: 0.8vw;width: 8% !important; border: 1px solid black;">Location</th>
        <th style="text-align:center;font-size: 0.7vw;width: 4% !important; border: 1px solid black;">Appt</th>

        <th style="text-align:center;font-size: 0.8vw;width: 8% !important; border: 1px solid black;">Location</th>
        <th style="text-align:center;font-size: 0.7vw;width: 4% !important; border: 1px solid black;">Appt</th>

        <th style="text-align:center;font-size: 0.8vw;width: 8% !important; border: 1px solid black;">Location</th>
        <th style="text-align:center;font-size: 0.7vw;width: 3.95% !important; border: 1px solid black;">Appt</th>

        <th style="text-align:center;font-size: 0.8vw;width: 8% !important; border: 1px solid black;">Location</th>
        <th style="text-align:center;font-size: 0.7vw;width: 3.95% !important; border: 1px solid black;">Appt</th>


        <th style="text-align:center;font-size: 0.8vw;width: 8% !important; border: 1px solid black;">Location</th>
        <th style="text-align:center;font-size: 0.7vw;width: 3.95% !important; border: 1px solid black;">Appt</th>

        <th style="text-align:center;font-size: 0.8vw;width: 8% !important; border: 1px solid black;">Location</th>
        <th style="text-align:center;font-size: 0.7vw;width: 4% !important; border: 1px solid black;">Appt</th>


        <th style="text-align:center;font-size: 0.8vw;width: 8% !important; border: 1px solid black;">Location</th>
        <th style="text-align:center;font-size: 0.7vw;width: 3.5% !important; border: 1px solid black;">Appt</th>





      </tr>
</thead>
  </table>
  <table  style="width:100% !important;">
    <tr *ngFor="let mon of tabledata_1">
      <td style="text-align:center;font-size: 0.8vw;width: 16.6% !important;border: 1px solid  black"> {{mon.name}}</td>
      <ng-container
      *ngIf="mon.colorfirst == null; then textcontolfirst; else buttoncontrolfirst">
    </ng-container>
    <ng-template #buttoncontrolfirst>
      <td style="text-align:center;font-size: 0.6vw;width:8% !important;border: 1px solid  black">
        <input type="button"  [ngStyle]="{'backgroundColor': mon.colorfirst == 'yellow' ? '#ffe600d8' : mon.colorfirst == 'red' ? '#ec4141' : mon.colorfirst == 'green' ? '#84bd3a': mon.colorfirst == null ? 'white' :'white' }" [hidden] ="mon.gridfirst === null" style="width:70px;font-size: 0.8vw" value={{mon.gridfirst}} style="width:70px;font-size: 0.8vw" (dblclick)="ScheduleNavigate(mon.personcompanyid,mon.gridfirst,mon.mondayOfWeek)" />
      </td>
    </ng-template>
    <ng-template #textcontolfirst>
      <td [ngStyle]="{'backgroundColor': mon.colorfirst == 'yellow' ? '#ffe600d8' : mon.colorfirst == 'red' ? '#ec4141' : mon.colorfirst == 'green' ? '#84bd3a': mon.colorfirst == null ? 'white' :'white' }"style="text-align:center;width:8%;font-size: 0.8vw; border: 1px solid black;">{{mon.gridfirst}}</td>
    </ng-template>

    <td style="text-align:center;font-size: 0.8vw;width: 4% !important;border: 1px solid black"> {{mon.countfirst}}</td>


      <ng-container
      *ngIf="mon.colorsecond == null; then textcontolsecond; else buttoncontrolsecond">
    </ng-container>
    <ng-template #buttoncontrolsecond>
      <td style="text-align:center;font-size: 0.6vw;width:8% !important;border: 1px solid  black">
        <input type="button"  [ngStyle]="{'backgroundColor': mon.colorsecond == 'yellow' ? '#ffe600d8' : mon.colorsecond == 'red' ? '#ec4141' : mon.colorsecond == 'green' ? '#84bd3a': mon.colorsecond == null ? 'white' :'white' }" [hidden] ="mon.gridsecond === null" style="width:70px;font-size: 0.8vw" value={{mon.gridsecond}} style="width:70px;font-size: 0.8vw" (dblclick)="ScheduleNavigate(mon.personcompanyid,mon.gridsecond,mon.tuesdayOfWeek)" />
      </td>
    </ng-template>
    <ng-template #textcontolsecond>
      <td [ngStyle]="{'backgroundColor': mon.colorsecond == 'yellow' ? '#ffe600d8' : mon.colorsecond == 'red' ? '#ec4141' : mon.colorsecond == 'green' ? '#84bd3a': mon.colorsecond == null ? 'white' :'white' }"style="text-align:center;width:8%;font-size: 0.8vw; border: 1px solid black;">{{mon.gridsecond}}</td>
    </ng-template>




      <td style="text-align:center;font-size: 0.8vw;width: 4% !important;border: 1px solid black"> {{mon.countsecond}}</td>

      <ng-container
      *ngIf="mon.colorthird == null; then textcontolthird; else buttoncontrolthird">
    </ng-container>
    <ng-template #buttoncontrolthird>
      <td style="text-align:center;font-size: 0.6vw;width:8% !important;border: 1px solid  black">
        <input type="button"  [ngStyle]="{'backgroundColor': mon.colorthird == 'yellow' ? '#ffe600d8' : mon.colorthird == 'red' ? '#ec4141' : mon.colorthird == 'green' ? '#84bd3a': mon.colorthird == null ? 'white' :'white' }" [hidden] ="mon.gridthird === null" style="width:70px;font-size: 0.8vw" value={{mon.gridthird}} style="width:70px;font-size: 0.8vw" (dblclick)="ScheduleNavigate(mon.personcompanyid,mon.gridthird,mon.wednesdayOfWeek)" />
      </td>
    </ng-template>
    <ng-template #textcontolthird>
      <td [ngStyle]="{'backgroundColor': mon.colorthird == 'yellow' ? '#ffe600d8' : mon.colorthird == 'red' ? '#ec4141' : mon.colorthird == 'green' ? '#84bd3a': mon.colorthird == null ? 'white' :'white' }"style="text-align:center;width:8%;font-size: 0.8vw; border: 1px solid black;">{{mon.gridthird}}</td>
    </ng-template>


      <td style="text-align:center;font-size: 0.8vw;width: 4% !important;border: 1px solid  black"> {{mon.countthird}}</td>

      <ng-container
      *ngIf="mon.colorfourth  == null; then textcontolfourth; else buttoncontrolfourth">
    </ng-container>
    <ng-template #buttoncontrolfourth>
      <td style="text-align:center;font-size: 0.6vw;width:8% !important;border: 1px solid  black">
        <input type="button"  [ngStyle]="{'backgroundColor': mon.colorfourth == 'yellow' ? '#ffe600d8' : mon.colorfourth == 'red' ? '#ec4141' : mon.colorfourth == 'green' ? '#84bd3a': mon.colorfourth == null ? 'white' :'white' }" [hidden] ="mon.gridfourth === null" style="width:70px;font-size: 0.8vw" value={{mon.gridfourth}} style="width:70px;font-size: 0.8vw" (dblclick)="ScheduleNavigate(mon.personcompanyid,mon.gridfourth,mon.thursdayOfWeek)" />
      </td>
    </ng-template>
    <ng-template #textcontolfourth>
      <td [ngStyle]="{'backgroundColor': mon.colorfourth == 'yellow' ? '#ffe600d8' : mon.colorfourth == 'red' ? '#ec4141' : mon.colorfourth == 'green' ? '#84bd3a': mon.colorfourth == null ? 'white' :'white' }"style="text-align:center;width:8%;font-size: 0.8vw; border: 1px solid black;">{{mon.gridfourth}}</td>
    </ng-template>



<td style="text-align:center;font-size: 0.8vw;width: 3.95% !important;border: 1px solid  black"> {{mon.countfourth}}</td>

<ng-container
*ngIf="mon.colorfifth  == null; then textcontolfifth; else buttoncontrolfifth">
</ng-container>
<ng-template #buttoncontrolfifth>
<td style="text-align:center;font-size: 0.6vw;width:8% !important;border: 1px solid  black">
  <input type="button"  [ngStyle]="{'backgroundColor': mon.colorfifth == 'yellow' ? '#ffe600d8' : mon.colorfifth == 'red' ? '#ec4141' : mon.colorfifth == 'green' ? '#84bd3a': mon.colorfifth == null ? 'white' :'white' }" [hidden] ="mon.gridfifth === null" style="width:70px;font-size: 0.8vw" value={{mon.gridfifth}} style="width:70px;font-size: 0.8vw" (dblclick)="ScheduleNavigate(mon.personcompanyid,mon.gridfifth,mon.fridayOfWeek)" />
</td>
</ng-template>
<ng-template #textcontolfifth>
<td [ngStyle]="{'backgroundColor': mon.colorfifth == 'yellow' ? '#ffe600d8' : mon.colorfifth == 'red' ? '#ec4141' : mon.colorfifth == 'green' ? '#84bd3a': mon.colorfifth == null ? 'white' :'white' }"style="text-align:center;width:8%;font-size: 0.8vw; border: 1px solid black;">{{mon.gridfifth}}</td>
</ng-template>


<td style="text-align:center;font-size: 0.8vw;width:3.95% !important;border: 1px solid  black"> {{mon.countfifth}}</td>

<ng-container
*ngIf="mon.colorsix  == null; then textcontolsix; else buttoncontrolsix">
</ng-container>
<ng-template #buttoncontrolsix>
<td style="text-align:center;font-size: 0.6vw;width:8% !important;border: 1px solid  black">
  <input type="button"  [ngStyle]="{'backgroundColor': mon.colorsix == 'yellow' ? '#ffe600d8' : mon.colorsix == 'red' ? '#ec4141' : mon.colorsix == 'green' ? '#84bd3a': mon.colorsix == null ? 'white' :'white' }" [hidden] ="mon.gridsix === null" style="width:70px;font-size: 0.8vw" value={{mon.gridsix}} style="width:70px;font-size: 0.8vw" (dblclick)="ScheduleNavigate(mon.personcompanyid,mon.gridsix,mon.satdayOfWeek)" />
</td>
</ng-template>
<ng-template #textcontolsix>
<td [ngStyle]="{'backgroundColor': mon.colorsix == 'yellow' ? '#ffe600d8' : mon.colorsix == 'red' ? '#ec4141' : mon.colorsix == 'green' ? '#84bd3a': mon.colorsix == null ? 'white' :'white' }"style="text-align:center;width:8%;font-size: 0.8vw; border: 1px solid black;">{{mon.gridsix}}</td>
</ng-template>

      <td style="text-align:center;font-size: 0.8vw;width: 3.95% !important;border: 1px solid  black"> {{mon.countsix}}</td>

      <ng-container
*ngIf="mon.colorseven  == null; then textcontolseven; else buttoncontrolseven">
</ng-container>
<ng-template #buttoncontrolseven>
<td style="text-align:center;font-size: 0.6vw;width:8% !important;border: 1px solid  black">
  <input type="button"  [ngStyle]="{'backgroundColor': mon.colorseven == 'yellow' ? '#ffe600d8' : mon.colorseven == 'red' ? '#ec4141' : mon.colorseven == 'green' ? '#84bd3a': mon.colorseven == null ? 'white' :'white' }" [hidden] ="mon.gridseven === null" style="width:70px;font-size: 0.8vw" value={{mon.gridseven}} style="width:70px;font-size: 0.8vw" (dblclick)="ScheduleNavigate(mon.personcompanyid,mon.gridseven,mon.sundayOfWeek)" />
</td>
</ng-template>
<ng-template #textcontolseven>
<td [ngStyle]="{'backgroundColor': mon.colorseven == 'yellow' ? '#ffe600d8' : mon.colorseven == 'red' ? '#ec4141' : mon.colorseven == 'green' ? '#84bd3a': mon.colorseven == null ? 'white' :'white' }"style="text-align:center;width:8%;font-size: 0.8vw; border: 1px solid black;">{{mon.gridseven}}</td>
</ng-template>


      <td style="text-align:center;font-size: 0.8vw;width: 3.5% !important;border: 1px solid   black"> {{mon.countseven}}</td>





    </tr>

    </table>


  </mat-card>

</div>
</div>
