import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { Module } from 'src/app/models/module';
import Swal from 'sweetalert2';
import { take, publish } from 'rxjs/operators';
import { ConnectableObservable } from 'rxjs';
import { WaitDialogComponent } from '../../util/wait-dialog/wait-dialog.component';

@Component({
    selector: 'app-module-create',
    templateUrl: './module-create.component.html',
    styleUrls: ['./module-create.component.scss']
})
export class ModuleCreateComponent implements OnInit {


    moduleId: any;
    moduleName: any;
    moduleActive: boolean = true;
    ifModuleExists: boolean = true;
    IfMandatory: boolean = false;
    errorMessage: any;
    selectedModuleItem: any;
    moduleItems = [];
    ifSubMenu: boolean = false;
    parentMenuItems = [];
    selectedParentMenu: any;
    submenuPath: any;
    className:any;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog,
        private router: Router,
        private userService: UserService) {
            this.className=localStorage.getItem("themeName");
         }

    ngOnInit() {
        this.getModuleItems();
    }

    getModuleItems() {
        this.userService.getModuleItem().subscribe(res => {
            this.moduleItems = res;
        }, err => {
            this.moduleItems = [];
        })
    }
    clearMessage() {
        this.errorMessage = "";
    }

    moduleItemChange(data) {
        if (data.toLowerCase() == ("SubMenuItem").toLowerCase()) {
            this.ifSubMenu = true;
            this.showParentMenu();
            this.submenuPath = "/" + this.moduleName + "Admin";
        }
        else {
            this.ifSubMenu = false;
            this.submenuPath = null;
        }
    }

    showParentMenu() {
        this.userService.showParentMenu().subscribe(res => {
            this.parentMenuItems = res;
        }, err => {
            console.log(err)
        })
    }

    saveModuleIfTrue() {
        let self = this;
        let moduleObj = new Module();
        moduleObj.ModuleId = this.moduleId;
        moduleObj.ModuleName = this.moduleName;
        moduleObj.IsActive = this.moduleActive;
        moduleObj.ModuleItem = this.selectedModuleItem;
        moduleObj.CreatedBy = localStorage.getItem("loggedInUserName");
        moduleObj.ModifiedBy = localStorage.getItem("loggedInUserName");
        moduleObj.Path = this.submenuPath;
    
        if (this.selectedParentMenu != undefined && this.selectedParentMenu != null &&
            this.selectedParentMenu != "") {
            moduleObj.ModuleRef = this.selectedParentMenu;

        }
        else {
            moduleObj.ModuleRef = null;
        }

        var moduleExists = this.userService.moduleExists(moduleObj.ModuleId, moduleObj.ModuleName).pipe(take(1));
        var createModule = this.userService.createModule(moduleObj).pipe(take(1), publish()) as ConnectableObservable<any>;

        moduleExists.subscribe(res => {
            this.ifModuleExists = true;
        }, error => {
            this.ifModuleExists = false;
            this.errorMessage = "Module already exists";
        }, createModule.connect.bind(createModule));

        let className = localStorage.getItem("themeName");
        const dialogRef = this.dialog.open(WaitDialogComponent, {data: {caption: 'Saving...'},panelClass:className});
        createModule.subscribe(function (data) {
            dialogRef.close();
            if (data && self.ifModuleExists) {
                Swal.fire({
                    text: "Module Created Successfully",
                    icon: 'success',
                    confirmButtonText: 'OK',
                }).then(() => {
                    self.dialog.closeAll();
                })
            }
            else {
                Swal.fire({
                    text: "Module Create failed",
                    icon: 'error',
                    confirmButtonText: 'OK',
                }).then(() => {
                    self.dialog.closeAll();
                })
            }

        }, error => {
            Swal.fire({
                text: "Module Create failed. Server Error",
                icon: 'error',
                confirmButtonText: 'OK',
            }).then(() => {
                self.dialog.closeAll();
            })
        });

      
    }

    saveModule() {
        this.checkAllFieldsAreEmpty();
        if (!this.IfMandatory) {
            this.saveModuleIfTrue();
        }
        else {
            this.errorMessage = "All fields are mandatory";
        }


    }

    checkAllFieldsAreEmpty() {

        if ((this.moduleId == null || this.moduleId == undefined || this.moduleId == "")
            || (this.moduleName == null || this.moduleName == undefined || this.moduleName == "") ||
            (this.selectedModuleItem == null || this.selectedModuleItem == undefined)
            ) {
            this.IfMandatory = true;
        }
        else {
            this.IfMandatory = false;
        }
    }

    cancelModule() {
        this.dialog.closeAll();
        this.router.navigate(['moduleAdmin']);
    }

}
