<h5 mat-dialog-title>Add Grid</h5>
<mat-divider></mat-divider>
<div mat-dialog-content style="width: 100vh;">
  <mat-card class="user-card">
    <mat-card-content>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Grid</mat-label>
        <mat-select [(ngModel)]="gridType" [disabled]="resourcegrid_id !==null">
          <mat-option *ngFor="let rtype of indService.indDetail.GridsTab.GridTypeList" [value]="rtype" style="font-size: 11px;">
            {{rtype.DESCRIPTION}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="endDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <br />
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="saveGrid()" [disabled]="gridType === undefined">SAVE</button>
      <button mat-raised-button color="primary" [mat-dialog-close]>CANCEL</button>
    </mat-card-actions>
  </mat-card>
</div>
