import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/services/user.service';
import { Module } from 'src/app/models/module';
import { Role } from 'src/app/models/role';
import { Router } from '@angular/router';
import { WaitDialogComponent } from '../../util/wait-dialog/wait-dialog.component';

@Component({
    selector: 'app-module-detail',
    templateUrl: './module-detail.component.html',
    styleUrls: ['./module-detail.component.scss']
})
export class ModuleDetailComponent implements OnInit {

    moduleName: any;
    moduleData: any;
    selectedRole = [];
    moduleActive: boolean;
    selectedRoleId = [];
    roles: Role[] = [];
    IfMandatory: boolean = false;
    errorMessage: any;
    selectedModuleItem: any;
    moduleItems = [];
    ifSubMenu: boolean = false;
    parentMenuItems = [];
    selectedParentMenu: any;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router, private userService: UserService, private dialog: MatDialog) { }

    ngOnInit() {
        this.moduleData = this.data.dataKey;
        this.moduleName = this.moduleData.ModuleName;
        this.moduleActive = this.moduleData.IsActive;
        this.selectedModuleItem = this.moduleData.ModuleItemId;
        this.selectedParentMenu= this.moduleData.ModuleRefId;
        this.moduleItemChange(this.selectedModuleItem);
        this.getModuleItems();
        this.userService.getRoles().subscribe(res => {
            res.forEach(element => {
                if (element.RoleActive) {
                    this.roles.push(element);
                }
            });

            this.moduleData.RoleModule.forEach(element => {
                let selectedRoleId = this.roles.find(c => c.RoleId == element.RoleId);

                this.selectedRole.push(selectedRoleId.RoleId);
            });
        });




    }

    getModuleItems() {
        this.userService.getModuleItem().subscribe(res => {
            this.moduleItems = res;
        }, err => {
            this.moduleItems = [];
        })
    }

    moduleItemChange(data) {
        if (data.toLowerCase() == ("SubMenuItem").toLowerCase()) {
            this.ifSubMenu = true;
            this.showParentMenu();        
        }
        else {
            this.selectedParentMenu=null;
            this.ifSubMenu = false;
        }
    }

    showParentMenu() {
        this.userService.showParentMenu().subscribe(res => {
            this.parentMenuItems = res;
        }, err => {
            console.log(err)
        })
    }

    checkAllFieldsAreEmpty() {
        if ((this.selectedRole.length <= 0)
            || (this.moduleName == null || this.moduleName == undefined)) {
            this.IfMandatory = true;
        }
        else {
            this.IfMandatory = false;
        }
    }

    saveModule() {
        Swal.fire({
            title: 'Save Changes?',
            text: "Changing this update your configuration.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Save Changes'
        }).then((result) => {
            if (result.value) {
                this.doSaveModule();
            }
        });
    }

    private doSaveModule() {
        this.checkAllFieldsAreEmpty();
        if (!this.IfMandatory) {
            let className = localStorage.getItem("themeName");
            const dialogRef = this.dialog.open(WaitDialogComponent, {data: {caption: 'Saving...'},panelClass:className});
            let moduleObj = new Module();
            moduleObj.ModuleId = this.moduleData.ModuleId;
            moduleObj.ModuleName = this.moduleName;
            moduleObj.IsActive = this.moduleActive;
            moduleObj.Rolemodules = this.selectedRole;
            moduleObj.ModuleRef = this.selectedParentMenu;
            moduleObj.ModuleItem = this.selectedModuleItem;
            moduleObj.ModifiedBy = localStorage.getItem("loggedInUserName");
            this.userService.updateModules(moduleObj).subscribe(res => {
                dialogRef.close();
                if (res == true) {
                    Swal.fire({
                        text: "Updated Successfully",
                        icon: 'success',
                        confirmButtonText: 'OK',
                    }).then(() => {
                        this.dialog.closeAll();
                    })
                }
                else {
                    Swal.fire({
                        text: "Update failed",
                        icon: 'error',
                        confirmButtonText: 'OK',
                    }).then(() => {
                        this.dialog.closeAll();
                    })
                }
            }, err => {
                Swal.fire({
                    text: "Update failed",
                    icon: 'error',
                    confirmButtonText: 'OK',
                }).then(() => {
                    this.dialog.closeAll();
                })
            })

        } else {
            this.errorMessage = "All fields are mandatory"
        }
    }

    cancelModule() {
        this.dialog.closeAll();
        this.router.navigate(['moduleAdmin']);
    }
}
