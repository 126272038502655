import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IndividualService } from '../../../services/individual.service';
import * as moment from 'moment';

@Component({
    selector: 'app-daily-hours',
    templateUrl: './daily-hours.component.html',
    styleUrls: ['./daily-hours.component.scss']
})
export class DailyHoursComponent implements OnInit {

    dayType;
    startDate;
    endDate;

    constructor(
        public dialogRef: MatDialogRef<DailyHoursComponent>,
        public indService: IndividualService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        if (this.data.editMode) {
            this.startDate = this.indService.indDetail.DailyHoursTab.DailyHours[this.data.index].STARTDATE;
            this.endDate = this.indService.indDetail.DailyHoursTab.DailyHours[this.data.index].ENDDATE;

            for (var i = 0; i < this.indService.indDetail.DailyHoursTab.WEEKDAYSLIST.length; i++) {
                if (this.indService.indDetail.DailyHoursTab.WEEKDAYSLIST[i].CODE
                    === this.indService.indDetail.DailyHoursTab.DailyHours[this.data.index].SELECTEDDAYOFWEEK) {
                    this.dayType = this.indService.indDetail.DailyHoursTab.WEEKDAYSLIST[i];
                    break;
                }
            }
        }
        else
        {
            var today = new Date();
            var year = today.getFullYear();
            var month = today.getMonth();
            var day = today.getDate();
            var startHour = 8;
            var endHour = 17;

            this.startDate= new Date(year, month, day,startHour);
            this.endDate = new Date(year + 5, month, day,endHour);
        }
    }

    saveDailyHours() {
        if (this.data.editMode) {
            this.indService.indDetail.DailyHoursTab.DailyHours[this.data.index].STARTTIME = moment(this.startDate).format('MM/DD/YYYY HH:mm:ss');
            this.indService.indDetail.DailyHoursTab.DailyHours[this.data.index].ENDTIME = moment(this.endDate).format('MM/DD/YYYY HH:mm:ss');
            this.indService.indDetail.DailyHoursTab.DailyHours[this.data.index].STARTDATE = moment(this.startDate).format('MM/DD/YYYY HH:mm:ss');
            this.indService.indDetail.DailyHoursTab.DailyHours[this.data.index].ENDDATE = moment(this.endDate).format('MM/DD/YYYY HH:mm:ss');

            this.indService.indDetail.DailyHoursTab.DailyHours[this.data.index].DAYOFTHEWEEK = this.dayType.CODE;
            this.indService.indDetail.DailyHoursTab.DailyHours[this.data.index].SELECTEDDAYOFWEEKDESC = this.dayType.DESCRIPTION;
            this.indService.indDetail.DailyHoursTab.DailyHours[this.data.index].SELECTEDDAYOFWEEK = this.dayType.CODE;
        } else {
            const hours = {
                DAILYHOURS_ID: null,
                DAYOFTHEWEEK: this.dayType.CODE,
                STARTTIME: moment(this.startDate).format('MM/DD/YYYY HH:mm:ss'),
                ENDTIME: moment(this.endDate).format('MM/DD/YYYY HH:mm:ss'),
                STARTDATE: moment(this.startDate).format('MM/DD/YYYY HH:mm:ss'),
                ENDDATE: moment(this.endDate).format('MM/DD/YYYY HH:mm:ss'),
                PERSONCOMPANY_ID: this.indService.personcompanyId,
                SELECTEDDAYOFWEEKDESC: this.dayType.DESCRIPTION,
                SELECTEDDAYOFWEEK: this.dayType.CODE
            };
    
            this.indService.indDetail.DailyHoursTab.DailyHours.push(hours);
        }

        this.dialogRef.close('1');
    }

}
