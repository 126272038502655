<mat-spinner [diameter]="50" color="primary" style="margin-left: 100px;" *ngIf="searching"></mat-spinner>
<div [hidden]="searching">
  <mat-card class="enroll-card">
    <mat-card-header>
      <mat-card-title style="font-size: 16px">
        <span matBadge="{{ questionList.length }}" matBadgeOverlap="false">Energy Advisor Questions</span>
      </mat-card-title>
      <mat-card-subtitle>
        <p>{{ customerService.currentCustomer.Name | titlecase }}</p>
        <p>{{ customerService.currentCustomer.ServiceStreetAddress1 | titlecase}},
          {{ customerService.currentCustomer.ServiceCity | titlecase}},
          {{ customerService.currentCustomer.ServiceState | uppercase}}
          {{ customerService.currentCustomer.ServiceZipcode}}</p>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>
      <br>
      <h2 *ngIf="questionList.length < 1">No Questions Found</h2>
      <table mat-table [dataSource]="questionList" class="mat-elevation-z8">
        <ng-container matColumnDef="required">
          <th mat-header-cell *matHeaderCellDef style="text-align: left;">Required</th>
          <td mat-cell *matCellDef="let ques" style="text-align: center;" matTooltip="Priority High">
            <mat-icon color="warn" *ngIf="ques.QUESTION_REQUIRED">priority_high</mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="question">
          <th mat-header-cell *matHeaderCellDef style="width:49% !important;">Question</th>
          <td mat-cell *matCellDef="let ques"> {{ ques.QUESTION_TEXT }} </td>
        </ng-container>

        <ng-container matColumnDef="custanswer">
          <th mat-header-cell *matHeaderCellDef style="width: 49%;">Customer Response</th>
          <td mat-cell *matCellDef="let ques;let indx = index">

            <span *ngIf="ques.QUESTION_TYPE === 'TEXT'">
                <mat-form-field style="width: 80%;">
                  <input matInput [(ngModel)]="ques.currentValue">
                </mat-form-field>
            </span>

            <span *ngIf="ques.QUESTION_TYPE === 'LABEL'">
              {{ ques.currentValue }}
            </span>

            <span *ngIf="ques.QUESTION_TYPE === 'NUMBER' || ques.QUESTION_TYPE === 'VALUE'">
                <mat-form-field style="width: 30%;">
                  <input matInput type="number" style="text-align: right;" [(ngModel)]="ques.currentValue">
                </mat-form-field>
            </span>

            <span *ngIf="ques.QUESTION_TYPE === 'LIST'">
              <mat-form-field style="width: 60%;">
                <mat-select [(value)]="ques.currentValue">
                  <mat-option *ngFor="let selitem of ques.QUESTION_RESPONSES" value="{{selitem.RESPONSE_ID}}" style="font-size: 11px;">
                    {{ selitem.RESPONSE_TEXT }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="getRecommendations()">SAVE AND VIEW RECOMMENDATIONS</button>
      <button mat-raised-button color="primary" routerLink="/detail">CANCEL</button>
    </mat-card-actions>
  </mat-card>
</div>

<div style="margin-bottom: 75px;"></div>
