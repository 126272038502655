import { Injectable } from '@angular/core';
import { OrganizationSearch } from '../models/organizationsearch'
import { OrganizationDetail, OrganizationTab } from '../models/organizationdetail'
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilityService } from './utility.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrganizationMaintainenceService {

  private organizationMaintenanceUrl = environment.apiUrl + 'organizationmaintenance/';

  constructor(
    private http: HttpClient,
    private utilityService: UtilityService
  ) { }
  public getHeaders() {
    const email = localStorage.getItem('loggedInUserEmail');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-email': email,
        'x-programsponsorid': this.utilityService.currentUtility.ProgramsponsorId
      })
    };
    return httpOptions;
  }

  addNewOrganization(): Observable<OrganizationTab> {
    const bbcsUserId = this.utilityService.currentUtility.BBCSUserId;

    const orgAddUrl = this.organizationMaintenanceUrl + this.utilityService.currentUtility.OfficeId
      + '/organization/new/userorganization/ALL/bbcsuser/' + bbcsUserId + '?code=' + environment.apiCode;

    return this.http.get<OrganizationTab>(orgAddUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }


  saveOrganization(orgType, selOrg, data) {
    const bbcsUserId = this.utilityService.currentUtility.BBCSUserId;
    const selProgId = selOrg;
    const orgSaveUrl = this.organizationMaintenanceUrl + this.utilityService.currentUtility.OfficeId
      + '/organization/save/' + selProgId + '/userorganization/ALL/bbcsuser/' + bbcsUserId + '?code=' + environment.apiCode + '&newOrg=' + orgType;

    return this.http.post<any[]>(orgSaveUrl, data, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  saveContractor(status, selOrg, data) {
    const bbcsUserId = this.utilityService.currentUtility.BBCSUserId;
    const selProgId = selOrg;
    const orgContUrl = this.organizationMaintenanceUrl + this.utilityService.currentUtility.OfficeId
      + '/organization/' + selProgId + '/contractors/update/userorganization/ALL/bbcsuser/' + bbcsUserId + '?code=' + environment.apiCode + '&disable='+ status;

    return this.http.post<any[]>(orgContUrl, data, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  saveZip(selOrg, data) {
    const bbcsUserId = this.utilityService.currentUtility.BBCSUserId;
    const selProgId = selOrg;
    const orgZipUrl = this.organizationMaintenanceUrl + this.utilityService.currentUtility.OfficeId
      + '/organization/addzipcodes/' + selProgId + '/userorganization/ALL/bbcsuser/' + bbcsUserId + '?code=' + environment.apiCode;

    return this.http.post<any[]>(orgZipUrl, data, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  updateDoc(selOrg, data) {
    const bbcsUserId = this.utilityService.currentUtility.BBCSUserId;
    const selProgId = selOrg;
    const orgdocUrl = this.organizationMaintenanceUrl + this.utilityService.currentUtility.OfficeId
      + '/organization/' + selProgId + '/document/save/userorganization/ALL/bbcsuser/' + bbcsUserId + '?code=' + environment.apiCode;

    return this.http.post<any[]>(orgdocUrl, data, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  viewAttachDoc(selOrg, data) {
    //const bbcsUserId = this.utilityService.currentUtility.BBCSUserId;
    const selProgId = selOrg;
    const orgdocUrl = this.organizationMaintenanceUrl + this.utilityService.currentUtility.OfficeId
      + '/organization/' + selProgId + '/userorganization/ALL/document/' + data + '?code=' + environment.apiCode;

    return this.http.get<any[]>(orgdocUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  deleteZip(selOrg, data) {
    //const bbcsUserId = this.utilityService.currentUtility.BBCSUserId;
    const selProgId = selOrg;
    const orgZipUrl = this.organizationMaintenanceUrl + this.utilityService.currentUtility.OfficeId
      + '/organization/deletezipcode/' + selProgId + '/userorganization/ALL/zip/' + data + '?code=' + environment.apiCode;

    return this.http.post<any[]>(orgZipUrl, data, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  deleteDoc(selOrg, data) {
    const bbcsUserId = this.utilityService.currentUtility.BBCSUserId;
    const selProgId = selOrg;
    const orgZipUrl = this.organizationMaintenanceUrl + this.utilityService.currentUtility.OfficeId
      + '/organization/' + selProgId + '/document/delete/userorganization/ALL/bbcsuser/' + bbcsUserId + '/id/' + data + '?code=' + environment.apiCode;

    return this.http.post<any[]>(orgZipUrl, data, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  getOrganizationSearch(params): Observable<OrganizationSearch[]> {

    const orgSearchUrl = this.organizationMaintenanceUrl + this.utilityService.currentUtility.OfficeId
      + '/organizations/search/userorganization/ALL' + '?code=' + environment.apiCode + params;

    return this.http.get<OrganizationSearch[]>(orgSearchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  // https://onering-api.azurewebsites.net/api/organizationmaintenance/APC1/
  // organization/detail/APC1000001/userorganization/ALL?code=ZHlne4CWtAzZKColEDYImUFvNHkdqekpkuLVMJG20LS7WprTI3xIrA==

  getSelectedOrganizationDetails(params): Observable<OrganizationDetail> {
    //console.log('this.utilityService.currentUtility', this.utilityService.currentUtility)
    const orgDetailedUrl = this.organizationMaintenanceUrl + this.utilityService.currentUtility.OfficeId
      + '/organization/detail/' + params + '/userorganization/ALL' + '?code=' + environment.apiCode;

    return this.http.get<OrganizationDetail>(orgDetailedUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  deleteAccreditation(params, data) {
    const orgDetailedUrl = this.organizationMaintenanceUrl + this.utilityService.currentUtility.OfficeId
      + '/organization/deleteaccreditation/' + params +  '?code=' + environment.apiCode;

      return this.http.post<any[]>(orgDetailedUrl, data, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );   
  }

  addAccreditation(params,data){
    const orgDetailedUrl = this.organizationMaintenanceUrl + this.utilityService.currentUtility.OfficeId
    + '/organization/addaccreditation/' + params + '/userorganization/ALL' + '?code=' + environment.apiCode;

    return this.http.post<any[]>(orgDetailedUrl, data, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );    
  }

  editAccreditation(params,data){
    const orgDetailedUrl = this.organizationMaintenanceUrl + this.utilityService.currentUtility.OfficeId
    + '/organization/updateaccreditation/' + params + '?code=' + environment.apiCode;

   
    return this.http.post<any[]>(orgDetailedUrl, data, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      ); 
  }


}
