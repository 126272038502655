import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError, Observable, Subject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';
import { Utility } from '../models/utility';
import { ProgramSponsor } from '../models/programsponsor';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    currentUtility: Utility;
    currentProgramSponsor: ProgramSponsor;
    firstDefaultSet = false;
    errorSub: Subject<string>;
    programSponsorId: any;
    
    private utilityWsUrl;
    private userUtilityUrl;
    private userSettingsUrl;
    private userSettingsUrl1;
    utilityMethod$: Observable<any>;
    private utilitySubject = new Subject<any>();

    logoutMethod$: Observable<any>;
    private logoutSubject = new Subject<any>();

    constructor(private http: HttpClient,private spinner:NgxSpinnerService) {
        this.utilityWsUrl = environment.apiUrl + 'provider';
        this.userUtilityUrl = environment.apiUrl + 'programsponsorlist';
        this.userSettingsUrl = environment.apiUrl;
        this.errorSub = new Subject();
        this.utilityMethod$ = this.utilitySubject.asObservable();
        this.logoutMethod$ = this.logoutSubject.asObservable();
    }

    public getHeaders() {
        const email = localStorage.getItem('loggedInUserEmail');

        if (this.currentUtility != null || this.currentUtility !== undefined) {
            this.programSponsorId = this.currentUtility.ProgramsponsorId;
        }
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-email': email,
            })
        };

        return httpOptions;
    }

    public getHeaderswithProgramSponsor(officeId) {
        const email = localStorage.getItem('loggedInUserEmail');
        if (this.currentUtility != null || this.currentUtility !== undefined) {
            this.programSponsorId = this.currentUtility.ProgramsponsorId;
        }
        console.log("Programsponsor",this.programSponsorId)
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-email': email,
                'x-programsponsorid': officeId,
            })
        };
        return httpOptions;
    }

    enrollCustomer(officeId,bodyContent): Observable<any[]> {
  
      
        const searchUrl = environment.apiUrl  + "customercreate/" + officeId + "/" 
          +  '?code=' + environment.apiCode; 
      
          const body={
      
           Firstname: bodyContent.Firstname,
           LastName: bodyContent.LastName,
           CompanyName:bodyContent.CompanyName,
           AccountNumber: bodyContent.AccountNumber,
           ServiceStreetnumber:bodyContent.ServiceStreetnumber,
           ServiceStreetName:bodyContent.ServiceStreetName,
           ServiceAppartment:bodyContent.ServiceAppartment,
           ServiceCity:bodyContent.ServiceCity,
           ServiceState:bodyContent.ServiceState,
           ServiceZipCode:bodyContent.ServiceZipCode,
           MailStreetNumber:bodyContent.MailStreetNumber,
           MailStreetName:bodyContent.MailStreetName,
           MailAppartment:bodyContent.MailAppartment,
           MailCity:bodyContent.MailCity,
           MailState:bodyContent.MailState,
           MailZip:bodyContent.MailZip,
           PrimaryPhone:bodyContent.PrimaryPhone,
           AlternatePhone:bodyContent.AlternatePhone,
           Email:bodyContent.Email,
           CustomerType:bodyContent.CustomerType,
          }
          
        return this.http.post<any[]>(searchUrl, body, this.getHeaderswithProgramSponsor(officeId) )
          .pipe(map(response => response),
            catchError(err => this.handleError(err, this, []))
          );
      
       }

    getUserUtilities(userEmail): Observable<Utility[]> {
        const httpOptions = this.getHeaders();
        this.userUtilityUrl = this.userUtilityUrl + '/' + userEmail + '?code=' + environment.apiCode;
        return this.http.get<Utility[]>(this.userUtilityUrl, httpOptions)
            .pipe(map(response => response),
                catchError(err => this.handleError(err, this, [])));
    }

    getSelfScheduleSettings(officeId): Observable<Utility[]> {
        const httpOptions = this.getHeaderswithProgramSponsor(officeId);
        this.userSettingsUrl1 = this.userSettingsUrl + 'selfschedule/utilitySettings/' + officeId + '?code=' + environment.apiCode;
        return this.http.get<Utility[]>(this.userSettingsUrl1, httpOptions)
            .pipe(map(response => response),
                catchError(err => this.handleError(err, this, [])));
    }

    getAllUtilities(): Observable<Utility[]> {
        const httpOptions = this.getHeaders();
        this.utilityWsUrl = this.utilityWsUrl + '?code=' + environment.apiCode;
        return this.http.get<Utility[]>(this.utilityWsUrl, httpOptions)
            .pipe(map(response => response),
                catchError(err => this.handleError(err, this, [])));
    }

    setCurrentUtility(util) {
        this.currentUtility = util;
        this.setCurrentProgramSponsor().subscribe(ps => this.currentProgramSponsor = ps);
    }

    setCurrentProgramSponsor(): Observable<any> {
        const httpOptions = this.getHeaders();
        const psUrl = environment.apiUrl + 'onering/admin/ProgramSponsor/'
            + this.currentUtility.ProgramsponsorId + '?code=' + environment.apiCode;
        return this.http.get<any>(psUrl, httpOptions)
            .pipe(catchError(err => this.handleError(err, this, [])));
    }

    dataChange() {
        this.utilitySubject.next();
    }

    logOutOption() {
        this.logoutSubject.next();
    }

    public handleError(error: HttpErrorResponse, x, returnVal): Observable<any> {
        console.log("test");
        this.spinner.hide();
        let isWarning = false;

        let mesg = 'An error has occurred. Please try again.';
        let showErrorMesg = true;
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            mesg = 'An error occurred:' + error.error.message;
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            // mesg = 'Backend returned code ' + error.status + ', body was: ' + error.error;
            mesg = 'Incorrect response!';
            console.error('Backend returned code ' + error.status + ', body was: ' + error.error);

            if (error.status === 401) {
                mesg = 'You do not have permission to perform this function.';
            }

            if (error.status === 400) {    //  && error.error === 404) { various error codes are returned for not found 200, 500, 404
                // search not found
                showErrorMesg = false;
                mesg = 'Server error!';
            }

            if (error.status === 404) {    //  && error.error === 404) { various error codes are returned for not found 200, 500, 404
                // search not found
                showErrorMesg = true;
                isWarning = true;
                mesg = 'No results found.';
            }
        }

        if (showErrorMesg) {
            if (isWarning) {
                Swal.fire({
                    title: 'No Results',
                    text: mesg,
                    icon: 'info',
                    confirmButtonText: 'OK',
                });
            } else {
                Swal.fire({
                    title: 'Error',
                    text: mesg,
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
            }

        }
        return throwError(mesg);
    }
}
