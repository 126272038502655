<h5 mat-dialog-title  style="padding:0.2vw; color:black"> Program Sponsor Detail : {{pSponsorData.ProgramSponsorId}}</h5>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <mat-card class="user-card">
    <mat-card-content>
      <br>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>ProgramSponsor Description</mat-label>
        <input matInput placeholder="Description" [(ngModel)]="pSponsorDescription" (focusin)="clearMessage()"
          maxlength="50">
      </mat-form-field>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Compass URI</mat-label>
        <input matInput type="url" placeholder="Uri" [(ngModel)]="compassUri" (focusin)="clearMessage()" maxlength="50">
      </mat-form-field>
      <br>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Compass Email</mat-label>
        <input matInput type="email" placeholder="Email" [(ngModel)]="compassEmail" (focusin)="clearMessage()"
          maxlength="50">
      </mat-form-field>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Compass Password</mat-label>
        <input matInput type="password" placeholder="Password" [(ngModel)]="compassPassword" (focusin)="clearMessage()"
          maxlength="50">
      </mat-form-field>

      <br>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>SalesForce User Name</mat-label>
        <input matInput placeholder="SF Username" [(ngModel)]="salesforceUsername" (focusin)="clearMessage()"
          maxlength="255">
      </mat-form-field>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>SalesForce Password</mat-label>
        <input matInput type="password" placeholder="SF Password" [(ngModel)]="salesforcePassword"
          (focusin)="clearMessage()" maxlength="255">
      </mat-form-field>
      <br>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Source</mat-label>
        <mat-select [(ngModel)]="selectedSource">
          <mat-option *ngFor="let source of sources" [value]="source.SourceId" style="font-size: 11px;">
            {{source.SourceName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-slide-toggle class="formfield-half" [(ngModel)]="pSponsorActive">Active</mat-slide-toggle>
      <br>
      <label class="formfield-half" style="color: red; font-size: 0.9vw">{{errorMessage}}</label>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="savePSponsor();">SAVE</button>
      <button mat-raised-button color="primary" (click)="cancelPSponsor();">CANCEL</button>
    </mat-card-actions>
  </mat-card>
</div>