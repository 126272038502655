import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
// import { UtilityService } from 'src/app/services/utility.service';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit, OnDestroy {

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        private adalSvc: MsAdalAngular6Service
    ) { }

    timeOut:any;
    ngOnInit() {
        let self = this;
     this.timeOut =  setTimeout(()=>{
        self.adalSvc.logout();      
       },environment.idleWarningTime)

       

    }

    handleContinue() {
        this.dialogRef.close('1');

    }

    forceLogout() {
        let self = this;
        this.adalSvc.logout();
    }

    ngOnDestroy(){
        clearTimeout(this.timeOut);
    }
}
