<h5 mat-dialog-title style="padding:0.2vw; color:black"> Report Config Edit </h5>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <mat-card class="user-card">
    <mat-card-content>
      <br>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Report Name</mat-label>
        <input matInput placeholder="Name" [(ngModel)]="reportName">
      </mat-form-field>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Report Description</mat-label>
        <input matInput placeholder="Description" [(ngModel)]="reportDescription">
      </mat-form-field>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Path</mat-label>
        <input matInput type="url" placeholder="Path" [(ngModel)]="path">
      </mat-form-field>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>External Path</mat-label>
        <input matInput type="url" placeholder="Path" [(ngModel)]="externalPath">
      </mat-form-field>
      <mat-form-field appearance="outline" style="padding-right: 2.2vw;width:17.6vw;font-size: 1vw;">
        <mat-label>Select Menu Item</mat-label>
        <mat-select [(ngModel)]="selectedMenu" style="font-size: 1vw;">
          <mat-option *ngFor="let menu of menuList" [value]="menu" style="font-size: 11px;">{{menu.MenuName}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br>
      <mat-slide-toggle class="formfield-half" [(ngModel)]="dateControl">Date Control</mat-slide-toggle>

      <mat-slide-toggle class="formfield-half" [(ngModel)]="active">Active</mat-slide-toggle>

    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="saveEditedInfo()">SAVE</button>
      <button mat-raised-button color="primary" (click)="close()">CANCEL</button>
    </mat-card-actions>
  </mat-card>
</div>