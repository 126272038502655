export class Interaction {
    TypeCode: string;
    WhyCode:string;
    User: string;
    ReasonCode: string;
    IsComplete: boolean;
    MarketingCode: string;
    Notes: string;
    TypeDescription:string;
    ReasonDescription:string;
    WhyDescription:string;
    MarketingDescription:string;
    CustomerId: string;
    ProgramSponsorId: string;
    StartDate: any;
    EndDate: any;
    DoneDate: any;
    WorkorderId:string;
    EnrollmentId:string;
  
      public setFromWeb(data) {
     
          this.CustomerId = data.CustomerId;
          this.ProgramSponsorId = data.ProgramSponsorId;
          this.TypeDescription = data.TypeDescription;
          this.User = data.User;
          this.ReasonDescription = data.ReasonDescription;
          this.WhyDescription = data.WhyDescription;
          this.MarketingDescription = data.MarketingDescription;
          this.Notes = data.Notes;
          this.WorkorderId = data.WorkorderId;
          this.EnrollmentId = data.EnrollmentId;
  
          if (data.StartDate === undefined) {
              this.StartDate = undefined;
          } else {
              this.StartDate = new Date(data.StartDate);
          }
  
          if (data.EndDate === undefined) {
              this.EndDate = undefined;
          } else {
              this.EndDate = new Date(data.EndDate);
          }
  
          if (data.DoneDate === undefined) {
              this.DoneDate = undefined;
              this.IsComplete = false;
          } else {
              this.DoneDate = new Date(data.DoneDate);
              this.IsComplete = true;
          }
      }
  }
  