import { parsePhoneNumberFromString } from 'libphonenumber-js';

export class Customer {

    AccountNumber: string;
    Provider: string;
    Name: string;
    FirstName: string;
    LastName: string;
    MiddleName:string;
    PrimaryPhone: string;
    AlternatePhone: string;
    MailingStreetAddress1: string;
    MailingStreetAddress2: string;
    MailingCity: string;
    MailingState: string;
    MailingZipcode: string;
    MailingCounty: string;
    MailingGrid: string;
    MailingBlock: string;
    ServiceStreetAddress1: string;
    ServiceStreetAddress2: string;
    ServiceCity: string;
    ServiceState: string;
    ServiceZipcode: string;
    ServiceCounty: string;
    ServiceGrid: string;
    ServiceBlock: string;
    Email: string;
    StartDate: Date;
    EndDate: Date;
    RateCode: string;
    ExternalId: string;
    PremiseId: string;
    DoNotMail = false;
    DoNotCall = false;
    DoNotEmail = false;
    FromMaster: boolean;
    AccountSource: string;
    ProviderName: any;

    // new fields
    ProgramSponsorId: string;
    attributes: string;
    Id: string;
    SalesforceId: string;
    PersonCompanyId: string;
    OfficeId: string;
    IsCompany: boolean;
    FromCustomer: boolean;
    AccountId: string;
    OwnerId: string;
    CreatedDate: string;
    CreatedById: string;
    LastModifiedDate: string;
    LastModifiedById: string;
    LastActivityDate: string;
    IsDeleted: boolean;
    MailingStreetName:string;
    MailingStreetNumber: string;
    MailingAddressId:string;
    ServiceAddressId:string;
ServiceStreetName: string;
ServiceStreetNumber: string;
mailingStreetType:string;
serviceStreetType:string;
    formatPhone(phoneValue: string): string {
        if (phoneValue === undefined) {
            return ' ';
        }

        try {
            const phoneNumber = parsePhoneNumberFromString(phoneValue, 'US');
            return phoneNumber.formatNational();
        } catch (error) {
            return phoneValue;
        }
    }

    public setUpFromWS(inbound) {
        
        this.AccountNumber = inbound.AccountNumber === null ? "" : inbound.AccountNumber;
        this.Provider = inbound.Provider === null ? "" : inbound.Provider;
        this.Name = inbound.Name === null ? "" : inbound.Name;
        this.MiddleName=inbound.MiddleName === null ? "" : inbound.MiddleName;
        this.FirstName = inbound.FirstName === null ? "" : inbound.FirstName;  // not returned
        this.LastName = inbound.LastName === null ? "" : inbound.LastName;    // not returned
        this.PrimaryPhone = inbound.PrimaryPhone === null ? "" : this.formatPhone(inbound.PrimaryPhone);
        this.AlternatePhone = inbound.AlternatePhone === null ? "" : this.formatPhone(inbound.AlternatePhone);
        this.MailingStreetAddress1 = inbound.MailingStreetAddress1 === null ? "" : inbound.MailingStreetAddress1;
        this.MailingStreetAddress2 = inbound.MailingStreetAddress2 === null ? "" : inbound.MailingStreetAddress2;
        this.MailingCity = inbound.MailingCity === null ? "" : inbound.MailingCity;
        this.MailingState = inbound.MailingState === null ? "" : inbound.MailingState;
        this.MailingZipcode = inbound.MailingZipcode === null ? "" : inbound.MailingZipcode;
        this.MailingCounty = inbound.MailingCounty === null ? "" : inbound.MailingCounty;
        this.MailingGrid = inbound.MailingGrid === null ? "" : inbound.MailingGrid;
        this.MailingBlock = inbound.MailingBlock === null ? "" : inbound.MailingBlock;
        this.ServiceStreetAddress1 = inbound.ServiceStreetAddress1 === null ? "" : inbound.ServiceStreetAddress1;
        this.ServiceStreetAddress2 = inbound.ServiceStreetAddress2 === null ? "" : inbound.ServiceStreetAddress2;
        this.ServiceCity = inbound.ServiceCity === null ? "" : inbound.ServiceCity;
        this.ServiceState = inbound.ServiceState === null ? "" : inbound.ServiceState;
        this.ServiceZipcode = inbound.ServiceZipcode === null ? "" : inbound.ServiceZipcode;
        this.ServiceCounty = inbound.ServiceCounty === null ? "" : inbound.ServiceCounty;
        this.ServiceGrid = inbound.ServiceGrid === null ? "" : inbound.ServiceGrid;
        this.ServiceBlock = inbound.ServiceBlock === null ? "" : inbound.ServiceBlock;
        this.Email = inbound.Email === null ? "" : inbound.Email;
        this.ProviderName = inbound.ProviderName;
        this.MailingStreetName=inbound.MailingStreetName=== null ? "" : inbound.MailingStreetName;
        this.MailingStreetNumber=inbound.MailingStreetNumber=== null ? "" : inbound.MailingStreetNumber;
        this.ServiceStreetName=inbound.ServiceStreetName=== null ? "" : inbound.ServiceStreetName;
        this.ServiceStreetNumber=inbound.ServiceStreetNumber=== null ? "" : inbound.ServiceStreetNumber;
        this.ServiceAddressId=inbound.ServiceAddressId;
         this.MailingAddressId=inbound.MailingAddressId;
         this.serviceStreetType=inbound.serviceStreetType=== null ? "" : inbound.serviceStreetType;
         this.mailingStreetType=inbound.mailingStreetType=== null ? "" : inbound.mailingStreetType;
        if (inbound.CreatedDate == undefined || inbound.CreatedDate == null || inbound.CreatedDate == "") {
            this.StartDate = undefined;
        } else {
            this.StartDate = new Date(inbound.CreatedDate);
        }

        if (inbound.EndDate === undefined || inbound.EndDate === null) {
            this.EndDate = undefined;
        } else {
            this.EndDate = new Date(inbound.EndDate);
        }

        this.RateCode = inbound.RateCode === null ? "" : inbound.RateCode;

        if (inbound.ProviderName == null && this.RateCode != null) {
            let character = this.RateCode.substring(0, 2);
            switch (character) {
                case "PE":
                    this.ProviderName = "Potomac Energy";
                    break;

                case "PN":
                    this.ProviderName = "Penelec";
                    break;

                case "ME":
                    this.ProviderName = "Met-Ed";
                    break;

                case "PP":
                    this.ProviderName = "Penn Power";
                    break;

                case "WP":
                    this.ProviderName = "West Penn Power";
                    break;

            }
        }
        this.ExternalId = inbound.ExternalId === null ? "" : inbound.ExternalId;
        this.PremiseId = inbound.PremiseId === null ? "" : inbound.PremiseId;
        this.DoNotMail = inbound.DoNotMail === null ? "" : inbound.DoNotMail;
        this.DoNotCall = inbound.DoNotCall === null ? "" : inbound.DoNotCall;
        this.DoNotEmail = inbound.DoNotEmail === null ? "" : inbound.DoNotEmail;
        this.FromMaster = inbound.FromMaster === null ? "" : inbound.FromMaster;
        this.AccountSource = inbound.AccountSource === null ? "" : inbound.AccountSource;

        if (this.MailingGrid === undefined || this.MailingGrid === null) {
            this.MailingGrid = '';
        }
        if (this.MailingBlock === undefined || this.MailingBlock === null) {
            this.MailingBlock = '';
        }
        if (this.ServiceGrid === undefined || this.ServiceGrid === null) {
            this.ServiceGrid = '';
        }
        if (this.ServiceBlock === undefined || this.ServiceBlock === null) {
            this.ServiceBlock = '';
        }

        // new fields
        this.ProgramSponsorId = inbound.ProgramSponsorId === null ? "" : inbound.ProgramSponsorId;
        this.attributes = inbound.attributes === null ? "" : inbound.attributes;
        this.Id = inbound.Id === null ? "" : inbound.Id;
        this.SalesforceId = inbound.SalesforceId === null ? "" : inbound.SalesforceId;
        this.PersonCompanyId = inbound.PersonCompanyId === null ? "" : inbound.PersonCompanyId;
        this.OfficeId = inbound.OfficeId === null ? "" : inbound.OfficeId;
        this.IsCompany = inbound.IsCompany === null ? "" : inbound.IsCompany;
        this.FromCustomer = inbound.FromCustomer === null ? "" : inbound.FromCustomer;
        this.AccountId = inbound.AccountId === null ? "" : inbound.AccountId;
        this.OwnerId = inbound.OwnerId === null ? "" : inbound.OwnerId;
        this.CreatedDate = inbound.CreatedDate === null ? "" : inbound.CreatedDate;
        this.CreatedById = inbound.CreatedById === null ? "" : inbound.CreatedById;
        this.LastModifiedDate = inbound.LastModifiedDate === null ? "" : inbound.LastModifiedDate;
        this.LastModifiedById = inbound.LastModifiedById === null ? "" : inbound.LastModifiedById;
        this.LastActivityDate = inbound.LastActivityDate === null ? "" : inbound.LastActivityDate;
        this.IsDeleted = inbound.IsDeleted === null ? "" : inbound.IsDeleted;
    }
}
