<mat-card class="search-card" style=" margin-right: 1vw !important;">
    <form>
    <fieldset class="field-set-results-container">
        <legend>Installed Devices</legend>
    <mat-form-field appearance="outline" color="primary" style="width:16vw;font-size: 12px;" >
        <mat-label>Actions</mat-label>
        <mat-select  [(ngModel)]="selectedValue" (selectionChange)="onSelected($event)"  name="select" id="select" required>
            <mat-option  *ngFor="let action of actionList" [value]="action" style="font-size: 11px;" >{{action.ACTION_DESC}}</mat-option>
        </mat-select>
    </mat-form-field> 
 
    <span *ngIf="selected =='Opt Out'">
    <mat-form-field appearance="outline"  color="primary" style="width:16vw;font-size: 12px;">
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="picker" name="actionDate" [(ngModel)]="actionDate" required>
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    </span>

    
    <button  mat-raised-button (click)="submitAction()" color="primary"  style="width:200px;height:44px;font-size: 18px !important" [disabled]="((!actionDate) && (actions))  ">Submit Action</button>
    <br>
    
    <mat-card class="list-card" style="margin-right: 1vw !important">
        <mat-card-content style="margin-left:0.3vw">
            
            <div class="org-table-container mat-elevation-z8">
            <table style="width: 100% !important; margin-top: 0.2vw !important" mat-table [dataSource]="devicesList"
                class="mat-elevation-z8">
                <ng-container matColumnDef="checkbox" >
                    <th mat-header-cell  *matHeaderCellDef  style="text-align: left; font-size: 0.8vw" ><mat-checkbox [(ngModel)]="select_all" name="checkbox1" (ngModelChange)="onSelectAll($event)"></mat-checkbox></th>
                    <td style="font-size:0.8vw; text-align: left" mat-cell *matCellDef="let device">
                    <mat-checkbox  [(ngModel)]="device.SELECTED" name="checkbox2" required
                    checkboxPosition="before" [ngModelOptions]="{standalone: true}"></mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="serialno">
                    <th mat-header-cell *matHeaderCellDef  style="text-align: left;font-size: 0.8vw">Serial#</th>
                    <td style="font-size:0.8vw; text-align: left" mat-cell *matCellDef="let device">
                        {{device.INSTALL_SERIALNO}} </td>
                </ng-container>
                <ng-container matColumnDef="devicename">
                    <th mat-header-cell *matHeaderCellDef  style="text-align: left;font-size: 0.8vw">Device</th>
                    <td style="font-size:0.8vw; text-align: left" mat-cell *matCellDef="let device">
                        {{device.PRODUCT_DESC}} </td>
                </ng-container>
                <ng-container matColumnDef="devicestatus">
                    <th mat-header-cell *matHeaderCellDef  style="text-align: left;font-size: 0.8vw">Device Status</th>
                    <td style="font-size:0.8vw; text-align: left" mat-cell *matCellDef="let device">
                        {{device.STATUS_CD}} </td>
                </ng-container>
                <ng-container matColumnDef="statusdate">
                    <th mat-header-cell *matHeaderCellDef  style="text-align: left;font-size: 0.8vw">Status Date</th>
                    <td style="font-size:0.8vw; text-align: left" mat-cell *matCellDef="let device">
                        {{device.LINEITEM_STATUSDATE  | date:'M/d/yyyy'}}  </td>
                </ng-container>
                <ng-container matColumnDef="workorderid">
                    <th mat-header-cell *matHeaderCellDef  style="text-align: left;font-size: 0.8vw">Workorder Id</th>
                    <td style="font-size:0.8vw; text-align: left" mat-cell *matCellDef="let device">
                        {{device.WORKORDER_ID}} </td>
                </ng-container>
                
                <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns2;" (click)="handleDevicesClick(row)"
                    class="curr-interaction"></tr>
            </table>
            </div>
            
        </mat-card-content>
        
      </mat-card>
   





    </fieldset>
    </form>
    <br>
    <br>

    <fieldset class="field-set-results-container">
        <legend>Transactions</legend>
      <mat-card class="list-card" style="margin-right: 1vw !important">
        
        <mat-card-content style="margin-left:0.3vw">
            <div class="org-table-container mat-elevation-z8">
            <table style="width: 100% !important; margin-top: 0.2vw !important" mat-table [dataSource]="transactionsList"
                class="mat-elevation-z8">
                <ng-container matColumnDef="workorderid">
                    <th mat-header-cell  *matHeaderCellDef  style="text-align: left; font-size: 0.8vw;width:10%">Workorder ID</th>
                    <td style="font-size:0.8vw; text-align: left" mat-cell *matCellDef="let transaction">
                        {{transaction.WORKORDER_ID | titlecase}} </td>
                </ng-container>
                <ng-container matColumnDef="programname">
                    <th mat-header-cell *matHeaderCellDef  style="text-align: left;font-size: 0.8vw;width:10%">Program Name</th>
                    <td style="font-size:0.8vw; text-align: left" mat-cell *matCellDef="let transaction">
                        {{transaction.PROGRAM_NAME | titlecase}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell  style="text-align: left;font-size: 0.8vw;width:5%" *matHeaderCellDef>Status</th>
                    <td mat-cell style="font-size:0.8vw;text-align: left" *matCellDef="let transaction">
                        {{transaction.STATUS_CD | titlecase}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="statusdate">
                    <th mat-header-cell  style="text-align: left;font-size: 0.8vw;width:10%" *matHeaderCellDef>Status Date</th>
                    <td mat-cell style="font-size:0.8vw;text-align: left" *matCellDef="let transaction">
                        {{transaction.STATUSDATE | titlecase}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="createdate">
                    <th mat-header-cell  style="text-align: left;font-size: 0.8vw;width:10%" *matHeaderCellDef>Create Date</th>
                    <td mat-cell style="font-size:0.8vw;text-align: left" *matCellDef="let transaction">
                        {{transaction.CREATEDATE | titlecase}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell  style="text-align: left;font-size: 0.8vw;width:5%" *matHeaderCellDef>Action</th>
                    <td mat-cell style="font-size:0.8vw;text-align: left" *matCellDef="let transaction">
                        {{transaction.ACTION_DESC | titlecase}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="errormessage">
                    <th mat-header-cell  style="text-align: left;font-size: 0.8vw;width:35%" *matHeaderCellDef>Error Message</th>
                    <td mat-cell style="font-size:0.8vw;text-align: left" *matCellDef="let transaction">
                        {{transaction.ERRORMESSAGE}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="macaddress">
                    <th mat-header-cell  style="text-align: left;font-size: 0.8vw;width:10%" *matHeaderCellDef>Mac Address</th>
                    <td mat-cell style="font-size:0.8vw;text-align: left" *matCellDef="let transaction">
                        {{transaction.MAC_ADDRESS | titlecase}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="serialno">
                    <th mat-header-cell  style="text-align: left;font-size: 0.8vw;width:5%" *matHeaderCellDef>Serial#</th>
                    <td mat-cell style="font-size:0.8vw;text-align: left" *matCellDef="let transaction">
                        {{transaction.SERIAL_NO | titlecase}}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns1;" (click)="handleInteractionClick(row)"
                    class="curr-interaction"></tr>
            </table>
        </div>
        </mat-card-content>
      </mat-card>
    </fieldset>

</mat-card>

