import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { EnrollmentService } from '../../services/enrollment.service';
import { CustomerService } from '../../services/customer.service';
import { WorkorderService } from 'src/app/services/workorder.service';
import Swal from 'sweetalert2';
import { UtilityService } from 'src/app/services/utility.service';
import { SkipQuestionaire } from 'src/app/services/skip-questionaire.service';

@Component({
    selector: 'app-enroll',
    templateUrl: './enroll.component.html',
    styleUrls: ['./enroll.component.scss']
})
export class EnrollComponent implements OnInit {

    recs = [];
    searching = true;
    saving = true;
    group: any;

    constructor(
        public dialogRef: MatDialogRef<EnrollComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private enrollmentService: EnrollmentService,
        public customerService: CustomerService,
        public workorderService: WorkorderService,
        private utilityService: UtilityService,
        private skipQuestionaire: SkipQuestionaire,
        private router: Router
    ) { }

    ngOnInit() {
        this.searching = true;
        this.saving = true;
        this.group = localStorage.getItem('Group');
        this.saveQuestions();
    }

    private saveQuestions() {
        this.enrollmentService.saveQuestionsByPersonCompanyId(this.data.personcompId, this.data.elList)
            .subscribe(() => {
                this.saving = false;
                this.getRecs();
            }, err => {
                console.log('Error: ' + JSON.stringify(err, null, 2));

                this.searching = false;
            });
    }

    private getRecs() {
        this.enrollmentService.getRecommendationsByPersonCompanyId(this.data.personcompId)
            .subscribe((data) => {
                this.recs = data[this.data.personcompId];

                this.searching = false;
            }, err => {
                console.log('Error: ' + JSON.stringify(err, null, 2));

                this.searching = false;
            });
    }

    handleCancel() {
        this.router.navigate(['/detail']);
        this.dialogRef.close('1');
    }

    handleEnroll(rec) {
        this.skipQuestionaire.handleEnroll(rec);
    }
}