<h1 mat-dialog-title >SESSION TIMEOUT</h1>
<mat-divider></mat-divider>
<div mat-dialog-content style="height: 100px; width: 300px;">
    <br>
    <p class="mat-body" style="font-size:1.5vw">You are about to be logged out.</p>
    <br>
    
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="handleContinue()">CONTINUE</button>
</div>