export class Enrollment{
    EnrollmentId: string;
    EnrollmentStartdate: Date;
    EnrollmentStatus: string;
    Workorders: BBCSWorkorder[];

}

export class BBCSWorkorder{
    Status: string;
    StartDate:Date;
    Name: string;
    Id: string;
    CustomerName: string;
    Group:any;
    SubGroup:any;
}

export class Workorder {
    StatusNumber:any;
    StatusDate: Date;//for workorderSearch
    Status: string;//for workorderSearch
    Name: string;//for workorderSearch
    Id: string;
    CustomerName: string;
    WorkorderStatusDate: Date;
    WorkorderStatus: string;
    WorkorderName: string;
    WorkorderId: string
    WorkorderExceptions: string
    DeactivationReasons: string
    WorkorderApplicationSource: string
    PaymentStatus: string;
    PayeeZip: string;
    PayeeState: string;
    PayeeName: string;
    PayeeCity: string;
    PayeeEmail:string;
    PayeeAddress: string;
    ExternalId: string;
    DatePaid: Date;
    CustomerZip: string;
    CustomerStreet: string;
    CustomerState: string;
    CustomerName1: string;
    CustomerId: string;
    CustomerCity: string;
    CheckNumber: string;
    AmountPaid: string;
    CreatedDate: Date;
    AssignedBy: string;
    BucketScheduleEnabled:any;
    InstalledWONotes:any;
    MailorEmail:any;
    Address: string;//for workorderSearch
    City: string;//for workorderSearch
    SerialNo:string;//for serialnumbersearch
    Program: string;//for workorderSearch
    Customer: string;//for workorderSearch
    Externalkey: string;//for workorderSearch
    Group:any;// for workorderSearch
    SubGroup:any;// for workorderSearch
    FromMaster: boolean;
    ProgramName: any;//for workorder detail
    isWorkorder: boolean;// to check if workorder for sidebar navigation
    ProgramSponsorId:any;
    ServiceProviderName:any;
    ContractorPhone:any;
    ContractorEmail:any;
    CompanyContact:any;
    EnrollmentId:any;
    SubGroup1: any;

    public setFromWeb(data) {
  
        this.Status = data.Status;
        this.BucketScheduleEnabled = data.BucketScheduleEnabled;
        this.StatusNumber = data.StatusNumber
        this.Name = (data.Name === undefined || data.Name === null) ? data.WorkorderName : data.Name;
        this.Id = (data.Id ===undefined || data.Id === null) ? data.WorkorderId:data.Id;
        this.CustomerName = data.CustomerName;
        this.WorkorderStatus = data.WorkorderStatus;
        this.WorkorderName = data.WorkorderName;
        this.WorkorderId = (data.WorkorderId ===undefined || data.WorkorderId === null) ? data.Id:data.WorkorderId;
        this.WorkorderExceptions = data.WorkorderExceptions;
        this.DeactivationReasons =  data.DeactivationReasons;
        this.WorkorderApplicationSource= data.WorkorderApplicationSource;
        this.PaymentStatus = data.PaymentStatus;
        this.PayeeZip = data.PayeeZip;
        this.PayeeEmail = data.PayeeEmail;
        this.PayeeState = data.PayeeState;
        this.PayeeName = data.PayeeName;;
        this.PayeeCity = data.PayeeCity;
        this.PayeeAddress = data.PayeeAddress;
        this.ExternalId = data.ExternalId;
        this.CustomerZip = data.CustomerZip;
        this.CustomerStreet = data.CustomerStreet;
        this.CustomerState = data.CustomerState;
        this.CustomerName1 = data.CustomerName;
        this.MailorEmail = data.MailorEmail;
        this.CustomerId = data.CustomerId;
        this.CustomerCity = data.CustomerCity;
        this.CheckNumber = data.CheckNumber;
        this.AmountPaid = data.AmountPaid;
        this.AssignedBy = data.AssignedBy;
        this.Customer = data.Customer;//for workorderSearch
        this.Address = data.Address;//for workorderSearch
        this.City = data.City;//for workorderSearch
        this.SerialNo = data.SerialNo;// for serialnumber search
        this.Program = data.Program;//for workorderSearch
        this.Externalkey = data.Externalkey;//for workorderSearch
        this.Group = (data.Group == null || data.Group == undefined) ? "DEFAULT":data.Group;//for WorkorderSearch
        this.SubGroup=data.SubGroup//for WorkorderSearch
        this.SubGroup1 = data?.SubGroup1 ?? "ALL";
        this.FromMaster = data.FromMaster;
        this.ProgramName = data.ProgramName;//for workorderDetail
        this.InstalledWONotes = data.InstalledWONotes;
        this.ProgramSponsorId = data.ProgramSponsorId;
        this.ServiceProviderName = data.ServiceProviderName;
        this.ContractorEmail = data.ContractorEmail;
        this.ContractorPhone = data.ContractorPhone;
        this.CompanyContact = data.CompanyContact;
        this.EnrollmentId = data.EnrollmentId;

        if (data.StatusDate === undefined || data.StatusDate == null || data.StatusDate =="") {
            this.StatusDate = undefined;
        } else {
            this.StatusDate = new Date(data.StatusDate);
        }
        if (data.WorkorderStatusDate === undefined || data.WorkorderStatusDate == null || data.WorkorderStatusDate =="") {
            this.WorkorderStatusDate = undefined;
        } else {
            this.WorkorderStatusDate = new Date(data.WorkorderStatusDate);
        }
        if (data.DatePaid === undefined || data.DatePaid == null || data.DatePaid == "") {
            
            this.DatePaid = undefined;
        } else {
           
            this.DatePaid = new Date(data.DatePaid);
        }
        if (data.CreatedDate === undefined || data.CreatedDate == null || data.CreatedDate == "") {
            this.CreatedDate = undefined;
        } else {
            this.CreatedDate = new Date(data.CreatedDate);
        }

    }
}

