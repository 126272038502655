import { Injectable, NgZone } from "@angular/core";
import { environment } from "src/environments/environment";
import { UtilityService } from "../services/utility.service";
import { Observer } from "rxjs";

import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { url } from "inspector";
import { Maps } from "../models/Maps";
declare var google: any;
@Injectable({
  providedIn: "root",
})
export class GoogleMapsService {
  private woSearchWsUrl1 = environment.apiUrl + "workorder/";
  public customeraddress: any;
  public platform: any;
  public geocoder: any;
  constructor(
    private http: HttpClient,
    private utilityService: UtilityService,

    private __zone: NgZone
  ) {}
  public getHeaders() {
    const email = localStorage.getItem("loggedInUserEmail");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-email": email,
        "x-programsponsorid": this.utilityService.currentUtility
          .ProgramsponsorId,
      }),
    };
    return httpOptions;
  }

  public setcustomeraddress(customeraddress) {
    this.customeraddress = customeraddress;
  }
  public getcustomeraddress() {
    return this.customeraddress;
  }

  getnearestappointment(response): Observable<Maps> {
    const searchUrl =
      this.woSearchWsUrl1 +
      "shedule/mappushpins/" +
      this.utilityService.currentUtility.OfficeId +
      "?code=" +
      environment.apiCode;

    const body = response;
    return this.http.post<Maps>(searchUrl, body, this.getHeaders()).pipe(
      map((response) => response),
      catchError((err) => this.utilityService.handleError(err, this, []))
    );
  }
}
