import { AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, Component, DoCheck, Inject, OnInit, ViewChild } from '@angular/core';
import { Customer } from 'src/app/models/customer';
import { CustomerService } from 'src/app/services/customer.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatInput } from '@angular/material/input';


@Component({
  selector: 'app-edit-customer-name',
  templateUrl: './edit-customer-name.component.html',
  styleUrls: ['./edit-customer-name.component.scss']
})
export class EditCustomerNameComponent implements OnInit {

  constructor(public customerService: CustomerService,
    public dialogRef: MatDialogRef<EditCustomerNameComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
    customer: Customer;
    firstname = new UntypedFormControl('', [Validators.required]);
     lastname= new UntypedFormControl('', [Validators.required]);
     middlename = new UntypedFormControl('');
     myData: any;
     saveWorkorder: any = false;
  ngOnInit(): void {
    this.customer = this.customerService.currentCustomer;
    this.firstname.setValue(this.customerService.currentCustomer.FirstName);
    this.lastname.setValue(this.customerService.currentCustomer.LastName);
    this.middlename.setValue(this.customerService.currentCustomer.MiddleName);
  }
  savename(){
    this.myData = {
     FIRSTNAME:this.firstname.value.toUpperCase(),
     MIDDLENAME:this.middlename.value.toUpperCase(),
     LASTNAME:this.lastname.value.toUpperCase()
    };
    this.saveWorkorder=true;
    this.customerService.updatecustomername(
      JSON.stringify(this.myData),this.customerService.currentCustomer.PersonCompanyId).subscribe((data) => //
    
      Swal.fire({
        text: "Customer Name Updated Successfully",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        this.finishSave()
      })
    
    
    
    
    , err => {
      Swal.fire({
        text: err,
        icon: 'error',
        confirmButtonText: 'OK',
      });
      this.saveWorkorder=false;
      this.dialogRef.close('1');
    });
    
  }
  private finishSave() {
    this.updateOriginalValues();
    this.saveWorkorder=false;
      this.dialogRef.close();
  }
  private updateOriginalValues(){

    this.customerService.getCustomerById(this.customerService.currentCustomer.Id).subscribe((detailCust:Customer[]) => {
      const tempcust: Customer = new Customer();
          tempcust.setUpFromWS(detailCust);
  
          this.customerService.currentCustomer.Name = tempcust.Name;
          this.customerService.currentCustomer.MiddleName=tempcust.MiddleName;
          this.customerService.currentCustomer.FirstName=tempcust.FirstName;
          this.customerService.currentCustomer.LastName=tempcust.LastName;
  
  
   });
  }
  badDataname() {
    return  this.firstname.invalid
        || this.lastname.invalid;
  }
}
