<div style="height: 300px; width: 500px;">
  <div>
    <h1 mat-dialog-title class="header" style="text-align:center;padding:0.5vw;font-size:1.2vw;color:white;">Eligibility
      Qualification</h1>
  </div>
  <!--<p style="font-size: 16px; text-align: center"><b>Eligibility  Qualification</b></p>-->
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <br>

    <mat-card *ngIf="searching">
      <span style="font-size:0.9vw"> Verifying the qualification criteria. Please wait...
        <mat-spinner style="float:right" [diameter]="25" color="primary"></mat-spinner></span>
      <!-- <p style="font-size:1.2vw"> Verifying the qualification criteria. Please wait...<mat-spinner style="float:right;margin-left:0px" [diameter]="25" color="primary"></mat-spinner></p>-->

    </mat-card>

    <div *ngIf="!searching">

      <ng-container *ngIf="!showForEligible">


        <mat-list>

          <p style="font-size:1.1vw" *ngIf="recommendedCount.length ==0" [innerHTML]=notQualifiedReason></p>

          <ng-container *ngFor="let rec of recommendedCount;">

            <mat-list-item style="align-items: center;margin-top: 0px">


              <mat-icon mat-list-icon style="color: green;margin-left: 10px;"
                matTooltip="Eligible">check_circle</mat-icon>
              <button mat-raised-button class="header" style="color:white;" (click)="handleEnroll(rec)">Enroll in
                {{rec.WORKORDERTYPE}}</button>


            </mat-list-item>

          </ng-container>
        </mat-list>
      </ng-container>

    </div>
    <br>
  </div>



  <div mat-dialog-actions>
    <button mat-raised-button class="header" style="color:white;margin-left: 10px"
      (click)="handleCancel()">CANCEL</button>
  </div>
</div>