<div [hidden]="customerSearching">
    <!-- *ngIf="!customerSearching" -->
    <mat-tab-group color="accent" backgroundColor="primary" #workorderTabs
        style="width: 99% !important; margin-top:0.9vw;">
        <mat-tab label="Detail">
            <br>

            <mat-card *ngIf="workorder.WorkorderId !== undefined"
                style="width: 95% !important; margin-left: 0.5vw;margin-top: 0.9vw">
                <mat-card-header>
                    <table style="width: 100% !important; table-layout: fixed">
                        <tr>
                            <td style="text-align: center;font-size: 0.9vw"> <a href=""
                                (click)="customerClick(workorder.CustomerId)"><span>{{workorder.CustomerName}}</span></a>
                        </td>
                            <td style="text-align: center; font-size: 0.9vw"> <span>{{workorder.ProgramName}}</span>
                            </td>
                            <td style="text-align: center;font-size: 0.9vw"><a href=""
                                    (click)="workorderStatus(workorder.WorkorderId)"><span>{{workorder.WorkorderName}} :
                                        {{workorder.WorkorderStatus}}</span></a></td>
                        </tr>
                    </table>

                </mat-card-header>
            </mat-card>

            <table style="width:98.2% !important; table-layout: fixed; margin-left:0.25vw;margin-top:0.5vw">
                <tr>
                    <td>
                        <mat-card style="height:12vw;">
                            <mat-card-header class="workorderHeaders">Customer Details</mat-card-header>
                            <mat-card-content>
                                <label class="labelStyle" style="margin-top: 1.2vw !important;">
                                    {{workorder.CustomerName | titlecase}} </label>
                                <label class="labelStyle" style="margin-top: 1.2vw !important;">
                                    {{workorder.CustomerStreet | titlecase}} </label>
                                <label class="labelStyle" style="margin-top: 1.2vw !important;">
                                    {{workorder.CustomerCity | titlecase}},
                                    {{workorder.CustomerState | uppercase}} {{workorder.CustomerZip}}
                                </label>
                            </mat-card-content>
                        </mat-card>
                    </td>
                    <td>
                        <mat-card style="height:12vw;">
                            <mat-card-header class="workorderHeaders">Payee Details</mat-card-header>
                            <mat-card-content>
                                <div>
                                    <label class="labelStyle" style="margin-top: 1.2vw !important;">
                                        {{workorder.PayeeName | titlecase}} </label>
                                        <label class="labelStyle" style="margin-top: 1.2vw !important;">
                                            {{workorder.PayeeEmail}} </label>
                                    <label class="labelStyle" style="margin-top: 1.2vw !important;">
                                        {{workorder.PayeeAddress | titlecase}} </label>
                                    <label class="labelStyle" style="margin-top: 1.2vw !important;">
                                        {{workorder.PayeeCity | titlecase}},
                                        {{workorder.PayeeState | uppercase}} {{workorder.PayeeZip}} </label>
                                </div>

                            </mat-card-content>
                        </mat-card>
                    </td>
                    <td>
                        <mat-card style=" height:12vw;">
                            <mat-card-header class="workorderHeaders">Rebate Details</mat-card-header>
                            <mat-card-content>
                                <label class="labelStyle" style="text-align: left;"> Amount Paid : {{workorder.AmountPaid}} </label>
                                <label class="labelStyle" style="text-align: left;"> Check # :
                                    {{workorder.CheckNumber}}
                                </label>
                                <label class="labelStyle" style="text-align: left;"> Date Paid :
                                    {{workorder.DatePaid | date : 'M/dd/yy'}} </label>
                                    <label class="labelStyle" style="text-align: left;"> Emailed or Mailed : {{workorder.MailorEmail}}
                                        </label>
                                <label class="labelStyle" style="text-align: left;"> Payment Status
                                    :{{workorder.PaymentStatus}} </label>
                                <label class="labelStyle" style="text-align: left;"> WorkorderStatus Date :
                                    {{workorder.WorkorderStatusDate | date : 'M/dd/yy'}} </label>
                                <label class="labelStyle" style="margin-top:0.5vw;text-align: left;"> Workorder Application Source :
                                    {{workorder.WorkorderApplicationSource? workorder.WorkorderApplicationSource:' '}} </label>    
                            </mat-card-content>
                        </mat-card>
                    </td>
                </tr>
                <tr>
                    <td>
                        <mat-card style="
                            height: 12vw;">
                            <mat-card-header class="workorderHeaders">
                                Contractor Details
                            </mat-card-header>
                            <mat-card-content style="margin-top:0.9vw">
                                <label class="labelStyle" style="text-align: left;"> Service Provider Name :
                                    {{workorder.ServiceProviderName}}</label>
                                <label class="labelStyle" style="margin-top:0.9vw; text-align: left;"> Contractor Phone :
                                    {{workorder.ContractorPhone}}</label>
                                <label class="labelStyle" style="margin-top:0.9vw;text-align: left;"> Contractor Email :
                                    {{workorder.ContractorEmail}}</label>
                                <label class="labelStyle" style="margin-top:0.9vw;text-align: left;"> Company Contact :
                                    {{workorder.CompanyContact}} </label>
                            </mat-card-content>
                        </mat-card>


                    </td>
                    <td>
                        <mat-card style=" height:12vw;">
                            <mat-card-header class="workorderHeaders">
                                Workorder Notes
                            </mat-card-header>
                            <mat-card-content style="margin-top:0.5vw">
                                <div></div>
                                <label *ngIf="workorder.InstalledWONotes"
                                    style="font-size: 0.9vw">{{workorder.InstalledWONotes}}</label>
                                <label *ngIf="!workorder.InstalledWONotes" style="font-size: 0.9vw">No Workorder
                                    Notes</label>
                            </mat-card-content>
                        </mat-card>
                    </td>
                    <td>
                        <mat-card style="height: 12vw;">
                            <mat-card-header class="workorderHeaders">Attachments</mat-card-header>
                            <mat-card-content>
                                <div *ngIf="showAttachMessage" style="text-align: center;margin:0.9vw">
                                    <label style="font-size: 0.9vw">{{attachmentMessage}}</label></div>
                                <table *ngIf="!showAttachMessage"
                                    style="width: 100% !important; margin-top: 0.5vw !important" mat-table
                                    [dataSource]="dataSource3" class="mat-elevation-z8">
                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef style="font-size:0.9vw; height: 2vw">Name
                                        </th>
                                        <td style="font-size:0.9vw;height: 2vw" mat-cell *matCellDef="let attachment">
                                            {{attachment.FileName}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="date">
                                        <th mat-header-cell *matHeaderCellDef style="font-size:0.9vw;height: 2vw">Date
                                        </th>
                                        <td style="font-size:0.9vw;height: 2vw" mat-cell *matCellDef="let attachment">
                                            {{attachment.DateUploaded |  date:'M/d/yyyy'}}</td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns4"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns4;"
                                        (click)="handleAttachmentClick(row)" class="curr-attachment"></tr>
                                </table>
                                <mat-paginator #attpaginator [pageSize]="3" showFirstLastButtons>
                                </mat-paginator>
                            </mat-card-content>
                        </mat-card>
                    </td>
                </tr>
                <tr *ngIf="['Deactivated', 'Canceled'].includes(workorder.WorkorderStatus)">
                    <td colspan="3">
                        <mat-card style="height:4vw;">
                            <mat-card-header class="workorderHeaders" style="color:red;font-size: 1vw">
                                Deactivate/Cancel Reason
                            </mat-card-header>
                            <mat-card-content style="margin-top:0.5vw">
                                <label style="font-size: 0.9vw"
                                    *ngIf="workorder.DeactivationReasons">{{workorder.DeactivationReasons}}</label>
                                <label style="font-size: 0.9vw" *ngIf="!workorder.DeactivationReasons"></label>
                            </mat-card-content>
                        </mat-card>

                    </td>

                </tr>
                <tr>
                    <td colspan="3">
                        <mat-card style="height:4vw;">
                            <mat-card-header class="workorderHeaders" style="color:red;font-size: 1vw">
                                Workorder Exceptions
                            </mat-card-header>
                            <mat-card-content style="margin-top:0.5vw">
                                <label style="font-size: 0.9vw"
                                    *ngIf="workorder.WorkorderExceptions">{{workorder.WorkorderExceptions}}</label>
                                <label style="font-size: 0.9vw" *ngIf="!workorder.WorkorderExceptions">No Workorder
                                    Exceptions</label>
                            </mat-card-content>
                        </mat-card>

                    </td>

                </tr>
            </table>
        </mat-tab>
        <mat-tab label="Measures">

          <!--   <mat-spinner *ngIf="measuresSearching && measures.MeasuresArray.length === 0" [diameter]="70"
                color="primary" style="margin: 0 auto; width:50%; height: 100%;">
            </mat-spinner> -->


            <div *ngIf="!measuresSearching && measures.MeasuresArray.length === 0" style="color: red; font-size: 0.9vw;">
                No measures for the selected
                workorder</div>

            <div [hidden]="!measuresSearching && !(measures.MeasuresArray.length > 0)">
                <!-- *ngIf="!measuresSearching  &&  measures.MeasuresArray.length > 0"> -->
                <mat-paginator style="float: right !important" #MeasurePaginator [length]="totalRows$ | async"
                    [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

                <button matTooltip="Collapse All" mat-button (click)="closeAll()" style="margin-top: 0.5vw">
                    <mat-icon style="color: black;" aria-hidden="false" aria-label="collapse">unfold_less
                    </mat-icon>
                </button>
                <br>
                <mat-accordion #accordion="matAccordion" displayMode="flat" [multi]="true" class="mat-table"
                    style=" table-layout: fixed;width:100% !important;">

                    <br>
                    <mat-expansion-panel *ngFor="let measure of displayedRows$ | async" style="margin: 0.2vw">
                        <mat-expansion-panel-header class="mat-row">
                            <span style="width:30%" class="mat-cell">{{measure.Name}}</span>
                            <span style="width:25%" class="mat-cell">{{measure.Category}}</span>
                            <span style="width:15%" class="mat-cell">{{measure.Quantity}}</span>
                            <span style="width:24%" class="mat-cell">{{measure.Status}}</span>
                            <span style="width:15%" class="mat-cell">{{measure.Cost}}</span>
                        </mat-expansion-panel-header>
                        <tr *ngIf="measure.Notes">Notes : {{measure.Notes}}</tr>
                        <div *ngIf=" measure.MeasureAttributes.length === 0" style="color: red; font-size: 0.9vw;">No
                            attributes for the current measure</div>
                        <table *ngIf="measure.MeasureAttributes.length > 0" class="mat-elevation-z8"
                            style="table-layout: fixed;width:50%; margin:0 auto">
                            <th class="backgroundClass" style="text-align: left; font-size: 0.7vw ; color:whitesmoke;">
                                Attribute Name
                            </th>
                            <th class="backgroundClass" style="text-align: left; font-size: 0.7vw;  color:whitesmoke;">
                                Attribute Value
                            </th>
                            <tbody>

                                <tr *ngFor="let attr of measure.MeasureAttributes" class="alternateColors">
                                    <td style="font-size: 0.7vw">{{attr.Name}}</td>
                                    <td style="font-size: 0.7vw">{{attr.Value}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-expansion-panel>

                </mat-accordion>

                <!--  <mat-accordion #accordion="matAccordion" [multi]="true">

                    <button matTooltip="Collapse All" style="float:right;margin-right: 1.5vw" mat-button
                        (click)="accordion.closeAll()">
                        <mat-icon style="color: black;" aria-hidden="false" aria-label="collapse">unfold_less
                        </mat-icon>
                    </button>
                    <mat-expansion-panel *ngFor="let measure of measures.MeasuresArray"
                        style=" margin-top:0.9vw !important; margin-bottom:0.75vw;margin-left:1.5vw;width:95% !important">
                        <mat-expansion-panel-header style="font-size: 13px;">
                            <table style="table-layout: fixed; width: 100% !important">
                                <tr>
                                    <td> {{measure.Name}}</td>
                                    <td>
                                        {{ measure.Category }}
                                    </td>
                                    <td>
                                        Quantity: {{ measure.Quantity }}
                                    </td>
                                    <td>
                                        {{ measure.Status }}
                                    </td>
                                    <td>
                                        {{ measure.Cost  | currency:'USD'}}
                                    </td>
                                </tr>
                            </table>

                        </mat-expansion-panel-header>
                        <tr *ngIf="measure.Notes">Notes : {{measure.Notes}}</tr>
                        <div *ngIf=" measure.MeasureAttributes.length === 0" style="color: red; font-size: 0.9vw;">No
                            attributes for the current measure</div>
                        <table *ngIf="measure.MeasureAttributes.length > 0" class="mat-elevation-z8"
                            style="table-layout: fixed;width:50%; margin:0 auto">
                            <th style="text-align: left; font-size: 0.7vw ;background-color:#37474F; color:whitesmoke;">
                                Attribute Name
                            </th>
                            <th style="text-align: left; font-size: 0.7vw; background-color:#37474F; color:whitesmoke;">
                                Attribute Value
                            </th>
                            <tbody>

                                <tr *ngFor="let attr of measure.MeasureAttributes" class="alternateColors">
                                    <td style="font-size: 0.7vw">{{attr.Name}}</td>
                                    <td style="font-size: 0.7vw">{{attr.Value}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-expansion-panel>

                </mat-accordion> -->


            </div>


        </mat-tab>
    </mat-tab-group>
</div>

<div *ngIf="customerSearching">
    <mat-spinner [diameter]="100" color="primary" style="margin: 0 auto; width:50%; margin-top:0.9vw"></mat-spinner>
</div>