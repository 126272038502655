import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IndividualService } from '../../../services/individual.service';

@Component({
    selector: 'app-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

    addressType;
    address_id = null;

    stNo = new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]);
    stName = new UntypedFormControl('', [Validators.required]);
    stType = new UntypedFormControl('', [Validators.required]);
    stCity = new UntypedFormControl('', [Validators.required]);
    stState = new UntypedFormControl('', [Validators.required, Validators.maxLength(2)]);
    stZip = new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]);

    constructor(
        public dialogRef: MatDialogRef<AddressComponent>,
        public indService: IndividualService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        if (this.data.editMode) {
            this.stNo.setValue(this.indService.indDetail.IndividualTab.Address[this.data.index].STREETNUMBER);
            this.stName.setValue(this.indService.indDetail.IndividualTab.Address[this.data.index].STREETNAME);
            this.stType.setValue(this.indService.indDetail.IndividualTab.Address[this.data.index].STREETTYPE);
            this.stCity.setValue(this.indService.indDetail.IndividualTab.Address[this.data.index].CITY);
            this.stState.setValue(this.indService.indDetail.IndividualTab.Address[this.data.index].STATEPROVINCE);
            this.stZip.setValue(this.indService.indDetail.IndividualTab.Address[this.data.index].ZIP);

            this.address_id = this.indService.indDetail.IndividualTab.Address[this.data.index].ADDRESS_ID;
            for (var i = 0; i < this.indService.indDetail.IndividualTab.AddressTypeList.length; i++) {
                if (this.indService.indDetail.IndividualTab.AddressTypeList[i].CODE
                    === this.indService.indDetail.IndividualTab.Address[this.data.index].LINKTYPE_CD) {
                    this.addressType = this.indService.indDetail.IndividualTab.AddressTypeList[i];
                    break;
                }
            }
        }
    }

    badData() {
        return this.addressType === undefined
            || this.stNo.invalid
            || this.stName.invalid
           // || this.stType.invalid
            || this.stCity.invalid
            || this.stState.invalid
            || this.stZip.invalid;
    }

    saveAddress() {
        // this fields are all required so we can assume they have values
        const address1 = this.stNo.value;
        const address2 = this.stName.value;
        let streettype = this.stType.value;
        const city = this.stCity.value;
        const state = this.stState.value;
        const zip = this.stZip.value;

        if (streettype === undefined) {
            streettype = null;
        }

        if (this.data.editMode) {
            this.indService.indDetail.IndividualTab.Address[this.data.index].LINKTYPE_CD = this.addressType.CODE;
            this.indService.indDetail.IndividualTab.Address[this.data.index].SELECTEDADDRTYPEDESCRIPTION = this.addressType.DESCRIPTION;
            this.indService.indDetail.IndividualTab.Address[this.data.index].SELECTEDADDRTYPECODE = this.addressType.CODE;

            this.indService.indDetail.IndividualTab.Address[this.data.index].STREETNUMBER = address1;
            this.indService.indDetail.IndividualTab.Address[this.data.index].STREETNAME = address2.toUpperCase();
            this.indService.indDetail.IndividualTab.Address[this.data.index].STREETTYPE = streettype.toUpperCase();
            this.indService.indDetail.IndividualTab.Address[this.data.index].CITY = city.toUpperCase();
            this.indService.indDetail.IndividualTab.Address[this.data.index].STATEPROVINCE = state.toUpperCase();
            this.indService.indDetail.IndividualTab.Address[this.data.index].ZIP = zip;
        } else {
            const address = {
                ADDRESS_ID: null,
                LINKTYPE_CD: this.addressType.CODE,
                STREETNUMBER: address1,
                STREETNAME: address2.toUpperCase(),
                STREETTYPE: streettype.toUpperCase(),
                CITY: city.toUpperCase(),
                STATEPROVINCE: state.toUpperCase(),
                ZIP: zip,
                ADDRESS: null,
                ADDRESS2: null,
                SELECTEDADDRTYPEDESCRIPTION: this.addressType.DESCRIPTION,
                SELECTEDADDRTYPECODE: this.addressType.CODE
            };

            this.indService.indDetail.IndividualTab.Address.push(address);
        }

        this.dialogRef.close('1');
    }
}
