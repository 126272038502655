import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { Office } from 'src/app/models/office';
import Swal from 'sweetalert2';
import { take, publish } from 'rxjs/operators';
import { ConnectableObservable } from 'rxjs';
import { create } from 'domain';
import { WaitDialogComponent } from '../../util/wait-dialog/wait-dialog.component';

@Component({
  selector: 'app-office-create',
  templateUrl: './office-create.component.html',
  styleUrls: ['./office-create.component.scss']
})
export class OfficeCreateComponent implements OnInit {

  officeId: any;
  officeDescription: any;
  officeActive: boolean = true;
  ifOfficeExists: boolean = false;
  selectedProgramsponsor: any;
  pSponsors = [];
  IfMandatory: boolean = false;
  errorMessage: any;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog,
    private router: Router,
    private userService: UserService) { }

  ngOnInit() {
    this.userService.getProgramSponsors().subscribe(res => {
      res.forEach(element => {
        if(element.ProgramSponsorActive){
          this.pSponsors.push(element);
        }
      });
     /*  this.pSponsors = res; */
    })
  }


  saveOffice() {
    this.checkAllFieldsAreEmpty();
    if (!this.IfMandatory) {
      this.officeSaveIfTrue();
    }
    else {
      this.errorMessage = "All fields are mandatory"
    }
  }

  clearMessage() {
    this.errorMessage = "";
  }


  checkAllFieldsAreEmpty() {

    if ((this.officeId == null || this.officeId == undefined || this.officeId == "")
      || (this.officeDescription == null || this.officeDescription == undefined || this.officeDescription == "")
      || (this.selectedProgramsponsor == null || this.selectedProgramsponsor == undefined || this.selectedProgramsponsor == "")) {
      this.IfMandatory = true;
    }
    else {
      this.IfMandatory = false;
    }
  }

  officeSaveIfTrue() {
    let office = new Office();
    let self = this;
    office.OfficeId = this.officeId.toUpperCase();
    office.OfficeDescription = this.officeDescription;
    office.ProgramSponsorId = this.selectedProgramsponsor;
    office.OfficeActive = this.officeActive;
    office.CreatedBy = localStorage.getItem("loggedInUserName");
    office.ModifiedBy = localStorage.getItem("loggedInUserName");

    var officeExists = this.userService.officeExists(office.OfficeId, office.OfficeDescription).pipe(take(1));
    var createOffice = this.userService.createOffice(office).pipe(take(1), publish()) as ConnectableObservable<any>;

    officeExists.subscribe(res => {
        this.ifOfficeExists = true;
    }, error => {
        this.ifOfficeExists = false;
        this.errorMessage = "Office already exists";
    }, createOffice.connect.bind(createOffice));
    let className = localStorage.getItem("themeName");
    const dialogRef = this.dialog.open(WaitDialogComponent, {data: {caption: 'Saving...'},panelClass:className});
    createOffice.subscribe(function (data) {
        dialogRef.close();
        
        if (data && self.ifOfficeExists) {
            Swal.fire({
                text: "Office Created Successfully",
                icon: 'success',
                confirmButtonText: 'OK',
            }).then(() => {
                self.dialog.closeAll();
            })
        }
        else {
            Swal.fire({
                text: "Office Create failed",
                icon: 'error',
                confirmButtonText: 'OK',
            }).then(() => {
                self.dialog.closeAll();
            })
        }

    }, error => {
        Swal.fire({
            text: "Office Create failed. Server Error",
            icon: 'error',
            confirmButtonText: 'OK',
        }).then(() => {
            self.dialog.closeAll();
        })
    });
  }


  cancelOffice() {

    this.dialog.closeAll();
    this.router.navigate(['/officeAdmin']);

  }

}
