<h5 mat-dialog-title>Add Daily Hours</h5>
<mat-divider></mat-divider>
<div mat-dialog-content style="width: 100vh;">
  <mat-card class="user-card">
    <mat-card-content>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Day of the Week</mat-label>
        <mat-select [(ngModel)]="dayType">
          <mat-option *ngFor="let dtype of indService.indDetail.DailyHoursTab.WEEKDAYSLIST" [value]="dtype" style="font-size: 11px;">
            {{dtype.DESCRIPTION}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <br />
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Start Time</mat-label>
        <input matInput [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" [(ngModel)]="startDate">
        <owl-date-time #dt1></owl-date-time>
      </mat-form-field>

      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>End Time</mat-label>
        <input matInput [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2" [(ngModel)]="endDate">
        <owl-date-time #dt2></owl-date-time>
      </mat-form-field>

      <br />
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="saveDailyHours()"
        [disabled]="startDate === undefined || endDate === undefined ">SAVE</button>
      <button mat-raised-button color="primary" [mat-dialog-close]>CANCEL</button>
    </mat-card-actions>
  </mat-card>
</div>
