<h5 mat-dialog-title  style="padding:0.2vw; padding-top: 0!important; color:black">User Detail : {{userData.UserEmail}}
</h5>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <mat-form-field appearance="outline" class="formfield-half">
    <mat-label>ProgramSponsor</mat-label>
    <mat-select [(ngModel)]="selectedProgramsponsor" (selectionChange)="enablePUser()">
      <mat-option *ngFor="let pSponsor of pSponsors"  style="font-size: 11px;" [value]="pSponsor.ProgramSponsorId">{{pSponsor.ProgramSponsorDescription}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="formfield-half">
    <mat-label>Role</mat-label>
    <mat-select multiple [(ngModel)]="selectedRole" (selectionChange)="enablePUser()">
      <mat-option *ngFor="let role of roles" [value]="role.RoleId" style="font-size: 11px;">{{role.RoleName}}</mat-option>
    </mat-select>
  </mat-form-field>

  <button [disabled]="ifPUserEmpty" mat-raised-button color="primary" (click)="addPSRole()"
    style="float:right !important; height: 10% !important; margin-top: 1.5vw;"> SAVE
   <!--  <mat-icon matTooltip="Add" aria-hidden="false" color="primary" style="font-size: 2.4vw; 
  text-align: center;
  width: 98%;float:right; margin-bottom: 0.5vw">
      add
    </mat-icon> -->
  </button>

  <table style="width: 50% !important;
  vertical-align: middle;
  margin: 0 auto;" mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="pUserRoles.ProgramsponsorUserRole.length > 0">
    <ng-container>
      <th mat-header-cell *matHeaderCellDef   style="width: 48%;">Program Sponsor</th>
      <td mat-cell *matCellDef="let pUserRole"> {{pUserRole.ProgramsponsorId}}</td>
    </ng-container>
    <ng-container matColumnDef="programsponsor">
        <th mat-header-cell *matHeaderCellDef   style="width: 48%;">Program Sponsor</th>
        <td mat-cell *matCellDef="let pUserRole"> {{pUserRole.ProgramsponsorDescription}}</td>
      </ng-container> 
    <ng-container>
      <th mat-header-cell *matHeaderCellDef>Role</th>
      <td mat-cell *matCellDef="let pUserRole"> {{pUserRole.RoleId}}  </td>
    </ng-container>
    <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef>Role</th>
        <td mat-cell *matCellDef="let pUserRole"> {{pUserRole.RoleName}}  </td>
      </ng-container>
    <ng-container matColumnDef="delete" stickyEnd>
      <th mat-header-cell *matHeaderCellDef style="width: 10%"></th>
      <td mat-cell *matCellDef="let pUserRole">
        <button mat-button (click)="deletepUserRole(pUserRole)">
            <mat-icon matTooltip="Delete" color="primary" >delete</mat-icon>
        </button>
        
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator style="  width: 50%;
  vertical-align: middle;
  margin: 0 auto;" #paginator [pageSize]="4" showFirstLastButtons></mat-paginator>
  <mat-divider></mat-divider>


  <mat-slide-toggle style="margin-top: 0.5vw; margin-bottom: 0.5vw" [(ngModel)]="userActive">Active</mat-slide-toggle>
  <br>
  <button mat-raised-button color="primary" (click)="saveUser()" style="margin-right: 1vw">SAVE</button>
  <button mat-raised-button color="primary" (click)="cancelUser()">CANCEL</button>

</div>