import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Router, ActivatedRoute } from "@angular/router";
import { DataTransferService } from "../services/data-transfer.service";
import { DistributorService } from "../services/distributor.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-distributor",
  templateUrl: "./distributor.component.html",
  styleUrls: ["./distributor.component.scss"],
})
export class DistributorComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ["DistributorName"];
  searching = false;
  searching2 = false;
  filterText: string;
  indName: string;

  constructor(
    public distributorService: DistributorService,
    private router: Router,
    private route: ActivatedRoute,
    private dataTransfer: DataTransferService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.dataTransfer.myMethod(true);
    const toggle = this.route.snapshot.paramMap.get("toggle");
    if (toggle === "0") {
      this.refresh();
    } else {
      this.getOrganizationList();
      this.dataSource.data = this.distributorService.indvList;
    }

    this.dataSource.filterPredicate = (data: any, filtersJson: string) => {
      const matchFilter = [];
      const filters = JSON.parse(filtersJson);

      filters.forEach((filter) => {
        const val = data[filter.id] === null ? "" : data[filter.id];
        matchFilter.push(
          val.toLowerCase().includes(filter.value.toLowerCase())
        );
      });
      return matchFilter.every(Boolean);
    };
  }

  refresh() {
    this.indName = "";
    this.distributorService.selectedUtility = "";
    this.distributorService.orgList = [];
    this.distributorService.indvList = [];
    this.dataSource.data = this.distributorService.indvList;

    this.filterText = null;
    this.dataSource.filter = this.filterText;
    this.getOrganizationList();
  }

  getOrganizationList() {
    if (
      this.distributorService.selectedUtility !== undefined &&
      this.distributorService.orgList.length > 0
    ) {
      return;
    }

    this.searching = true;
    this.spinner.show();
    this.distributorService.getOrganizationList().subscribe((data) => {
      this.distributorService.orgList = data;
      this.distributorService.selectedUtility =
        this.distributorService?.orgList?.Individuals.SelectedProviderId;
      this.spinner.hide();
      this.searching = false;
    });
  }

  doSearch() {
    this.searching2 = true;
    let val = this.distributorService.selectedUtility;
    this.distributorService.indName = this.indName;
    this.spinner.show();
    this.distributorService.search(val, this.indName).subscribe(
      (data) => {
        this.spinner.hide();
        this.distributorService.indvList = data;
        this.dataSource.data = this.distributorService.indvList;
        this.searching2 = false;
      },
      (err) => {
        this.searching2 = false;
      }
    );
  }

  handleClick(row) {
    this.searching2 = true;
    this.spinner.show();
    this.distributorService
      .newDetail(row.Utility, row.DistributorCode)
      .subscribe((data) => {
        this.spinner.hide();
        this.distributorService.indDetail = data;
        this.distributorService.selectedDistributorCode =
          this.distributorService.indDetail.DistributorDetail?.DistributorCode;
        this.distributorService.selectedUtility =
          this.distributorService.indDetail.DistributorDetail?.Utility;
        this.distributorService.editMode = true;
        this.searching2 = false;
        this.router.navigate(["/distributorDetail"]);
      });
  }

  applyFilter(filterValue: string) {
    const tableFilters = [];
    tableFilters.push({
      id: "DistributorName",
      value: filterValue,
    });
    this.dataSource.filter = JSON.stringify(tableFilters);
  }

  newIndividual() {
    this.searching = true;
    this.spinner.show();
    this.distributorService
      .newDetail(this.distributorService.selectedUtility, this.indName)
      .subscribe((data) => {
        this.spinner.hide();
        this.distributorService.indDetail = data;
      });
    this.distributorService.editMode = false;

    this.searching = false;
    this.router.navigate(["/distributorDetail"]);
  }

  selectedUtility(event) {
    this.distributorService.selectedUtility = event.value;
  }
}
