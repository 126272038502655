<div style="float: right; top: -5px; right: -5px; color: white">
  <button mat-icon-button (click)="closedialog()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<h1 mat-dialog-title class="header" style="padding: 0.5vw; font-size: 1.2vw; color: white">
  {{ data.caption }}
</h1>
<div mat-dialog-content>
  <mat-card style="width: 40vw">
    <mat-card-content>
      <input type="file" (change)="handleFiles($event)" name="uploadExcel" />
      <a href="{{templateDownload}}" download>Download Template</a>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button (click)="uploadDoc()">Upload</button>
    </mat-card-actions>
  </mat-card>
</div>