<h5 mat-dialog-title  style="padding:0.2vw; color:black; margin:0 !important">Create User</h5>
<mat-divider></mat-divider>
<div mat-dialog-content style="height: 37vh;
width: 100vh;">
    <mat-card class="user-card" style="width: 93%;">
        <mat-card-content>
            <mat-form-field appearance="outline" class="formfield-half" style="width:50% !important">
                <mat-label>User Email</mat-label>
                <input type="email" matInput placeholder="Email" [(ngModel)]="userEmail" (focusin)="clearMessage()" ngModel email maxlength="50">
            </mat-form-field>
            <mat-form-field appearance="outline" class="formfield-half" style="width:50% !important">
                <mat-label>User Name</mat-label>
                <input  matInput placeholder="Username" [(ngModel)]="userName" (focusin)="clearMessage()" ngModel maxlength="7">
            </mat-form-field>
            <br>
            <mat-slide-toggle [(ngModel)]="userActive">Active</mat-slide-toggle>
            <br>
           
            <label style="color: red; font-size: 0.9vw">{{errorMessage}}</label>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="primary"
                (click)="saveUser();">SAVE</button>
            <button mat-raised-button color="primary" (click)="cancelUser();">CANCEL</button>
        </mat-card-actions>
    </mat-card>

</div>