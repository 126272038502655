import {
  Component,
  // ChangeDetectionStrategy,
  ViewEncapsulation,
  ChangeDetectorRef,
  HostListener,
} from "@angular/core";

import {
  CalendarView,
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  // CalendarMonthViewBeforeRenderEvent,
  // CalendarWeekViewBeforeRenderEvent,
  // CalendarDayViewBeforeRenderEvent,
} from "angular-calendar";

// import {
//   addHours,
//   startOfDay,
//   parseISO,
//   addDays,
//   addMinutes,
//   setDate,
// } from "date-fns";
import { User } from "../day-view-scheduler/day-view-scheduler.component";
import { Subject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { SchedulerDialogComponent } from "../util/scheduler-dialog/scheduler-dialog.component";
import { SchedulingService } from "../services/scheduling.service";
import { Router, ActivatedRoute } from "@angular/router";
import { UtilityService } from "../services/utility.service";
// import { parse, format } from "path";
// import { formatISO } from "date-fns/esm";
import * as moment from "moment";
import { Customer } from "../models/customer";
import { CustomerService } from "../services/customer.service";
import { DataTransferService } from "../services/data-transfer.service";
import { LocationsearchService } from "../services/locationsearch.service";
import { LocationSearchComponent } from "../location-search_/location-search.component";
import { WorkorderService } from "../services/workorder.service";
// import { AnyARecord } from "dns";
import Swal from "sweetalert2";
import { CancelscheduleDialogComponent } from "../util/cancelschedule-dialog/cancelschedule-dialog.component";
import { GoogleMapsComponent } from "../google-maps/google-maps.component";
import { GoogleMapsService } from "../services/google-maps.service";
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: "mwl-demo-component",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./scheduling.component.html",
  styleUrls: ["./scheduling.component.scss"],
})
export class SchedulingComponent {
  constructor(
    private dialog: MatDialog,
    private scheduleService: SchedulingService,
    private cdr: ChangeDetectorRef,
    private workorderService: WorkorderService,
    private dataTransfer: DataTransferService,
    private locationsearchService: LocationsearchService,
    private route: ActivatedRoute,
    private router: Router,
    private utilityService: UtilityService,
    private customerService: CustomerService,
    private googleMapsService: GoogleMapsService,
    private spinner: NgxSpinnerService
  ) { }

  searching: Boolean = true;
  initialInfo: any;
  gridCode: any;
  viewDate: any = new Date(moment().utc().format("MM/DD/YYYY"));
  frompersoncompany: any;
  selectedViewDate: any;
  loadedData: any = false;
  viewRendered: any = false;
  selectedResources: any = [];
  hasDateChanged: boolean = true;
  selectedUser: any;
  moveClicked = true;
  // hasResourcesChanged: boolean = true;
  workorderID: any = "";
  selectedWorkorderID: any;
  customerName: any;
  customerAddress: any;
  serviceaddress: any;
  customerId: any;
  startRef: any;
  endRef: any;
  dailyInfo: any;
  selectedModule: any;
  workorderYes: any = false;
  prevSegment: any;
  prevSDate: any;
  prevEDate: any;
  prevAdDateStart: any;
  prevAdDateEnd: any;
  dayStart: any;
  dayEnd: any;
  hourColumns: any;
  businessClicked: any = true;
  group: any;
  subgroup: any;
  subgroup1: any;
  showMoveContext: any = true;
  showPasteContext: any = true;
  selectedpersoncompany: any;
  name: any;
  ifCurrentWorkorder: any = false;
  disableLocationSearch: any = true;
  contextmenuHidden: any;
  prevViewDate: any;
  programDetailId: any;
  assignmentID: any;
  moveAllDisable: any;
  moveSingleDisable: any;
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent("Edited", event);
      },
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent("Deleted", event);
      },
    },
  ];

  users: User[] = [];
  selectedInterval: any = {
    id: 2,
    name: "30 minutes",
    value: 30,
    tickValue: 2,
  };

  timeIntervals = [
    {
      id: 1,
      name: "15 minutes",
      value: 15,
      tickValue: 4,
    },
    {
      id: 2,
      name: "30 minutes",
      value: 30,
      tickValue: 2,
    },
  ];

  grid: any = "All";
  selectedView: any = "day";
  calendarViews = [
    {
      id: 1,
      name: "Week",
      value: "week",
    },
    {
      id: 2,
      name: "Day",
      value: "day",
    },
  ];
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.moveClicked = true;
    this.showMoveContext = true;
    this.disableLocationSearch = true;
    if (this.workorderID == undefined) {
      this.events.forEach((element) => {
        if (element.cssClass == "batch") {
          element.cssClass = "open";
        }
      });
    }
  }

  ngOnInit() {
    if (
      this.locationsearchService.schdate != null &&
      this.locationsearchService.schdate != undefined
    ) {
      this.viewDate = this.locationsearchService.schdate;
    }

    if (
      this.locationsearchService.personcompany != null &&
      this.locationsearchService.personcompany != undefined
    ) {
      this.selectedpersoncompany = this.locationsearchService.personcompany;
    } else {
      this.selectedpersoncompany = null;
    }
    this.dataTransfer.myMethod(true);
    this.selectedWorkorderID = this.route.snapshot.paramMap.get("id");
    this.name = this.route.snapshot.paramMap.get("id2");
    this.group = this.route.snapshot.paramMap.get("group");
    this.subgroup = this.route.snapshot.paramMap.get("subgroup");
    this.subgroup1 = localStorage.getItem("subGroup1");
    this.fetchResources(this.selectedWorkorderID, 0);
  }

  selectSingleAppt(e, item) {

    this.disableLocationSearch = false;
    this.moveAllDisable = true;
    if (item.cssClass == "open") {
      item.cssClass = "batch";
    }

    let topersoncompany = item.personcompanyid;
    let currentDate = this.viewDate;
    this.disableLocationSearch = false;
    this.showMoveContext = false;
    this.assignmentID = item.id;
    localStorage.setItem('assignmentId', this.assignmentID);
    this.assignmentID = localStorage.getItem("assignmentId");
    this.frompersoncompany = item.meta.user.personcompanyid;
    this.moveSingleOpenAppt(e, item);
  }


  goToLocationSearch() {
    let className = localStorage.getItem("themeName");
    this.locationsearchService.setlocationparameters(
      this.viewDate,
      this.gridCode
    );
    const dialogRefs = this.dialog.open(LocationSearchComponent, {
      panelClass: className,
      width: "2600px",
      height: "600px",
    });
  }
  goToGoogleMap() {
    let className = localStorage.getItem("themeName");
    this.fetchResources(this.selectedWorkorderID);
    this.locationsearchService.setlocationparameters(
      this.viewDate,
      this.gridCode
    );
    this.googleMapsService.setcustomeraddress(this.serviceaddress);

    const dialogRefs = this.dialog.open(GoogleMapsComponent, {
      panelClass: className,
      width: "2600px",
      height: "600px",
    });


  }

  workorderClick(workorderID) {
    this.router.navigate([
      "/workorderGetSave",
      this.group,
      this.subgroup,
      workorderID,
      this.subgroup1 ?? "ALL"
    ]);
    return false;
  }

  customerClick(customerId) {
    this.customerService
      .getCustomerById(customerId)
      .subscribe((cust: Customer[]) => {
        this.navigateToCustomer(cust);
      });

    return false;
  }

  navigateToCustomer(cust) {
    const tempcust: Customer = new Customer();
    tempcust.setUpFromWS(cust);
    this.customerService.currentCustomer = tempcust;
    this.customerService.storedCustomers(tempcust);
    this.router.navigate(["/detail"]);
  }

  dateChanged(e) {
    this.users = [];


    this.dayStart = undefined;
    this.dayEnd = undefined;
    this.prevSDate = undefined;
    this.prevEDate = undefined;
    this.prevAdDateStart = undefined;
    this.prevAdDateEnd = undefined;
    this.loadedData = false;
    this.fetchResources(this.selectedWorkorderID, 1);

    this.getDailyInformation();

  }

  intervalChanged(interval) {
    this.selectedInterval = interval;
    this.getDailyInformation();
  }

  resourceChanged(e) {
    localStorage.setItem('selectedMaintainence', JSON.stringify(e.value))
    this.loadedData = false;
    this.dayStart = undefined;
    this.dayEnd = undefined;
    this.prevSDate = undefined;
    this.prevEDate = undefined;
    this.prevAdDateStart = undefined;
    this.prevAdDateEnd = undefined;
    if (this.selectedView == "day") {
      this.loadedData = true;
      this.getDailyInformation();

    } else if (this.selectedView == "week") {
      this.loadedData = true;
      this.getDailyInformation();

    }
  }

  calendarViewChanged(viewId) {
    this.dayStart = undefined;
    this.dayEnd = undefined;
    this.prevSDate = undefined;
    this.prevEDate = undefined;
    this.prevAdDateStart = undefined;
    this.prevAdDateEnd = undefined;
    this.selectedView = viewId;
    this.loadedData = false;
    this.fetchResources(this.selectedWorkorderID, 0);

    if (viewId == "day") {
      this.getDailyInformation();
    } else if (viewId == "week") {
      if (this.selectedResources[0] != undefined) {
        this.selectedUser = this.selectedResources[0].personcompanyid;
        //this.fetchResources(this.selectedWorkorderID);
        this.getDailyInformation();
      }
    }
  }

  getWeekEvents() {
    if (this.selectedUser != undefined) {
      this.events = [];
      let pickedDate = this.viewDate;
      let startSunday = new Date(pickedDate);
      startSunday.setDate(pickedDate.getDate() - pickedDate.getDay());
      let startDate = startSunday;
      let endSaturday = new Date(pickedDate);
      endSaturday.setDate(pickedDate.getDate() + (7 - pickedDate.getDay()));

      let endDate = endSaturday;
      this.scheduleService
        .getWeeklyAvailability(
          this.selectedUser,
          this.workorderID,
          this.grid,
          startDate.toDateString(),
          endDate.toDateString(),
          this.selectedInterval.value
        )
        .subscribe((res) => {
          this.loadedData = res;
          this.events = res.ResourceAvailability;

          this.events.forEach((element: any) => {
            element.start = new Date(
              moment(element.start).utc().format("MM/DD/YYYY HH:mm:ss")
            );
            element.end = new Date(
              moment(element.end).utc().format("MM/DD/YYYY HH:mm:ss")
            );
            let metaUser = this.users.filter(
              (x) => x.personcompanyid == element.meta.user.personcompanyid
            )[0];
            element.meta.user = metaUser;
          });

          this.events = [...this.events];
          this.searching = false;
        });
      this.refresh.next();
    }
  }

  fetchEvents() {
    this.events = [];
    let personCompanyArray = [];

    this.selectedResources.forEach((element) => {
      personCompanyArray.push(element.personcompanyid);
    });
    if (personCompanyArray.length > 0) {
      this.scheduleService
        .getResourceAvailability(
          this.viewDate.toDateString(),
          this.selectedInterval.value,
          this.grid,
          personCompanyArray,
          this.workorderID
        )
        .subscribe((res) => {
          this.loadedData = res;
          this.selectedResources = this.selectedResources;
          this.events = res.ResourceAvailability;
          this.events.forEach((element: any) => {
            element.start = new Date(
              moment(element.start).utc().format("MM/DD/YYYY HH:mm:ss")
            );
            element.end = new Date(
              moment(element.end).utc().format("MM/DD/YYYY HH:mm:ss")
            );
            let metaUser = this.selectedResources.filter(
              (x) => x.personcompanyid == element.meta.user.personcompanyid
            )[0];
            element.meta.user = metaUser;
          });
          this.searching = false;
        });
    }
    this.refresh.next();
  }

  private addSelectedDayViewClass(pcoid) {
    if (this.selectedView == "day") {
      this.hourColumns.users.forEach((element11) => {
        let index = this.hourColumns.users.findIndex(
          (x) => x.personcompanyid == pcoid
        );
        this.hourColumns.hourColumns[index].hours.forEach((hour) => {
          hour.segments.forEach((segment) => {
            if (segment.date.getTime() === this.selectedViewDate.getTime()) {
              if (!(segment.cssClass == "cal-day-selected")) {
                segment.cssClass = "cal-day-selected";
                this.prevSegment = segment;
              } else {
                delete segment.cssClass;
              }
            }
          });
        });
      });
    } else {
      this.hourColumns.hourColumns.forEach((column) => {
        column.hours.forEach((hourSegment) => {
          hourSegment.segments.forEach((segment) => {
            //   delete segment.cssClass;
            if (
              this.selectedViewDate &&
              segment.date.getTime() === this.selectedViewDate.getTime()
            ) {
              if (!(segment.cssClass == "cal-day-selected")) {
                segment.cssClass = "cal-day-selected";
                this.prevSegment = segment;
              } else {
                delete segment.cssClass;
              }
            }
          });
        });
      });
    }
    //
  }

  fetchResources(selectedWOID, load: number = 0) {
    let pcoids = [];

    if (this.selectedView == "day" && selectedWOID == null) {
      this.selectedResources.forEach((element) => {
        pcoids.push(element.personcompanyid);
      });
    } else if (this.selectedView == "week" && selectedWOID == null) {
      pcoids.push(this.selectedUser);
      // this.selectedUser = this.selectedResources[0].personcompanyid;
    }

    this.scheduleService
      .getResources(
        this.viewDate.toDateString(),
        load,
        this.grid,
        selectedWOID,
        this.selectedpersoncompany,
        pcoids
      )
      .subscribe((res) => {
        this.initialInfo = res;
        this.users = res.Resources;
        this.programDetailId = res.ProgramdetailId;
        localStorage.setItem('prgdetailid', this.programDetailId);
        //console.log('prgdetailid:',this.programDetailId);


        if (
          res.ScheduleSelectionDate != null &&
          res.ScheduleSelectionDate != undefined
        ) {
          this.viewDate = new Date(
            moment(res.ScheduleSelectionDate).utc().format("MM/DD/YYYY")
          );
        }

        if (this.selectedView == "day") {
          if (
            res.SelectedResources != null &&
            res.SelectedResources != undefined
          ) {
            this.selectedResources = [];

            res.SelectedResources.forEach((element) => {
              let metaUser = this.users.filter(
                (x) => x.personcompanyid == element.personcompanyid
              )[0];
              if (metaUser != null && metaUser != undefined) {
                this.selectedResources.push(metaUser);
              }
            });
            if (this.selectedResources.length > 0) {
              this.getDailyInformation();
            }
          }

          this.users.forEach((element) => {
            if (res.SelectedResources != undefined) {
              let user = res.SelectedResources.filter(
                (x) => x.personcompanyid == element.personcompanyid
              )[0];
              if (user != undefined && user != null) {
                let color = user.bkcolor;

                element.bkcolor = color;
              }
            }
          });
        }

        this.gridCode = res.Grid;
        this.workorderID = res.Workorderid;
        console.log(this.workorderID);
        this.customerName = res.Name;
        this.customerId = res.CustomerId;
        this.serviceaddress = res.Address;

        //console.log()
        this.customerAddress = res.Address + "/ Grid : " + res.Grid;
      });
  }

  handleEvent(action: string, event: CalendarEvent): void { }

  checkifPrevSegmentEnabled(e) {

    if (this.prevSegment != null && this.prevSegment != undefined) {
      delete this.prevSegment.cssClass;
      /*   this.contextmenuHidden = true; */
    }
    this.selectedViewDate = e.value.date;
    if (this.selectedView == "day") {
      this.addSelectedDayViewClass(e.value.personcompanyid);
    } else {
      this.addSelectedDayViewClass(this.selectedUser);
    }
  }

  addEvent(e, item): void {


    let user = {};
    let startDate = JSON.parse(JSON.stringify(item.date));
    let date = startDate;
    let endTime = new Date(date);
    if (this.selectedView == "day") {
      user = {
        id: item.id,
        name: item.name,
        personcompanyid: item.personcompanyid,
      };
    } else if (this.selectedView == "week") {
      user = {
        personcompanyid: this.selectedUser,
        name: this.initialInfo.Name,
      };
    }
    let className = localStorage.getItem("themeName");
    endTime.setMinutes(endTime.getMinutes() + this.selectedInterval.value);
    const dialogRef = this.dialog.open(SchedulerDialogComponent, {
      data: {
        weekEvent: {
          title: "",
          start: startDate,
          end: JSON.parse(JSON.stringify(endTime)),
          meta: {
            user: user,
          },
        },
        users: this.users,
        initialInfo: this.initialInfo,
        disableReasons: this.name == "schedule" ? false : true,
      },
      panelClass: className,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res.data) {
        this.fetchResources(this.selectedWorkorderID, 1);
        if (this.selectedView == "day") {
          this.fetchEvents();
        } else if (this.selectedView == "week") {
          this.getWeekEvents();
        }
        if (this.name == "schedule") {
          this.name = "reschedule";
          this.workorderService.workorderStatusCode = "reschedule";
          //this.router.navigate(['scheduling',this.group,this.subgroup,this.selectedWorkorderID,this.name])
        }
        /*    else {
          this.name = 'schedule';
          this.workorderService.workorderStatusCode = 'schedule'
        } */
      }
    });
  }


  moveSingleOpenAppt(event, item) {


    let currentDate = this.viewDate;
    this.disableLocationSearch = false;
    this.gridCode = "ALL";
    this.moveClicked = !this.moveClicked;
    let topersoncompany = null;
    topersoncompany = item.personcompanyid;
    console.log('topersoncompany:', topersoncompany);
    if (this.moveClicked) {
      topersoncompany = item.personcompanyid;
      if (this.selectedView == "day") {
        topersoncompany = item.personcompanyid;
      } else {
        currentDate = item.date;
        topersoncompany = this.selectedUser;

      }

      this.showMoveContext = true;
      this.spinner.show();
      this.scheduleService
        .singleMoveAppointment(
          topersoncompany,
          this.frompersoncompany,
          currentDate.toDateString(),
          this.utilityService.currentUtility.BBCSUserId,
          this.assignmentID,
        )
        .subscribe((res) => {
          console.log("res", res);
          if (res.Action == true) {

            this.spinner.hide();
            Swal.fire({
              text: res.Message,
              icon: "error",
              confirmButtonText: "OK",
            }).then((result) => {
              if (result.value) {
                this.dialog.closeAll();
                this.disableLocationSearch = true;
                if (this.selectedView == "day") {
                  this.fetchEvents();
                } else if (this.selectedView == "week") {
                  this.getWeekEvents();
                }
              }
            });
          } else {

            this.spinner.hide();
            Swal.fire({
              text: "Scheduled Successfully!",
              icon: "success",
              confirmButtonText: "OK",
            }).then((result) => {
              if (result.value) {
                this.disableLocationSearch = true;
                this.dialog.closeAll();
                if (this.selectedView == "day") {
                  this.fetchEvents();
                } else if (this.selectedView == "week") {
                  this.getWeekEvents();
                }
              }
            });
          }
        });
    }


  }

  moveAllOpenAppt(event, item) {

    this.moveSingleDisable = true;
    let currentDate = this.viewDate;
    this.disableLocationSearch = false;
    this.gridCode = "ALL";
    this.moveClicked = !this.moveClicked;
    let topersoncompany = null;
    if (this.moveClicked) {
      if (this.selectedView == "day") {
        topersoncompany = item.personcompanyid;
      } else {
        currentDate = item.date;
        topersoncompany = this.selectedUser;
      }
      this.showMoveContext = true;
      this.spinner.show();
      this.scheduleService
        .batchMoveAppointment(
          topersoncompany,
          this.frompersoncompany,
          "ALL",
          this.prevViewDate.toDateString(),
          currentDate.toDateString(),
          this.utilityService.currentUtility.BBCSUserId
        )
        .subscribe((res) => {
          if (res.Action == true) {
            this.spinner.hide();
            Swal.fire({
              text: res.Message,
              icon: "error",
              confirmButtonText: "OK",
            }).then((result) => {
              if (result.value) {
                this.dialog.closeAll();
                this.disableLocationSearch = true;
                if (this.selectedView == "day") {
                  this.fetchEvents();
                } else if (this.selectedView == "week") {
                  this.getWeekEvents();
                }
              }
            });
          } else {
            this.spinner.hide();
            Swal.fire({
              text: "Scheduled Successfully!",
              icon: "success",
              confirmButtonText: "OK",
            }).then((result) => {
              if (result.value) {
                this.disableLocationSearch = true;
                this.dialog.closeAll();
                if (this.selectedView == "day") {
                  this.fetchEvents();
                } else if (this.selectedView == "week") {
                  this.getWeekEvents();
                }
              }
            });
          }
        });
    } else {
      // item.cssClass = "batch";
      this.showMoveContext = false;

      if (this.selectedView == "day") {
        this.prevViewDate = this.viewDate;
      } else {
        this.prevViewDate = item.start;
      }
      this.frompersoncompany = item.meta.user.personcompanyid;
      this.events.forEach((element) => {
        if (
          element.meta.user.personcompanyid == this.frompersoncompany &&
          element.cssClass == "open" &&
          element.start.toDateString() == item.start.toDateString()
        ) {
          element.cssClass = "batch";
        }
      });
    }
  }

  cancelSingleAppt(event, item) {


    if (item.draggable) {
      let className = localStorage.getItem("themeName");
      const dialogRef = this.dialog.open(CancelscheduleDialogComponent, {
        data: {
          workorderID: this.workorderID,
          scheduleDate: this.viewDate,
          personcompany: item.meta.user.personcompanyid,
        },
        panelClass: className,
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res.data) {
          this.name = "schedule";
          this.workorderService.workorderStatusCode = "schedule";
          this.router.navigate([
            "scheduling",
            this.group,
            this.subgroup,
            this.workorderID,
            this.subgroup1 ?? "ALL",
            this.name,
          ]);
        }
      });
    } else {
      Swal.fire({
        text: "Only current workorder can be cancelled!",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }



  cancelAllOpenAppt(event, item) {

    let className = localStorage.getItem("themeName");
    const dialogRef = this.dialog.open(CancelscheduleDialogComponent, {
      data: {
        workorderID: this.workorderID,
        scheduleDate: this.viewDate,
        personcompany: item.meta.user.personcompanyid,
      },
      panelClass: className,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res.data) {
        if (this.selectedView == "day") {
          this.fetchEvents();
        } else if (this.selectedView == "week") {
          this.getWeekEvents();
        }
      }
    });
  }

  getDailyInformation() {
    let personCompanyArray = [];
    this.dailyInfo = [];
    let startDate = this.viewDate;
    let endDate = this.viewDate;
    if (this.selectedView == "day") {
      this.selectedResources.forEach((element) => {
        personCompanyArray.push(element.personcompanyid);
      });
    } else {
      let pickedDate = this.viewDate;
      let startSunday = new Date(pickedDate);
      startSunday.setDate(pickedDate.getDate() - pickedDate.getDay());
      startDate = startSunday;
      let endSaturday = new Date(pickedDate);
      endSaturday.setDate(pickedDate.getDate() + (7 - pickedDate.getDay()));
      endDate = endSaturday;
      if (this.selectedUser != undefined) {
        personCompanyArray.push(this.selectedUser);
      }
    }

    if (personCompanyArray.length > 0) {
      this.scheduleService
        .getDailyInformation(
          this.grid,
          personCompanyArray,
          this.selectedInterval.value,
          startDate.toDateString(),
          endDate.toDateString()
        )
        .subscribe((res) => {
          this.dailyInfo = res.ResourcesTimings;
          console.log(this.dailyInfo);
          this.loadedData = true;
          if (this.selectedView == "day") {
            this.loadedData = true;
            this.fetchEvents();
          } else if (this.selectedView == "week") {
            this.loadedData = true;
            this.getWeekEvents();
          }
        });
    }
  }

  checkTheGreatest(sDate, eDate, sAdDate, eAdDate) {
    let tempStart = undefined;
    let tempEnd = undefined;
    if (this.businessClicked == true) {
      tempStart = new Date(moment(sDate).utc().format("MM/DD/YYYY HH:mm:ss"));
      tempEnd = new Date(moment(eDate).utc().format("MM/DD/YYYY HH:mm:ss"));
      let adDateStart = new Date(
        moment(sAdDate).utc().format("MM/DD/YYYY HH:mm:ss")
      );
      let adDateEnd = new Date(
        moment(eAdDate).utc().format("MM/DD/YYYY HH:mm:ss")
      );

      if (this.prevSDate == undefined && this.prevEDate == undefined) {
        this.prevSDate = tempStart.getHours();
        this.prevEDate = tempEnd.getHours();
      }
      if (this.prevSDate >= tempStart.getHours()) {
        this.dayStart = tempStart.getHours();
        this.prevSDate = tempStart.getHours();
      }
      if (this.prevEDate <= tempEnd.getHours()) {
        this.dayEnd = tempEnd.getHours();
        this.prevEDate = tempEnd.getHours();
      }
      if (sAdDate != null && eAdDate != null) {
        if (adDateStart.getHours() <= tempStart.getHours()) {
          this.dayStart = adDateStart.getHours();
          if (this.prevAdDateStart <= adDateStart.getHours()) {
            this.dayStart = this.prevAdDateStart;
          }
        }
        if (adDateEnd.getHours() >= tempEnd.getHours()) {
          this.dayEnd = adDateEnd.getHours();
          if (this.prevAdDateEnd >= adDateEnd.getHours()) {
            this.dayEnd = this.prevAdDateEnd;
          }
        }
      }
      //   }
      this.prevAdDateStart = adDateStart.getHours();
      this.prevAdDateEnd = adDateEnd.getHours();
      /*    this.prevSDate = tempStart;
         this.prevEDate = tempEnd; */
    } else {
      this.dayStart = 0;
      this.dayEnd = 24;
    }
  }

  beforeViewRender(body) {
    this.hourColumns = body;

    if (
      this.selectedView == "day" &&
      this.dailyInfo.length > 0 &&
      this.dailyInfo != undefined
    ) {
      body.users.forEach((element11) => {
        this.dailyInfo.forEach((element) => {
          if (
            element?.DayStart != null &&
            element?.DayStart != undefined &&
            element?.DayEnd != null &&
            element?.DayEnd != undefined
          ) {
            let index = body.users.findIndex(
              (x) => x.personcompanyid == element.PersoncompanyId
            );
            let sDate = new Date(
              moment(element.DayStart).utc().format("MM/DD/YYYY HH:mm:ss")
            );
            let eDate = new Date(
              moment(element.DayEnd).utc().format("MM/DD/YYYY HH:mm:ss")
            );

            if (element.AdditionalHours.length <= 0) {
              this.checkTheGreatest(
                element.DayStart,
                element.DayEnd,
                null,
                null
              );
              body.hourColumns[index].hours.forEach((hour) => {
                hour.segments.forEach((segment) => {
                  if (segment.date < sDate || segment.date >= eDate) {
                    segment.cssClass = "cal-disabled";
                  }
                });
              });
            } else {
              body.hourColumns.forEach((hourCol) => {
                hourCol.hours.forEach((hour) => {
                  hour.segments.forEach((segment) => {
                    element.AdditionalHours.forEach((addHours) => {
                      let sAdDate = new Date(
                        moment(addHours.AddHoursStart)
                          .utc()
                          .format("MM/DD/YYYY HH:mm:ss")
                      );
                      let eAdDate = new Date(
                        moment(addHours.AddHoursEnd)
                          .utc()
                          .format("MM/DD/YYYY HH:mm:ss")
                      );
                      this.checkTheGreatest(
                        element.DayStart,
                        element.DayEnd,
                        addHours.AddHoursStart,
                        addHours.AddHoursEnd
                      );
                      if (
                        (sAdDate < sDate &&
                          eAdDate < sDate &&
                          segment.date < sDate) ||
                        (sAdDate > eDate &&
                          eAdDate > eDate &&
                          segment.date >= eDate)
                      ) {
                        if (
                          !(segment.date >= sAdDate && segment.date < eAdDate)
                        ) {
                          segment.cssClass = "cal-disabled";
                        }
                      }
                    });
                  });
                });
              });
            }
          }
        });
      });
    } else if (
      this.selectedView == "week" &&
      this.dailyInfo.length > 0 &&
      this.dailyInfo != undefined
    ) {
      this.dailyInfo[0].forEach((element, index) => {
        //let index = body.users.findIndex(x => x.personcompanyid == element.PersoncompanyId);
        let sDate = new Date(
          moment(element?.DayStart).utc().format("MM/DD/YYYY HH:mm:ss")
        );
        let eDate = new Date(
          moment(element?.DayEnd).utc().format("MM/DD/YYYY HH:mm:ss")
        );

        if (element?.AdditionalHours.length <= 0) {
          this.checkTheGreatest(element?.DayStart, element?.DayEnd, null, null);
          body.hourColumns[index].hours.forEach((hour) => {
            hour.segments.forEach((segment) => {
              if (segment.date < sDate || segment.date >= eDate) {
                segment.cssClass = "cal-disabled";
              }
            });
          });
        } else {
          body.hourColumns.forEach((hourCol) => {
            hourCol.hours.forEach((hour) => {
              hour.segments.forEach((segment) => {
                element.AdditionalHours.forEach((addHours) => {
                  this.checkTheGreatest(
                    element.DayStart,
                    element.DayEnd,
                    addHours.AddHoursStart,
                    addHours.AddHoursEnd
                  );
                  let sAdDate = new Date(
                    moment(addHours.AddHoursStart)
                      .utc()
                      .format("MM/DD/YYYY HH:mm:ss")
                  );
                  let eAdDate = new Date(
                    moment(addHours.AddHoursEnd)
                      .utc()
                      .format("MM/DD/YYYY HH:mm:ss")
                  );
                  if (
                    (sAdDate < sDate &&
                      eAdDate < sDate &&
                      segment.date < sDate) ||
                    (sAdDate > eDate &&
                      eAdDate > eDate &&
                      segment.date >= eDate)
                  ) {
                    if (!(segment.date >= sAdDate || segment.date < eAdDate)) {
                      segment.cssClass = "cal-disabled";
                    }
                  }
                });
              });
            });
          });
        }
      });
    }
  }

  hourChanged(hourKey) {
    if (hourKey == "full") {
      this.businessClicked = false;
      this.getDailyInformation();
    } else {
      this.businessClicked = true;
      this.getDailyInformation();
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    let user = {};
    if (this.selectedView == "week") {
      user = {
        personcompanyid: this.selectedUser,
        name: this.initialInfo.Name,
      };
      event.meta.user = user;
    }
    let className = localStorage.getItem("themeName");
    event.start = newStart;
    event.end = newEnd;
    this.events = [...this.events];
    const dialogRef = this.dialog.open(SchedulerDialogComponent, {
      data: {
        weekEvent: event,
        users: this.users,
        initialInfo: this.initialInfo,
        disableReasons: true,
      },
      panelClass: className,
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.fetchResources(this.selectedWorkorderID, 1);
      if (this.selectedView == "day") {
        this.fetchEvents();
      } else if (this.selectedView == "week") {
        this.getWeekEvents();
      }
    });
  }

  hourSegmentClicked(e) {
    this.selectedViewDate = e.data.date;
    let pcoid = null;

    if (this.selectedView == "day") {
      pcoid = e.data.personcompanyid;
    }
    this.addSelectedDayViewClass(pcoid);
  }

  userChanged({ event, newUser }) {
    event.color = newUser.color;
    event.meta.user = newUser;
    this.events = [...this.events];
  }
}
