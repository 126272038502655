import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilityService } from './utility.service';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SchedulingService {

  constructor(private http: HttpClient, private utilityService: UtilityService) { }

  public getHeaders() {
    const email = localStorage.getItem('loggedInUserEmail');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-email': email,
        'x-programsponsorid': this.utilityService.currentUtility.ProgramsponsorId
      })
    };
    return httpOptions;
  }

  getDailyInformation(orgCode, selectedResources, increment, startDate, endDate) {

    let infoObj = {
      startDate: startDate,
      endDate: endDate,
      increment: increment,
      personcompanyids: selectedResources,
      workorderId: null
    }


    const searchUrl = environment.apiUrl + "workorder/individual/dailyInformation/" + this.utilityService.currentUtility.OfficeId + "/organization/" + orgCode + "?code=" + environment.apiCode;

    return this.http.post<any[]>(searchUrl, infoObj, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );

  }

  batchMoveAppointment(toPersoncompany, fromPersoncompany, orgCode, fromDate, toDate, user) {
    let scheduleObj = {
      toPCO: toPersoncompany,
      fromDate: fromDate,
      toDate: toDate,
      user: user,
      increment: "30"
    }

    const searchUrl = environment.apiUrl + "workorder/shedule/moveappointments/" + this.utilityService.currentUtility.OfficeId + "/organization/" + orgCode + "/personcompany/" + fromPersoncompany + "?code=" + environment.apiCode;


    return this.http.post<any[]>(searchUrl, scheduleObj, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  singleMoveAppointment(toPersoncompany, orgCode, toDate, user, assignmentId) {
    let scheduleObj = {
      toPCO: toPersoncompany,
      toDate: toDate,
      user: user,
    }

    const searchUrl = environment.apiUrl + "workorder/shedule/movesingleappointment/" + this.utilityService.currentUtility.OfficeId + "/organization/" + orgCode + "/assignment/" + assignmentId + "?code=" + environment.apiCode;


    return this.http.post<any[]>(searchUrl, scheduleObj, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  getResources(scheduledate, increment, orgCode, workorderID, personcompanyid, pcoids) {
    let scheduleObj = {
      scheduleDate: scheduledate,
      increment: increment,
      workorderId: workorderID,
      personcompanyId: personcompanyid,
      pcoids: pcoids
    }

    console.log(scheduleObj);
    const searchUrl = environment.apiUrl + "workorder/individual/dailyschedule/" + this.utilityService.currentUtility.OfficeId + "/organization/" + orgCode + "?code=" + environment.apiCode;
    console.log(searchUrl);


    return this.http.post<any[]>(searchUrl, scheduleObj, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  cancelOpenAppointments(scheduleDate, personcompany, reason, orgCode) {

    const searchUrl = environment.apiUrl + "workorder/shedule/cancelappointments/" + this.utilityService.currentUtility.OfficeId + "/organization/" + orgCode + "/personcompany/" + personcompany + "?code=" + environment.apiCode;

    let scheduleObj = {
      scheduleDate: scheduleDate,
      reason: reason,
      user: this.utilityService.currentUtility.BBCSUserId
    }

    return this.http.post<any[]>(searchUrl, scheduleObj, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  getWeeklyAvailability(personCompanyId, workorderId, orgCode, scheduleStartDate, scheduleEndDate, increment) {

    let scheduleObj = {
      "startdate": scheduleStartDate,
      "enddate": scheduleEndDate,
      "increment": increment,
      "workorderId": workorderId
    }

    const searchUrl = environment.apiUrl + "workorder/individual/weeklyavailability/" + this.utilityService.currentUtility.OfficeId + "/personcompany/" + personCompanyId + "/organization/" + orgCode + "?code=" + environment.apiCode;


    return this.http.post<any[]>(searchUrl, scheduleObj, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  addSchedulingEvent(bodyObj, workorderID, personcompanyid) {

    const searchUrl = environment.apiUrl + "workorder/schedule/" + this.utilityService.currentUtility.OfficeId + "/workorder/" + workorderID + "/personcompany/" + personcompanyid + "?code=" + environment.apiCode;


    return this.http.post<any[]>(searchUrl, bodyObj, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );

  }

  getCancelScheduleReasons() {
    const searchUrl = environment.apiUrl + "workorder/appointments/reschedulecancel/reasons/" + this.utilityService.currentUtility.OfficeId + "?code=" + environment.apiCode;


    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  //to call the compass schedule appointment
  getCompassScheduleAppt(workorderId, userId) {

    const searchUrl = environment.apiUrl + "CompassIntegration/schedule/" + this.utilityService.currentUtility.OfficeId + "/workorder/" + workorderId + "/user/" + userId + "?code=" + environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );

  }
  //to call the compass schedule appointment -END

  //to call the compass Reschedule appointment
  getCompassReScheduleAppt(workorderId, userId, jobId) {

    const searchUrl = environment.apiUrl + "CompassIntegration/reschedule/" + this.utilityService.currentUtility.OfficeId + "/workorder/" + workorderId + "/user/" + userId + "/" + jobId + "?code=" + environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );

  }
  //to call the compass Reschedule appointment -END

  //to call the compass cancel scheduled appoitment
  getCompassCancelScheduleAppointment(workorderId, userId, jobId) {
    const searchUrl = environment.apiUrl + "CompassIntegration/cancelschedule/" + this.utilityService.currentUtility.OfficeId + "/workorder/" + workorderId + "/user/" + userId + "/" + jobId + "?code=" + environment.apiCode;
    console.log("URL", searchUrl);
    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );

  }
  //to call the compass cancel scheduled appointment -END


  cancelSchedule(workorderID, reason) {
    //console.log("testCancelProgramDetailId", localStorage.getItem('programdetailid'));
    let cancelObj = {
      user: this.utilityService.currentUtility.BBCSUserId,
      reason: reason,
      ProgramdetailId: localStorage.getItem('programdetailid')
    }

    const searchUrl = environment.apiUrl + "workorder/unschedule/" + this.utilityService.currentUtility.OfficeId + "/workorder/" + workorderID + "?code=" + environment.apiCode;


    return this.http.post<any[]>(searchUrl, cancelObj, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );

  }

  getResourceAvailability(scheduledate, increment, orgCode, personcompanyIds, workorderId) {

    let scheduleObj = {
      scheduledate: scheduledate,
      increment: increment,
      personcompanyids: personcompanyIds,
      workorderId: workorderId
    }

    console.log(scheduleObj);

    const searchUrl = environment.apiUrl + "workorder/individual/dailyavailability/" + this.utilityService.currentUtility.OfficeId + "/organization/" + orgCode + "?code=" + environment.apiCode;


    return this.http.post<any[]>(searchUrl, scheduleObj, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }
}
