<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#ff4081" type="timer" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div style="display: flex;">
    <mat-card class="user-card" style="height: 350px;">
        <div style="display:flex">
            <div>
                <mat-card-title style="font-size: 16px;">
                    <div matBadge="{{dataAging.length > 0 && detailAging.length == 0 ? dataAging.length : detailAging.length}}"
                        matBadgeOverlap="false" class="demo-section">Workorder Aging</div>
                </mat-card-title>
            </div>
            <div style="flex-grow: 1; display: grid;">
                <span *ngIf="selectedRecord != ''" style="text-align: center;">{{selectedRecord}}</span>
            </div>
            <button mat-flat-button color="primary" style="margin-bottom: 5px;" type="submit"
                *ngIf="detailAging.length > 0" (click)="refresh()">Back</button>
        </div>
        <mat-divider></mat-divider>

        <br />

        <div style="height: 300px;overflow-y: scroll;">
            <table *ngIf="dataAging.length > 0 && detailAging.length == 0" class="mat-elevation-z8" id="gridvalue">
                <thead>
                    <tr>
                        <th class="mat-header-cell" style="text-align: left; padding: 10px !important;"
                            *ngFor="let head of dataAging[0] | keyvalue : originalOrder">
                            <span *ngIf="head.key != 'Status_Cd' && head.key != 'Programdetail_Id'"> {{head.key}}
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of dataAging">
                        <td *ngFor="let list of item | keyvalue : originalOrder" (click)="getAgingDetail(item)"
                            style="cursor: pointer;">
                            <span *ngIf="list.key != 'Status_Cd' && list.key != 'Programdetail_Id'"> {{item[list.key]}}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table *ngIf="detailAging.length > 0" class="mat-elevation-z8">
                <thead>
                    <tr>
                        <th class="mat-header-cell" style="text-align: left; padding: 10px !important;"
                            *ngFor="let head of detailAging[0] | keyvalue : originalOrder">
                            <span
                                *ngIf="head.key != 'EnrollmentId' && head.key != 'GroupId' && head.key != 'SubGroupId' && head.key != 'SubGroup1'">
                                {{head.key}} </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of detailAging">
                        <td *ngFor="let list of item | keyvalue : originalOrder"
                            (click)="handleEnrollmentWorkorderClick(item)" style="cursor: pointer;">
                            <span
                                *ngIf="list.key != 'EnrollmentId' && list.key != 'GroupId' && list.key != 'SubGroupId' && list.key != 'SubGroup1'">
                                {{item[list.key]}}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </mat-card>

    <mat-card class="user-card" style="height: 350px; overflow: scroll;">
        <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [legend]="true" [type]="'bar'">
        </canvas>
    </mat-card>
</div>

<div style="display: flex;">
    <mat-card class="user-card" style="height: 350px;">
        <div style="display:flex">
            <div>
                <mat-card-title style="font-size: 16px;">
                    <div matBadge="{{dataExceptionSummary.length > 0 && detailSummary.length == 0 ? dataExceptionSummary.length : detailSummary.length}}"
                        matBadgeOverlap="false">Workorder Exception Summary</div>
                </mat-card-title>
            </div>
            <div style="flex-grow: 1; display: grid;">
                <span *ngIf="selectedException != ''" style="text-align: center;">{{selectedException}}</span>
            </div>
            <button mat-flat-button color="primary" style="margin-bottom: 5px;" type="submit"
                *ngIf="detailSummary.length > 0" (click)="refreshSummary()">Back</button>
        </div>
        <mat-divider></mat-divider>
        <br />

        <div style="height: 300px;overflow-y: scroll;">
            <table *ngIf="dataExceptionSummary.length > 0 && detailSummary.length == 0" class="mat-elevation-z8"
                id="exceptiongridvalue" #tableContainer>
                <thead>
                    <tr>
                        <th class="mat-header-cell" style="text-align: left; padding: 10px !important;"
                            *ngFor="let head of dataExceptionSummary[0] | keyvalue : originalOrder">
                            <span *ngIf="head.key != 'ExceptionCd'"> {{head.key}} </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of dataExceptionSummary; let index = index"
                        [class.curr-cust]="index == selectedRowIndex">
                        <td *ngFor="let list of item | keyvalue : originalOrder;" (click)="getSummaryDetail(item)">
                            <span *ngIf="list.key != 'ExceptionCd'"> {{item[list.key]}}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table *ngIf="detailSummary.length > 0" class="mat-elevation-z8">
                <thead>
                    <tr>
                        <th class="mat-header-cell" style="text-align: left; padding: 10px !important;"
                            *ngFor="let head of detailSummary[0] | keyvalue : originalOrder">
                            <span
                                *ngIf="head.key != 'Exception' && head.key != 'EnrollmentId' && head.key != 'GroupId' && head.key != 'SubGroupId' && head.key != 'SubGroup1'">
                                {{head.key}} </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of detailSummary">
                        <td *ngFor="let list of item | keyvalue : originalOrder"
                            (click)="handleEnrollmentWorkorderClick(item)" style="cursor: pointer;">
                            <span
                                *ngIf="list.key != 'Exception' && list.key != 'EnrollmentId' && list.key != 'GroupId' && list.key != 'SubGroupId' && list.key != 'SubGroup1'">
                                {{item[list.key]}}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </mat-card>
    <mat-card class="user-card" style="height: 350px; overflow: scroll;">
        <canvas baseChart [datasets]="barChartDataSummary" [labels]="barChartLabelSummary" [legend]="true"
            [type]="'bar'" [options]="chartOptions" (chartClick)="chartClicked($event)">
        </canvas>
    </mat-card>
</div>

<div style="display: flex;">
    <mat-card class="user-card" style="height: 350px;">
        <div style="display:flex">
            <div>
                <mat-card-title style="font-size: 16px;">
                    <div matBadge="{{installerProd.length}}" matBadgeOverlap="false">Installer Production</div>
                </mat-card-title>
            </div>
        </div>
        <mat-divider></mat-divider>
        <br />

        <div style="height: 300px;overflow-y: scroll;">
            <table *ngIf="installerProd.length > 0" class="mat-elevation-z8" id="installergridvalue">
                <thead>
                    <tr>
                        <th class="mat-header-cell" style="text-align: left; padding: 10px !important;"
                            *ngFor="let head of installerProd[0] | keyvalue : originalOrder">
                            <span *ngIf="head.key != 'Org_cd' && head.key != 'Personcompany_Id'"> {{head.key}} </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of installerProd; let index = index">
                        <td *ngFor="let list of item | keyvalue : originalOrder;">
                            <span *ngIf="list.key != 'Org_cd' && list.key != 'Personcompany_Id'"> {{item[list.key]}}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </mat-card>
    <mat-card class="user-card" style="height: 350px; overflow: scroll;">
        <canvas baseChart [datasets]="barChartInstaller" [labels]="barChartLabelInstaller" [legend]="true"
            [type]="'bar'" [options]="chartOptions">
        </canvas>
    </mat-card>
</div>

<div style="display: flex;">
    <mat-card class="user-card" style="height: 350px;">
        <div style="display:flex">
            <div>
                <mat-card-title style="font-size: 16px;">
                    <div matBadge="{{dataHoldReasonSummary.length > 0 && detailHoldReasonSummary.length == 0 ? dataHoldReasonSummary.length : detailHoldReasonSummary.length}}"
                        matBadgeOverlap="false">Hold Reason Summary</div>
                </mat-card-title>
            </div>
            <div style="flex-grow: 1; display: grid;">
                <span *ngIf="selectedReason != ''" style="text-align: center;">{{selectedReason}}</span>
            </div>
            <button mat-flat-button color="primary" style="margin-bottom: 5px;" type="submit"
                *ngIf="detailHoldReasonSummary.length > 0" (click)="refreshReasonSummary()">Back</button>
        </div>
        <mat-divider></mat-divider>
        <br />

        <div style="height: 300px;overflow-y: scroll;">
            <table *ngIf="dataHoldReasonSummary.length > 0 && detailHoldReasonSummary.length == 0" class="mat-elevation-z8"
                id="reasongridvalue" #tableContainerReason>
                <thead>
                    <tr>
                        <th class="mat-header-cell" style="text-align: left; padding: 10px !important;"
                            *ngFor="let head of dataHoldReasonSummary[0] | keyvalue : originalOrder">
                            <span *ngIf="head.key != 'Reason_cd'"> {{head.key}} </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of dataHoldReasonSummary; let index = index"
                        [class.curr-cust]="index == selectedRowIndex">
                        <td *ngFor="let list of item | keyvalue : originalOrder;" (click)="getHoldReasonDetail(item)">
                            <span *ngIf="list.key != 'Reason_cd'"> {{item[list.key]}}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table *ngIf="detailHoldReasonSummary.length > 0" class="mat-elevation-z8">
                <thead>
                    <tr>
                        <th class="mat-header-cell" style="text-align: left; padding: 10px !important;"
                            *ngFor="let head of detailHoldReasonSummary[0] | keyvalue : originalOrder">
                            <span
                                *ngIf="head.key != 'ReasonDesc' && head.key != 'EnrollmentId' && head.key != 'GroupId' && head.key != 'SubGroupId' && head.key != 'SubGroup1'">
                                {{head.key}} </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of detailHoldReasonSummary">
                        <td *ngFor="let list of item | keyvalue : originalOrder"
                            (click)="handleEnrollmentWorkorderClick(item)" style="cursor: pointer;">
                            <span
                                *ngIf="list.key != 'ReasonDesc' && list.key != 'EnrollmentId' && list.key != 'GroupId' && list.key != 'SubGroupId' && list.key != 'SubGroup1'">
                                {{item[list.key]}}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </mat-card>
    <mat-card class="user-card" style="height: 350px; overflow: scroll;">
        <canvas baseChart [datasets]="barChartDataReason" [labels]="barChartLabelReason" [legend]="true"
            [type]="'bar'" [options]="chartOptions" (chartClick)="chartReasonClicked($event)">
        </canvas>
    </mat-card>
</div>