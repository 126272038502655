<h5 mat-dialog-title>Add Schedule Bucket</h5>
<mat-divider></mat-divider>
<div mat-dialog-content style="width: 100vh;">
  <mat-card class="user-card">
    <mat-card-content>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Workorder Type</mat-label>
        <mat-select [(ngModel)]="workorderType" [disabled]="webtimeslotsetup_id !== null">
          <mat-option *ngFor="let wtype of indService.indDetail.BucketSchedulingTab.WORKORDERTYPELIST" [value]="wtype" style="font-size: 11px;">
            {{wtype.DESCRIPTION}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Grid</mat-label>
        <mat-select [(ngModel)]="gridType" [disabled]="webtimeslotsetup_id !== null" >
          <mat-option *ngFor="let gtype of indService.indDetail.BucketSchedulingTab.GRIDLIST" [value]="gtype" style="font-size: 11px;">
            {{gtype.DESCRIPTION}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <br />

      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Timeslot</mat-label>
        <input matInput placeholder="Timeslot" [(ngModel)]="timeslot" [disabled]="webtimeslotsetup_id !== null">
      </mat-form-field>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Appointment Quantity</mat-label>
        <input matInput placeholder="Appt Qty" type="number" [(ngModel)]="apptQty" [disabled]="webtimeslotsetup_id !== null" >
      </mat-form-field>
      <br>

      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="spicker" [(ngModel)]="startDate" [disabled]="webtimeslotsetup_id !== null">
        <mat-datepicker-toggle matSuffix [for]="spicker"></mat-datepicker-toggle>
        <mat-datepicker #spicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="epicker" [(ngModel)]="endDate" [disabled]="webtimeslotsetup_id !== null">
        <mat-datepicker-toggle matSuffix [for]="epicker"></mat-datepicker-toggle>
        <mat-datepicker #epicker></mat-datepicker>
      </mat-form-field>
      <br />

      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Start Time</mat-label>
        <input matInput [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" [(ngModel)]="apptstarttime" [disabled]="webtimeslotsetup_id !== null">
        <owl-date-time [pickerType]="'timer'" #dt1></owl-date-time>
      </mat-form-field>

      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>End Time</mat-label>
        <input matInput [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2" [(ngModel)]="apptendtime" [disabled]="webtimeslotsetup_id !== null">
        <owl-date-time [pickerType]="'timer'" #dt2></owl-date-time>
      </mat-form-field>

      <br />

      <mat-checkbox [(ngModel)]="sunday" style="margin-right: 20px;"  [disabled]="webtimeslotsetup_id !== null">Sun</mat-checkbox>
      <mat-checkbox [(ngModel)]="monday" style="margin-right: 20px;" [disabled]="webtimeslotsetup_id !== null">Mon</mat-checkbox>
      <mat-checkbox [(ngModel)]="tuesday" style="margin-right: 20px;" [disabled]="webtimeslotsetup_id !== null">Tues</mat-checkbox>
      <mat-checkbox [(ngModel)]="wednesday" style="margin-right: 20px;" [disabled]="webtimeslotsetup_id !== null">Wed</mat-checkbox>
      <mat-checkbox [(ngModel)]="thursday" style="margin-right: 20px;" [disabled]="webtimeslotsetup_id !== null">Thur</mat-checkbox>
      <mat-checkbox [(ngModel)]="friday" style="margin-right: 20px;" [disabled]="webtimeslotsetup_id !== null">Fri</mat-checkbox>
      <mat-checkbox [(ngModel)]="saturday" style="margin-right: 20px;" [disabled]="webtimeslotsetup_id !== null">Sat</mat-checkbox>
      <br />
      <br/>

      <mat-checkbox [(ngModel)]="modifiable" style="margin-right: 20px;" [disabled]="webtimeslotsetup_id !== null">Modifiable</mat-checkbox>
      <mat-checkbox [(ngModel)]="deactivated" style="margin-right: 20px;" [disabled]="webtimeslotsetup_id !== null && deactivated == true" >Deactivate Slot</mat-checkbox>
      <br />
      <br/>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="saveBucket()"
        [disabled]="workorderType === undefined || gridType === undefined || timeslot === undefined
        || apptQty === undefined || startDate === undefined || endDate === undefined || apptstarttime === undefined || apptendtime === undefined">SAVE</button>
      <button mat-raised-button color="primary" [mat-dialog-close]>CANCEL</button>
    </mat-card-actions>
  </mat-card>
</div>
