import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DistributorService } from "src/app/services/distributor.service";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";

@Component({
  selector: "app-company",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.scss"],
})
export class CompanyComponent implements OnInit {
  companyName = new UntypedFormControl("");
  companyAbbr = new UntypedFormControl("", [Validators.maxLength(15)]);
  WorkResource;

  constructor(
    public dialogRef: MatDialogRef<CompanyComponent>,
    public distributorService: DistributorService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
  }

  badData() {
    return this.companyAbbr.invalid || this.companyName.invalid;
  }

  reset() {
    this.companyName.reset();
    this.companyAbbr.reset();
  }

  closedialog() {
    this.dialogRef.close();
  }

  saveCompany() {
    const company = {
      CompanyName: this.companyName.value,
      CompanyAbbr: this.companyAbbr.value,
      WorkResource: this.WorkResource,
    };
    this.spinner.show();
    this.distributorService.saveCompany(company).subscribe((data) => {
      if (data.Action) {
        this.distributorService
          .newDetail(
            this.distributorService.selectedUtility,
            this.distributorService.selectedDistributorCode
          )
          .subscribe((details) => {
            this.spinner.hide();
            Swal.fire({
              text: data.Message,
              icon: "success",
              confirmButtonText: "OK",
            });
            this.dialogRef.close(details);
          });
        this.reset();
      } else
        Swal.fire({
          title: "Error",
          text: data.Message,
          icon: "error",
          confirmButtonText: "OK",
        });
      this.spinner.hide();
    });
  }
}
