import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Office } from 'src/app/models/office';
import Swal from 'sweetalert2';
import { WaitDialogComponent } from '../../util/wait-dialog/wait-dialog.component';

@Component({
    selector: 'app-office-detail',
    templateUrl: './office-detail.component.html',
    styleUrls: ['./office-detail.component.scss']
})
export class OfficeDetailComponent implements OnInit {

    selectedProgramsponsor: any;
    pSponsors = [];
    officeData: Office;
    officeActive: boolean;
    officeDescription: any;
    IfMandatory: boolean = false;
    errorMessage:any;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router, private userService: UserService, private dialog: MatDialog) { }

    ngOnInit() {
        this.officeData = this.data.dataKey;
        this.officeActive = this.officeData.OfficeActive;
        this.officeDescription = this.officeData.OfficeDescription;
        this.userService.getProgramSponsors().subscribe(res => {
            this.pSponsors = res;
            this.selectedProgramsponsor = this.officeData.ProgramSponsorId;
        })
    }

    checkAllFieldsAreEmpty() {

        if ((this.officeDescription == null || this.officeDescription == undefined||this.officeDescription=="")) {
            this.IfMandatory = true;
        }
        else {
            this.IfMandatory = false;
        }
    }

    saveOffice() {
        Swal.fire({
            title: 'Save Changes?',
            text: "Changing this update your configuration.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Save Changes'
        }).then((result) => {
            if (result.value) {
                this.doSaveOffice();
            }
        });
    }

    private doSaveOffice() {
        this.checkAllFieldsAreEmpty();

        if (!this.IfMandatory) {
            let className = localStorage.getItem("themeName");
            const dialogRef = this.dialog.open(WaitDialogComponent, {data: {caption: 'Saving...'},panelClass:className});

            let office = new Office();
            office.ModifiedBy = localStorage.getItem("loggedInUserName");
            office.OfficeActive = this.officeActive;
            office.OfficeDescription = this.officeDescription;
            office.ProgramSponsorId = this.selectedProgramsponsor;
            this.userService.updateOffice(this.officeData.OfficeId, office).subscribe(res => {
                dialogRef.close();

                if (res) {
                    Swal.fire({
                        text: "Updated Successfully",
                        icon: 'success',
                        confirmButtonText: 'OK',
                    }).then(() => {
                        this.dialog.closeAll();
                    })
                }
                else {
                    Swal.fire({
                        text: "Update failed",
                        icon: 'error',
                        confirmButtonText: 'OK',
                    }).then(() => {
                        this.dialog.closeAll();
                    })
                }
            }, err => {
                Swal.fire({
                    text: "Update failed",
                    icon: 'error',
                    confirmButtonText: 'OK',
                }).then(() => {
                    this.dialog.closeAll();
                })
            })

        }
        else
        {
            this.errorMessage="All fields are mandatory"
        }
    }

    clearMessage(){
        this.errorMessage="";
    }

    cancelOffice() {
        this.dialog.closeAll();
        this.router.navigate(['officeAdmin']);
    }

}
