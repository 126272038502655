<mat-card class="user-card" style="width: 93% !important">
  <mat-card-header>
    <mat-card-title style="font-size: 16px">
      Distributor Maintenance
    </mat-card-title>
    <mat-card-subtitle>
      Select an Utility, Search and click on the distributor or click New.
    </mat-card-subtitle>
  </mat-card-header>
  <mat-divider></mat-divider>
  <br />
  <div class="tp-form">
    <mat-form-field class="tp-full-width" appearance="outline">
      <mat-label>Distributor Name</mat-label>
      <input matInput placeholder="Search by Name" [(ngModel)]="indName" />
    </mat-form-field>

    <mat-form-field class="tp-full-width" appearance="outline">
      <mat-label>Utility</mat-label>
      <mat-select (selectionChange)="selectedUtility($event)" [(ngModel)]="distributorService.selectedUtility">
        <mat-option *ngFor="let org of distributorService?.orgList?.Individuals?.Utility" [value]="org.ProviderId"
          style="font-size: 11px;">
          {{org.ProviderShortName}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <br />
    <div class="tp-button">
      <button mat-raised-button color="primary" (click)="doSearch()">
        Search
      </button>
      <button mat-raised-button color="primary" [disabled]="distributorService.selectedUtility === null"
        (click)="newIndividual()">
        New
      </button>
      <button mat-raised-button color="primary" (click)="refresh()">
        Reset
      </button>
    </div>
    <br />
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter Text" [(ngModel)]="filterText" />
    </mat-form-field>
    <h3 *ngIf="distributorService.indvList.length < 1">No Search Results</h3>
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8, tp-full-width">
      <ng-container matColumnDef="DistributorName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 27%">
          Distributor Name
        </th>
        <td mat-cell *matCellDef="let element">{{ element.DistributorName }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" class="curr-cust" (click)="handleClick(row)"></tr>
    </table>
  </div>
</mat-card>