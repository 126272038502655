import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { EnrollmentService } from '../services/enrollment.service';
import { CustomerService } from '../services/customer.service';
import { WorkorderService } from 'src/app/services/workorder.service';
import { EnrollComponent } from '../enrollment/enroll/enroll.component';
import { SkipQuestionaire } from '../services/skip-questionaire.service';


@Component({
  selector: 'app-programenrollment',
  templateUrl: './programenrollment.component.html',
  styleUrls: ['./programenrollment.component.scss']
})
export class ProgramenrollmentComponent implements OnInit {

  public otherEntities: string;

  constructor(public dialogRef: MatDialogRef<EnrollComponent>,
    public enrollmentService: EnrollmentService,
    public customerService: CustomerService,
    public workorderService: WorkorderService,
    private skipQuestionaire: SkipQuestionaire,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private dialog: MatDialog,) {

  }
  programList: any[];
  showWorkOrder: boolean = false;
  loadFile: boolean = true;
  confirmData: any = {};
  action: boolean;
  message: any;
  programCount: any;
  group: any;
  subGroup: any;

  ngOnInit(): void {
    this.getProgramsData();

  }
  getProgramsData() {
    this.programList = []
    this.enrollmentService.getProgramTypes().subscribe(res => {
      this.loadFile = false;
      this.programList = res.Programs || []
      console.log('Actionvalue:', res.Action);

      if (res.Action == 1) {

        Swal.fire({
          text: res.Message,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          this.dialog.closeAll();

        });

      }

      this.group = res.Programs[0].Group;
      this.subGroup = res.Programs[0].SubGroup;
      console.log('subgroup:', this.subGroup ?? "ALL");
      localStorage.setItem('Group', this.group);
      localStorage.setItem('SubGroup', this.subGroup ?? "ALL");
      this.group = localStorage.getItem('Group');
      this.action = res.Action;
      this.message = res.Message;
      this.programCount = res.Programs.length;


      if (this.programList.length > 0) {
        console.log("Program List", this.programList);

        this.showWorkOrder = true;
      } else {
        this.showWorkOrder = false;
      }

    }, err => {
      console.log(err);
      this.showWorkOrder = false;
    });
    console.log("Program List", this.programList);
  }

  startEnrollment(prog) {
    console.log("program", prog);
    console.log("confirm data", this.confirmData);

    prog?.Eligibility === 0 ? this.handleEnroll(prog) : this.router.navigate(['/enrollqualify'], { state: { data: this.confirmData, program: prog } });
  }

  handleEnroll(prog) {
    this.skipQuestionaire.handleEnroll(prog);
  }

}
