<!-- <div [ngClass]="className"> -->
<h5 mat-dialog-title style="padding:0.2vw; color:black; margin:0 !important">Create Module</h5>
<mat-divider></mat-divider>
<div mat-dialog-content style="height: 51vh;
width: 100vh;">
    <mat-card class="user-card" style="width: 93%;">
        <mat-card-content>
            <mat-form-field appearance="outline" class="formfield-half" style="width:50% !important">
                <mat-label>Module ID</mat-label>
                <input matInput placeholder="Name" [(ngModel)]="moduleId" (focusin)="clearMessage()" maxlength="50"  >
            </mat-form-field>
            <mat-form-field appearance="outline" class="formfield-half" style="width:50% !important">
                <mat-label>Module Name</mat-label>
                <input matInput placeholder="Name" [(ngModel)]="moduleName" (focusin)="clearMessage()" maxlength="50">
            </mat-form-field>
            <br>
            <mat-form-field appearance="outline" class="formfield-half">
                <mat-label>Module Item</mat-label>
                <mat-select [(ngModel)]="selectedModuleItem" (selectionChange)="moduleItemChange(selectedModuleItem)">
                    <mat-option *ngFor="let item of moduleItems" [value]="item.ModuleItemId" style="font-size: 11px;">{{item.ModuleItemName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="formfield-half">
                <mat-label>Parent Menu</mat-label>
                <mat-select [(ngModel)]="selectedParentMenu" [disabled]="!ifSubMenu">
                    <mat-option *ngFor="let parent of parentMenuItems" [value]="parent.ModuleId" style="font-size: 11px;">{{parent.ModuleName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>        
            <br>
            <mat-slide-toggle  [(ngModel)]="moduleActive">Active</mat-slide-toggle>
            <br>
            <label style="color: red; font-size: 0.9vw">{{errorMessage}}</label>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="primary" (click)="saveModule();">SAVE</button>
            <button mat-raised-button color="primary" (click)="cancelModule();">CANCEL</button>
        </mat-card-actions>
    </mat-card>
</div>
<!-- </div> -->