<h1 mat-dialog-title class="header" style="color:black">Add Customer</h1>
<div mat-dialog-content style="height: 50vw;width: 76vw;">
    <!--  <button mat-raised-button (click)="isLinear = !isLinear" id="toggle-linear">
        {{!isLinear ? 'Enable linear mode' : 'Disable linear mode'}}
      </button> -->
    <mat-horizontal-stepper [linear]="isLinear" #stepper>
        <mat-step [stepControl]="firstFormGroup">
            <form [formGroup]="firstFormGroup">
                <ng-template matStepLabel>Customer Information</ng-template>
                <mat-form-field appearance="outline" style="padding-right: 0.5vw;width:99% !important;font-size: 1vw;">
                    <mat-label>Full Name</mat-label>
                    <input matInput placeholder="Full Name" />
                </mat-form-field>
                <br>
                <mat-form-field appearance="outline" style="padding-right: 0.45vw;width:32.65%;font-size: 1vw;">
                    <mat-label>First Name</mat-label>
                    <input matInput placeholder="First Name" />
                </mat-form-field>
                <mat-form-field appearance="outline" style="padding-right: 0.45vw;width:32.65%;font-size: 1vw;">
                    <mat-label>Middle Name</mat-label>
                    <input matInput placeholder="Middle Name" />
                </mat-form-field>
                <mat-form-field appearance="outline" style="padding-right: 0.45vw;width:32.65%;font-size: 1vw;">
                    <mat-label>Last Name</mat-label>
                    <input matInput placeholder="Last Name" />
                </mat-form-field>
                <br>
                <div style="margin-top: 0.5vw;">
                    <button mat-icon-button color="primary" matStepperNext matTooltip="Next"><mat-icon style="color: whitesmoke;">arrow_right
                    </mat-icon></button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup">
            <form [formGroup]="secondFormGroup">
                <ng-template matStepLabel>Address Information</ng-template>
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline"
                            style="padding-right: 0.5vw;width:99% !important;font-size: 1vw;">
                            <mat-label>Address Type</mat-label>
                            <input matInput placeholder="Address Type" />
                        </mat-form-field>
                        <br>
                        <mat-form-field appearance="outline" style="padding-right: 0.45vw;width:32.65%;font-size: 1vw;">
                            <mat-label>City</mat-label>
                            <input matInput placeholder="City" />
                        </mat-form-field>
                        <mat-form-field appearance="outline" style="padding-right: 0.45vw;width:32.65%;font-size: 1vw;">
                            <mat-label>State</mat-label>
                            <input matInput placeholder="State" />
                        </mat-form-field>
                        <mat-form-field appearance="outline" style="padding-right: 0.45vw;width:32.65%;font-size: 1vw;">
                            <mat-label>Zip</mat-label>
                            <input matInput placeholder="Zip" />
                        </mat-form-field>
                        <br>
                        <mat-form-field appearance="outline" style="padding-right: 0.45vw;width:32.65%;font-size: 1vw;">
                            <mat-label>Apartment</mat-label>
                            <input matInput placeholder="Apartment" />
                        </mat-form-field>
                        <mat-form-field appearance="outline" style="padding-right: 0.45vw;width:32.65%;font-size: 1vw;">
                            <mat-label>Grid/Lot</mat-label>
                            <input matInput placeholder="Grid/Lot" />
                        </mat-form-field>
                        <mat-form-field appearance="outline" style="padding-right: 0.45vw;width:32.65%;font-size: 1vw;">
                            <mat-label>Block</mat-label>
                            <input matInput placeholder="Block" />
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-button>Delete</button>
                    </mat-card-actions>
                </mat-card>


                <div style="margin-top: 0.5vw;">
                    <button mat-icon-button matStepperPrevious matTooltip="Back" color="primary"><mat-icon style="color: whitesmoke;">arrow_left
                    </mat-icon></button>
                    <button mat-icon-button matStepperNext matTooltip="Next" color="primary"><mat-icon style="color: whitesmoke;">arrow_right
                    </mat-icon></button>
                    
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="thirdFormGroup">
            <form [formGroup]="thirdFormGroup">
                <ng-template matStepLabel>Account Information</ng-template>
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline"
                            style="padding-right: 0.5vw;width:32.65%;font-size: 1vw;">
                            <mat-label>Provider</mat-label>
                            <input matInput placeholder="Provider" />
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" style="padding-right: 0.45vw;width:32.65%;font-size: 1vw;">
                            <mat-label>Account Number</mat-label>
                            <input matInput placeholder="Account Number" />
                        </mat-form-field>
                        <mat-form-field appearance="outline" style="padding-right: 0.45vw;width:32.65%;font-size: 1vw;">
                            <mat-label>Project ID</mat-label>
                            <input matInput placeholder="Project ID" />
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-button>Delete</button>
                    </mat-card-actions>
                </mat-card>
                <div style="margin-top: 0.5vw;">
                    <button mat-icon-button matStepperPrevious matTooltip="Back" color="primary"><mat-icon style="color: whitesmoke;">arrow_left
                    </mat-icon></button>
                    <button mat-icon-button matStepperNext matTooltip="Next" color="primary"><mat-icon>arrow_right
                    </mat-icon></button>
                    
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="fourthFormGroup">
            <form [formGroup]="fourthFormGroup">
                <ng-template matStepLabel>Phone</ng-template>
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline"
                            style="padding-right: 0.5vw;width:32.65%;font-size: 1vw;">
                            <mat-label>Type</mat-label>
                            <input matInput placeholder="Type" />
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" style="padding-right: 0.45vw;width:32.65%;font-size: 1vw;">
                            <mat-label>Phone Number</mat-label>
                            <input matInput placeholder="Phone Number" />
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-button>Delete</button>
                    </mat-card-actions>
                </mat-card>
                <div style="margin-top: 0.5vw;">
                    <button mat-icon-button matStepperPrevious matTooltip="Back"><mat-icon>arrow_left
                    </mat-icon></button>
                    <button mat-icon-button matStepperNext matTooltip="Next"><mat-icon>arrow_right
                    </mat-icon></button>
                    
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="fifthFormGroup">
            <form [formGroup]="fifthFormGroup">
                <ng-template matStepLabel>Email</ng-template>
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline"
                            style="padding-right: 0.5vw;width:99%;font-size: 1vw;">
                            <mat-label>Email</mat-label>
                            <input matInput placeholder="Email" />
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-button>Delete</button>
                    </mat-card-actions>
                </mat-card>
                <div style="margin-top: 0.5vw;">
                    <button mat-icon-button matStepperPrevious matTooltip="Back"><mat-icon>arrow_left
                    </mat-icon></button>
                    <button mat-icon-button matStepperNext matTooltip="Next"><mat-icon>arrow_right
                    </mat-icon></button>
                    
                </div>
            </form>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Done</ng-template>
            <p>You are now done.</p>
            <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button (click)="stepper.reset()">Reset</button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
    <!--  <mat-card>
        <mat-card-content>
            <mat-card>
                <mat-card-header style="margin-bottom: 1vw;
                font-size: 1.2vw;">Customer Information</mat-card-header>
                <mat-card-content>
                    <mat-form-field appearance="outline" style="padding-right: 0.5vw;width:99% !important;font-size: 1vw;">
                        <mat-label>Full Name</mat-label>
                        <input matInput placeholder="Full Name" />
                    </mat-form-field>
                    <br>
                    <mat-form-field appearance="outline" style="padding-right: 0.45vw;width:32.65%;font-size: 1vw;">
                        <mat-label>First Name</mat-label>
                        <input matInput placeholder="First Name" />
                    </mat-form-field>
                    <mat-form-field appearance="outline" style="padding-right: 0.45vw;width:32.65%;font-size: 1vw;">
                        <mat-label>Middle Name</mat-label>
                        <input matInput placeholder="Middle Name" />
                    </mat-form-field>
                    <mat-form-field appearance="outline" style="padding-right: 0.45vw;width:32.65%;font-size: 1vw;">
                        <mat-label>Last Name</mat-label>
                        <input matInput placeholder="Last Name" />
                    </mat-form-field>
                </mat-card-content>
            </mat-card>
            <mat-card>
                <mat-card-header style="margin-bottom: 1vw;
                font-size: 1.2vw;">Address</mat-card-header>
                <mat-card-content>
                    <mat-form-field appearance="outline" style="padding-right: 0.5vw;width:99% !important;font-size: 1vw;">
                        <mat-label>Address Type</mat-label>
                        <input matInput placeholder="Address Type" />
                    </mat-form-field>
                    <br>
                    <mat-form-field appearance="outline" style="padding-right: 0.45vw;width:32.65%;font-size: 1vw;">
                        <mat-label>City</mat-label>
                        <input matInput placeholder="City" />
                    </mat-form-field>
                    <mat-form-field appearance="outline" style="padding-right: 0.45vw;width:32.65%;font-size: 1vw;">
                        <mat-label>State</mat-label>
                        <input matInput placeholder="State" />
                    </mat-form-field>
                    <mat-form-field appearance="outline" style="padding-right: 0.45vw;width:32.65%;font-size: 1vw;">
                        <mat-label>Zip</mat-label>
                        <input matInput placeholder="Zip" />
                    </mat-form-field>
                    <br>
                    <mat-form-field appearance="outline" style="padding-right: 0.45vw;width:32.65%;font-size: 1vw;">
                        <mat-label>Apartment</mat-label>
                        <input matInput placeholder="Apartment" />
                    </mat-form-field>
                    <mat-form-field appearance="outline" style="padding-right: 0.45vw;width:32.65%;font-size: 1vw;">
                        <mat-label>Grid/Lot</mat-label>
                        <input matInput placeholder="Grid/Lot" />
                    </mat-form-field>
                    <mat-form-field appearance="outline" style="padding-right: 0.45vw;width:32.65%;font-size: 1vw;">
                        <mat-label>Block</mat-label>
                        <input matInput placeholder="Block" />
                    </mat-form-field>

                </mat-card-content>
                <mat-card-actions>
                    <button mat-button>Add Row</button>
                </mat-card-actions>
            </mat-card>
        </mat-card-content>
    </mat-card> -->
</div>