import { Component, OnInit, ViewChild } from '@angular/core';
import { Chart, ChartType, ChartTypeRegistry, registerables } from 'chart.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilityService } from '../services/utility.service';
import { UserService } from '../services/user.service';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { EnrollmentService } from '../services/enrollment.service';
import { WorkorderService } from '../services/workorder.service';
import { Workorder } from '../models/workorder';

@Component({
  selector: 'app-chart-dashboard',
  templateUrl: './chart-dashboard.component.html',
  styleUrls: ['./chart-dashboard.component.scss']
})
export class ChartDashboardComponent implements OnInit {
  public displayedLabels: string[] = [];
  public chartData: any = [];
  public chartType = ['bar', 'doughnut', 'line', 'pie', 'polarArea', 'radar'];
  public selectedChartType: keyof ChartTypeRegistry = 'bar';
  public selectedWorkorderType: string;
  public chart: Chart;
  dataMatrix: any = [];
  public barChartData: any = [];
  public barChartType: ChartType = 'bar';

  ///-----------Workorder Grid-------
  displayedColumns: string[] = ['WorkorderID', 'WorkorderType', 'AccountHolder', 'Distributor', 'Contractor', 'WorkorderStatus', 'Updated', 'Details'];
  dataSource = new MatTableDataSource<any>();
  workorderIDFilter = new FormControl();
  workorderTypeFilter = new FormControl();
  accountHolderFilter = new FormControl();
  distributorFilter = new FormControl();
  contractorFilter = new FormControl();
  workorderStatusFilter = new FormControl();
  globalFilter = '';

  filteredValues = {
    WorkorderID: '', WorkorderType: '', AccountHolder: '',
    Distributor: '', Contractor: '', WorkorderStatus: ''
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  constructor(public utilityService: UtilityService,
    private spinner: NgxSpinnerService,
    public userService: UserService,
    private workorderService: WorkorderService,
    private enrollmentService: EnrollmentService,
    private router: Router) {
    Chart.register(...registerables);
  }

  ngOnInit(): void {
    this.getbuckets();
    this.getMatrix();
    this.getWorkOrders();

    this.workorderIDFilter.valueChanges.subscribe((workorderIDFilterValue) => {
      this.filteredValues['WorkorderID'] = workorderIDFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.workorderTypeFilter.valueChanges.subscribe((workorderTypeFilter) => {
      this.filteredValues['WorkorderType'] = workorderTypeFilter;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.accountHolderFilter.valueChanges.subscribe((accountHolderFilter) => {
      this.filteredValues['AccountHolder'] = accountHolderFilter;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.distributorFilter.valueChanges.subscribe((distributorFilter) => {
      this.filteredValues['Distributor'] = distributorFilter;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.contractorFilter.valueChanges.subscribe((contractorFilter) => {
      this.filteredValues['Contractor'] = contractorFilter;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.workorderStatusFilter.valueChanges.subscribe((workorderStatusFilter) => {
      this.filteredValues['WorkorderStatus'] = workorderStatusFilter;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.dataSource.filterPredicate = this.customFilterPredicate();
  }

  getbuckets() {
    var temp = new Array<any>();
    this.userService.getDashboardBuckets(this.utilityService.currentUtility.OfficeId).subscribe((data) => {
      data["Buckets"].forEach(element => {
        if (element.label != 'Total Workorders') {
          this.userService.resourceRole?.toLowerCase() == 'admin' ? this.displayedLabels.push(element.WorkorderStatus) : "";
          temp.push(element.Count);
        }
      });
      this.chartData = [
        {
          data: temp,
          label: 'Workorder Count/ s',
          categoryPercentage: 1,
        }];
    });
  }

  changeSelectedType(event) {
    this.selectedChartType = event.value;
  }

  ///---------------------Workorder Type and Chart Type-------------------
  getMatrix() {
    this.spinner.show();
    this.userService.getAdminMatrix(this.utilityService.currentUtility.OfficeId).subscribe((data1) => {
      this.dataMatrix = data1["DATA"];
      if (this.userService.resourceRole?.toLowerCase() != 'admin') {
        this.processData(this.dataMatrix);
      }
      this.spinner.hide();
    });
  }

  changeWorkorderType(event) {
    this.selectedWorkorderType = event.value;
    this.getWorkorderTypeChart();
  }

  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      xAxes: [{
        gridLines: {
          offsetGridLines: true
        }
      }],
      yAxes: [{
        ticks: {
          min: 5
        }
      }]
    }
  };

  changeWoSelectedType(event) {
    this.barChartType = event.value;
    this.getWorkorderTypeChart();
  }

  getWorkorderTypeChart() {
    const temp = this.dataMatrix.filter((item) => item.WorkorderType === this.selectedWorkorderType);
    const labels = Object.keys(temp[0]).filter(key => key !== 'WorkorderType');
    const data = labels.map(label => temp[0][label] || 0);
    this.barChartData = [
      {
        data: data,
        label: temp[0]?.["WorkorderType"],
        categoryPercentage: 1,
      }];
  }

  public chartClicked(e: any, isWorkorderType) {
    if (isWorkorderType) {
      let filterValue = {
        WorkorderType: this.selectedWorkorderType
      }
      this.applyFilter(filterValue, this.displayedLabels[e.active[0].index]);
    }
    else
      this.applyFilter(this.displayedLabels[e.active[0].index]);
  }

  public matrixChartClicked(e: any) {
    let WorkorderType;
    for (let index = 0; index < this.barChartData.length; index++) {
      index == e.active[0].datasetIndex ? WorkorderType = this.barChartData[index]["label"] : "";
    }
    let filterValue = {
      WorkorderType: WorkorderType,
      WorkorderStatus: this.displayedLabels[e.active[0].index],
    }
    this.applyFilter(filterValue, this.displayedLabels[e.active[0].index]);
  }

  //---------Workorder grid----------
  customFilterPredicate() {
    const myFilterPredicate = (data: any, filter: string): boolean => {
      var globalMatch = !this.globalFilter;
      if (this.globalFilter) {
        globalMatch = data.WorkorderStatus.toString().trim().toLowerCase() === this.globalFilter;
      }
      if (!globalMatch) {
        return;
      }
      let searchString = JSON.parse(filter);
      if (searchString.WorkorderType != "" && searchString.WorkorderStatus != "" &&
        searchString.WorkorderID === "" && searchString.AccountHolder === "" &&
        searchString.Distributor === "" && searchString.Contractor === "") {
        return data.WorkorderType?.toString().trim().toLowerCase() === searchString.WorkorderType?.toLowerCase() &&
          data.WorkorderStatus?.toString().trim().toLowerCase() === searchString.WorkorderStatus?.toLowerCase();
      }
      else if (searchString.WorkorderType != "" && searchString.WorkorderStatus == "" &&
        searchString.WorkorderID === "" && searchString.AccountHolder === "" &&
        searchString.Distributor === "" && searchString.Contractor === "") {
        return data.WorkorderType?.toString().trim().toLowerCase() === searchString.WorkorderType?.toLowerCase();
      }
      else if (searchString.WorkorderStatus === "Complete" || searchString.WorkorderStatus === "Install Complete") {
        return data.WorkorderStatus?.toString().trim().toLowerCase() === searchString.WorkorderStatus?.toLowerCase();
      }
      else {
        return data.WorkorderID.toString().trim().indexOf(searchString.WorkorderID) !== -1 &&
          data.WorkorderType?.toString().trim().toLowerCase().indexOf(searchString.WorkorderType?.toLowerCase()) !== -1 &&
          data.AccountHolder?.toString().trim().toLowerCase().indexOf(searchString.AccountHolder?.toLowerCase()) !== -1 &&
          data.Distributor?.toString().trim().toLowerCase().indexOf(searchString.Distributor?.toLowerCase()) !== -1 &&
          data.Contractor?.toString().trim().toLowerCase().indexOf(searchString.Contractor?.toLowerCase()) !== -1 &&
          data.WorkorderStatus?.toString().trim().toLowerCase().indexOf(searchString.WorkorderStatus?.toLowerCase()) !== -1;
      }
    }
    return myFilterPredicate;
  }

  refrehPage() {
    this.router.navigateByUrl('/graphDashboard', { skipLocationChange: false })
      .then(() => this.router.navigate(['/graphDashboard']));
  }

  private getWorkOrders() {
    this.spinner.show();
    this.userService.getDashboardBuckets(this.utilityService.currentUtility.OfficeId).subscribe((data) => {
      if (this.userService.orgCd != null) {
        this.dataSource.data = data["DATA"];;

        this.spinner.hide();
        if (this.userService.resourceRole == "ADMIN") {
          this.getMatrix();
        }
      }
      else {
        this.spinner.hide();
        Swal.fire({
          text: data["Message"],
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    });
  }

  applyFilter(filterValue, value: any = null) {
    if (filterValue?.WorkorderType) {
      this.filteredValues.WorkorderType = filterValue.WorkorderType;
      this.filteredValues.WorkorderStatus = value;
    }
    else if (filterValue) {
      this.filteredValues.WorkorderStatus = filterValue;
    } else {
      this.globalFilter = filterValue;
    }
    this.dataSource.filter = JSON.stringify(this.filteredValues);
  }

  clearColumn(columnKey: string): void {
    columnKey == 'workorderIDFilter' ? this.workorderIDFilter.reset() :
      columnKey == 'workorderTypeFilter' ? this.workorderTypeFilter.reset() :
        columnKey == 'accountHolderFilter' ? this.accountHolderFilter.reset() :
          columnKey == 'distributorFilter' ? this.distributorFilter.reset() :
            columnKey == 'contractorFilter' ? this.contractorFilter.reset() :
              columnKey == 'workorderStatusFilter' ? this.workorderStatusFilter.reset() : "";
    this.refrehPage();
  }

  handleEnrollmentWorkorderClick(data, enrollmentId) {
    const body = { Id: data.WorkorderID };
    this.workorderService.currentWorkorderID = data.WorkorderID;
    this.enrollmentService.currentEnrollmentID = enrollmentId;
    this.router.navigate(['/workorderGetSave', data.Group, data.SubGroup, data.WorkorderID, data?.SubGroup1 ?? "ALL"]);
  }

  processData(data: any[]): void {
    const firstItem = data[0];
    const keys = Object.keys(firstItem).filter(key => key !== 'WorkorderType');
    this.displayedLabels = keys;

    this.barChartData = data.map(item => {
      const values = keys.map(key => item[key]);
      return {
        label: `${item.WorkorderType}`,
        data: values,
      };
    });
  }
}