import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { UtilityService } from 'src/app/services/utility.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-report-config-edit',
  templateUrl: './report-config-edit.component.html',
  styleUrls: ['./report-config-edit.component.scss']
})
export class ReportConfigEditComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router, private userService: UserService,
    private dialog: MatDialog, private utilityService: UtilityService) { }

  reportName: any;
  reportDescription: any;
  path: any;
  active: any;
  selectedMenu: any;
  dateControl: any;
  menuList: any;
  externalPath:any;
  ngOnInit(): void {
    console.log(this.data);
    this.getMenuList();
    this.reportName = this.data.ReportName;
    this.reportDescription = this.data.ReportDescription;
    this.path = this.data.ReportPath
    this.externalPath = this.data.ExternalReportPath;
   
    this.dateControl = this.data.DateControlParameter;
    this.active = this.data.ReportActive
  }

  getMenuList() {
    this.userService.getMenuList().subscribe(res => {
      this.menuList = res;
      this.menuList.forEach(element => {
        if(element.MenuId==this.data.ReportMenuId){
          this.selectedMenu = element;
        }
      });
    })
  }

  close(){
    this.dialog.closeAll();
  }

  saveEditedInfo() {
    let ReportID = this.data.ReportId;
    let ReportName = this.reportName;
    let ReportDescription = this.reportDescription;
    let Path = this.path;
    let Active = this.active;
    let MenuItem = this.selectedMenu.MenuId;
    let OfficeId = this.utilityService.currentUtility.OfficeId;
    let user = this.utilityService.currentUtility.BBCSUserId;
    let dataControl = this.dateControl;

    if(this.externalPath == null){
      this.externalPath = '';
    }
    // if (!this.secondFormGroup.invalid) {
    this.userService.createReportConfig(ReportID, ReportName, ReportDescription, Path, this.externalPath, dataControl, MenuItem, Active, user, OfficeId).subscribe(res => {
      if (res) {
        Swal.fire({
          title: 'Success',
          text: "Saved successfully",
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(result=>{
          this.dialog.closeAll();
        })
      }
      else {
        Swal.fire({
          title: 'Error',
          text: "Could not be saved! Please try again.",
          icon: 'error',
          confirmButtonText: 'OK',
        }).then(result=>{
          this.dialog.closeAll();
        })
      }
      
    }, err => {
      Swal.fire({
        title: 'Error',
        text: "Server Error",
        icon: 'error',
        confirmButtonText: 'OK',
      }).then(result=>{
        this.dialog.closeAll();
      })
    })
  }

}
