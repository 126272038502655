<div style="float: right; top: -5px; right: -5px">
  <button mat-icon-button (click)="closedialog()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<h5 mat-dialog-title>Add Company</h5>
<mat-divider></mat-divider>
<div mat-dialog-content style="width: 100vh">
  <br />
  <mat-card-content>
    <fieldset>
      <legend>Company Details</legend>
      <mat-form-field appearance="outline" class="mat-field-width50">
        <mat-label>Company Name</mat-label>
        <input matInput required [formControl]="companyName" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="mat-field-width50">
        <mat-label>Company Abbr.</mat-label>
        <input matInput required [formControl]="companyAbbr" />
        <mat-error *ngIf="companyAbbr.invalid">Max length is 15 characters</mat-error>
      </mat-form-field>
    </fieldset>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="saveCompany()" [disabled]="badData()">
        SAVE
      </button>
      <button mat-raised-button color="primary" (click)="reset()">
        CANCEL
      </button>
    </mat-card-actions>
  </mat-card-content>
</div>