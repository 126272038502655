<mat-card class="user-card" style="width: 95%;">
  <mat-card-header>
    <mat-card-title>
        Inventory Maintenance  {{inventoryName}}
    </mat-card-title>
    <mat-card-subtitle>
           
    </mat-card-subtitle>  
  </mat-card-header>
  <mat-divider></mat-divider>  
  <br>
  <mat-card-content> 
    <mat-tab-group color="accent" backgroundColor="primary"  [(selectedIndex)]="tabIndex"  (selectedIndexChange)="tabItemChange()">
      <mat-tab label="View Inven. Totals">   
        <br/>  
        <mat-form-field class="tp-full-width" appearance="outline" style="padding-right: 2.2vw;width:17.6vw">
          <mat-label>Warehouse</mat-label>
          <mat-select [(ngModel)]="selectedWarehouseValue" 
           (selectionChange)="getwarehouseTable($event.value)">
            <mat-option *ngFor="let warehouseList of wareHouseListObj"
             style="font-size: 11px;"
             [value]="warehouseList.WORKRESOURCE_ID"> 
              {{warehouseList.DESCRIPTION}}
            </mat-option>
          </mat-select> 
        </mat-form-field>   

        <mat-form-field style="margin-left: 0px;"  [hidden]="hidePaginator">
          <mat-label>Filter</mat-label>
          <input matInput  placeholder="Filter Text" autocomplete="off" (keyup)="applyTableFilter($event.target.value)" style="font-size: 12.5px;">
        </mat-form-field>
      
        <mat-checkbox [(ngModel)]="isChecked" (change)= "showInactiveProduct($event.checked)"
        style="font-size:1vw !important; margin-left: 1.8vw">Show Inactive Products</mat-checkbox>  
    
        
        <button mat-icon-button  matTooltipPosition="right"  style="margin-left: 0.5vw"
         matTooltip="Click on Column Header to sort by that column. Click on item or select it and hit Enter to retrieve Past Transactions and Serial Numbers. Double click to see a list of all open purchase orders for that item. Inactive Products are listed in grey text.">
          <mat-icon>info</mat-icon>
        </button>

        <button mat-mini-fab color="primary" style="margin-left: 34%; " [hidden]="hidePaginator" (click)="onSaveClick()"> 
          <mat-icon matTooltip="Save">save</mat-icon>
        </button>
        <!-- <button mat-mini-fab color="primary" style=" align-items:right" (click)="onSaveClick()"  [hidden]="hidePaginator"> 
          <mat-icon matTooltip="Save">save</mat-icon>
        </button> -->
        <!-- <p> status </p>{{!searching}}
        <p> Count </p>{{inventoryMaintenanceService.WarehouseDetail.ViewInventoryTable.length}} -->
        <br/>      

        <table mat-table [dataSource]="dataSource" 
        
         matSort class="mat-elevation-z8, tp-full-width"
          style="width: 100% !important;">

          <ng-container matColumnDef="sku">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>SKU</th>
            <td mat-cell *matCellDef="let element"
            [ngStyle]="{'color': (element.TOTALQTY<element.REORDERQTY)? 'red': 
                                 (element.PRODUCT_ACTIVE!=1.0)?'rgb(150,150,150)':'black' }"> {{element.SKU}} </td>
          </ng-container>

          <ng-container matColumnDef="desc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
            <td mat-cell *matCellDef="let element"
            [ngStyle]="{'color': (element.TOTALQTY<element.REORDERQTY)? 'red':
                                 (element.PRODUCT_ACTIVE!=1.0)?'rgb(150,150,150)':'black' }"> {{element.DESCRIPTION }} </td>
          </ng-container>
      

          <ng-container matColumnDef="manufacturer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacturer</th>
            <td mat-cell *matCellDef="let element"
            [ngStyle]="{'color': (element.TOTALQTY<element.REORDERQTY)? 'red': 
                                 (element.PRODUCT_ACTIVE!=1.0)?'rgb(150,150,150)':'black' }"> {{element.MANUFACTURER| titlecase}} </td>
          </ng-container>

          <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
            <td mat-cell *matCellDef="let element"
            [ngStyle]="{'color': (element.TOTALQTY<element.REORDERQTY)? 'red': 
            (element.PRODUCT_ACTIVE!=1.0)?'rgb(150,150,150)':'black' }"> {{element.CATEGORY| titlecase}} </td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
            <td mat-cell *matCellDef="let element"
           
            [ngStyle]="{'color': (element.TOTALQTY<element.REORDERQTY)? 'red': 
            (element.PRODUCT_ACTIVE!=1.0)?'rgb(150,150,150)':'black' }"> {{element.TYPE_CD}} </td>
          </ng-container>
          <!--
             [ngClass]="(element.TOTALQTY<element.REORDERQTY)?'negativeQty':''"
              [ngStyle]="{'backgroundColor': mon.colorfirst == 'yellow' ? '#ffe600d8' : mon.colorfirst == 'red' ?
          '#ec4141' : mon.colorfirst == 'green' ? '#84bd3a': mon.colorfirst == null ? 'white' :'white' }" -->
          <ng-container matColumnDef="availQty">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Avail.</th>
            <td mat-cell *matCellDef="let element" 
            [ngClass]="(element.TOTALQTY<element.REORDERQTY)?'negativeQty':
                        (element.PRODUCT_ACTIVE!=1.0)?'colorCoding':'' ">
               {{element.TOTALQTY}} </td>
          </ng-container>

          
          <!-- (keyup)="onlyNumber($event.target.value, i,element, $event.keyCode)"    -->
          <ng-container matColumnDef="reorderQty">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Reorder Qty</th>
            <td mat-cell *matCellDef="let element; let i = index">              
              <input matInput type="number"
              [max]="1000" [(ngModel)]=
               "element.REORDERQTY" autocomplete="off"
                         
                [ngClass]="(element.TOTALQTY<element.REORDERQTY)?'negativeReOrderQty':'reOrderQty'"
                [disabled]='element.PRODUCT_ACTIVE!=1.0'>              
              </td>
          </ng-container>

          <!-- Number try
            <ng-container matColumnDef="REORDERQTY">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Reorder Qty</th>
            <td mat-cell *matCellDef="let element"           
            >              
              <input matInput type="number" ng-pattern="/^(0|[1-9][0-9]*)$/" [(ngModel)]=
               "element.REORDERQTY" autocomplete="off"
               (change)="onlyNumber($event.target.value)"               
                [ngClass]="(element.TOTALQTY<0)?'negativeReOrderQty':'reOrderQty'">              
              </td>
          </ng-container> -->
<!-- 
          <ng-container matColumnDef="REORDERQTY">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Reorder Qty</th>
            <td mat-cell *matCellDef="let element"
            [ngClass]="(element.TOTALQTY<0)?'negativeQty':''"
            > 
              <mat-form-field appearance="outline" >
              <input matInput pattern="[0-9]*" [(ngModel)]=
               "element.REORDERQTY" autocomplete="false">
              </mat-form-field>
              </td>
          </ng-container> -->

          <ng-container matColumnDef="ONORDERQTY">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>On Order</th>
            <td mat-cell *matCellDef="let element"
            [ngStyle]="{'color': (element.TOTALQTY<element.REORDERQTY)? 'red': 
            (element.PRODUCT_ACTIVE!=1.0)?'rgb(150,150,150)':'black' }"> {{element.ONORDERQTY}} </td>
          </ng-container>

          <ng-container matColumnDef="PREFERREDVENDOR_ID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Preferred Vendor</th>
            <td mat-cell *matCellDef="let element"
            [ngStyle]="{'color': (element.TOTALQTY<element.REORDERQTY)? 'red': 
            (element.PRODUCT_ACTIVE!=1.0)?'rgb(150,150,150)':'black' }"> {{element.PREFERREDVENDOR_ID}} </td>
          </ng-container>

          <ng-container matColumnDef="UNITOFMEASURE">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Unit of Measure</th>
            <td mat-cell *matCellDef="let element"
            [ngStyle]="{'color': (element.TOTALQTY<element.REORDERQTY)? 'red': 
            (element.PRODUCT_ACTIVE!=1.0)?'rgb(150,150,150)':'black' }"> {{element.UNITOFMEASURE| titlecase}} </td>
          </ng-container>

          <ng-container matColumnDef="PRODUCT_ACTIVE">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
            <td mat-cell *matCellDef="let element">
              <mat-icon *ngIf="element.PRODUCT_ACTIVE==1.0" class="product-icon" >check</mat-icon> </td>
          </ng-container>       
  
          <!-- <ng-container matColumnDef="Edit">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Edit</th>
            <td mat-cell *matCellDef="let role; let i = index" align="center">
              <button mat-icon-button color="accent" >
                   <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container> class="curr-cust" [ngClass]="{'highlight': selectedRowIndex == row.id}"  -->

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" 
          class="curr-cust"         
           (dblclick)="selectRow(row)"></tr>

           <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;" 
           [ngClass]="{highlighted: selection.isSelected(row)}"                         
            (dblclick)="handleClick(row)"></tr> 
             [ngClass]="(row.STATUS=='RED')?'negativeQty':''"     -->

            <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"          
            [class.negativeQty]=  row.REORDERQTY1    (click)="highlight(row)"                        
             (dblclick)="handleClick(row)"></tr>  -->
           <!--   <tr mat-header-row *matHeaderRowDef="['sku', 'desc', 'manufacturer', 'category', 'type', 'availQty', 'reorderQty', 'ONORDERQTY', 'PREFERREDVENDOR_ID', 'UNITOFMEASURE', 'PRODUCT_ACTIVE']"></tr>
             <tr mat-row *matRowDef="let row; columns: displayedColumns;"          
             (click)="selectRow(row)" 
             (mouseover)="row.hovered = true" 
             (mouseout)="row.hovered = false"
             [ngClass]="{
                         hovered: row.hovered,
                         highlighted: selection.isSelected(row)
                        }"                       
             ></tr>   -->

        </table>
         <mat-paginator [pageSizeOptions]="[ 10, 15, 20, 25]" #matViewPaginator
              [hidden]="hidePaginator"
        showFirstLastButtons > </mat-paginator> 
      </mat-tab>  
      
      <mat-tab label="View Past Transactions"  [disabled]='!isActive' >   
        <br/>  
       <!-- {{productName}} -->

       <mat-form-field appearance="outline" style="padding-right: 2.2vw; width:17.6vw">
        <mat-label>Product Name</mat-label>
        <input matInput disabled [(ngModel)]="productName"/>
      </mat-form-field>

       <mat-form-field appearance="outline" style="padding-right: 2.2vw; width:17.6vw">
        <mat-label>Only show Transactions after</mat-label>
        <input matInput [matDatepicker]="myDp" [max]="maxDate" [min]="minDate"  [(ngModel)]="selectedDate" (dateInput)="onDate($event)"  placeholder="Choose a date" >
        <mat-datepicker-toggle [for]="myDp" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #myDp (selectedChanged)="onDate($event)" ></mat-datepicker>          
    </mat-form-field>

    <mat-form-field appearance="outline" style="padding-right: 2.2vw; width:17.6vw">
      <mat-label>Starting balance</mat-label>
      <input matInput disabled [(ngModel)]="balance"/>
    </mat-form-field>

      <table mat-table [dataSource]="dataSourcePastTransaction"  class="mat-elevation-z8, tp-full-width"
      style="width: 100% !important;">
      <ng-container matColumnDef="TRANSDATESYS">
         <th mat-header-cell *matHeaderCellDef> System Date</th>
         <td mat-cell  *matCellDef ="let element">{{element.TRANSDATESYS}} </td>
      </ng-container>
      
      <ng-container matColumnDef="TRANSDATE">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell  *matCellDef="let element"> {{element.TRANSDATE}}</td>
      </ng-container>
       
     <ng-container matColumnDef="TYPE_CD">
        <th mat-header-cell *matHeaderCellDef> Type</th>
        <td mat-cell  *matCellDef="let element"> {{element.TYPE_CD | titlecase}}</td>
     </ng-container>

     <ng-container matColumnDef="QTY">
       <th mat-header-cell *matHeaderCellDef> Qty</th>
       <td mat-cell  *matCellDef="let element">{{element.QTY}}</td>
     </ng-container>
     
     <ng-container matColumnDef="TOTALQTY">
      <th mat-header-cell *matHeaderCellDef>Total Qty</th>
      <td mat-cell  *matCellDef="let element">{{element.TOTALQTY}}</td>
    </ng-container>

    <ng-container matColumnDef="CLOSEQTY"> 
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell  *matCellDef="let element">{{element.CLOSEQTY}}</td>
    </ng-container>
    
    <ng-container matColumnDef="FROMTO_ID">
      <th mat-header-cell *matHeaderCellDef>From/To </th>
      <td mat-cell  *matCellDef="let element">{{element.FROMTO_ID| titlecase}}</td>
    </ng-container>
    
    <ng-container matColumnDef="REASON_CD">
      <th mat-header-cell *matHeaderCellDef>Reason</th>
      <td mat-cell  *matCellDef="let element">{{element.REASON_CD| titlecase}} </td>
    </ng-container>

     <ng-container matColumnDef="PURCHASEORDERNUMBER">
      <th mat-header-cell *matHeaderCellDef>Reason</th>
      <td mat-cell  *matCellDef="let element">{{element.PURCHASEORDERNUMBER}} </td>
    </ng-container>

     <ng-container matColumnDef="NOTES">
      <th mat-header-cell *matHeaderCellDef>Notes</th>
      <td mat-cell  *matCellDef="let element">{{element.NOTES}} </td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedPTColumns"></tr>
    <tr mat-row *matRowDef="let row; columns:displayedPTColumns;" ></tr>    
    
  </table> 
  
 

  <mat-paginator #matPTPaginator [pageSizeOptions]="[10,15,20,25]" showFirstLastButtons [hidden]="hidePTPaginator"> </mat-paginator> 

      </mat-tab>  


      <!-- <mat-tab label="View Serial Numbers"  [disabled]='!isActive'>   
        <br/>  
        
       <div>
       
       <mat-form-field appearance="outline" style="padding-right: 2.2vw; width:17.6vw">
        <mat-label>Product Name</mat-label>
        <input matInput disabled [(ngModel)]="productName"/>
      </mat-form-field>

       <mat-form-field appearance="outline" style="padding-right: 2vw; width:17.6vw">
        <mat-label>Find Serialno.</mat-label>        
        <input matInput [(ngModel)]="serialNoTxt"/>      
    </mat-form-field>

    <button mat-raised-button color="primary" style="padding-left: 1rem" (click)="serialNoClick()" > Find </button>
  
    <button mat-raised-button color="primary" style="padding-left: 1rem; margin-left: 1rem;" (click)="getAllSerialNo()"> View All Serialnos </button>
     
    <br>
  </div>
  
      <br>
      <br>
      <mat-grid-list cols="10" rowHeight="200px">
        <mat-grid-tile style="color: aqua; width: 100px; overflow: auto;" rowspan="2" colspan='2' >
      <table mat-table [dataSource]="dataSourceSerialNo" matSort  class="serial-grid">
        <ng-container matColumnDef="SERIALTEXT">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 27%;">Serial Numbers</th>
          <td mat-cell *matCellDef="let element"> {{element.SERIALTEXT}}  </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnSerialNo"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnSerialNo;" class="curr-cust" (click)="serialListClick(row)"></tr>
      </table>  
      </mat-grid-tile>
      <mat-grid-tile style="color:blue; overflow: auto;" colspan='8'>      
      
     <table mat-table [dataSource]="transHistoryDataSource" *ngIf="showHistory" matSort #sorter1="matSort" class="mat-elevation-z8, text-inside-grid">

          <ng-container matColumnDef="TRANSDATESYS">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>System Date</th>
            <td mat-cell *matCellDef="let transHistory"> {{transHistory.TRANSDATESYS}} </td>
          </ng-container>

          <ng-container matColumnDef="TRANSDATE">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
            <td mat-cell *matCellDef="let transHistory"> {{transHistory.TRANSDATE}} </td>
          </ng-container>

          <ng-container matColumnDef="TYPE_CD">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
            <td mat-cell *matCellDef="let transHistory"> {{transHistory.TYPE_CD}} </td>
          </ng-container>

          <ng-container matColumnDef="WAREHOUSE">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Warehouse</th>
            <td mat-cell *matCellDef="let transHistory"> {{transHistory.WAREHOUSE}} </td>
          </ng-container>

          <ng-container matColumnDef="FROMTO">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>From/To</th>
            <td mat-cell *matCellDef="let transHistory"> {{transHistory.FROMTO}} </td>
          </ng-container>

          <ng-container matColumnDef="REASON_CD">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Reason</th>
            <td mat-cell *matCellDef="let transHistory"> {{transHistory.REASON_CD}} </td>
          </ng-container>

          <ng-container matColumnDef="PURCHASEORDERNUMBER">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>PO #</th>
            <td mat-cell *matCellDef="let transHistory"> {{transHistory.PURCHASEORDERNUMBER}} </td>
          </ng-container>

          <ng-container matColumnDef="NOTES">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Notes</th>
            <td mat-cell *matCellDef="let transHistory"> {{transHistory.NOTES}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedTransHistoryColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedTransHistoryColumns;" class="curr-cust" ></tr>
        </table>       
        <h3 *ngIf="!showHistory">No Search Results</h3>

      </mat-grid-tile>
      <mat-grid-tile colspan='8'></mat-grid-tile>
      </mat-grid-list>
      </mat-tab>   -->

    </mat-tab-group>

    <div style="height: 35px; top:-100px">
      <mat-spinner *ngIf="inventoryMaintenanceService.searching" style="margin:0 auto;" ></mat-spinner>
      </div> 
  </mat-card-content>
</mat-card> 
<br>
<br>




