<div mat-dialog-title class="flex-container">
  <h1 mat-dialog-title class="header" style="padding:1vw;width: 100%;color: whitesmoke">Workorder Status History</h1>
  <button [mat-dialog-close]="true" matTooltip="Close" style="margin-bottom: 20px;">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<div class="mat-dialog-content">
  <table style="width: 100% !important; margin-bottom: 5%;" mat-table [dataSource]="dataSource"
    class="mat-elevation-z8">

    <ng-container matColumnDef="STATUS_DESC">
      <th mat-header-cell *matHeaderCellDef>
        <h3>
          Status
        </h3>
      </th>
      <td mat-cell *matCellDef="let workorder"
        [ngStyle]="workorder.TYPE_CD == 'ST'? {'color': 'OrangeRed'} :{'color': 'Darkgreen'}"> {{workorder.STATUS_DESC}}
      </td>
    </ng-container>
    <ng-container matColumnDef="STATUSDATE">
      <th mat-header-cell *matHeaderCellDef>
        <h3>
          Date
        </h3>
      </th>
      <td mat-cell *matCellDef="let workorder"
        [ngStyle]="workorder.TYPE_CD == 'ST'? {'color': 'OrangeRed'} :{'color': 'Darkgreen'}">{{workorder.STATUSDATE |
        date:'M/dd/yy, h:mm:ss a' }} </td>
    </ng-container>
    <ng-container matColumnDef="STATUSDATESYS">
      <th mat-header-cell *matHeaderCellDef>
        <h3>
          System Date
        </h3>
      </th>
      <td mat-cell *matCellDef="let workorder"
        [ngStyle]="workorder.TYPE_CD == 'ST'? {'color': 'OrangeRed'} :{'color': 'Darkgreen'}">{{workorder.STATUSDATESYS
        | date:'M/dd/yy, h:mm:ss a' }} </td>
    </ng-container>
    <ng-container matColumnDef="REASON_DESC">
      <th mat-header-cell *matHeaderCellDef>
        <h3>
          Reason
        </h3>
      </th>
      <td mat-cell *matCellDef="let workorder"
        [ngStyle]="workorder.TYPE_CD == 'ST'? {'color': 'OrangeRed'} :{'color': 'Darkgreen'}"> {{workorder.REASON_DESC}}
      </td>
    </ng-container>
    <ng-container matColumnDef="USER_ID">
      <th mat-header-cell *matHeaderCellDef>
        <h3>
          User Id
        </h3>
      </th>
      <td mat-cell *matCellDef="let workorder"
        [ngStyle]="workorder.TYPE_CD == 'ST'? {'color': 'OrangeRed'} :{'color': 'Darkgreen'}"> {{workorder.USER_ID}}
      </td>
    </ng-container>
    <ng-container matColumnDef="EXPORTDATE">
      <th mat-header-cell *matHeaderCellDef>
        <h3>
          Export date
        </h3>
      </th>
      <td mat-cell *matCellDef="let workorder"
        [ngStyle]="workorder.TYPE_CD == 'ST'? {'color': 'OrangeRed'} :{'color': 'Darkgreen'}"> {{workorder.EXPORTDATE |
        date:'M/dd/yy, h:mm:ss a' }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="curr-workorder"></tr>
  </table>
  <mat-radio-group style="margin-left: 35%;">
    <mat-radio-button value="1" color="primary" (change)="radioStatusChange('all')">View all</mat-radio-button>
    <mat-radio-button style="padding-left: 3%;color:orangered" value="2"
      (change)="radioStatusChange('ST')">Statuses</mat-radio-button>
    <mat-radio-button style="padding-left: 3%;color:darkgreen" value="3"
      (change)="radioStatusChange('MS')">Milestone</mat-radio-button>
  </mat-radio-group>
</div>