<div id="loading" *ngIf="loadFile" class="loading-indicator">
  <mat-spinner  [diameter]="30" color="primary"></mat-spinner> 
</div>

<div class="grid-container">
    <div class="qual-intro-div">
        <mat-card>
        <div class="header" style="text-align:left;font-size: 1.2vw !important;color:white;padding:0.2vw">
        <p>{{programName}}</p>
        </div>
        <p style="text-align:left;font-size: 0.9vw !important">Please answer these questions to qualify you for this program</p>
        <p style="text-align:center;font-size: 0.9vw !important">{{answered}} of {{questions.length}} Questions Answered</p>
        <mat-card-actions>
            <button  class="header" style="color:white;margin-left:8px" mat-raised-button routerLink="/detail">Back</button>
            <span *ngIf="quesCount != answeredQuesCount" >
              <button  style="margin-left:30px;color:white" mat-raised-button  [disabled]="quesCount != answeredQuesCount" 
            (click)="getRecommendations()">Verify</button>
            </span>
            <span *ngIf="quesCount == answeredQuesCount" >
            <button  class="header" style="margin-left:30px;color:white" mat-raised-button  
            (click)="getRecommendations()">Verify</button>
            </span>
        </mat-card-actions>
      </mat-card>
    </div>
  
  
    <div>
      <div>
        <div  class="header" style="text-align:center;padding:0.5vw;font-size: 1.4vw !important;margin-top:25px; color:white;border-left: 62.5px solid #fafafa">
        <p>Questions</p>
        </div>
        <table width="95%" cellspacing="0">
          <tr *ngFor="let ques of questions; index as i">
            <td width="60px" align="center">
              <span class="rcorners header">
                <p class="rcorners-text">{{ i + 1 }}</p>
              </span>
            </td>
            <td width="1px" style="background-color: black;">
            </td>
            <td style="padding-left: 20px;">
              <mat-card style="margin: 10px;">
                <p style="font-size: 0.9vw !important" [ngClass]="{'asterix--before': ques.QUESTION_REQUIRED }">{{ ques.QUESTION_TEXT }}</p>
                <mat-divider></mat-divider>
                <br>
        
                <mat-form-field style="width: 80%; font-size: 0.9vw !important;" *ngIf="ques.QUESTION_TYPE === 'TEXT'">
                  <input matInput [(ngModel)]="ques.currentValue" (focusout)="onAnswering(i,i+1,ques.currentValue)">
                </mat-form-field>
  
  
                <span *ngIf="ques.QUESTION_TYPE === 'LABEL'" style="font-size: 0.9vw !important;">
                  {{ ques.currentValue }}
                </span>
  
  
                <mat-form-field style="width: 30%;font-size: 0.9vw !important;"
                  *ngIf="ques.QUESTION_TYPE === 'NUMBER' || ques.QUESTION_TYPE === 'VALUE'">
                  <input matInput type="number" style="text-align: right;" [(ngModel)]="ques.currentValue"
                    (focusout)="onAnswering(i,i+1,ques.currentValue)">
                </mat-form-field>
  
  
  
                <mat-form-field style="width: 60%;font-size: 0.9vw !important;" *ngIf="ques.QUESTION_TYPE === 'LIST'">
                  <mat-select [(value)]="ques.currentValue">
                    <mat-option *ngFor="let selitem of ques.QUESTION_RESPONSES" value="{{selitem.RESPONSE_ID}}"
                      (focusout)="onAnswering(i,i+1,ques.currentValue)" style="font-size: 0.9vw !important;">
                      {{ selitem.RESPONSE_TEXT }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
  
  
              </mat-card>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <br>
    <br>

    <div *ngIf="ownandrent != undefined " style="font-size: 1vw !important;margin-left:60px">
      <ng-container>
        <mat-radio-group style="font-size: 0.8vw !important" aria-label="Do you own or rent" name="ownorrent"
          [(ngModel)]="ownorrent" required>
          <label style="font-size: 1vw !important"
            id="example-radio-group-label">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Do
            you own or rent ?&nbsp;<sup style="color:red">*</sup> &nbsp;&nbsp;</label>
          <mat-radio-button style="font-size: 0.8vw !important" *ngFor="let method of ownOrRent" [value]="method.Id">
            {{method.Value}}&nbsp;&nbsp;
          </mat-radio-button>
        </mat-radio-group>
      </ng-container>
    </div>



</div>


