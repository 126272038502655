import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BucketschedulingService } from 'src/app/services/bucketscheduling.service';
import { UtilityService } from 'src/app/services/utility.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-timeslot-dialog',
  templateUrl: './timeslot-dialog.component.html',
  styleUrls: ['./timeslot-dialog.component.scss']
})
export class TimeslotDialogComponent implements OnInit {

  WonotesObj:any;
  ApptnotesObj:any;
  email1:any;
  email2:any;
  bucketObj:any;
  address:any;
  workorderId:any;
  timeSlotId:any;
  phone:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private dialog: MatDialogRef<TimeslotDialogComponent>, private scheduleService: BucketschedulingService,
   private utilityService: UtilityService) { }

  ngOnInit(): void {
    this.bucketObj = this.data.bucketObj;
    this.WonotesObj = this.data.workorderNotes;
    this.email1 = this.data.email;
    this.address = this.data.address;
    this.phone = this.data.phone;
    this.workorderId = this.data.workorderId;
    this.timeSlotId = this.bucketObj.Resources[0].WebTimeSlotId;
  }

  onSchedule(){
    const body={
    "User":this.utilityService.currentUtility.BBCSUserId,
    "StartDate":this.bucketObj.SlotStart,
    "EndDate":this.bucketObj.SlotEnd,
    "WorkorderNotes":this.WonotesObj,
    "AppointmentNotes":this.ApptnotesObj,
    "EmailId1":null,
    "Email1":this.email1,
    "EmailId2":null,
    "Email2":this.email2
    }

    this.scheduleService.selfScheduleAppointment(body,this.workorderId,this.timeSlotId).subscribe(res=>{
      if(res.Action==0){
        Swal.fire({
        text: 'Scheduled Successfully!',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then((result) => {
        if (result.value) {

          this.dialog.close({data:true});
        }
      });
    }
    else {
      Swal.fire({
        text: res.Message,
        icon: 'error',
        confirmButtonText: 'OK',
      }).then((result) => {

        if (result.value) {
          this.dialog.close({data:false});
          //this.onLoad();
        }
      });
    }
  })
    



  }

}
