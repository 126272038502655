import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UtilityService } from 'src/app/services/utility.service';
import { SchedulingService } from 'src/app/services/scheduling.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-scheduler-dialog',
  templateUrl: './scheduler-dialog.component.html',
  styleUrls: ['./scheduler-dialog.component.scss']
})
export class SchedulerDialogComponent implements OnInit {

  startTimeObj: any;
  endTimeObj: any;
  startTime: any = [];
  endTime: any = [];
  email1: any;
  emailid1: any;
  email2: any;
  emailid2: any;
  isSaved: any = false;
  selectedStartTime: any;
  selectedEndTime: any;
  eventName: any;
  user: any;
  resourceName: any;
  WonotesObj: any;
  ApptnotesObj: any;
  resources: any;
  isReschedule: any = false;
  reasons: any;
  reasonName: any;
  disableReasons: any = true;
  userStart: any;
  userEnd: any;
  hasSaved: any;
  ProgramDetailId:any;
  compassEnabled:any;
  compassJobId:any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
   private dialog: MatDialogRef<SchedulerDialogComponent>, private scheduleService: SchedulingService,
    private utilityService: UtilityService, private spinner:NgxSpinnerService) { }

  ngOnInit(): void {

    this.disableReasons = this.data.disableReasons;
    this.eventName = this.data.weekEvent.title == '' ? 'New Event' : this.data.weekEvent.title;
    this.selectedStartTime = this.data.weekEvent.start;
    this.selectedEndTime = this.data.weekEvent.end;
    this.resourceName = this.data.weekEvent.meta.user.personcompanyid;
    this.resources = this.data.users;
    this.user = this.utilityService.currentUtility.BBCSUserId;
    this.email1 = this.data.initialInfo.Email1 == 'null' ? '' : this.data.initialInfo.Email1;
    this.emailid1 = this.data.initialInfo.EmailId1 == 'null' ? '' : this.data.initialInfo.EmailId1;
    this.email2 = this.data.initialInfo.Email2 == 'null' ? '' : this.data.initialInfo.Email2;
    this.emailid2 = this.data.initialInfo.EmailId2 == 'null' ? '' : this.data.initialInfo.EmailId2;
    this.WonotesObj = this.data.initialInfo.WoNotes == 'null' ? '' : this.data.initialInfo.WoNotes;
    this.ApptnotesObj = this.data.initialInfo.AppNotes == 'null' ? '' : this.data.initialInfo.AppNotes;
    this.compassEnabled = this.data.initialInfo.CompassEnabled == 'null' ? '' : this.data.initialInfo.CompassEnabled;
    this.compassJobId = this.data.initialInfo.CompassJobId == 'null' ? '' : this.data.initialInfo.CompassJobId;
    this.ProgramDetailId=localStorage.getItem('prgdetailid');
    //this.compassEnabled=localStorage.getItem('compassEnabled');
    //this.compassJobId=localStorage.getItem('compassJobId');
    this.scheduleService.getCancelScheduleReasons().subscribe(res => {
      this.reasons = res;
    })
  }

  onSave() {
    let apptStart = ((moment(this.selectedStartTime).format("MM/DD/YYYY HH:mm:ss")));
    let apptEnd = ((moment(this.selectedEndTime).format("MM/DD/YYYY HH:mm:ss")));
    console.log('prgdetail id:',this.ProgramDetailId);
    if (apptStart < apptEnd) {
      //show reason mandatory message
      if (this.disableReasons && this.reasonName != null && this.reasonName != undefined) {
        let body = {
          apptstart: ((moment(this.selectedStartTime).format("MM/DD/YYYY HH:mm:ss"))),
          apptend: ((moment(this.selectedEndTime).format("MM/DD/YYYY HH:mm:ss"))),
          user: this.user,
          workordernotes: this.WonotesObj,
          assignmentnotes: this.ApptnotesObj,
          emailid1: this.emailid1,
          email1: this.email1,
          emailid2: this.emailid2,
          email2: this.email2,
          reason: this.reasonName,
          ProgramdetailId: this.ProgramDetailId,
        }


        this.doSave(body);
      }
      else if (this.disableReasons && (this.reasonName == null || this.reasonName == undefined)) {
        Swal.fire({
          text: "Please select a reason.",
          icon: 'error',
          confirmButtonText: 'OK',
        })/* .then((result) => {

        if (result.value) {
          this.dialog.closeAll();
          //this.onLoad();
        }
      }); */
      }
      else if (!this.disableReasons) {
        let body = {
          apptstart: ((moment(this.selectedStartTime).format("MM/DD/YYYY HH:mm:ss"))),
          apptend: ((moment(this.selectedEndTime).format("MM/DD/YYYY HH:mm:ss"))),
          user: this.user,
          workordernotes: this.WonotesObj,
          assignmentnotes: this.ApptnotesObj,
          emailid1: this.emailid1,
          email1: this.email1,
          emailid2: this.emailid2,
          email2: this.email2,
          reason: '',
          ProgramdetailId: this.ProgramDetailId,
        }


        this.doSave(body);
      }
    }
    else {
      Swal.fire({
        text: "Appointment Start should be greater than Appointment End.",
        icon: 'error',
        confirmButtonText: 'OK',
      })
    }

  }

  doSave(body) {
    if (this.disableReasons) {
      Swal.fire({
        title: 'Are you sure?',
        text: "This will delete the existing open appointment.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        
        if (result.value) {
          this.spinner.show();

          this.scheduleService.addSchedulingEvent(body, this.data.initialInfo.Workorderid, this.resourceName).subscribe(res => {
            //Start to handle the response
            if (res.Action == "0" && this.compassEnabled == undefined)
            { 
             
                this.spinner.hide();
                  this.spinner.hide();
                  Swal.fire({
                    text: 'Rescheduled Successfully!',
                    icon: 'success',
                    confirmButtonText: 'OK',
                  }).then((result) => {
                    if (result.value) {
        
                      this.dialog.close({data:true});
                    }
                  }); 
        
            }

            if (res.Action != "0" && this.compassEnabled == undefined)
            {
              this.spinner.hide();
              Swal.fire({
                text: res.Message,
                icon: 'error',
                confirmButtonText: 'OK',
              }).then((result) => {
    
                if (result.value) {
                  this.dialog.close({data:false});
                  
                }
              });
            }

            //End for normal

            if (res.Action == "0" && this.compassEnabled == "1") 
            {
             
              this.scheduleService.getCompassReScheduleAppt(this.data.initialInfo.Workorderid,this.user,this.compassJobId).subscribe(res1 => {
              
                if(res1.Action)
                {
                  this.spinner.hide();
                  Swal.fire({
                    text: 'Reschedule is not successful. Reason: '+ res1.Message,
                    icon: 'error',
                    confirmButtonText: 'OK',
                  }).then((result) => {
                    if (result.value) {
        
                      this.dialog.close({data:true});
                    }
                  }); 
                
                }
                else
                {
                  this.spinner.hide();
                  Swal.fire({
                    text: 'Rescheduled Successfully!',
                    icon: 'success',
                    confirmButtonText: 'OK',
                  }).then((result) => {
                    if (result.value) {
        
                      this.dialog.close({data:true});
                    }
                  }); 
                }
           
              }
              );
            }
             
    //compass schedule   
          if (res.Action == "0" && this.compassEnabled == "0")
          { 
            this.spinner.hide();
                this.spinner.hide();
                Swal.fire({
                  text: 'Rescheduled Successfully!',
                  icon: 'success',
                  confirmButtonText: 'OK',
                }).then((result) => {
                  if (result.value) {
      
                    this.dialog.close({data:true});
                  }
                }); 
      
          }
              
            
          if (res.Action != "0" && this.compassEnabled == "0") {
            this.spinner.hide();
              Swal.fire({
                text: res.Message,
                icon: 'error',
                confirmButtonText: 'OK',
              }).then((result) => {

                if (result.value) {
                  this.hasSaved = false;
                  this.dialog.close({data:false});
                  //this.onLoad();
                }
              });
            }
          })
        }
        else {
          this.dialog.close({data:false});
        }

      })
    } 
    else {
      this.spinner.show();
      this.scheduleService.addSchedulingEvent(body, this.data.initialInfo.Workorderid, this.resourceName).subscribe(res => {
        
        if(res.Action==0 && this.compassEnabled == undefined)
        {
          this.spinner.hide();
           Swal.fire({
            text: 'Scheduled Successfully!',
            icon: 'success',
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result.value) {

              this.dialog.close({data:true});
              this.spinner.hide();
            }
          }); 

        }
        if (res.Action != "0" && this.compassEnabled == undefined)
        {
          this.spinner.hide();
          Swal.fire({
            text: res.Message,
            icon: 'error',
            confirmButtonText: 'OK',
          }).then((result) => {

            if (result.value) {
              this.dialog.close({data:false});
              
            }
          });
        }
        //compass schedule 
        if (res.Action == "0" && this.compassEnabled == "1" ) 
            {
             
              this.scheduleService.getCompassScheduleAppt(this.data.initialInfo.Workorderid,this.user).subscribe(res1 => {
             
              if(res1.Action)
              {
                this.spinner.hide();
                Swal.fire({
                  text: 'Schedule is not successful. Reason: '+ res1.Message,
                  icon: 'error',
                  confirmButtonText: 'OK',
                }).then((result) => {
                  if (result.value) {
      
                    this.dialog.close({data:true});
                  }
                }); 
              
              }
              else
              {
                this.spinner.hide();
                Swal.fire({
                  text: 'Scheduled Successfully!',
                  icon: 'success',
                  confirmButtonText: 'OK',
                }).then((result) => {
                  if (result.value) {
      
                    this.dialog.close({data:true});
                  }
                }); 
              }
         
            }
            );
          }
               
  //compass schedule   
    if (res.Action == "0" && this.compassEnabled == "0")
    { 
      this.spinner.hide();
           Swal.fire({
            text: 'Scheduled Successfully!',
            icon: 'success',
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result.value) {

              this.dialog.close({data:true});
              this.spinner.hide();
            }
          }); 

        }
      
        if (res.Action != "0" && this.compassEnabled == "0")
        {
          this.spinner.hide();
          Swal.fire({
            text: res.Message,
            icon: 'error',
            confirmButtonText: 'OK',
          }).then((result) => {

            if (result.value) {
              this.dialog.close({data:false});
              
            }
          });
        }
      })
    }

  }


}
