import { Component, OnInit } from '@angular/core';
declare var tableau: any;
@Component({
  selector: 'app-tableau-visualization',
  templateUrl: './tableau-visualization.component.html',
  styleUrls: ['./tableau-visualization.component.scss']
})
export class TableauVisualizationComponent implements OnInit {

  constructor() { }
  viz: any;
  ngOnInit(){

    var placeholderDiv = document.getElementById('vizContainer');
    // Replace this url with the url of your Tableau dashboard
    var url = localStorage.getItem('tableauLink')

    var options = {
      hideTabs: false,
      width: "100%",
      height: "100%",
      
      onFirstInteractive: function () {
       
        // The viz is now ready and can be safely used.
      
      }
    };
    // Creating a viz object and embed it in the container div.
    this.viz = new tableau.Viz(placeholderDiv, url, options);
  }

}

