import { Injectable } from '@angular/core';
import { ModuleAccess } from '../models/moduleaccess';
import { environment } from 'src/environments/environment';
import { UtilityService } from './utility.service';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Module } from '../models/module';
import { map, catchError } from 'rxjs/operators';
import { HttpClientModule } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ModuleAccessService {

  moduleAccessObj: ModuleAccess[]=[];
  private userSearchWsUrl1 = environment.apiUrl + 'onering/admin';

  constructor(private http: HttpClient, private utilityService: UtilityService) {

  }

  getModulesForUser(userEmail): Observable<ModuleAccess[]> {
    const searchUrl = this.userSearchWsUrl1 + "/user/modules/" +userEmail + "/" + this.utilityService.currentUtility.ProgramsponsorId + '?code=' + environment.apiCode;
    return this.http.get<ModuleAccess[]>(searchUrl);
        /* .pipe(map(response => response),
            catchError(err => this.utilityService.handleError(err, this, []))
        ); */
}



}
