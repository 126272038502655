
import { HttpClient } from '@angular/common/http';
import {Component, OnInit,ViewChild,ViewEncapsulation } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {} from 'google.maps';
import { RoutingService } from '../services/route.service';

@Component({
    selector: 'app-map',
    templateUrl: './route-optimization-detail.component.html',
    styleUrls: ['./route-optimization-detail.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class RouteOptimizationDetailComponent implements OnInit {
 @ViewChild("map") mapElement: any;
 map: google.maps.Map;
 searching=false;
 mapSearching=true;
 dataSource:any;
 rodate: any;
 lats:any;
 lngs:any;
 responseData: any;
 colorname: any;
 strokeColorArray = [];
 constructor(private routingService : RoutingService, 
    public http: HttpClient){
 }
 @ViewChild(MatSort) sort: MatSort;
 name:any;
 tabIndex=0;    
 displayedColumns: string[] = ['workorder_id', 'address', 'city', 'stateprovince', 'zip', 'status_cd',  'apptstart', 'apptwindow', 'resource'];   

  ngOnInit(): void {    
    this.colorname= (localStorage.getItem('colorCode')==null) ?
                   '#37474F': localStorage.getItem('colorCode');   
    var id= localStorage.getItem("RO_ID");       
    console.log("ID**", id);
    this.rodate=localStorage.getItem("RO_DATE");
    console.log("Init start ");
    this.init();  
    console.log("Init end");  
    } 

  async Map() {
   console.log("Inside Map");
    var map = new google.maps.Map(document.getElementById('map'), {
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        zoom:12,
        styles: [
          {
              featureType: "poi",
              elementType: "labels",
              stylers: [
                    { visibility: "off" }
              ]
          }
      ]
    });

    var infowindow = new google.maps.InfoWindow();
    var bounds = new google.maps.LatLngBounds();    
    var icon = {
        path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z", //SVG path of awesomefont marker
        fillColor: 'white', //color of the marker
        fillOpacity: 1,
        strokeWeight: 0,
        scale: 0.09, //size of the marker, careful! this scale also affects anchor and labelOrigin
        anchor: new google.maps.Point(200,510), //position of the icon, careful! this is affected by scale
        labelOrigin: new google.maps.Point(205,190) //position of the label, careful! this is affected by scale
    }
   console.log(this.routingService.mapData);
   var tech;
    for (let i = 0; i < this.routingService.mapData.length; i++) 
    {
        const cusomerData = this.routingService.mapData[i]; 
        console.log('->');
        console.log(cusomerData);
        
        var infoString = '<h2> <center> Appointment Detail - <i>'+ cusomerData.workorder_id+' </i><center> </h2> '+
                          '<hr/>'+                      
                          '<p> <b> Customer Address : </b>'+   cusomerData.customerfulladdress + '</p>'+
                          '<p> <b> Appointment Time : </b>'+   cusomerData.apptstart +'</p>'+
                          '<p> <b> Appointment Duration : </b>'+   cusomerData.apptwindow +'</p>';
                          if (cusomerData.resource !== undefined && cusomerData.resource != null ) {
                            tech = cusomerData.resource;
                            icon.fillColor=this.colorname;
                          }
                          else {
                            tech= 'Not Assigned';
                            icon.fillColor='Red';
                          }
                          infoString+=  '<p> <b> Technician : </b>'+   tech +'</p>';  
                        //  '<p> <b> Workorder Id : </b>'+   cusomerData.workorder_id +'</p>';                                         
 

        const infowindow = new google.maps.InfoWindow({
        content:infoString       
      });
      console.log("lat: "+ cusomerData.lat+ ", lng: "+cusomerData.lng);
      const marker =  new google.maps.Marker({
        position: { lat: cusomerData.lat, lng: cusomerData.lng },
        map,
        icon: icon,          
        label: {
          fontFamily: "'Font Awesome 5 Free'",
          text:(cusomerData.shortname=== undefined)? 'N/A':cusomerData.shortname ,            
          fontWeight: '900',
         // color:(cusomerData.shortname=== undefined)? 'white':'red',
         color:'white'
        }
      });
    
      marker.addListener("mouseover", function() {
       infowindow.open(map, marker); 
    }); 
    // assuming you also want to hide the infowindow when user mouses-out
    marker.addListener('mouseout', function() {
        infowindow.close();
    });    
  
    // draw line
    bounds.extend(marker.getPosition());  
    map.fitBounds(bounds); 
  }
      
    const result = [];
            const maps = new Map();
            for (const item of this.routingService.mapData) {
              if (item.resource !== undefined && item.resource != null ) {
                if(!maps.has(item.resource)){
                    maps.set(item.resource, true);    // set any value to Map
                    result.push(
                        //id: item.id,
                         item.resource
                        );
                }
              }
            }
    console.log(result)
    
    for(var i=0; i<result.length; i++) {           
       // console.log(strokeColorArray[i]); 
       this.strokeColorArray.push('#'+(0x1000000+Math.random()*0xffffff).toString(16).substr(1,6));     
       console.log('COLOR->'+ this.strokeColorArray[i]);   
        console.log("matchFilter for " + result[i]);
        var data_filter = this.routingService.mapData.filter( element => element.resource == result[i])
        console.log(data_filter) 
        var lat_lng = [];
        for (let z = 0; z < data_filter.length; z++) {
          const cusomerData = data_filter[z];   
          lat_lng.push({ lat: cusomerData.lat, lng: cusomerData.lng })   
        }
        await this.DrawRoute(lat_lng, i, map, this.strokeColorArray);
     
      map.fitBounds(bounds);
      console.log("matchFilter for " + result[i] + "ends");
      }
    
    console.log("Ending map");
    this.mapSearching=false;
  
}
 async DrawRoute(lat_lng, i, map, color) {  
 var strokeColorArray = [ 'red',  'black', 'blue','#C84B31','#03506F', '#734046', '#595B83', '#892CDC', '#03C4A1','#F05454'];
//var strokeColorArray = color; 
  console.log('DRAW');
  console.log(this.strokeColorArray);
  console.log('lat list');
  console.log( lat_lng );
    for (var t = 0;
      (t + 1) < lat_lng.length; t++) {        
    
      //Intialize the Direction Service
      var service = new google.maps.DirectionsService();
      var directionsDisplay = new google.maps.DirectionsRenderer();
  
      var bounds = new google.maps.LatLngBounds();
     console.log ("Inside i"+ i);
      if ((t + 1) < lat_lng.length) {
        var src = lat_lng[t];
        var des = lat_lng[t + 1];
        service.route({
          origin: src,
          destination: des,
          travelMode: google.maps.TravelMode.DRIVING,
        }, function(result, status) {
          if (status == google.maps.DirectionsStatus.OK) {
            console.log(result);
            console.log("i color" +strokeColorArray[i] + "  i")
            // new path for the next result
            var path = new google.maps.MVCArray();
            //Set the Path Stroke Color
            // new polyline for the next result
             console.log( "i =" + i);
             let color= (strokeColorArray[i]!== undefined)? strokeColorArray[i]:'#'+(0x1000000+Math.random()*0xffffff).toString(16).substr(1,6);
            var poly = new google.maps.Polyline({
              map: map,
             strokeColor: color
             // strokeColor: '#'+(0x1000000+Math.random()*0xffffff).toString(16).substr(1,6)
            });
            poly.setPath(path);
            for (var k = 0, len = result.routes[0].overview_path.length; k < len; k++) {
              path.push(result.routes[0].overview_path[k]);
              bounds.extend(result.routes[0].overview_path[k]);
              map.fitBounds(bounds);
            }
          } else alert("Directions Service failed:" + status);
        });
      }
    }  
  }
    

    init()
    {
      this.searching = true;
      var id= localStorage.getItem("RO_ID");       
      console.log("ID**", id);

       this.searching =true;     
       this.routingService.getRoute(id).subscribe(
         res=>{      
            console.log("*************DETAIL********");
            console.log(res);
            this.searching =false;
            this.dataSource= res.RODetail;
            this.dataSource.sort=this.sort;
            this.routingService.mapData= res.RODetail; 

            //this.routingService.GetMapData();
           this.routingService.requestDataFromMultipleSources().subscribe(
              result => {
                console.log(result);

                this.responseData = result[0];
                console.log( result[0]);
                console.log ("map data" );
                console.log(this.routingService.mapData);

                for (var i = 0; i < this.routingService.mapData.length; i++) {     
                console.log("Inside loop");
                console.log(result[i]);
                var lats = result[i].results[0].geometry.location.lat;
                var lngs = result[i].results[0].geometry.location.lng;
                console.log("New lat :" + lats+ "  New lng: "+ lngs); 
                this.routingService.mapData[i].lat= lats;
                this.routingService.mapData[i].lng= lngs; 
                }
             this.Map();
            })      
           
      });          
      this.searching = false;
    }    
}
