import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UtilityService } from '../services/utility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { DataTransferService } from '../services/data-transfer.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  reports: Report[] = [];
  reportParent: any;
  displayedColumns: string[] = ['ReportName', 'ReportDescription', 'StartDate', 'EndDate', 'extract'];
  dataSource = new MatTableDataSource<any>();
  startDate: any = new Date();
  tableauLink = "https://tableau.honeywell.com/t/HBT/views/";
  constructor(private utilityService: UtilityService, private route: ActivatedRoute,
    private router: Router, private userService: UserService,private dataTransfer:DataTransferService) { }

  ngOnInit() {
    this.dataTransfer.myMethod(true);
    this.reportParent = this.route.snapshot.paramMap.get('id')
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.viewReportConfigurations();
    /*  this.reports.push({ 'Report': "Interaction",'ReportId':"Interaction" })
     this.reports.push({ 'Report': "Workorder Exceptions",'ReportId':"WO_Exceptions" }) */
    /*   this.dataSource.data = this.reports; */
  }

  viewReportConfigurations() {

    this.userService.viewReportConfig(this.utilityService.currentUtility.OfficeId, this.reportParent, this.utilityService.currentUtility.LoggedInUserRole).subscribe(data => {

      if (data.length > 0) {
        data.forEach(element => {
          if (element.StartDate != null || element.StartDate != undefined) {
            element.StartDate = new Date(element.StartDate);
          }

        });
        //  this.dataSource.paginator = this.firstpaginator;
        this.dataSource.data = data;

      }


    });

  }

  reportExtractClick(reportData) {
    let reportLink = ''
    let start = moment();
    let end = moment()
    let officeParams = ''
   
    if (reportData.StartDate != null && reportData.EndDate != null) {
      start = moment(reportData.StartDate);
      end = moment(reportData.EndDate).add(1, 'days');
      console.log('EndDate',end);
    }

    //check if authorized
    this.userService.authorizeReports(reportData.ReportId, this.utilityService.currentUtility.OfficeId).subscribe(res => {
      if (res) {

        if (reportData.ReportPath != null) {
          if(this.utilityService.currentUtility.Source == 'BBCS'){
           officeParams = this.utilityService.currentUtility.OfficeId;
          }
          else
          {
            officeParams = this.utilityService.currentUtility.ProgramsponsorDescription;
          }
          //reportLink = reportData.ReportPath + "?OfficeId=" + officeParams + "&StartDate=" + start.format("YYYY-MM-DD") + "&EndDate=" + end.format("YYYY-MM-DD") + "&iframeSizedToWindow=true&:embed=y&:showAppBanner=false&:display_count=no&:showVizHome=no";
          reportLink = reportData.ReportPath + "?OfficeId=" + officeParams + "&StartDate=" + start.format("YYYY-MM-DD") + "&EndDate=" + end.format("YYYY-MM-DD") +"&:showShareOptions=false" + "&iframeSizedToWindow=true&:embed=y&:showAppBanner=false&:display_count=no&:showVizHome=no";
        }
        else {
          Swal.fire({
            text: "Path not available",
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }


        /* let reportName = reportData.ReportId + "Report";
        let utility = this.utilityService.currentUtility;
        //reportData.Report + "Extract"+
        let reportLink = "";
        if (reportData.ReportId == "Interaction") {
          reportLink = this.tableauLink + reportName + "/" + reportData.Report + "Extract" + "?Program_Sponsor=" + utility.ProgramsponsorDescription + "&iframeSizedToWindow=true&:embed=y&:showAppBanner=false&:display_count=no&:showVizHome=no";
        }
        else {
          reportLink = this.tableauLink + reportData.ReportId + "_Report/" + "ExceptionsDashboard?Office_Id=" + utility.OfficeId + "&iframeSizedToWindow=true&:embed=y&:showAppBanner=false&:display_count=no&:showVizHome=no";
        }
 */
        localStorage.setItem('tableauLink', reportLink);
        this.router.navigate(['Reports']);
      }
    }, err => err);

  }


}
export class Report {
  public Report: string;
  public ReportId: string;
}
