<mat-card class="user-card" style="width: 93% !important;">
  <mat-card-header>
    <mat-card-title style="font-size: 16px">
      Individual Maintenance
    </mat-card-title>
    <mat-card-subtitle>
        Select an Organization, Search and click on the individual or click New.
    </mat-card-subtitle>
  </mat-card-header>
  <mat-divider></mat-divider>
  <br> 
  <div class="tp-form">
    <br/>
    <div style="height: 35px;">
        <mat-spinner *ngIf="searching" [diameter]=30 style="margin-bottom: 20px;"></mat-spinner>
    </div>

    <mat-form-field class="tp-full-width" appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput placeholder="Search by Name" [(ngModel)]="indName">
    </mat-form-field>

    <mat-form-field class="tp-full-width" appearance="outline">
      <mat-label>Organization</mat-label>
      <mat-select [(ngModel)]="individualService.selectedOrg">
        <mat-option *ngFor="let org of individualService.orgList" [value]="org" style="font-size: 11px;">
          {{org.DESCRIPTION}}
        </mat-option>
      </mat-select>
    </mat-form-field>


    <br/>
    <div class="tp-button">
      <button mat-raised-button color="primary" (click)="doSearch()">Search</button>
      <button mat-raised-button color="primary" [disabled]="individualService.selectedOrg === undefined" (click)="newIndividual()">New</button>
      <button mat-raised-button color="primary" (click)="refresh()">Reset</button>
    </div>
    <br>
    <div style="height: 35px;">
        <mat-spinner *ngIf="searching2" [diameter]=30 style="margin-bottom: 20px;"></mat-spinner>
    </div>
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter Text" [(ngModel)]="filterText">
      </mat-form-field>
    <h3 *ngIf="individualService.indvList.length < 1">No Search Results</h3>
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8, tp-full-width">
      <ng-container matColumnDef="FULLNAME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 27%;">Individual Name</th>
        <td mat-cell *matCellDef="let element"> {{element.FULLNAME}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="curr-cust" (click)="handleClick(row)"></tr>
    </table>
  </div>
</mat-card>
