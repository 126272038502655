<div style="float:right;top:-5px;right:-5px;color:white">
  <button mat-icon-button [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </button>  
</div> 

<h1 mat-dialog-title class="header" style="padding:0.5vw;font-size:1.2vw; color:white">File Upload</h1>
<div mat-dialog-content>

    <mat-card style="width: 40vw;">
        <mat-card-content>
            <mat-form-field  appearance="outline" class="formfield-half">
                <mat-label>File Type</mat-label>
                <mat-select [(ngModel)]="selectedImageCode">
                  <mat-option *ngFor="let fileType of fileTypes" [value]="fileType.ImageCode" style="font-size: 11px;">{{fileType.ImageDescription}}</mat-option>
                </mat-select>
              </mat-form-field>
             <!--  <mat-form-field  appearance="outline" class="formfield-half"> -->
              <!--   <mat-label>Select File:</mat-label> -->
                <input type="file"  accept=".jpg,.doc,.docx,.pdf|image/*" (change)="handleFiles($event)" [(ngModel)]="fileObj">
             
            
        </mat-card-content>
        <mat-card-actions>
            <button mat-button (click)="uploadDoc()">Upload</button>
        </mat-card-actions>
    </mat-card>
</div>