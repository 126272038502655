
export class LocationSearch{
  Grids:BBCSGrids[];
  TerritoryStrings:BBCSTerritoryStrings[];
  LocationSearch:BBCSLocationSearch[];
  }
  export class BBCSLocationSearch{
    personcompanyid:any;
    name:any;
    apptdate:any;
    grid:any;
    count:any;
    day:any;


  }
export class BBCSTerritoryStrings{
  grid:any;
  second:any;
  third:any;
}
export class BBCSGrids{
  grid:any;
  grid_cd:any;
}
export class BBCSLocationsearch_temp{
  personcompanyidfirst:any;
  name:any;
  apptdatefirst:any;
  gridfirst:any;
  countfirst:any;
  dayfirst:any;
  gridsecond:any;
  countsecond:any;
  gridthird:any;
  countthird:any;
  gridfourth:any;
  countfourth:any;
  gridfifth:any;
  countfifth:any;
  gridsix:any;
  countsix:any;
  gridseven:any;
  countseven:any;
  colorfirst:any;
  colorsecond:any;
  colorthird:any;
  colorfourth:any;
  colorfifth:any;
  colorsix:any;
  colorseven:any;
  personcompanyid:any;
  mondayOfWeek: any;
  tuesdayOfWeek: any;
  wednesdayOfWeek: any;
  thursdayOfWeek: any;
  fridayOfWeek: any;
  satdayOfWeek: any;
  sundayOfWeek: any;

  public setFromWeb(name,grid,count,gridsecond,countsecond,gridthird,countthird,gridfourth,countfouth,gridfifth,countfifth,gridsix,countsix,gridseven,countseven,colorfirst,colorsecond,colorthird,colorfouth,colorfifth,colorsix,colorseven,
    personcompanyid,
    mondayOfWeek,
  tuesdayOfWeek,
  wednesdayOfWeek,
  thursdayOfWeek,
  fridayOfWeek,
  satdayOfWeek,
  sundayOfWeek

    ) {

    this.name=name;
    this.gridfirst=grid;
    this.countfirst=count;
    //this.dayfirst=day;
    this.gridsecond=gridsecond;
    this.countsecond=countsecond;
    this.gridthird=gridthird;
    this.countthird=countthird;
    this.gridfourth=gridfourth
    this.countfourth=countfouth;
    this.gridfifth=gridfifth;
    this.countfifth=countfifth;
    this.gridsix=gridsix;
    this.countsix=countsix;
    this.gridseven=gridseven;
    this.countseven=countseven;
    this.colorfirst=colorfirst;
    this.colorsecond=colorsecond;
    this.colorthird=colorthird;
    this.colorfourth=this.colorfourth;
    this.colorfifth=colorfifth;
    this.colorsix=colorsix;
    this.colorseven=colorseven;
    this.personcompanyid=personcompanyid;
    this.mondayOfWeek=mondayOfWeek;
    this.tuesdayOfWeek=tuesdayOfWeek;
    this.wednesdayOfWeek=wednesdayOfWeek;
    this.thursdayOfWeek=thursdayOfWeek;
    this.fridayOfWeek=fridayOfWeek;
    this.satdayOfWeek=satdayOfWeek;
    this.sundayOfWeek=sundayOfWeek;


      }


}
