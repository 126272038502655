import { Injectable, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomerService } from './customer.service';
import { WorkorderService } from 'src/app/services/workorder.service';
import { EnrollmentService } from './enrollment.service';
import { Router } from '@angular/router';
import { EnrollComponent } from '../enrollment/enroll/enroll.component';
import Swal from 'sweetalert2';


@Injectable({
    providedIn: 'root'
})
export class SkipQuestionaire {

    constructor(
        @Optional() public dialogRef: MatDialogRef<EnrollComponent>,
        public enrollmentService: EnrollmentService,
        public customerService: CustomerService,
        public workorderService: WorkorderService,
        private router: Router,
    ) { }

    group: any;

    handleEnroll(rec) {
        Swal.fire({

            text: "Are you sure you want to enroll?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, enroll!'

        })
            .then((result) => {
                if (result.value) {
                    this.doEnroll(rec);
                }
            })

    }

    doEnroll(rec) {
        const programId = rec?.ProgramId ?? localStorage.getItem('programdetailid');
        const personcompanyid = localStorage.getItem('personcompanyid');
        this.enrollmentService.enrollCustomer(
            personcompanyid, this.customerService.currentCustomer.Id, programId, this.group).subscribe((res) => {


                if (res.Action == 0) {
                    this.workorderService.currentWorkorderID = res.WorkorderId;
                    this.enrollmentService.currentEnrollmentID = res.EnrollmentId;

                    this.router.navigate(['/workorderGetSave', res.Group, res.SubGroup, res.WorkorderId, res?.SubGroup1 ?? "ALL"])

                } else {
                    Swal.fire({
                        title: 'Error',
                        text: res.Message,
                        icon: 'error',
                        confirmButtonText: 'OK',
                    })
                }

                this.dialogRef?.close('1');
            }, err => {

                Swal.fire({
                    title: 'Error',
                    text: err,
                    icon: 'error',
                    confirmButtonText: 'OK',
                })
                this.dialogRef?.close('1');
            });
    }

}
