import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class BucketschedulingService {

  dayStatus = [0, 0, 0, 0, 0];  // 0 = loading, 1 = no data, 2 = has slots

  constructor(public utilityService:UtilityService, public http:HttpClient) { }

  public getHeaders() {
    const email = localStorage.getItem('loggedInUserEmail');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-email': email,
        'x-programsponsorid': this.utilityService.currentUtility.ProgramsponsorId
      })
    };
    return httpOptions;
  }

  getSlotsForDay(date,selectedWO): Observable<any> {
    
    /* debugger;
    if (day.day() == 1) {
      this.dayStatus[day.day() - 1] = 1;
      return of([]);
    }

    this.dayStatus[day.day() - 1] = 2; */

    const searchUrl = environment.apiUrl + "workorder/selfschedule/gettimeslots/" + this.utilityService.currentUtility.OfficeId + "/workorder/" +selectedWO + "?code=" + environment.apiCode;

  const body=  {
      "scheduledate":date
  }
    return this.http.post<any[]>(searchUrl, body, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );

    

   // return of(this.slotlist.AvailableSlotsResources);
  }

  selfScheduleAppointment(body,workorderId,timeSlotId){
    const searchUrl = environment.apiUrl + "workorder/selfschedule/createappt/" + this.utilityService.currentUtility.OfficeId + "/workorder/" +workorderId +"/timeslot/"+timeSlotId + "?code=" + environment.apiCode;

    
      return this.http.post<any[]>(searchUrl, body, this.getHeaders())
        .pipe(map(response => response),
          catchError(err => this.utilityService.handleError(err, this, []))
        );
  }

  optimizeRoutes(date){
 
    const searchUrl = environment.apiUrl + "selfschedule/optimizeRoutes/" + this.utilityService.currentUtility.OfficeId + "?code=" + environment.apiCode;

    const body={
      "OptimizeDate":date
    }
    return this.http.post<any[]>(searchUrl, body, this.getHeaders())
    .pipe(map(response => response),
      catchError(err => this.utilityService.handleError(err, this, []))
    );
  }

  slotlist = {
    "AvailableSlotsResources": [
      {
        "Day": "12/18/20",
        "SlotStart": "12/18/20 08:00",
        "SlotEnd": "12/18/20 08:30",
        "AvailableResources": 3,
        "Resources": [
          {
            "WebTimeSlotId": "D000206142",
            "PersoncompanyId": "D049738770",
            "Resource": "A1 ALLIANCE"
          },
          {
            "WebTimeSlotId": "D000198300",
            "PersoncompanyId": "D021609937",
            "Resource": "CMC ENERGY SERVICES"
          },
          {
            "WebTimeSlotId": "D000213968",
            "PersoncompanyId": "D050312166",
            "Resource": "KUMUDHA RAMASWAMY"
          }
        ]
      },
      {
        "Day": "12/18/20",
        "SlotStart": "12/18/20 08:30",
        "SlotEnd": "12/18/20 09:00",
        "AvailableResources": 3,
        "Resources": [
          {
            "WebTimeSlotId": "D000206142",
            "PersoncompanyId": "D049738770",
            "Resource": "A1 ALLIANCE"
          },
          {
            "WebTimeSlotId": "D000198300",
            "PersoncompanyId": "D021609937",
            "Resource": "CMC ENERGY SERVICES"
          },
          {
            "WebTimeSlotId": "D000213968",
            "PersoncompanyId": "D050312166",
            "Resource": "KUMUDHA RAMASWAMY"
          }
        ]
      },
      {
        "Day": "12/18/20",
        "SlotStart": "12/18/20 09:00",
        "SlotEnd": "12/18/20 09:30",
        "AvailableResources": 3,
        "Resources": [
          {
            "WebTimeSlotId": "D000206142",
            "PersoncompanyId": "D049738770",
            "Resource": "A1 ALLIANCE"
          },
          {
            "WebTimeSlotId": "D000198300",
            "PersoncompanyId": "D021609937",
            "Resource": "CMC ENERGY SERVICES"
          },
          {
            "WebTimeSlotId": "D000213968",
            "PersoncompanyId": "D050312166",
            "Resource": "KUMUDHA RAMASWAMY"
          }
        ]
      },
      {
        "Day": "12/18/20",
        "SlotStart": "12/18/20 09:30",
        "SlotEnd": "12/18/20 10:00",
        "AvailableResources": 3,
        "Resources": [
          {
            "WebTimeSlotId": "D000206142",
            "PersoncompanyId": "D049738770",
            "Resource": "A1 ALLIANCE"
          },
          {
            "WebTimeSlotId": "D000198300",
            "PersoncompanyId": "D021609937",
            "Resource": "CMC ENERGY SERVICES"
          },
          {
            "WebTimeSlotId": "D000213968",
            "PersoncompanyId": "D050312166",
            "Resource": "KUMUDHA RAMASWAMY"
          }
        ]
      },
      {
        "Day": "12/18/20",
        "SlotStart": "12/18/20 10:00",
        "SlotEnd": "12/18/20 10:30",
        "AvailableResources": 3,
        "Resources": [
          {
            "WebTimeSlotId": "D000206142",
            "PersoncompanyId": "D049738770",
            "Resource": "A1 ALLIANCE"
          },
          {
            "WebTimeSlotId": "D000198300",
            "PersoncompanyId": "D021609937",
            "Resource": "CMC ENERGY SERVICES"
          },
          {
            "WebTimeSlotId": "D000213968",
            "PersoncompanyId": "D050312166",
            "Resource": "KUMUDHA RAMASWAMY"
          }
        ]
      },
      {
        "Day": "12/18/20",
        "SlotStart": "12/18/20 10:30",
        "SlotEnd": "12/18/20 11:00",
        "AvailableResources": 3,
        "Resources": [
          {
            "WebTimeSlotId": "D000206142",
            "PersoncompanyId": "D049738770",
            "Resource": "A1 ALLIANCE"
          },
          {
            "WebTimeSlotId": "D000198300",
            "PersoncompanyId": "D021609937",
            "Resource": "CMC ENERGY SERVICES"
          },
          {

            "WebTimeSlotId": "D000213968",

            "PersoncompanyId": "D050312166",

            "Resource": "KUMUDHA RAMASWAMY"

          }

        ]

      },

      {

        "Day": "12/18/20",

        "SlotStart": "12/18/20 11:00",

        "SlotEnd": "12/18/20 11:30",

        "AvailableResources": 1,

        "Resources": [

          {

            "WebTimeSlotId": "D000198300",

            "PersoncompanyId": "D021609937",

            "Resource": "CMC ENERGY SERVICES"

          }

        ]

      },

      {

        "Day": "12/18/20",

        "SlotStart": "12/18/20 11:30",

        "SlotEnd": "12/18/20 12:00",

        "AvailableResources": 1,

        "Resources": [

          {

            "WebTimeSlotId": "D000198300",

            "PersoncompanyId": "D021609937",

            "Resource": "CMC ENERGY SERVICES"

          }

        ]

      },

      {

        "Day": "12/18/20",

        "SlotStart": "12/18/20 12:00",

        "SlotEnd": "12/18/20 12:30",

        "AvailableResources": 1,

        "Resources": [

          {

            "WebTimeSlotId": "D000198300",

            "PersoncompanyId": "D021609937",

            "Resource": "CMC ENERGY SERVICES"

          }

        ]

      },

      {

        "Day": "12/18/20",

        "SlotStart": "12/18/20 12:30",

        "SlotEnd": "12/18/20 13:00",

        "AvailableResources": 1,

        "Resources": [

          {

            "WebTimeSlotId": "D000198300",

            "PersoncompanyId": "D021609937",

            "Resource": "CMC ENERGY SERVICES"

          }

        ]

      },

      {

        "Day": "12/18/20",

        "SlotStart": "12/18/20 13:00",

        "SlotEnd": "12/18/20 13:30",

        "AvailableResources": 2,

        "Resources": [

          {

            "WebTimeSlotId": "D000198300",

            "PersoncompanyId": "D021609937",

            "Resource": "CMC ENERGY SERVICES"

          },

          {

            "WebTimeSlotId": "D000215273",

            "PersoncompanyId": "D050312166",

            "Resource": "KUMUDHA RAMASWAMY"

          }

        ]

      },

      {

        "Day": "12/18/20",

        "SlotStart": "12/18/20 13:30",

        "SlotEnd": "12/18/20 14:00",

        "AvailableResources": 2,

        "Resources": [

          {

            "WebTimeSlotId": "D000198300",

            "PersoncompanyId": "D021609937",

            "Resource": "CMC ENERGY SERVICES"

          },

          {

            "WebTimeSlotId": "D000215273",

            "PersoncompanyId": "D050312166",

            "Resource": "KUMUDHA RAMASWAMY"

          }

        ]

      },

      {

        "Day": "12/18/20",

        "SlotStart": "12/18/20 14:00",

        "SlotEnd": "12/18/20 14:30",

        "AvailableResources": 1,

        "Resources": [

          {

            "WebTimeSlotId": "D000215273",

            "PersoncompanyId": "D050312166",

            "Resource": "KUMUDHA RAMASWAMY"

          }

        ]

      },

      {

        "Day": "12/18/20",

        "SlotStart": "12/18/20 14:30",

        "SlotEnd": "12/18/20 15:00",

        "AvailableResources": 1,

        "Resources": [

          {

            "WebTimeSlotId": "D000215273",

            "PersoncompanyId": "D050312166",

            "Resource": "KUMUDHA RAMASWAMY"

          }

        ]

      },

      {

        "Day": "12/18/20",

        "SlotStart": "12/18/20 15:00",

        "SlotEnd": "12/18/20 15:30",

        "AvailableResources": 1,

        "Resources": [

          {

            "WebTimeSlotId": "D000215273",

            "PersoncompanyId": "D050312166",

            "Resource": "KUMUDHA RAMASWAMY"

          }

        ]

      },

      {

        "Day": "12/18/20",

        "SlotStart": "12/18/20 15:30",

        "SlotEnd": "12/18/20 16:00",

        "AvailableResources": 1,

        "Resources": [

          {

            "WebTimeSlotId": "D000215273",

            "PersoncompanyId": "D050312166",

            "Resource": "KUMUDHA RAMASWAMY"

          }

        ]

      },

      {

        "Day": "12/18/20",

        "SlotStart": "12/18/20 16:00",

        "SlotEnd": "12/18/20 16:30",

        "AvailableResources": 1,

        "Resources": [

          {

            "WebTimeSlotId": "D000215273",

            "PersoncompanyId": "D050312166",

            "Resource": "KUMUDHA RAMASWAMY"

          }

        ]

      },

      {

        "Day": "12/18/20",

        "SlotStart": "12/18/20 16:30",

        "SlotEnd": "12/18/20 17:00",

        "AvailableResources": 1,

        "Resources": [

          {

            "WebTimeSlotId": "D000215273",

            "PersoncompanyId": "D050312166",

            "Resource": "KUMUDHA RAMASWAMY"

          }

        ]

      }

    ]

  };
}
