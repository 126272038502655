<h5 mat-dialog-title style="padding:0.2vw; color:black"> Office Detail : {{officeData.OfficeId}}</h5>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <mat-card class="user-card">
    <mat-card-content>
      <br>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Description</mat-label>
        <input matInput [(ngModel)]="officeDescription" placeholder="Description" (focusin)="clearMessage()" maxlength="50">
      </mat-form-field>
      <mat-form-field appearance="outline" class="formfield-half">
          <mat-label>ProgramSponsor</mat-label>
          <mat-select [(ngModel)]="selectedProgramsponsor">
              <mat-option *ngFor="let pSponsor of pSponsors" [value]="pSponsor.ProgramSponsorId" style="font-size: 11px;">
                 {{pSponsor.ProgramSponsorDescription}}
              </mat-option>
          </mat-select>
      </mat-form-field>
      <br>
      <mat-slide-toggle [(ngModel)]="officeActive">Active</mat-slide-toggle>
      <br>
      <label style="color: red; font-size: 0.9vw">{{errorMessage}}</label>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="saveOffice();">SAVE</button>
      <button mat-raised-button color="primary" (click)="cancelOffice();">CANCEL</button>
    </mat-card-actions>
  </mat-card>
</div>