import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { EnrollmentService } from '../services/enrollment.service';
import { UtilityService } from '../services/utility.service';
import { WorkorderService } from '../services/workorder.service';
import { SkipQuestionaire } from '../services/skip-questionaire.service';

@Component({
    selector: 'app-enroll-verify',
    templateUrl: './enroll-verify.component.html',
    styleUrls: ['./enroll-verify.component.scss']
})
export class EnrollVerifyComponent implements OnInit {
    recs = [];
    searching = true;
    saving = true;
    recommendedCount = [];
    recommendationText: string = "Finding the best programs for you based on your response";
    showForEligible = false;
    notQualifiedReason: string = '';
    programDetail: string;
    group: any;


    constructor(
        private dialog: MatDialogRef<EnrollVerifyComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private enrollmentService: EnrollmentService,
        public workorderService: WorkorderService,
        private utilityService: UtilityService,
        private skipQuestionaire: SkipQuestionaire,
        private router: Router
    ) { }

    ngOnInit() {
        this.searching = true;
        this.saving = true;
        this.group = localStorage.getItem('Group');
        this.saveQuestions();

    }

    private saveQuestions() {

        this.recommendationText = "Eligibility Qualification";
        this.programDetail = localStorage.getItem('programdetailid');
        this.enrollmentService.saveIndividualQuestionsByPersonCompanyId(this.data.personcompId, this.data.elList, this.programDetail, this.group)
            .subscribe(() => {
                this.saving = false;
                this.verifyEligibility();
            });
    }

    private verifyEligibility() {

        this.programDetail = localStorage.getItem('programdetailid');
        this.enrollmentService.verifyIndividualEligibilityQuestions(this.data.personcompId, this.programDetail)
            .subscribe((data) => {
                console.log('verify: ', data);
                if (data.QUALIFIED) {
                    this.recommendedCount = [data]
                }
                else {

                    this.notQualifiedReason = data.REASON + data.MESSAGE;
                    this.recommendedCount = []
                }

                this.searching = false;
            });
    }

    handleCancel() {
        this.dialog.close({ data: false });
    }

    handleEnroll(rec) {
        this.skipQuestionaire.handleEnroll(rec);
        this.dialog.close({ data: false });
    }


}



