<h5 mat-dialog-title>Add Role</h5>
<mat-divider></mat-divider>
<div mat-dialog-content style="width: 100vh;">
  <mat-card class="user-card">
    <mat-card-content>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Role Type</mat-label>
        <mat-select [(ngModel)]="roleType" [disabled]="workresource_id !== null">
          <mat-option *ngFor="let rtype of indService.indDetail.RolesTab.RoleTypeList" [value]="rtype" style="font-size: 11px;">
            {{rtype.DESCRIPTION}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="endDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <br />

      <mat-checkbox [(ngModel)]="inventory" style="margin-right: 20px;">Inventory</mat-checkbox>
      <mat-checkbox [(ngModel)]="active" style="margin-right: 20px;">Active</mat-checkbox>
      <mat-checkbox [(ngModel)]="certificate" style="margin-right: 20px;">Certificate</mat-checkbox>
      <br />

      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Client Key 1</mat-label>
        <input matInput placeholder="Client Key 1" [(ngModel)]="clientkey1">
      </mat-form-field>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Client Key 2</mat-label>
        <input matInput placeholder="Client Key 2" [(ngModel)]="clientkey2">
      </mat-form-field>
      <br>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Client Key 3</mat-label>
        <input matInput placeholder="Client Key 3" [(ngModel)]="clientkey3">
      </mat-form-field>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Client Key 4</mat-label>
        <input matInput placeholder="Client Key 4" [(ngModel)]="clientkey4">
      </mat-form-field>
      <br>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Client Key 5</mat-label>
        <input matInput placeholder="Client Key 5" [(ngModel)]="clientkey5">
      </mat-form-field>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Client Key 6</mat-label>
        <input matInput placeholder="Client Key 6" [(ngModel)]="clientkey6">
      </mat-form-field>
      <br>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="saveRole()" [disabled]="roleType === undefined">SAVE</button>
      <button mat-raised-button color="primary" [mat-dialog-close]>CANCEL</button>
    </mat-card-actions>
  </mat-card>
</div>
