import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WorkorderExceptionsService } from '../services/workorder-exceptions.service';
import { WorkorderExceptions } from '../models/workorderexceptions';
import { BBCSWorkorderExceptions } from '../models/workorderexceptions';
import Swal from 'sweetalert2';
import { DataTransferService } from '../services/data-transfer.service';

@Component({
  selector: 'app-workorder-exceptions',
  templateUrl: './workorder-exceptions.component.html',
  styleUrls: ['./workorder-exceptions.component.scss']
})
export class WorkorderExceptionsComponent implements OnInit {
  displayedColumns = ['Exception', 'Status', 'Message', 'Clearable', 'Clear'];
  dataSource = new MatTableDataSource<BBCSWorkorderExceptions>();
  workorderid: any;
  response: any = [];
  woexceptions: any = [];
  workorders: any;
  loadWorkorder: any = true;
  response_clear: any = [];
  response_clear_all: any = [];
  currentWorkorderexceptions: WorkorderExceptions;
  loadWorkorder_wait: any = false;
  exceptionArray: any;
  constructor(
    public dialogRef: MatDialogRef<WorkorderExceptionsComponent>, private dataTransfer: DataTransferService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private workorderExceptionsService: WorkorderExceptionsService
  ) { }

  ngOnInit(): void {
    this.dataTransfer.myMethod(true);
  }
  ngAfterViewInit() {
    this.Workorderexceptions();
  }
  Workorderexceptions() {
    this.loadWorkorder = true;
    this.workorderid = this.data.workorder;

    this.workorderExceptionsService.getWorkorderExceptions(this.workorderid).subscribe(data => {
      this.currentWorkorderexceptions = data;
      this.response = data;
      this.exceptionArray = this.currentWorkorderexceptions.Exceptions;
      this.dataSource.data = this.currentWorkorderexceptions.Exceptions;
      this.loadWorkorder = false;
    }, err => {
      Swal.fire({
        title: 'Error',
        text: err,
        icon: 'error',
        confirmButtonText: 'OK',
      })
      this.dialogRef.close("1");
    });
  }

  doClearExceptions() {
    this.response.Exceptions.forEach(element => {
      if ((element.ClearFlag === 1 || element.ClearFlag === true) && (element.WorkorderStatusCode === 'invalid')) {
        this.workorderExceptionsService.Clearworkorderexceptions(this.workorderid, element.ExceptionCode).subscribe(data => {
          this.response_clear = data;
          this.Workorderexceptions();
          this.data.loadDR();
          this.data.loadMenu();
        });
      }
    });
  }

  doClearAllExceptions() {
    this.response.Exceptions.forEach(element => {
      if (element.WorkorderStatusCode === 'invalid' && element.ExceptionClearable === 'Yes') {
        this.workorderExceptionsService.ClearAllworkorderexceptions(this.workorderid).subscribe(data => {
          this.response_clear_all = data;
          this.Workorderexceptions();
          this.data.loadDR();
          this.data.loadMenu();
        });
      }
    });
  }

  
  //tooltip for PS, HA and CL for Exception
  truncateAtWord(inputstr: string, length: number) {
    if (inputstr == "" || inputstr === undefined || inputstr.length < length) { return inputstr; }
    let iNextSpace = inputstr.indexOf(" ") > 0 ? inputstr.lastIndexOf(" ", length) : -1;
    return inputstr.substring(0, (iNextSpace > 0) ? iNextSpace : length).toString().trim() + "...";
  }

}
