import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';
import { Role } from 'src/app/models/role';
import { take, publish } from 'rxjs/operators';
import { ConnectableObservable } from 'rxjs';
import { WaitDialogComponent } from '../../util/wait-dialog/wait-dialog.component';

@Component({
  selector: 'app-role-create',
  templateUrl: './role-create.component.html',
  styleUrls: ['./role-create.component.scss']
})
export class RoleCreateComponent implements OnInit {

  roleId: any;
  roleName: any;
  roleActive: boolean = true;
  ifRoleExists: boolean = true;
  IfMandatory: boolean = false;
  errorMessage: any;
  createRoleMethod: any;
  existsMethod: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog,
    private router: Router,
    private userService: UserService) { }

  ngOnInit() {
  }

  saveRole() {
    this.checkAllFieldsAreEmpty();
    if (!this.IfMandatory) {
      this.saveRoleIfTrue();
    }
    else {
      this.errorMessage = "All fields are mandatory";
    }
  }

  saveRoleIfTrue() {
    let className = localStorage.getItem("themeName");
    const dialogRef = this.dialog.open(WaitDialogComponent, {data: {caption: 'Saving...'},panelClass:className});
    let self = this;
    let role = new Role();
    role.RoleId = this.roleId.toUpperCase();
    role.RoleName = this.roleName;
    role.RoleActive = this.roleActive;
    role.CreatedBy = localStorage.getItem("loggedInUserName");
    role.ModifiedBy = localStorage.getItem("loggedInUserName");

    var roleExists = this.userService.roleExists(role.RoleId, role.RoleName).pipe(take(1));
    var createRole = this.userService.createRole(role).pipe(take(1), publish()) as ConnectableObservable<any>;

    roleExists.subscribe(res => {
      this.ifRoleExists = true;
    }, error => {
      this.ifRoleExists = false;
      this.errorMessage = "Role already exists";
    }, createRole.connect.bind(createRole));


    createRole.subscribe(function (data) {
        dialogRef.close();
      if (data && self.ifRoleExists) {
        Swal.fire({
          text: "Role Created Successfully",
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          self.dialog.closeAll();
        })
      }
      else {
        Swal.fire({
          text: "Role Create failed",
          icon: 'error',
          confirmButtonText: 'OK',
        }).then(() => {
          self.dialog.closeAll();
        })
      }
    }, error => {
      Swal.fire({
          text: "Role Create failed. Server Error",
          icon: 'error',
          confirmButtonText: 'OK',
      }).then(() => {
          this.dialog.closeAll();
      })
  });
  }

  clearMessage() {
    this.errorMessage = "";
  }


  checkAllFieldsAreEmpty() {

    if ((this.roleId == null || this.roleId == undefined || this.roleId == "")
      || (this.roleName == null || this.roleName == undefined || this.roleName == "")) {
      this.IfMandatory = true;
    }
    else {
      this.IfMandatory = false;
    }
  }


  cancelRole() {

    this.dialog.closeAll();
    this.router.navigate(['/roleAdmin']);

  }

}
