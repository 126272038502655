import { Injectable } from '@angular/core';
import { Interaction } from '../models/interaction';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { UtilityService } from './utility.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InteractionsService {

  private intSearchWsUrl1 = environment.apiUrl + 'interaction';
  currentInteraction: Interaction;
  interactions: Interaction[];
  programSponsorId:any;

  constructor(
    private http: HttpClient,
    private utilityService: UtilityService
  ) {


  }
  public getHeaders() {
    const email = localStorage.getItem('loggedInUserEmail');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-email': email,
        'x-programsponsorid': this.utilityService.currentUtility.ProgramsponsorId
      })
    };
    return httpOptions;
  }


  getInteractionsByCustomerId(customerId): Observable<Interaction[]> {

    const searchUrl = this.intSearchWsUrl1 + '/' +this.utilityService.currentUtility.OfficeId
      + '/account/' + customerId + '?code='+environment.apiCode;

    return this.http.get<Interaction[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }


  getInteractionType(): Observable<any> {
    var url = this.intSearchWsUrl1 + '/' + this.utilityService.currentUtility.OfficeId + '/GetInteractionTypes/' +
      'ProgramSponsorId/' + this.utilityService.currentUtility.ProgramsponsorId + '?code='+environment.apiCode;

    return this.http.get(url, this.getHeaders()).pipe(map(response => response), catchError(err => this.utilityService.handleError(err, this, [])));
  }

  getInteractionMarketing(): Observable<any> {
    var url = this.intSearchWsUrl1 + '/' + this.utilityService.currentUtility.OfficeId + '/GetInteractionMarketing/' +
      'ProgramSponsorId/' + this.utilityService.currentUtility.ProgramsponsorId + '?code='+environment.apiCode;
    return this.http.get(url, this.getHeaders()).pipe(map(response => response),  catchError(err => this.utilityService.handleError(err, this, [])));
  }

  getInteractionWhys(typeCode): Observable<any> {
    var url = this.intSearchWsUrl1 + '/' + this.utilityService.currentUtility.OfficeId + '/GetInteractionWhys/' +
      'ProgramSponsorId/' + this.utilityService.currentUtility.ProgramsponsorId + '/TypeCode/' + typeCode + '?code='+environment.apiCode;
    return this.http.get(url, this.getHeaders()).pipe(map(response => response),  catchError(err => this.utilityService.handleError(err, this, [])));
  }

  getInteractionReasons(typeCode): Observable<any> {
    var url = this.intSearchWsUrl1 + '/' + this.utilityService.currentUtility.OfficeId + '/GetInteractionReasons/' +
      'ProgramSponsorId/' + this.utilityService.currentUtility.ProgramsponsorId + '/TypeCode/' + typeCode + '?code='+environment.apiCode;
    return this.http.get(url, this.getHeaders()).pipe(map(response => response),  catchError(err => this.utilityService.handleError(err, this, [])));
  }


  addInteractions(interaction: Interaction): Observable<any> {
   
    var url = this.intSearchWsUrl1 + '/' + this.utilityService.currentUtility.OfficeId + '?code='+environment.apiCode;
    return this.http.post(url, interaction, this.getHeaders()).pipe(map(response => response),  catchError(err => this.utilityService.handleError(err, this, null)));
  }

}
