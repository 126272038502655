
export class AddressSearch {
    FirstName: string = "";
    LastName: string = "";
    FullName: string = "";
    StreetAddressNumber: string = "";
    StreetAddressName: string = "";
    Apartment: string = "";
    City: string = "";
    State: string = "";
    Zipcode: string = "";
}