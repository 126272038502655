<div style="margin: 50px; margin-left: 37%;">  
  <mat-form-field appearance="outline" style="padding-right: 2.2vw; width:17.6vwF">
    <mat-label>Select Date</mat-label>
    <input matInput [matDatepicker]="datePicker" placeholder="Choose a date" [(ngModel)]="selectedDate"
      (dateInput)="onDate($event)">
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker #datePicker (selectedChanged)="onDate($event)"></mat-datepicker>
  </mat-form-field>
  <button mat-raised-button color="primary"  (click)="optimizeRoute(selectedDate)">
   Search
  </button>
</div>
<div style="height: 35px; top:-150px"  *ngIf="searching">
  <mat-spinner style="margin:0 auto;" ></mat-spinner>
  </div> 
<div  *ngIf="showTableData">
<mat-card class="list-card" style="margin-right: 1vw !important">
  <mat-card-header>
  </mat-card-header>
  <mat-card-content style="margin-left:0.3vw">
   
<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100% !important;">

  <ng-container matColumnDef="ro_id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>RO ID</th>
    <td mat-cell *matCellDef="let element"> {{element.ro_id}} </td>
  </ng-container>

  <ng-container matColumnDef="timerun">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Time Run</th>
    <td mat-cell *matCellDef="let element"> {{element.timerun}} </td>
  </ng-container>

  <ng-container matColumnDef="targetday">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Target Day</th>
    <td mat-cell *matCellDef="let element"> {{element.targetday}} </td>
  </ng-container> 

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="curr-cust" (dblclick)="handleClick(row)"></tr>
</table>       
</mat-card-content>
</mat-card>
</div>