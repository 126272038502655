import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ProgramSponsor } from 'src/app/models/programsponsor';
import { WaitDialogComponent } from '../../util/wait-dialog/wait-dialog.component';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-program-sponsor-detail',
    templateUrl: './program-sponsor-detail.component.html',
    styleUrls: ['./program-sponsor-detail.component.scss']
})
export class ProgramSponsorDetailComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router, private userService: UserService, private dialog: MatDialog) { }
    pSponsorData: any;
    pSponsorActive: boolean = false;
    pSponsorDescription: any;
    compassUri: any;
    compassAuthorization: any;
    errorMessage: any;
    emailRegEx: RegExp = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
    URL_REGEXP = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    emailValid: boolean = true;
    urlValid: boolean = true;
    compassEmail: any;
    compassPassword: any;
    salesforceUsername: string;
    salesforcePassword: string;
    IfMandatory: boolean = false;
    sources:[] = [];
    selectedSource:any;
    
    ngOnInit() {

        this.pSponsorData = this.data.dataKey;
        this.pSponsorDescription = this.pSponsorData.ProgramSponsorDescription;
        this.pSponsorActive = this.pSponsorData.ProgramSponsorActive;
        this.compassUri = this.pSponsorData.CompassUri;
        this.compassAuthorization = this.pSponsorData.CompassAuthorization;

        let dataObj = this.compassAuthorization.split(':');
        this.compassEmail = dataObj[0];
        this.compassPassword = dataObj[1];

        this.salesforceUsername = this.pSponsorData.SalesforceUsername;
        this.salesforcePassword = this.pSponsorData.SalesforcePassword;

        this.userService.getSourceList().subscribe(res=>{
            this.sources = res;
            this.selectedSource = this.pSponsorData.SourceId
        })
    }

    checkAllFieldsAreEmpty() {
        
        if ((this.pSponsorDescription == null || this.pSponsorDescription == undefined||this.pSponsorDescription=="")||
            (this.compassEmail == null || this.compassEmail == undefined||this.compassEmail=="")
            || (this.compassPassword == null || this.compassPassword == undefined || this.compassPassword=="")
            || (this.salesforceUsername === null || this.salesforceUsername === undefined || this.salesforceUsername === "")
            || (this.salesforcePassword === null || this.salesforcePassword === undefined || this.salesforcePassword === "")
            || (this.compassUri == null || this.compassUri == undefined||this.compassUri=="")
            ||(this.selectedSource == null || this.selectedSource == undefined || this.selectedSource =="")) {
            this.IfMandatory = true;
        }
        else {
            this.IfMandatory = false;
        }
    }

    savePSponsor() {
        Swal.fire({
            title: 'Save Changes?',
            text: "Changing this update your configuration.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Save Changes'
        }).then((result) => {
            if (result.value) {
                this.doSavePSponsor();
            }
        });
    }

    private doSavePSponsor() {
        this.checkAllFieldsAreEmpty();
        if (!this.IfMandatory) {
            let compassEmailID = this.compassEmail.toLowerCase();
            let url = this.compassUri.toLowerCase();
            this.emailValid = this.emailRegEx.test(compassEmailID);
           // this.urlValid = this.URL_REGEXP.test(url);
            if (!this.emailValid) {
                this.errorMessage = "Not a valid email";
            }
           // if (!this.urlValid) {
              //  this.errorMessage = "Not a valid Url";
           // }

            if (this.emailValid ) {
                let className = localStorage.getItem("themeName");
                const dialogRef = this.dialog.open(WaitDialogComponent, {data: {caption: 'Saving...'},panelClass:className});
                let pSponsor = new ProgramSponsor();
                pSponsor.ModifiedBy = localStorage.getItem("loggedInUserName");
                pSponsor.ProgramSponsorId = this.pSponsorData.ProgramSponsorId;
                pSponsor.ProgramSponsorActive = this.pSponsorActive;
                pSponsor.ProgramSponsorDescription = this.pSponsorDescription;
                pSponsor.CompassUri = this.compassUri;
                pSponsor.CompassAuthorization = this.compassEmail + ":" + this.compassPassword;

                pSponsor.SalesforceUsername = this.salesforceUsername;
                pSponsor.SalesforcePassword = this.salesforcePassword;

                pSponsor.SourceId = this.selectedSource;

                this.userService.updateProgramSponsor(pSponsor).subscribe(res => {

                    dialogRef.close();

                    if (res) {
                        Swal.fire({
                            text: "Updated Successfully",
                            icon: 'success',
                            confirmButtonText: 'OK',
                        }).then(() => {
                            this.dialog.closeAll();
                        })
                    }
                    else {
                        Swal.fire({
                            text: "Update failed",
                            icon: 'error',
                            confirmButtonText: 'OK',
                        }).then(() => {
                            this.dialog.closeAll();
                        })
                    }
                }, err => {
                    Swal.fire({
                        text: "Update failed",
                        icon: 'error',
                        confirmButtonText: 'OK',
                    }).then(() => {
                        this.dialog.closeAll();
                    })
                })
            }
        }
        else {
            this.errorMessage = "All fields are mandatory"
        }
    }

    clearMessage() {
        this.errorMessage = "";
    }

    cancelPSponsor() {
        this.dialog.closeAll();
        this.router.navigate(['programSponsorAdmin']);
    }

}
