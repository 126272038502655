import { Office } from './office';
import { ProgramSponsorUserRole } from '../user/user-detail/user-detail.component';

export class ProgramSponsor {
    ProgramSponsorId: string = '';
    ProgramSponsorDescription: string = '';
    CreatedDate: Date;
    ModifiedDate: Date;
    CreatedBy:string;
    ModifiedBy:string;
    ProgramSponsorActive: boolean = true;
    CompassUri:string;
    CompassAuthorization:string;
    SalesforceUsername: string;
    SalesforcePassword: string;
    Office:Office[] = [];
    ProgramSponsorUserRole:ProgramSponsorUserRole[] = [];
    SourceId: string = '';     // BBCS
    Source: any;
    BBCSUserId:any;
}
