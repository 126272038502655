import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { IndividualService } from '../../../services/individual.service';

@Component({
  selector: 'app-additional-hours',
  templateUrl: './additional-hours.component.html',
  styleUrls: ['./additional-hours.component.scss']
})
export class AdditionalHoursComponent implements OnInit {

    startDate;
    endDate;

    constructor(
        public dialogRef: MatDialogRef<AdditionalHoursComponent>,
        public indService: IndividualService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        if (this.data.editMode) {
            this.startDate = this.indService.indDetail.AdditionalHoursTab.AdditionalHours[this.data.index].STARTTIME;
            this.endDate = this.indService.indDetail.AdditionalHoursTab.AdditionalHours[this.data.index].ENDTIME;
        }
    }

    saveAdditionalHours() {
        if (this.data.editMode) {
            this.indService.indDetail.AdditionalHoursTab.AdditionalHours[this.data.index].STARTTIME = moment(this.startDate).format("MM/DD/YYYY HH:mm:ss");
            this.indService.indDetail.AdditionalHoursTab.AdditionalHours[this.data.index].ENDTIME =moment( this.endDate).format("MM/DD/YYYY HH:mm:ss");
        } else {
            const hours = {
                ADDITIONALHOURS_ID: null,
                STARTTIME: moment(this.startDate).format("MM/DD/YYYY HH:mm:ss"),
                ENDTIME: this.endDate == null ? null : moment(this.endDate).format("MM/DD/YYYY HH:mm:ss")
            };

            this.indService.indDetail.AdditionalHoursTab.AdditionalHours.push(hours);
        }

        this.dialogRef.close('1');
    }

}
