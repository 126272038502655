import { Role } from './role';

export class Module {
    ModuleId: string = '';
    ModuleName: string = '';
    CreatedBy: string = '';
    ModifiedBy: string = '';
    CreatedDate: Date;
    ModifiedDate:Date;
    ModuleItem:any;
    IsActive: boolean = true;
    Rolemodules= [];
    ModuleRef:any = null;
    Path:any;
    RoleModule:[] = [];//for get modules
    RoleString:any = '';
}