import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from '../../../services/utility.service';
import { IndividualService } from '../../../services/individual.service';
import * as moment from 'moment';

@Component({
    selector: 'app-time-off',
    templateUrl: './time-off.component.html',
    styleUrls: ['./time-off.component.scss']
})
export class TimeOffComponent implements OnInit {

    startDate;
    endDate;
    reason: string;
    user: string;
    dateEntered;

    constructor(
        public dialogRef: MatDialogRef<TimeOffComponent>,
        public indService: IndividualService,
        public utilityService: UtilityService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        if (this.data.editMode) {
            this.startDate = this.indService.indDetail.TimeOffTab.TimeOff[this.data.index].STARTTIME;
            this.endDate = this.indService.indDetail.TimeOffTab.TimeOff[this.data.index].ENDTIME;
            this.reason = this.indService.indDetail.TimeOffTab.TimeOff[this.data.index].NOTES;
        }
    }

    saveTimeOff() {
        const username = this.utilityService.currentUtility.BBCSUserId;

        if (this.reason === undefined) {
            this.reason = null;
        }

        if (this.data.editMode) {
          
            this.indService.indDetail.TimeOffTab.TimeOff[this.data.index].STARTTIME = moment(this.startDate).startOf('minute').format('MM/DD/YYYY HH:mm:ss');
            this.indService.indDetail.TimeOffTab.TimeOff[this.data.index].ENDTIME =  moment(this.endDate).startOf('minute').format('MM/DD/YYYY HH:mm:ss');
            this.indService.indDetail.TimeOffTab.TimeOff[this.data.index].NOTES = this.reason;

            console.log("START TIME",this.indService.indDetail.TimeOffTab.TimeOff[this.data.index].STARTTIME);

        } else {
            const hours = {
                TIMEOFF_ID: null,
                STARTTIME: moment(this.startDate).startOf('minute').format('MM/DD/YYYY HH:mm:ss'),
                ENDTIME: moment(this.endDate).startOf('minute').format('MM/DD/YYYY HH:mm:ss'),
                PERSONCOMPANY_ID: this.indService.personcompanyId,
                USER_ID: username,
                STATUSDATESYS: new Date(),
                NOTES: this.reason
            };

            this.indService.indDetail.TimeOffTab.TimeOff.push(hours);
        }

        this.dialogRef.close('1');
    }

}
