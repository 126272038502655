<h5 mat-dialog-title  style="padding:0.2vw; color:black; margin:0 !important">Create Role</h5>
<mat-divider></mat-divider>
<div mat-dialog-content style="height: 45vh;
width: 100vh;">
    <mat-card class="user-card" style="width: 93%;">
        <mat-card-content>
            <mat-form-field appearance="outline" class="formfield-half" style="width:50% !important">
                <mat-label>Role ID</mat-label>
                <input matInput placeholder="Name" [(ngModel)]="roleId" (focusin)="clearMessage()" maxlength="20">
            </mat-form-field>
            <mat-form-field appearance="outline" class="formfield-half" style="width:50% !important">
                <mat-label>Role Name</mat-label>
                <input matInput placeholder="Name" [(ngModel)]="roleName" (focusin)="clearMessage()" maxlength="50">
            </mat-form-field>
            <br>
            <mat-slide-toggle [(ngModel)]="roleActive">Active</mat-slide-toggle>
            <br>
            <br>
            <label style="color: red; font-size: 0.9vw">{{errorMessage}}</label>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="primary" (click)="saveRole();">SAVE</button>
            <button mat-raised-button color="primary" (click)="cancelRole();">CANCEL</button>
        </mat-card-actions>
    </mat-card>
</div>