import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appInputlengthFilter]'
})
export class InputlengthFilterDirective {

  /* constructor(private el: ElementRef) {
    console.log(el.nativeElement.)
  } */

  @Input() max: any;
  @Input() value: any;
  @Input() maxlength:any;
  constructor(private elementRef: ElementRef) {
  }
 ngOnInit() {
    //  console.log(this.max, this.value, this.maxlength);
 }
  @HostListener('keypress', ['$event']) onKeyDown(event) {
      let e = <KeyboardEvent> event;
      if(this.value != undefined && this.max != undefined){
        if(this.value > this.max){
          e.preventDefault();
        }
        if(this.value != undefined && this.maxlength != undefined){
          if(this.value.length > this.maxlength){
            e.preventDefault();
          }
        }
      }
   

     /*  if (this.value != undefined && (this.max != undefined || this.maxlength != undefined) && (this.value > this.max || this.value.length > this.maxlength)) {
        e.preventDefault
      } else {
         return;
      } */

  }

  /* private highlight(color: string) {
    this.el.nativeElement.style.backgroundColor = color;
  } */
}
