 <h1 mat-dialog-title class="header">
    {{usageType}} Meter Usage 
        <button mat-icon-button class="close" (click)="closeDialog()">
          <mat-icon>close</mat-icon>
        </button>
  </h1> 

  <div *ngIf="spin && !viewUsage" class="spinner-wrapper">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>

  <ng-container *ngIf="!viewUsage && !spin">        
    <div><button mat-button (click)="showUsage('Electric')">View Electric Meter Usage</button></div>
    <div><button mat-button (click)="showUsage('Gas')">View Gas Meter Usage</button></div>    
  </ng-container>

  <div *ngIf="viewUsage" mat-dialog-content>
    <mat-card>
      <mat-card-content>
        <div *ngIf="spin" class="spinner-wrapper">
          <mat-spinner [diameter]="70"></mat-spinner>
        </div>
        <table *ngIf="!spin && jsonContent.length > 0" class="styled-table mat-elevation-z8">
          <thead class="table-header-color">
            <tr>
              <th>Serial Number</th>
              <th>Period Start</th>
              <th>Read Date</th>        
              <th>Usage</th>    
              <th>Demand</th>    
              <th>Amount</th>    
              <th>Usage Type</th>    
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let usage of jsonContent">
              <td> {{usage.SerialNo}} </td>
              <td> {{usage.PeriodStart | date:'M/d/yyyy'}}</td>
              <td> {{usage.ReadDate | date:'M/d/yyyy'}} </td>
              <td> {{usage.Usage}} </td>      
              <td></td>
              <td></td>
              <td></td>
          </tr>
          </tbody>
        </table>
        <input *ngIf="!spin" type="file" (change)="handleFiles($event)" name="uploadExcel" />
      </mat-card-content>
      <mat-card-actions *ngIf="!spin">
        <button (click)="uploadDoc()" class="button-spacing">Upload</button>
        <button (click)="back()">Back</button>
      </mat-card-actions>
      
    </mat-card>
  </div>