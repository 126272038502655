import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';

import { UserService } from '../../services/user.service';

import { User } from '../../models/user';
import { UserDetailComponent } from '../user-detail/user-detail.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { UserCreateComponent } from '../user-create/user-create.component';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    users = [];
    displayedColumns: string[] = ['UserEmail', 'UserActive','UserName', 'CreatedDate', 'ModifiedBy', 'edit'];
    dataSource = new MatTableDataSource<User>();
    dialogRef: MatDialogRef<UserDetailComponent>;
    searching = true;

    constructor(
        private userService: UserService,
        private router: Router,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate =
            (data: User, filtersJson: string) => {
                const matchFilter = [];
                const filters = JSON.parse(filtersJson);

                filters.forEach(filter => {
                    const val = data[filter.id] === null ? '' : data[filter.id];
                    matchFilter.push(val.toLowerCase().includes(filter.value.toLowerCase()));
                });
                return matchFilter.every(Boolean);
            };
        this.getUsers();
    }

    private getUsers() {
        this.userService.getUsers().subscribe(data => {

            this.users = data;
            this.dataSource.data = this.users;

            this.searching = false;
        });
    }

    public handleClick(user: User) {
        let className=localStorage.getItem("themeName");
        this.dialogRef = this.dialog.open(UserDetailComponent,
            {
                data: {
                    dataKey: user
                },panelClass:className
            });

        this.dialogRef.afterClosed().subscribe(result => {
            this.getUsers();
        });
    }

    addNewUser() {
        let className=localStorage.getItem("themeName");
        const dialogCreate = this.dialog.open(UserCreateComponent,{panelClass:className});
        dialogCreate.afterClosed().subscribe(result => {
            this.getUsers();
        });

    }

    /* applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    } */

    applyFilter(filterValue: string) {
        const tableFilters = [];
        tableFilters.push({
            id: 'UserEmail',
            value: filterValue
        });


        this.dataSource.filter = JSON.stringify(tableFilters);

    }
}
