<mat-spinner [diameter]="80" color="primary" *ngIf="searching"
  style="margin:0 auto; width:50% !important; margin-top: 2vw;"></mat-spinner>
<div [hidden]="searching">
  <mat-card class="user-card" style="width: 93% !important;">
    <mat-card-header>
      <mat-card-title style="font-size: 16px">
        <span matBadge="{{ programSponsors.length }}" matBadgeOverlap="false">Program Sponsors</span>
      </mat-card-title>
      <div style="width: 83% !important;">
        <button mat-button (click)="addNewPSponsor()" matTooltip="Add"
          style="background: transparent;float:right !important; height: 100% !important">
          <mat-icon matTooltip="Add ProgramSponsor" aria-hidden="false" color="primary" 
          style="text-align: center;
                width: 98%;float:right;">
            add
          </mat-icon>
        </button>
      </div>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>
      <br>
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        <ng-container matColumnDef="ProgramSponsorId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 18%">Program Sponsor ID</th>
          <td mat-cell *matCellDef="let sponsor" matTooltip="{{ sponsor.ProgramSponsorId }}">
            {{ sponsor.ProgramSponsorId }} </td>
        </ng-container>

        <ng-container matColumnDef="ProgramSponsorDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 17%">Name</th>
          <td mat-cell *matCellDef="let sponsor" matTooltip="{{ sponsor.ProgramSponsorDescription }}">
            {{sponsor.ProgramSponsorDescription}} </td>
        </ng-container>

        <ng-container matColumnDef="CompassUri">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 27%;">Compass URI</th>
          <td mat-cell *matCellDef="let sponsor" matTooltip="{{ sponsor.CompassUri }}"> {{sponsor.CompassUri}} </td>
        </ng-container>

        <ng-container matColumnDef="CompassAuthorization">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 16%">Compass Authorization</th>
          <td mat-cell *matCellDef="let sponsor">
            <mat-icon matTooltip="Security" *ngIf="sponsor.CompassAuthorization">security</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="ProgramSponsorActive">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 8%">Active</th>
          <td mat-cell *matCellDef="let sponsor">
            <mat-icon matTooltip="Check" *ngIf="sponsor.ProgramSponsorActive" color="primary">check_box</mat-icon>
            <mat-icon matTooltip="Cancel" *ngIf="!sponsor.ProgramSponsorActive" style="color: red !important">cancel</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="CreatedDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%">Created Date</th>
          <td mat-cell *matCellDef="let sponsor"> {{sponsor.CreatedDate  | date:'shortDate' }} </td>
        </ng-container>


        <ng-container matColumnDef="ModifiedBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%">ModifiedBy</th>
          <td mat-cell *matCellDef="let sponsor" matTooltip="{{ sponsor.ModifiedBy }}"> {{sponsor.ModifiedBy }} </td>
        </ng-container>


        <ng-container matColumnDef="edit" stickyEnd>
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 6% !important"></th>
          <td mat-cell *matCellDef="let sponsor">
            <button mat-button (click)="handleClick(sponsor)">
              <mat-icon matTooltip="Edit" color="primary">create</mat-icon>
            </button>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="curr-cust"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5,10,20]" showFirstLastButtons></mat-paginator>
    </mat-card-content>
  </mat-card>
</div>

<div style="margin-bottom: 75px;"></div>