<!-- <div [ngClass]="className"> -->
<mat-spinner [diameter]="80" color="primary" *ngIf="searching"
  style="margin:0 auto; width:50% !important; margin-top: 2vw;"></mat-spinner>
<div [hidden]="searching">
  <mat-card class="user-card" style="width: 90%;">
    <mat-card-header>
      <mat-card-title style="font-size: 16px">
        <span matBadge="{{ modules.length }}" matBadgeOverlap="false">Modules</span>
      </mat-card-title>
      <div style="width: 100% !important;">
        <button mat-button (click)="addNewModule()" matTooltip="Add Module"
          style="background: transparent;float:right !important; height: 100% !important">
          <mat-icon matTooltip="Add Module" aria-hidden="false" color="primary" 
          style="text-align: center;
                width: 98%;float:right;">
            add
          </mat-icon>
        </button>
      </div>
      <!--  <mat-card-subtitle>
            Double Click row to edit.
          </mat-card-subtitle> -->
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content style="margin-bottom: 0 !important">
      <br>
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="table-layout: fixed">
        <ng-container matColumnDef="ModuleId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:22% !important;">Module ID</th>
          <td mat-cell *matCellDef="let module" matTooltip="{{module.ModuleId}}"> {{ module.ModuleId }} </td>
        </ng-container>

        <ng-container matColumnDef="ModuleName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 22%;">Name</th>
          <td mat-cell *matCellDef="let module" matTooltip="{{module.ModuleName}}"> {{module.ModuleName}} </td>
        </ng-container>

        <ng-container matColumnDef="ModuleItemName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;">Module Item</th>
          <td mat-cell *matCellDef="let module" matTooltip="{{module.ModuleItemName}}"> {{module.ModuleItemName}} </td>
        </ng-container>

        <ng-container matColumnDef="Roles">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 13%;">Roles</th>
          <td mat-cell *matCellDef="let module" matTooltip="{{module.RoleString}}"> {{module.RoleString}} </td>
        </ng-container>

        <ng-container matColumnDef="IsActive">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
          <td mat-cell *matCellDef="let module">
            <mat-icon matTooltip="Is Active" *ngIf="module.IsActive" color="primary">check_box</mat-icon>
            <mat-icon matTooltip="Cancel" *ngIf="!module.IsActive" style="color: red !important">cancel</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="CreatedDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;">Created Date</th>
          <td mat-cell *matCellDef="let module"> {{module.CreatedDate  | date:'shortDate' }} </td>
        </ng-container>

        <ng-container matColumnDef="ModifiedBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Modified By</th>
          <td mat-cell *matCellDef="let module" matTooltip="{{module.ModifiedBy}}"> {{module.ModifiedBy }} </td>
        </ng-container>



        <ng-container matColumnDef="edit" stickyEnd>
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 8% !important"></th>
          <td mat-cell *matCellDef="let module">
            <button mat-button (click)="handleClick(module)" matTooltip="Create">
              <mat-icon matTooltip="Edit" color="primary">create</mat-icon>
            </button>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5,10,20]" showFirstLastButtons></mat-paginator>
    </mat-card-content>
    <!--  <mat-card-actions>
          <button mat-raised-button color="primary" routerLink="/search">DONE</button>
        </mat-card-actions> -->
  </mat-card>
</div>

<div style="margin-bottom: 75px;"></div>
<!-- </div> -->