import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { UserService } from 'src/app/services/user.service';
import { Role } from 'src/app/models/role';
import { ProgramSponsor } from 'src/app/models/programsponsor';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import Swal from 'sweetalert2';
import { WaitDialogComponent } from '../../util/wait-dialog/wait-dialog.component';

@Component({
    selector: 'app-user-detail',
    templateUrl: './user-detail.component.html',
    styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

    userData: any;
    userActive: boolean;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private userService: UserService
        , private dialog: MatDialog, private router: Router) { }
    dataSource = new MatTableDataSource<any>();
    pUserRoles: ProgramSponsorUserRole = new ProgramSponsorUserRole();
    pSponsors: ProgramSponsor[] = [];
    roles: Role[] = [];
    selectedRole = [];
    ifPUserEmpty: boolean = false;
    selectedProgramsponsor: any;
    displayedColumns: string[] = ['programsponsor', 'role', 'delete'];
    @ViewChild('paginator', { static: true }) paginator: MatPaginator;


    ngOnInit() {
        this.userData = this.data.dataKey;
        this.userActive = this.userData.UserActive;
        this.getProgramSponsorUserRole();
        this.userService.getRoles().subscribe(res => {
            res.forEach(element => {
                if(element.RoleActive){
                    this.roles.push(element);
                }
            });
           
        })

        this.userService.getProgramSponsors().subscribe(res => {
            res.forEach(element => {
                if(element.ProgramSponsorActive){
                    this.pSponsors.push(element);
                }
            });       
         })

        if ((this.selectedProgramsponsor == null || this.selectedProgramsponsor == "" || this.selectedProgramsponsor == undefined) && (this.selectedRole.length <= 0)) {
            this.ifPUserEmpty = true;
        }

    }

    enablePUser() {
       
        if ((this.selectedProgramsponsor != null && this.selectedProgramsponsor != "" && this.selectedProgramsponsor != undefined) && (this.selectedRole.length > 0)) {
            this.ifPUserEmpty = false;
        }
    }

    deletepUserRole(pUserRole) {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(() => {
            this.confirmDelete(pUserRole);
        
        })
    }

    confirmDelete(pUserRole) {
        let psRole = new ProgramSponsorUserRole();
        psRole.ModifiedBy = localStorage.getItem("loggedInUserName");
        psRole.ProgramsponsorUserRole.push(pUserRole);
        this.userService.deleteProgramSponsorUserRole(psRole, this.userData.UserId).subscribe(res => {
            if (res) {
                this.getProgramSponsorUserRole();
            }
            else {
                Swal.fire({
                    text: "Could not delete ProgramSponsor User Role!",
                    icon: 'error',
                    confirmButtonText: 'OK',
                })
            }
        }, err => {
            Swal.fire({
                text: "Delete Failed!",
                icon: 'error',
                confirmButtonText: 'OK',
            })
        })
    }

    getProgramSponsorUserRole() {
        this.userService.getProgramSponsorUserRole(this.userData.UserEmail).subscribe(res => {
            this.pUserRoles.ProgramsponsorUserRole = res;
            this.dataSource.data = this.pUserRoles.ProgramsponsorUserRole;
            this.dataSource.paginator = this.paginator;
        })
    }

    setUserPSponsor() {
        this.pUserRoles.ProgramsponsorUserRole.forEach(element => {
            this.selectedProgramsponsor = element.ProgramsponsorId;
            this.selectedRole.push(element.RoleId);
        });

    }

    addPSRole() {
        Swal.fire({
            title: 'Save Changes?',
            text: "Changing this update your configuration.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Save Changes'
        }).then((result) => {
            if (result.value) {
                this.doAddPSRole();
            }
        });
      }

      private doAddPSRole() {
        let psRole = new ProgramSponsorUserRole();
        let roles = this.selectedRole;
        psRole.ProgramsponsorUserRole = [];
        psRole.ModifiedBy = localStorage.getItem("loggedInUserName");
        roles.forEach(element => {
            psRole.ProgramsponsorUserRole.push({ 'ProgramsponsorId': this.selectedProgramsponsor, 'RoleId': element })
        });
        this.userService.addPSRole(psRole, this.userData.UserId).subscribe(res => {
            if (res == true) {
                Swal.fire({
                    text: "Program Sponsor Role Added Successfully",
                    icon: 'success',
                    confirmButtonText: 'OK',
                }).then(() => {
                    this.getProgramSponsorUserRole();
                    this.selectedProgramsponsor = null;
                    this.selectedRole = [];
                    this.ifPUserEmpty = true;
                })

            }
            else {
                Swal.fire({
                    text: "Program Sponsor Role Add failed",
                    icon: 'error',
                    confirmButtonText: 'OK',
                })
            }
        }, err => {
            Swal.fire({
                text: "Program Sponsor Role Add failed",
                icon: 'error',
                confirmButtonText: 'OK',
            })
        })

    }

    saveUser() {
        Swal.fire({
            title: 'Save Changes?',
            text: "Changing this update your configuration.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Save Changes'
        }).then((result) => {
            if (result.value) {
                this.doSaveUser();
            }
        });
      }

      private doSaveUser() {
        let className = localStorage.getItem("themeName");
        const dialogRef = this.dialog.open(WaitDialogComponent, {data: {caption: 'Saving...'},panelClass:className});
        let user = new User();
        user.ModifiedBy = localStorage.getItem("loggedInUserName");
        user.UserActive = this.userActive;
        this.userService.updateUser(this.userData.UserId, user).subscribe(res => {
            dialogRef.close();
            if (res) {
                Swal.fire({
                    text: "Updated Successfully",
                    icon: 'success',
                    confirmButtonText: 'OK',
                }).then(() => {
                    this.dialog.closeAll();
                })
            }
            else {
                Swal.fire({
                    text: "Update failed",
                    icon: 'error',
                    confirmButtonText: 'OK',
                }).then(() => {
                    this.dialog.closeAll();
                })
            }
        }, err => {
            Swal.fire({
                text: "Update failed",
                icon: 'error',
                confirmButtonText: 'OK',
            }).then(() => {
                this.dialog.closeAll();
            })
        })
    }

    cancelUser() {
        this.dialog.closeAll();
        this.router.navigate(['userAdmin']);
    }

}

export class ProgramSponsorUserRole {
    ProgramsponsorUserRole: {
        ProgramsponsorId: any;
        RoleId: any;
    }[] = [];
    ModifiedBy: string;
}
