import { Component, OnInit,ViewChild,OnChanges} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { InventoryMaintenanceService } from '../../services/inventory-maintenance.service';
import { DataTransferService } from '../../services/data-transfer.service';
import {MatSort} from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {UntypedFormControl} from '@angular/forms';
import {SelectionModel} from '@angular/cdk/collections';
import * as moment from 'moment';

@Component({
  selector: 'app-inventory-maintenace',
  templateUrl: './inventory-maintenace.component.html',
  styleUrls: ['./inventory-maintenace.component.scss']
})
export class InventoryMaintenaceComponent implements OnInit, OnChanges {
  dataSource = new MatTableDataSource<any>();
  dataSourceSerialNo = new MatTableDataSource<any>();
  transHistoryDataSource = new MatTableDataSource<any>();
  dataSourcePastTransaction=new MatTableDataSource<any>();

  pastTransactionData:any;

  searching = false;
  searching2 = false;
  filterText: any;
  inventoryName;
  indName: any;
  productName = '';
  productId='';
  maxDate: Date = new Date();
  minDate: Date = new Date(2000, 0, 1, 0, 0, 0);
  date = new UntypedFormControl(new Date(2022, this.maxDate.getMonth(), 1));
  tabIndex = 0;
  serialnoHistory;
  serialNoTxt = '';
  selectedWarehouseValue:any;
  wareHouseListObj:any;
  isChecked=false;
  selectedRowIndex = -1;
  isActive =false;
  warehouse:any;
  stringDate: any;
  selectedDate:any;
  balance;


  constructor(  public inventoryMaintenanceService: InventoryMaintenanceService,
                private router : Router,
                private route : ActivatedRoute,
                private dataTransfer: DataTransferService
                ) { }
                @ViewChild('attpaginator', { static: true }) attpaginator: MatPaginator;
    displayedColumns: string[] = ['sku', 'desc',  'manufacturer', 'category', 'type', 'availQty', 'reorderQty', 'ONORDERQTY', 'PREFERREDVENDOR_ID', 'UNITOFMEASURE', 'PRODUCT_ACTIVE'];
  //@ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
}
    @ViewChild(MatSort) sortSerialNoHistory: MatSort;
    @ViewChild('sorter1') sorter1: MatSort;
    @ViewChild('sorter2') sorter2: MatSort;
  // @ViewChild(MatPaginator) matViewPaginator : MatPaginator;
    @ViewChild('matViewPaginator', { static: true }) matViewPaginator: MatPaginator;
    @ViewChild('matPTPaginator', { static: true }) matPTPaginator: MatPaginator;
    @ViewChild('wopaginator', { static: true }) wopaginator: MatPaginator;
    //@ViewChild(MatSort, { static: false }) sort: MatSort;
    displayedColumnSerialNo: string[] = ['SERIALTEXT'];
    showHistory=true;
    hidePaginator=true;
    hidePTPaginator=true;
    selection = new SelectionModel<any>(false, []);
    /*@HostListener('input', ['$event']) onInput(event): void {
      console.log("Hta");
    //if (this.field === 'price') {
      const value = event.target.value;
      this.ngControl.control.setValue(parseFloat(value) || 0);
      if (value.slice(-1) === '.' && !value.slice(0, -1).includes('.')) {
        event.target.value = value;
      }
    }*/
    //displayedPTColumns:string[]=['TRANSDATESYS', 'TRANSDATE','QTY','TOTALQTY'];
    displayedPTColumns:string[]=['TRANSDATESYS', 'TRANSDATE','TYPE_CD', 'QTY', 'TOTALQTY', 'CLOSEQTY', 'FROMTO_ID', 'REASON_CD', 'PURCHASEORDERNUMBER', 'NOTES'];
    displayedTransHistoryColumns: string[] = ['TRANSDATESYS', 'TRANSDATE', 'TYPE_CD', 'WAREHOUSE', 'FROMTO', 'REASON_CD', 'PURCHASEORDERNUMBER', 'NOTES'];

  ngOnInit(): void {
    this.dataTransfer.myMethod(true);
    this.getwarehouseList();    
    this.selectedWarehouseValue = (localStorage.getItem('selectedWarehouse'));
    if(this.selectedWarehouseValue != null || this.selectedWarehouseValue != undefined) {
    this.inventoryMaintenanceService.selectedWarehouse = this.selectedWarehouseValue;    
    //this.getwarehouseTable(this.selectedWarehouseValue);
    } 

  
           
    // search results are kept moving between this page and detail but we want 
    // to clear them if coming in new from the setup menu
    const toggle = this.route.snapshot.paramMap.get('toggle');
    console.log(toggle);
    if (toggle === '0') {
      // this.refresh();
    } else {
        this.getwarehouseList();
    } 
  }

  ngOnChanges(){
  }
  ngAfterViewInit(): void {
    console.log("ngAfterViewInit");    
  }
  highlight(row){
    this.selectedRowIndex = row.id;
    row.REORDERQTY1=true;
    this.handleClick(row);
}
  getwarehouseList() {
    this.inventoryMaintenanceService.searching = true; 
    this.inventoryMaintenanceService.getWarehouseList().subscribe(data => {
    this.inventoryMaintenanceService.warehouseList = data;
    this.wareHouseListObj = data;
    this.inventoryMaintenanceService.searching = false;
    
    //this.hidePaginator=true;   
    });
  }

  showInactiveProduct(action) {
    localStorage.setItem('productChecked',action);
  
     if (action == false) {         
      const activeDevice =this.inventoryMaintenanceService.WarehouseDetail.ViewInventoryTable.filter(function (device) {
       return device.PRODUCT_ACTIVE == 1; // filter by active product
      });
      this.dataSource.data = activeDevice;     
  }
    else {      
      this.dataSource.data= this.inventoryMaintenanceService.WarehouseDetail.ViewInventoryTable;
    }  
  }  
  
  applyTableFilter(filterText) {
    console.log('filterText'+filterText);
    this.dataSource.filter=filterText;
  }

  onSaveClick() {
  
    console.log(this.inventoryMaintenanceService.WarehouseDetail.ViewInventoryTable);
  }
  onlyNumber(value, index, element, e) { 
    
    element.REORDERQTY1=true;

    (element.TOTALQTY < element.REORDERQTY) 
    element.REORDERQTY1=true;

    //element.style.color="yellow";
      if(!(e>=48&&e<=57)) {     
      
       element.REORDERQTY=value.substring(0, value.length-1)
       
    }
    console.log(element);
  }
  selectRow(row) {
    this.selection.toggle(row);
    console.log(this.selection.selected[0]);
    if(this.selection.isSelected(row)) {
      this.isActive= true;
    }
    else this.isActive= false;
    this.selectedRowIndex = row.id;
    this.productName = row.DESCRIPTION;
    this.productId = row.PRODUCT_ID;    
    this.tabIndex = 1;
  }
 getwarehouseTable(WarhouseData) {  
  localStorage.setItem('selectedWarehouse',WarhouseData);
  this.warehouse=WarhouseData;
  this.inventoryMaintenanceService.searching = true;   
  this.hidePaginator=true;
  this.inventoryMaintenanceService.getWarehouseTable(WarhouseData).subscribe(data => {
    console.log("Data",data);       
    this.inventoryMaintenanceService.WarehouseDetail = data;
   this.dataSource.data= this.inventoryMaintenanceService.WarehouseDetail.ViewInventoryTable;

    let isCheckedString=localStorage.getItem('productChecked');
    this.isChecked=(isCheckedString=='true')?true:false;   
    this.showInactiveProduct(this.isChecked); // enable later
    
    //Commented after that we need to remove
    if (this.inventoryMaintenanceService.WarehouseDetail.ViewInventoryTable.length >0){
     this.hidePaginator=false;
    //End Commented

   // setTimeout(() => {
     // this.dataSource.sort=this.sort;
      //this.dataSource.paginator=this.matViewPaginator;
   // });
    //this.dataSource.paginator = this.attpaginator;
    //this.dataSource.sort=this.sort;
    this.dataSource.paginator=this.matViewPaginator;
    }
    this.inventoryMaintenanceService.searching = false;       
   // this.inventoryName='- '+WarhouseData.DESCRIPTION;      

  });
}
// @ViewChild(MatSort) set matSort(sort: MatSort) {
//   if (!this.dataSource.sort) {
//       this.dataSource.sort = sort;
//   }
// }

handleClick(row){
  console.log(row);
  console.log( "DESCRIPTION" + row.DESCRIPTION);
  console.log(row.PRODUCT_ID);
  //this.selectedRowIndex = row.id;
  this.productName = row.DESCRIPTION;
  this.productId = row.PRODUCT_ID; 
  console.log("d"+ this.productId);
  this.tabIndex = 1;
}

applyFilter(filterValue: string) {
  const tableFilters = [];
  console.log(filterValue);
  tableFilters.push({
      id: 'SERIALNO',
      value: filterValue
  });
  this.dataSourceSerialNo.filter = JSON.stringify(tableFilters);
}
tabItemChange() {
  //alert(this.tabIndex)
  if( this.tabIndex == 1) {
    console.log("if")
    this.getPastTranaction();
  }else {
   // this.pastTransactionData=undefined;
    console.log("else")
  }

 
}



onDate(e){
  this.stringDate = moment(this.selectedDate).format("MM/DD/YYYY");
  console.log("Date " + this.stringDate);

  var resultProductData = this.pastTransactionData.filter(a => {
    var date = new Date(a.TRANSDATESYS);
    return (date >= this.selectedDate);
  });

  this.dataSourcePastTransaction.data = resultProductData; 
  console.log(resultProductData);
  this.hidePTPaginator= (resultProductData.length==0)?true:false; 
  this.calcStartingBalance(resultProductData)




}

  calcStartingBalance(data) {
    if(data.length >0) { 
      this.balance=data[0].TOTALQTY - data[0].QTY;
     }
  }


getPastTranaction() {
 this.inventoryMaintenanceService.searching = true;
 //this.pastTransactionData=undefined;
 this.dataSourcePastTransaction.data=[];
 this.hidePTPaginator= true; 
  this.inventoryMaintenanceService.getPastTransaction(this.warehouse,this.productId).
    subscribe(data => {console.log(data);
      if (data.length > 0) {
      this.pastTransactionData=data;
      this.dataSourcePastTransaction.data = this.pastTransactionData; 
      this.dataSourcePastTransaction.paginator=this.matPTPaginator ;   
      this.calcStartingBalance(data);   
      this.hidePTPaginator=false; 
    }            
    this.inventoryMaintenanceService.searching = false;
     
  });
}

  getAllSerialNo() {
   // this.searching = true;
    this.inventoryMaintenanceService.getSerialnoList('').
      subscribe(data => {console.log(data);
              this.inventoryMaintenanceService.serialnoList = data;
              this.dataSourceSerialNo.data=this.inventoryMaintenanceService.serialnoList.SerialnoList;
              this.transHistoryDataSource.data=this.inventoryMaintenanceService.serialnoList.SerialnoHistory;
              console.log(this.inventoryMaintenanceService.serialnoList.SerialnoList);
              console.log('serial history below');
              // console.log(this.inventoryMaintenanceService.serialnoList.SerialnoHistory);
              this.transHistoryDataSource.sort=this.sortSerialNoHistory;
              this.searching = false;
    });
}
 
serialListClick(row) {
  this.serialnoHistory = null;
  this.transHistoryDataSource.data = null;
  console.log(row);
  this.searching = true;
  this.inventoryMaintenanceService.getSerialnoList(row.SERIALNO).
    subscribe(data => {console.log(data);
                       this.serialnoHistory = data;
                       this.transHistoryDataSource.data = this.serialnoHistory.SerialnoHistory;
                       this.transHistoryDataSource.sort = this.sorter1;
                       this.searching = false;
  });
}


serialNoClick() {
  
  /*this.transHistoryDataSource.data = null;
  this.dataSourceSerialNo.data = null;
  this.inventoryMaintenanceService.serialnoList = null;  
  this.inventoryMaintenanceService.serialnoList.SerialnoHistory='';
  this.serialnoHistory='';*/
  
  console.log(this.serialNoTxt.toString());
  if(this.serialNoTxt.length>0)
  {
  this.searching = true;
  this.inventoryMaintenanceService.getSerialnoList(this.serialNoTxt)
    .subscribe(data => {console.log(data);
     
                        this.inventoryMaintenanceService.serialnoList = data;  
  
   
   
                        if(this.inventoryMaintenanceService.serialnoList.SerialnoHistory===undefined)
 {
  // this.showHistory=false;
   this.transHistoryDataSource.sort=null;
 }
 else{
 //this.showHistory=true;
 this.transHistoryDataSource.sort=this.sorter1;
 }
                        this.dataSourceSerialNo.data=this.inventoryMaintenanceService.serialnoList.SerialnoList;
                        this.transHistoryDataSource.data=this.inventoryMaintenanceService.serialnoList.SerialnoHistory;

                        this.searching = false;
  });
}
else{
  alert('Please enter some serial no');
}
}



}


