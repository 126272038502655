<h5 mat-dialog-title>Add Phone Number</h5>
<mat-divider></mat-divider>
<div mat-dialog-content style="width: 100vh;">
  <mat-card class="user-card">
    <mat-card-content>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Phone Type</mat-label>
        <mat-select [(ngModel)]="phoneType" [disabled]="phone_id !== null">
          <mat-option *ngFor="let ptype of indService.indDetail.IndividualTab.PhoneTypeList" [value]="ptype" style="font-size: 11px;">
            {{ptype.DESCRIPTION}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <br />
      <h3>Phone Number</h3>
      <mat-form-field appearance="outline">
        <mat-label>Enter your Area Code</mat-label>
        <input matInput placeholder="415" [formControl]="phoneAreaCodeVal" required size="3" maxlength="3">
        <mat-error *ngIf="phoneAreaCodeVal.invalid">{{getAreaCodeErrorMessage()}}</mat-error>
      </mat-form-field>
      -
      <mat-form-field appearance="outline">
        <mat-label>Enter the Exchange</mat-label>
        <input matInput placeholder="555" [formControl]="phoneExchangeVal" required size="3" maxlength="3">
        <mat-error *ngIf="phoneExchangeVal.invalid">{{getExchangeErrorMessage()}}</mat-error>
      </mat-form-field>
      -
      <mat-form-field appearance="outline">
        <mat-label>Enter the number</mat-label>
        <input matInput placeholder="6677" [formControl]="phoneNoVal" required size="4" maxlength="4">
        <mat-error *ngIf="phoneNoVal.invalid">{{getNoErrorMessage()}}</mat-error>
      </mat-form-field>
      <br>
      <mat-form-field appearance="outline" class="formfield-half">
        <mat-label>Extension</mat-label>
        <input matInput placeholder="Extension" [(ngModel)]="phoneExt">
      </mat-form-field>
      <br>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="savePhone()" [disabled]="badData()">SAVE</button>
      <button mat-raised-button color="primary" [mat-dialog-close]>CANCEL</button>
    </mat-card-actions>
  </mat-card>
</div>
