import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

import { EnrollmentService } from '../../services/enrollment.service';
import { CustomerService } from '../../services/customer.service';

import { EnrollComponent } from '../enroll/enroll.component';
import { DataTransferService } from 'src/app/services/data-transfer.service';

@Component({
    selector: 'app-eligibility',
    templateUrl: './eligibility.component.html',
    styleUrls: ['./eligibility.component.scss']
})
export class EligibilityComponent implements OnInit {

    searching = true;
    questionList = [];
    personcompId = '';
    displayedColumns: string[] = ['required', 'question', 'custanswer'];
    dialogRef: MatDialogRef<EnrollComponent>;

    constructor(
        private enrollmentService: EnrollmentService,
        public customerService: CustomerService,
        private dialog: MatDialog, private dataTransfer:DataTransferService
    ) { }

    ngOnInit() {
        this.dataTransfer.myMethod(true);
        this.getQuestions();
    }

    private getQuestions() {
        this.searching = true;

        this.personcompId = this.customerService.currentCustomer.PersonCompanyId;
        this.enrollmentService.getQuestionsByPersonCompanyId(this.personcompId)
            .subscribe((data) => {
                this.questionList = data[this.personcompId];

                this.questionList.forEach(ques => {
                 
                    if (ques.QUESTION_TYPE === 'TEXT') {
                        if (ques.QUESTION_RESPONSES !== undefined && ques.QUESTION_RESPONSES.length > 0
                             && ques.QUESTION_RESPONSES[0].ELIGIBILITY_ID !== undefined) {
                            ques.currentValue = ques.QUESTION_RESPONSES[0].RESPONSE_TEXT;
                        } else {
                            ques.currentValue = '';
                        }
                    }
                    if (ques.QUESTION_TYPE === 'LABEL') {
                        if (ques.QUESTION_RESPONSES !== undefined && ques.QUESTION_RESPONSES.length > 0
                              && ques.QUESTION_RESPONSES[0].ELIGIBILITY_ID !== undefined) {
                            ques.currentValue = ques.QUESTION_RESPONSES[0].RESPONSE_TEXT;
                        } else {
                            ques.currentValue = '';
                        }
                    }
                    if (ques.QUESTION_TYPE === 'NUMBER' || ques.QUESTION_TYPE === 'VALUE') {
                        if (ques.QUESTION_RESPONSES !== undefined && ques.QUESTION_RESPONSES.length > 0
                            && ques.QUESTION_RESPONSES[0].ELIGIBILITY_ID !== undefined) {
                            ques.currentValue = ques.QUESTION_RESPONSES[0].RESPONSE_TEXT;
                        } else {
                            ques.currentValue = '0';
                        }
                    }
                    if (ques.QUESTION_TYPE === 'LIST') {
                        ques.currentValue = '';
                        if (ques.QUESTION_RESPONSES !== undefined && ques.QUESTION_RESPONSES.length > 0) {
                            ques.QUESTION_RESPONSES.forEach(resp => {
                                if (resp.ELIGIBILITY_ID !== undefined) {
                                    ques.currentValue = resp.RESPONSE_ID;
                                }
                            });
                        }
                    }
                });

                this.searching = false;
            }, err => {
                this.searching = false;
                console.log('Error: ' + JSON.stringify(err, null, 2));
            });
    }

    private buildElibilityList(): any[] {

        const elList = [];

        this.questionList.forEach(ques => {
            let respId;
            let respText;
            let eligibility_Id;
         
            if (ques.QUESTION_TYPE === 'LIST') {
                respId = ques.currentValue;
                
                const index = ques.QUESTION_RESPONSES.findIndex(val => val.RESPONSE_ID === ques.currentValue);
                const eligibilityIndex = ques.QUESTION_RESPONSES.findIndex(val => val.ELIGIBILITY_ID != null);
                if (index === -1) {
                    respText = '';
                } else {
                    respText = ques.QUESTION_RESPONSES[index].RESPONSE_TEXT;

                }
                if (eligibilityIndex === -1) {
                    eligibility_Id = ques.ELIGIBILITY_ID;
                }
                else {
                    eligibility_Id = ques.QUESTION_RESPONSES[eligibilityIndex].ELIGIBILITY_ID;
                }
            } else {
                respId = ques.QUESTION_RESPONSES[0].RESPONSE_ID;
                respText = ques.currentValue;
                eligibility_Id = ques.QUESTION_RESPONSES[0].ELIGIBILITY_ID;
            }

            const el = {
                QUESTION_ID: ques.QUESTION_ID,
                RESPONSE_ID: respId,
                RESPONSE_TEXT: respText,
                ELIGIBILITY_ID: (eligibility_Id != null || eligibility_Id != undefined || eligibility_Id != "") ? eligibility_Id : null
            };

            elList.push(el);
        });

        return elList;
    }

    public getRecommendations() {
        let className = localStorage.getItem("themeName");
        this.dialogRef = this.dialog.open(EnrollComponent,
            {
                data: {
                    personcompId: this.personcompId,
                    elList: this.buildElibilityList()
                },panelClass:className
            }
        );
    }
}
