import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { Utility } from './models/utility';
import { UtilityService } from './services/utility.service';
import { DataTransferService } from './services/data-transfer.service';
import { environment } from '../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { Idle } from 'idlejs';
import Swal from 'sweetalert2';
import { MatDrawer } from '@angular/material/sidenav';
import { Customer } from './models/customer';
import { CustomerService } from './services/customer.service';
import { ConfirmDialogComponent } from './util/confirm-dialog/confirm-dialog.component';
import { Workorder } from './models/workorder';
import { WorkorderService } from './services/workorder.service';
import { ModuleAccessService } from './services/module-access.service';
// import { take, publish, groupBy } from 'rxjs/operators';
// import { ConnectableObservable } from 'rxjs';
import { EnrollmentService } from './services/enrollment.service';
import { AddCustomerDialogComponent } from './util/add-customer-dialog/add-customer-dialog.component';
import { LocationsearchService } from './services/locationsearch.service';
import { UserService } from './services/user.service';
// import { MatSelect } from '@angular/material/select';
import { MatMenuTrigger } from '@angular/material/menu';

import * as Sentry from "@sentry/angular-ivy";

import { UploadExcelComponent } from "./distributor/dialogs/upload-excel/upload-excel.component";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],

})
export class AppComponent implements OnInit {
    title = 'OneRing';
    loggedInUserName: any = "Default";
    loggedInUserEmail: any;
    year: any = '2019';
    versionNum = '1.0';
    displaySideBar: boolean = false;
    utilList: Utility[];
    prevParam: any;
    sidebarList: any = [];
    defaultUtility: Utility;
    defaultUtilCode: string;
    @Input() disableSelect: boolean = false;
    showSearch: boolean = true;
    loadHome: boolean = false;
    cachedUtil: Utility = new Utility();
    storedCustomers = new Array<Customer>();
    previousCustomer: any;
    sourceId: any;
    setupView:any;
    showLastViewed: any = false;
    showListView: any = false;
    tempArray: any = Array<Customer>();
    showMenu: any;
    @ViewChild('drawer') drawer: MatDrawer;
    @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
    @ViewChild('menuTrigger1') menuTrigger1: MatMenuTrigger;
    @ViewChild('menuTrigger2') menuTrigger2: MatMenuTrigger;
    @ViewChild('menuTrigger3') menuTrigger3: MatMenuTrigger;
    @ViewChild('menuTrigger4') menuTrigger4: MatMenuTrigger;
    //Import Export Menu
    @ViewChild("menuTrigger5") menuTrigger5: MatMenuTrigger;
    objectKeys = Object.keys;
    importExportMenu = {};
    //End of Import Export Menu
    //Dashboard
    @ViewChild("menuTrigger6") menuTrigger6: MatMenuTrigger;
    @ViewChild("menuTrigger7") menuTrigger7: MatMenuTrigger;
    setupDashboardList: any = [];
    searchList: any = [];
    setupdata: any = [];
    isworkorderAging: boolean = false;
    //End of Dashboard
    idle: any;
    showUserMenu = false;
    getModulesAccess: any;
    className: any;
    listName: any;
    hasThemeChanged: boolean = false;
    adminViewList: any = [];
    reportsViewList: any = [];
    setupViewList: any = [];
    ifDataLoad :any= false;
    userRole:any;
    environmentType:any;
    jcplSettings:any;
    resourceRole:any;
    constructor(
        private router: Router,
        public utilityService: UtilityService,
        private dataTransfer: DataTransferService,
        private adalSvc: MsAdalAngular6Service,
        public customerService: CustomerService,
        private dialog: MatDialog,
        private enrollmentService: EnrollmentService, public userService: UserService,
        private workorderService: WorkorderService, private locationSearchService: LocationsearchService,
        private moduleAccessService: ModuleAccessService,
        private spinner: NgxSpinnerService,) {


        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        }

        this.year = new Date().getFullYear().toString();
        this.showUserMenu = true;

        this.dataTransfer.myMethod$.subscribe(res => {
            this.disableSelect = res;
        });

        let themeName = localStorage.getItem('themeName');
        this.className = ((themeName == null) ? "grey-theme" : themeName);
        if (themeName == null || themeName == undefined) {
            localStorage.setItem('themeName', "grey-theme")
        }



        this.customerService.storedCustomer$.subscribe(res => {

            if (res !== null) {
                this.tempArray = new Array<any>();
                this.tempArray = JSON.parse(localStorage.getItem("storedCustomers"));
                if (this.tempArray === null) {
                    this.tempArray = new Array<any>();
                }
                else {


                    this.tempArray = this.tempArray.filter(item =>
                        item.Id !== res.Id);

                }

                if (this.tempArray.length < 10) {
                    this.tempArray.unshift(res);

                }
                else {
                    this.tempArray.pop();
                    this.tempArray.unshift(res);
                }

                localStorage.setItem('storedCustomers', JSON.stringify(this.tempArray));
                let storedCustomersArray = JSON.parse(localStorage.getItem("storedCustomers"));
                this.storedCustomers = [];

                storedCustomersArray.forEach(element => {
                    if (element.ProgramSponsorId == this.utilityService.currentUtility.ProgramsponsorId) {
                        this.storedCustomers.push(element);
                    }
                });
            }
        });

   


    }
    
    matSelectClick(param) {
        if (this.userRole != "CLIENT" && this.userRole != "UTILRO") {
            if (param == "first") {
                this.menuTrigger?.openMenu();
                this.menuTrigger1?.closeMenu();
                this.menuTrigger2?.closeMenu();
                this.menuTrigger3?.closeMenu();
                this.menuTrigger4?.closeMenu();
                this.menuTrigger5?.closeMenu();
                this.menuTrigger6?.closeMenu();
                this.menuTrigger7?.closeMenu();
            } else if (param == "second") {
                this.menuTrigger?.closeMenu();
                this.menuTrigger1?.openMenu();
                this.menuTrigger2?.closeMenu();
                this.menuTrigger3?.closeMenu();
                this.menuTrigger4?.closeMenu();
                this.menuTrigger5?.closeMenu();
                this.menuTrigger6?.closeMenu();
                this.menuTrigger7?.closeMenu();
            } else if (param == "third") {
                this.menuTrigger?.closeMenu();
                this.menuTrigger1?.closeMenu();
                this.menuTrigger2?.openMenu();
                this.menuTrigger3?.closeMenu();
                this.menuTrigger4?.closeMenu();
                this.menuTrigger5?.closeMenu();
                this.menuTrigger6?.closeMenu();
                this.menuTrigger7?.closeMenu();
            } else if (param == "fourth") {
                this.menuTrigger?.closeMenu();
                this.menuTrigger1?.closeMenu();
                this.menuTrigger2?.closeMenu();
                this.menuTrigger3?.openMenu();
                this.menuTrigger4?.closeMenu();
                this.menuTrigger5?.closeMenu();
                this.menuTrigger6?.closeMenu();
                this.menuTrigger7?.closeMenu();
            } else if (param == "fifth") {
                this.menuTrigger?.closeMenu();
                this.menuTrigger1?.closeMenu();
                this.menuTrigger2?.closeMenu();
                this.menuTrigger3?.closeMenu();
                this.menuTrigger4?.openMenu();
                this.menuTrigger5?.closeMenu();
                this.menuTrigger6?.closeMenu();
                this.menuTrigger7?.closeMenu();
            } else if (param == "sixth") {
                this.menuTrigger?.closeMenu();
                this.menuTrigger1?.closeMenu();
                this.menuTrigger2?.closeMenu();
                this.menuTrigger3?.closeMenu();
                this.menuTrigger4?.closeMenu();
                this.menuTrigger5?.openMenu();
                this.menuTrigger6?.closeMenu();
                this.menuTrigger7?.closeMenu();
            } else if (param == "seven") {
                this.menuTrigger?.closeMenu();
                this.menuTrigger1?.closeMenu();
                this.menuTrigger2?.closeMenu();
                this.menuTrigger3?.closeMenu();
                this.menuTrigger4?.closeMenu();
                this.menuTrigger5?.closeMenu();
                this.menuTrigger6?.openMenu();
                this.menuTrigger7?.closeMenu();
            } else if (param == "eight") {
                this.menuTrigger?.closeMenu();
                this.menuTrigger1?.closeMenu();
                this.menuTrigger2?.closeMenu();
                this.menuTrigger3?.closeMenu();
                this.menuTrigger4?.closeMenu();
                this.menuTrigger5?.closeMenu();
                this.menuTrigger6?.closeMenu();
                this.menuTrigger7?.openMenu();
            } else if (param == null) {
                this.menuTrigger?.closeMenu();
                this.menuTrigger1?.closeMenu();
                this.menuTrigger2?.closeMenu();
                this.menuTrigger3?.closeMenu();
                this.menuTrigger4?.closeMenu();
                this.menuTrigger5?.closeMenu();
                this.menuTrigger6?.closeMenu();
                this.menuTrigger7?.closeMenu();
            }
        } else {
            if (param == "third") {
                this.menuTrigger?.closeMenu();
                this.menuTrigger1?.closeMenu();
                this.menuTrigger2?.openMenu();
                this.menuTrigger3?.closeMenu();
                this.menuTrigger4?.closeMenu();
                this.menuTrigger5?.closeMenu();
                this.menuTrigger6?.closeMenu();
                this.menuTrigger7?.closeMenu();
            } else if (param == null) {
                this.menuTrigger?.closeMenu();
                this.menuTrigger1?.closeMenu();
                this.menuTrigger2?.closeMenu();
                this.menuTrigger3?.closeMenu();
                this.menuTrigger4?.closeMenu();
                this.menuTrigger5?.closeMenu();
                this.menuTrigger6?.closeMenu();
                this.menuTrigger7?.closeMenu();
            }
        }
    }
    

    loadListView() {
        this.adminViewList = [];
        this.adminViewList.push({ rl: "/programSponsorAdmin", name: 'Program Sponsors', obj: null },
            { rl: "/officeAdmin", name: 'Offices', obj: null },
            { rl: "/roleAdmin", name: 'Roles', obj: null },
            { rl: "/userAdmin", name: 'Users', obj: null },
            { rl: "/moduleAdmin", name: 'Modules', obj: null },
            { rl: "/reportConfig", name: 'Report Configuration', obj: null })
          //  { rl: "/enrollmentAdmin", name: 'Enrollment Configuration', obj: null})
    }

    loadReportView() {
      
        this.reportsViewList = [];
        this.userService.getMenuList().subscribe(res => {

            let menuList: any = res;
            menuList.forEach(element => {
                this.reportsViewList.push({ rl: "/reportAdmin/" + element.MenuId, name: element.MenuName, obj: element })
            });
        })
    }

    loadSetupViewList() {
        this.setupViewList = [];
        if (this.sourceId == 'BBCS') {
            this.setupViewList.push({ rl: "/individualmaintenance/0", name: 'Individual Maintenance', obj: null, ifDataLoad :false },
                { rl: "/organizationmaintenance", name: 'Organization Maintenance', obj: null,ifDataLoad :false });

                if(this.utilityService.currentUtility.OfficeId == "PSG1"){
                    this.ifDataLoad= true;
                    this.setupViewList.push({ rl: environment.dataLoadUrl, name: 'Data Load', obj: null,ifDataLoad :true });
                }
                
        }
        this.setupViewList.push({ rl: "/schedulemaintenance", name: 'Schedule Maintenance', obj: null,ifDataLoad :false });
        //this.setupViewList.push({ rl: "/routeOptimization", name: 'Route Optimization', obj: null,ifDataLoad :false });
		//this.setupViewList.push({ rl: "/inventory", name: 'Inventory Maintenance', obj: null });
        this.setupViewList.push({
            rl: "/distributormaintenance/0",
            name: "Distributor Maintenance",
            obj: null,
            ifDataLoad: false,
        });
    }

    adminClicked() {
        return false;
    }

    closeSidebar() {
        this.drawer.close();
    }



    Navigate(value,view) {
        if(this.utilityService.currentUtility.OfficeId == 'PSG1' && view == true){
           window.open(value);
        }
        else
        {
            this.router.navigated = false;
            this.router.navigate([value]);
        }
     
    }

    changeTheme(colorName, colorCode) {
        switch (colorName) {
            case "Indigo":
                this.className = "indigo-theme";
                break;
            case "Grey":
                this.className = "grey-theme";
                break;
            case "Blue":
                this.className = "blue-theme";
                break;
            case "DeepPurple":
                this.className = "deeppurple-theme";
                break;
            case "Teal":
                this.className = "teal-theme";
                break;
            case "Pink":
                this.className = "pink-theme";
                break;
            case "Cyan":
                this.className = "cyan-theme";
                break;
            case "Brown":
                this.className = "brown-theme";
                break;

        }
        localStorage.setItem('themeName', this.className);
        localStorage.setItem('colorCode', colorCode);
    }

    goHome() {
        this.userRole = localStorage.getItem("selectedUtilUserRole");
        console.log("HomeUserRole", this.userRole);
        if (this.userRole == "CLIENT") {
            this.router.navigate(["/clientReports"]);
            return false;
        } else if (this.userService?.resourceRole?.toLowerCase() == "distributor" && this.utilList.length == 1) {
            this.isworkorderAging ? this.router.navigate(["workorderAging"]) : this.router.navigate(["dashboard"]);
            return false;
        } else if (this.userService.resourceRole?.toLowerCase() == 'contractor' && this.utilList.length == 1) {
            this.isworkorderAging ? this.router.navigate(["dashboard"]) : this.router.navigate(["workorderAging"]);
            return false;
        } else {
            this.router.navigate(["/search"]);
            return false;
        }
    }

    goToCustomerDetails(customer) {

        this.router.navigated = false;
        this.locationSearchService.personcompany = null;
        this.locationSearchService.scheduledate = null;
        this.locationSearchService.grid = null;
        this.locationSearchService.workorderID = null;
        if (customer.isWorkorder) {
            if (this.utilityService.currentProgramSponsor.SourceId === 'BBCS') {
                this.workorderService.currentWorkorder = customer;
                this.workorderService.currentWorkorderID = customer.Id;
                this.enrollmentService.currentEnrollmentID = customer.EnrollmentId;
                this.customerService.storedCustomers(customer);
                if (this.workorderService.currentWorkorder.Group == null) {
                    this.workorderService.currentWorkorder.Group = "DEFAULT";
                }
                this.router.navigate(['workorderGetSave', this.workorderService.currentWorkorder.Group, this.workorderService.currentWorkorder.SubGroup, this.workorderService.currentWorkorderID, this.workorderService.currentWorkorder?.SubGroup1 ?? 'ALL']);
            }
            else {
                this.workorderService.currentWorkorder = customer;
                this.customerService.storedCustomers(customer);
                this.router.navigate(['workorderDetail']);
            }

        }
        else {
            this.customerService.currentCustomer = customer;
            this.customerService.storedCustomers(customer);
            this.router.navigate(['detail']);
        }



        return false;
    }

    getModuleAccessList() {
        let userEmail = localStorage.getItem('loggedInUserEmail');
        this.moduleAccessService.getModulesForUser(userEmail).subscribe(res => {
            /*  this.moduleAccessService.moduleAccessObj = res; */
            localStorage.setItem('Modules', JSON.stringify(res));
            /*    console.log(this.moduleAccessService.moduleAccessObj); */
            this.router.navigate(['/search']);

        })
    }


    ngOnInit() {

        let self = this;
        this.cachedUtil = new Utility();
        this.cachedUtil.BBCSUserId = localStorage.getItem('selectedBBCSUser');
        this.cachedUtil.LoggedInUserRole = localStorage.getItem('selectedUtilUserRole');
        this.userRole=this.cachedUtil.LoggedInUserRole;
             
        this.cachedUtil.OfficeId = localStorage.getItem('selectedUtilOId');
        this.cachedUtil.ProgramsponsorDescription = localStorage.getItem('selectedUtilPS');
        this.cachedUtil.ProgramsponsorId = localStorage.getItem('selectedUtilPId');
        this.cachedUtil.Source = localStorage.getItem('selectedSourceId');
        this.sourceId = this.cachedUtil.Source;
        this.versionNum = environment.versionNumber;
        this.environmentType = environment.environmentVariable;
        
        this.idle = new Idle()
            .whenNotInteractive()
            .within(environment.idleTimeBeforeWarning)
            .do(() => this.handleIdle())
            .start();



        if (environment.localMode) {

            localStorage.setItem('loggedInUserName', 'Test User');
            localStorage.setItem('loggedInUserEmail', 'guruprasad.konapalli@honeywell.com');
            Sentry.setUser({ email: localStorage.getItem('loggedInUserEmail') });

            this.getUtilities();
        } else {
            if (this.adalSvc.isAuthenticated) {

                this.loggedInUserName = this.adalSvc.LoggedInUserName;
                this.loggedInUserEmail = this.adalSvc.LoggedInUserEmail;
                localStorage.setItem('loggedInUserName', this.loggedInUserName);
                localStorage.setItem('loggedInUserEmail', this.loggedInUserEmail);

                Sentry.setUser({ email: localStorage.getItem('loggedInUserEmail') });

                this.getUtilities();
               
            }
            else {
                this.adalSvc.login();
            }


        }

        //this.getModuleAccessList();
        
    }

    private handleIdle() {
        let className = localStorage.getItem("themeName");
        const dialogRef = this.dialog.open(ConfirmDialogComponent, { panelClass: className });
    }

    getUtilities(): void {
        this.loadHome = true;
        this.loggedInUserEmail = localStorage.getItem('loggedInUserEmail');
        this.utilityService.getUserUtilities(this.loggedInUserEmail).subscribe((data: Utility[]) => {
            this.setUtilityData(data);
        }, err => {
            Swal.fire({
                title: 'Error',
                text: err,
                icon: 'error',
                confirmButtonText: 'OK',
            })
        });
    }

    openAddCustomer() {
        let className = localStorage.getItem("themeName");
        const dialogRef = this.dialog.open(AddCustomerDialogComponent, { panelClass: className });
    }

    setUtilityData(utils) {
        this.loadHome = false;
        if (utils != null) {
            this.utilList = utils;
            if (this.cachedUtil != undefined) {
                if (this.cachedUtil.ProgramsponsorId != null && this.cachedUtil.ProgramsponsorDescription != null
                    && this.cachedUtil.OfficeId != null) {
                    this.utilList.forEach((element, index) => {
                        if (element.ProgramsponsorId === this.cachedUtil.ProgramsponsorId &&
                            element.OfficeId === this.cachedUtil.OfficeId) {
                            let temp = this.utilList[0];
                            this.utilList[0] = this.cachedUtil;
                            this.utilList[index] = temp;

                        }
                    });

                }
            }

            this.utilList.length && this.setDefaultUtility(this.utilList[0]);
            this.utilityService.firstDefaultSet = true;
        }
        else {
            Swal.fire({
                title: 'Error',
                text: "Office List is not loaded ! Please make sure your email is authorized",
                icon: 'error',
                confirmButtonText: 'OK',
            })
        }

    }

    setDefaultUtility(util) {

        if (util != null) {
            this.defaultUtility = util;
            this.utilityService.setCurrentUtility(this.defaultUtility);
            this.sourceId = util.Source;
            this.userRole = util.LoggedInUserRole;
            console.log('defaultUserRole',this.userRole);
            
            localStorage.setItem('selectedUtilUserRole', this.userRole);
            console.log('defaultUserRoleLocalStorage',this.userRole);         
            this.getOrgCdBBCSIdRole(util?.OfficeId);
            if (this.userRole == "CLIENT") {
                this.router.navigate(["/clientReports"]);
            } else if (this.userService?.resourceRole?.toLowerCase() == "distributor") {
                this.isworkorderAging ? this.router.navigate(["workorderAging"]) : this.router.navigate(["dashboard"]);
            } else if (this.userService?.resourceRole?.toLowerCase() == "contractor") {
                this.isworkorderAging ? this.router.navigate(["dashboard"]) : this.router.navigate(["workorderAging"]);
            } else {
                this.router.navigate(["/search"]);
            }
            let storedCustomersArray = JSON.parse(localStorage.getItem("storedCustomers"));
            this.storedCustomers = [];
            storedCustomersArray && storedCustomersArray.forEach(element => {
                if (util.ProgramsponsorId == element.ProgramSponsorId) {
                    this.storedCustomers.push(element);
                }
            });
        }

    }

    utilityUpdated(selectedUtil) {
         
        this.utilityService.setCurrentUtility(selectedUtil);
        console.log('selectedutil:',selectedUtil.ProgramSponsorId);
        this.utilityService.dataChange();
        this.sourceId = selectedUtil.Source;
        let storedCustomersArray = JSON.parse(localStorage.getItem("storedCustomers"));
        this.storedCustomers = [];
        if(storedCustomersArray != null ){
            storedCustomersArray.forEach(element => {
                if (selectedUtil.ProgramsponsorId == element.ProgramSponsorId) {
                    this.storedCustomers.push(element);
                }
            });
        }
        this.importExportMenu = {};
        this.getOrgCdBBCSIdRole(selectedUtil?.OfficeId);
        if (this.userService.resourceRole?.toLowerCase() == 'distributor') {
            this.isworkorderAging ? this.router.navigateByUrl('workorderAging') : this.router.navigateByUrl('dashboard');
        } else if (this.userService.resourceRole?.toLowerCase() == 'contractor') {
            this.isworkorderAging ? this.router.navigateByUrl('dashboard') : this.router.navigateByUrl('workorderAging');
        } else if (this.userRole == "CLIENT") {
            this.router.navigate(["/clientReports"]);
        } else {
            this.router.navigateByUrl("search");
        }
        
        //Cached Model should support any change within Utility Model
        localStorage.setItem('selectedUtilPId', selectedUtil.ProgramsponsorId);
        localStorage.setItem('selectedUtilOId', selectedUtil.OfficeId);
        localStorage.setItem('selectedUtilPS', selectedUtil.ProgramsponsorDescription);
        localStorage.setItem('selectedUtilUserRole', selectedUtil.LoggedInUserRole);
        localStorage.setItem('selectedBBCSUser', selectedUtil.BBCSUserId);
        localStorage.setItem('selectedSourceId', selectedUtil.Source);

        console.log('selectedutilOfficeID',selectedUtil.OfficeId);
        this.userRole=selectedUtil.LoggedInUserRole;
    }


    handleClick(e: any) {

        this.disableSelect = e;
        if (e === true) {
            this.showSearch = false;
        }

    }

    //--------------Import Export Menu---------
    subMenuImportExportClick(subMenu: string) {
        if (subMenu === "IMPORT MIDSTREAM EQUIPMENT" || subMenu === "IMPORT BULK CONTRACTORS" ||
            subMenu === "IMPORT BULK DISTRIBUTORS") {
            let className = localStorage.getItem("themeName");
            const dialogRef = this.dialog.open(UploadExcelComponent, {
                data: {
                    caption: subMenu,
                },
                panelClass: className,
            });
            dialogRef.afterClosed().subscribe((result) => { });
        }
        if (subMenu === "MANUFACTURERS AND DISTRIBUTORS") {
            this.router.navigateByUrl("/manufacturerDistributerReport");
        }
        if (subMenu === "ENERGY STAR DATA") {
            this.router.navigateByUrl("/energyStarReport");
        }
    }

    loadJobList() {
        this.searchList = [];
        this.searchList.push({
            rl: "/search", name: "Customer/ Workorder", obj: null
        });
    }

    getOrgCdBBCSIdRole(officeId: string) {
        //---------------Currently deploying for FEE6, but according to Kumudha, internal team needs to implement this for other offices slowly one at a time.
        if (this.utilityService.currentUtility.OfficeId == "FEE6") {
            this.isworkorderAging = false;
            this.userService.getOrganizationCode(officeId, localStorage.getItem("loggedInUserEmail")).subscribe((data) => {
                this.spinner.show();
                this.setupdata = data;
                this.userService.orgCd = this.setupdata["Org_Cd"];
                this.userService.dbBBCSId = this.setupdata["BBCSUserId"];
                this.userService.orgDesc = this.setupdata["Org_Desc"];
                this.userService.resourceRole = this.setupdata["ResourceRoleName"];
                this.resourceRole = this.setupdata["ResourceRoleName"] != null ? this.setupdata["ResourceRoleName"].toLowerCase() : "";             
                if (this.setupdata["BBCSUserId"] != null) {
                    this.setupdata["importExport"] != undefined ? this.importExportMenu = this.setupdata["importExport"] : "";
                    //-----Dashboard & Graphs/ charts------------
                    if (this.setupdata["DASHBOARD"] != undefined) {
                        this.setupdata["DASHBOARD"].forEach(element => {
                            element.indexOf("DASHBOARD") != -1 ? this.setupDashboardList.push({ rl: "/dashboard", name: element, obj: null }) : "";
                            element.indexOf("GRAPH") != -1 ? this.setupDashboardList.push({ rl: "/graphDashboard", name: element, obj: null }) : "";
                            element.indexOf("AGING") != -1 ? this.setupDashboardList.push({ rl: "/workorderAging", name: element, obj: null }) : "";
                        });
                    } else {
                        this.setupDashboardList = [];
                    }
                    //------End of Dashboard & Graphs/ charts------------

                    //---------------Contractor Role---------------
                    if (this.resourceRole?.toLowerCase() == 'contractor' && this.setupdata["DASHBOARD"] != undefined) {
                        this.loadJobList();
                        this.isworkorderAging = true;
                        this.setupDashboardList.push({ rl: "/workorderAging", name: "Workorder Aging & Summary", obj: null })
                        this.router.navigateByUrl('dashboard');
                    }
                    else if (this.resourceRole?.toLowerCase() == 'contractor' && this.setupdata["DASHBOARD"] == undefined) {
                        this.loadJobList();
                        this.setupDashboardList.push({ rl: "/workorderAging", name: "Workorder Aging & Summary", obj: null });
                        this.router.navigateByUrl('workorderAging');
                    }
                    //---------------End of Contractor Role---------------
                    //---------------Distributor Role---------------
                    else if (this.resourceRole?.toLowerCase() == 'distributor' && this.setupdata["DASHBOARD"] != undefined) {
                        this.setupdata["DASHBOARD"].forEach(element => {
                            if (element.indexOf("AGING") != -1) {
                                this.isworkorderAging = true;
                                this.setupDashboardList.push({ rl: "/workorderAging", name: element, obj: null });
                            }
                        });
                        this.router.navigateByUrl('dashboard');
                    }
                    //---------------End of Distributor Role---------------
                    else {
                        this.azureRolewiseFunctionLoad();
                    }
                }
                this.spinner.hide();
            });
        }
        else {
            this.azureRolewiseFunctionLoad();
            this.userService.orgCd = "";
            this.userService.dbBBCSId = "";
            this.userService.orgDesc = "";
            this.userService.resourceRole = "";
            this.setupDashboardList = [];
        }
    }

    azureRolewiseFunctionLoad() {
        if (this.userRole != 'CLIENT' && this.userRole != 'UTILRO') {
            this.loadListView();
            this.loadReportView();
            this.loadSetupViewList();
            this.resourceRole = this.userRole;
        }
        if (this.userRole == 'CLIENT' || this.userRole == 'UTILRO') {
            this.loadReportView();
        }
    }
}
