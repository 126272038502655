import { AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, Component, DoCheck, Inject, OnInit, ViewChild } from '@angular/core';
import { Customer } from 'src/app/models/customer';
import { CustomerService } from 'src/app/services/customer.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatInput } from '@angular/material/input';


@Component({
  selector: 'app-editaddress',
  templateUrl: './editaddress.component.html',
  styleUrls: ['./editaddress.component.scss']
})
export class EditaddressComponent implements OnInit{
  customer: Customer;
  cust:any[];
  @ViewChild('firstname') nameInput: MatInput;
  tabIndex=0;
  editcust = {
    ADDRESS_ID: null,
    SELECTEDADDRTYPECODE: null,
    STREETNUMBER: null,
    STREETNAME: null,
    STREETTYPE: null,
    CITY: null,
    STATEPROVINCE:null,
    ZIP: null
};
saveWorkorder: any = false;
myData: any;
  constructor(public customerService: CustomerService,
    public dialogRef: MatDialogRef<EditaddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }



  stNo = new UntypedFormControl('', [Validators.required]);
  stName = new UntypedFormControl('', [Validators.required]);
  stType = new UntypedFormControl('');
  stCity = new UntypedFormControl('', [Validators.required]);
  stState = new UntypedFormControl('', [Validators.required, Validators.maxLength(2)]);
  stZip = new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]);


  mstNo = new UntypedFormControl('', [Validators.required]);
  mstName = new UntypedFormControl('', [Validators.required]);
  mstType = new UntypedFormControl('');
  mstCity = new UntypedFormControl('', [Validators.required]);
  mstState = new UntypedFormControl('', [Validators.required, Validators.maxLength(2)]);
  mstZip = new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]);
  ngOnInit(): void {


    this.customer = this.customerService.currentCustomer;
    this.stNo.setValue(this.customerService.currentCustomer.ServiceStreetNumber);
    this.stName.setValue(this.customerService.currentCustomer.ServiceStreetName);
    this.stCity.setValue(this.customerService.currentCustomer.ServiceCity);
    this.stState.setValue(this.customerService.currentCustomer.ServiceState)
    this.stZip.setValue(this.customerService.currentCustomer.ServiceZipcode);
    this.stType.setValue(this.customerService.currentCustomer.serviceStreetType);
    this.tabIndex=0;



    this.mstNo.setValue(this.customerService.currentCustomer.MailingStreetNumber);
    this.mstName.setValue(this.customerService.currentCustomer.MailingStreetName);
    this.mstCity.setValue(this.customerService.currentCustomer.MailingCity);
    this.mstState.setValue(this.customerService.currentCustomer.MailingState);
    this.mstZip.setValue(this.customerService.currentCustomer.MailingZipcode);
    this.mstType.setValue(this.customerService.currentCustomer.mailingStreetType);
  }

onfocus(){
  this.nameInput.focus();
}

  badDataservice() {
    return  this.stNo.invalid
        || this.stName.invalid
        || this.stCity.invalid
        || this.stState.invalid
        || this.stZip.invalid;
}
badDatamail() {
  return  this.mstNo.invalid
      || this.mstName.invalid
      || this.mstCity.invalid
      || this.mstState.invalid
      || this.mstZip.invalid;
}
savesericeAddress(){
  if (this.data.editMode) {
  const stno = this.stNo.value;
  const stName = this.stName.value;
  let streettype = this.stType.value;
  const city = this.stCity.value;
  const state = this.stState.value;
  const zip = this.stZip.value;
  this.myData = {
    ADDRESS_ID: this.customerService.currentCustomer.ServiceAddressId,
    SELECTEDADDRTYPECODE:"2",
    STREETNUMBER:stno.toUpperCase(),
    STREETNAME:stName.toUpperCase(),
    STREETTYPE:streettype.toUpperCase(),
    CITY:city.toUpperCase(),
    STATEPROVINCE:state.toUpperCase(),
    ZIP:zip
  };

//console.log(JSON.stringify(this.myData));




this.saveWorkorder=true;
this.customerService.updateaddress(
  JSON.stringify(this.myData),this.customerService.currentCustomer.PersonCompanyId).subscribe((data) => //

  Swal.fire({
    text: "Address Updated Successfully",
    icon: "success",
    confirmButtonText: "OK",
  }).then(() => {
    this.finishSave()
  })




, err => {
  Swal.fire({
    text: err,
    icon: 'error',
    confirmButtonText: 'OK',
  });
  this.saveWorkorder=false;
  this.dialogRef.close('1');
});

  }
 // this.dialogRef.close('1');
}
savemailAddress(){
  if (this.data.editMode) {
    const stno = this.mstNo.value;
    const stName = this.mstName.value;
    let streettype = this.mstType.value;
    const city = this.mstCity.value;
    const state = this.mstState.value;
    const zip = this.mstZip.value;

    this.myData = {
      ADDRESS_ID: this.customerService.currentCustomer.MailingAddressId,
      SELECTEDADDRTYPECODE:"1",
      STREETNUMBER:stno.toUpperCase(),
      STREETNAME:stName.toUpperCase(),
      STREETTYPE:streettype.toUpperCase(),
      CITY:city.toUpperCase(),
      STATEPROVINCE:state.toUpperCase(),
      ZIP:zip
    };
    this.saveWorkorder=true;
  this.customerService.updateaddress( JSON.stringify(this.myData),this.customerService.currentCustomer.PersonCompanyId).subscribe((data) => //
  
  Swal.fire({
    text: "Address Updated Successfully",
    icon: "success",
    confirmButtonText: "OK",
  }).then(() => {
    this.finishSavemail()

  })





  , err => {
    Swal.fire({
      text: err,
      icon: 'error',
      confirmButtonText: 'OK',
    });
    this.saveWorkorder=false;
    this.dialogRef.close('1');
  });
  
  //this.dialogRef.close('1');

    }

}
private finishSavemail(){
  this.updateOriginalValuesmailAfterSave();
  this.saveWorkorder=false;
    this.dialogRef.close();
}
private finishSave() {
  this.updateOriginalValuesAfterSave();
  this.saveWorkorder=false;
    this.dialogRef.close();
}
private updateOriginalValuesmailAfterSave(){
  this.customer.MailingStreetNumber=this.myData.STREETNUMBER;
  this.customer.MailingStreetName = this.myData.STREETNAME;
  this.customer.MailingCity = this.myData.CITY;
  this.customer.MailingState = this.myData.STATEPROVINCE;
  this.customer.MailingZipcode = this.myData.ZIP;
  this.customer.mailingStreetType=this.myData.STREETTYPE;
  this.customerService.getCustomerById(this.customerService.currentCustomer.Id).subscribe((detailCust:Customer[]) => {
    const tempcust: Customer = new Customer();
        tempcust.setUpFromWS(detailCust);

        this.customerService.currentCustomer.ServiceStreetAddress1 = tempcust.ServiceStreetAddress1;
        this.customerService.currentCustomer.MailingStreetAddress1=tempcust.MailingStreetAddress1;


 });
}
private updateOriginalValuesAfterSave() {

  this.customerService.currentCustomer.ServiceStreetNumber=this.myData.STREETNUMBER;
  this.customerService.currentCustomer.ServiceStreetName = this.myData.STREETNAME;
  this.customerService.currentCustomer.ServiceCity = this.myData.CITY;
  this.customerService.currentCustomer.ServiceState = this.myData.STATEPROVINCE;
  this.customerService.currentCustomer.ServiceZipcode = this.myData.ZIP;
  this.customerService.currentCustomer.serviceStreetType=this.myData.STREETTYPE;
  this.customerService.getCustomerById(this.customerService.currentCustomer.Id).subscribe((detailCust:Customer[]) => {
    const tempcust: Customer = new Customer();
        tempcust.setUpFromWS(detailCust);

        this.customerService.currentCustomer.ServiceStreetAddress1 = tempcust.ServiceStreetAddress1;
        this.customerService.currentCustomer.ServiceStreetAddress1=tempcust.ServiceStreetAddress1;


 });

}
}
