<h5 mat-dialog-title style="padding:0.2vw; color:black; margin:0 !important">Create ProgramSponsor</h5>
<mat-divider></mat-divider>
<div mat-dialog-content style="height: 80vh; width: 100vh;">
    <mat-card class="user-card" style="width: 95%;">
        <mat-card-content style="margin-bottom: 0px;height: 55vh;">
            <mat-form-field appearance="outline" class="formfield-half" style="width:50% !important">
                <mat-label>ProgramSponsor ID</mat-label>
                <input matInput placeholder="ProgramSponsor ID" [(ngModel)]="programSponsorId" (focusin)="clearMessage()" maxlength="20">
            </mat-form-field>
            <mat-form-field appearance="outline" class="formfield-half" style="width:50% !important">
                <mat-label>ProgramSponsor Name</mat-label>
                <input matInput placeholder="ProgramSponsor Name" [(ngModel)]="programSponsorName" (focusin)="clearMessage()" maxlength="50">
            </mat-form-field>
            <br>
            <mat-form-field appearance="outline" class="formfield-half">
                <mat-label>SalesForce User Name</mat-label>
                <input matInput placeholder="SF Username" [(ngModel)]="salesforceUsername" (focusin)="clearMessage()" maxlength="255">
            </mat-form-field>
            <mat-form-field appearance="outline" class="formfield-half">
                <mat-label>SalesForce Password</mat-label>
                <input matInput type="password" placeholder="SF Password" [(ngModel)]="salesforcePassword" (focusin)="clearMessage()" maxlength="255">
            </mat-form-field>
            <br>
            <mat-form-field appearance="outline" class="formfield-half" style="width:50% !important">
                <mat-label>Compass Email</mat-label>
                <input matInput type="email" placeholder="Email" [(ngModel)]="compassEmail" (focusin)="clearMessage()" maxlength="50">
            </mat-form-field>
            <mat-form-field appearance="outline" class="formfield-half" style="width:50% !important">
                <mat-label>Compass Password</mat-label>
                <input matInput type="password" placeholder="Password" [(ngModel)]="compassPassword" (focusin)="clearMessage()" maxlength="50">
            </mat-form-field>
            <br>
            <mat-form-field appearance="outline" class="formfield-half" style="width:50% !important">
                <mat-label>Compass Uri</mat-label>
                <input matInput type="url" placeholder="Compass Uri" [(ngModel)]="compassUri" (focusin)="clearMessage()" maxlength="50">
            </mat-form-field>
            <mat-form-field appearance="outline" class="formfield-half">
                <mat-label>Source</mat-label>
                <mat-select [(ngModel)]="selectedSource">
                    <mat-option *ngFor="let source of sources" [value]="source.SourceId" style="font-size: 11px;">
                      {{source.SourceName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <br>
            <mat-slide-toggle  class="formfield-half" [(ngModel)]="programSponsorActive">Active</mat-slide-toggle>
            <label class="formfield-half"  style="color: red; font-size: 0.9vw">{{errorMessage}}</label>
           
        </mat-card-content>
        <br>
        <mat-card-actions>
            <button mat-raised-button color="primary" (click)="savePSponsor();">SAVE</button>
            <button mat-raised-button color="primary" (click)="cancelPSponsor();">CANCEL</button>
        </mat-card-actions>
    </mat-card>
</div>