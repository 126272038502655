<mat-spinner *ngIf="saveWorkorder" [diameter]="100" style="margin: 0 auto;"></mat-spinner>  
<div *ngIf="!saveWorkorder">
<h3 mat-dialog-title>Edit Customer Name</h3>
<mat-divider></mat-divider>
<div mat-dialog-content style="width: 100vh;">
    <mat-card class="user-card">
        <mat-card-content>
            <mat-form-field appearance="outline" class="formfield-half">
                <mat-label>First Name</mat-label>
                <input  matInput placeholder="First Name" required [formControl]="firstname">
                <br/>

                <mat-error *ngIf="firstname.invalid">First Name is required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="formfield-half">
                <mat-label>Middle Name</mat-label>
                <input  matInput placeholder="Middle Name"  [formControl]="middlename">
                <br/>

              </mat-form-field>
              <mat-form-field appearance="outline" class="formfield-half">
                <mat-label>Last Name</mat-label>
                <input  matInput placeholder="Last Name" required [formControl]="lastname">
                <br/>

                <mat-error *ngIf="lastname.invalid">Last Name is required</mat-error>
              </mat-form-field>
            
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="primary"  [disabled]="badDataname()" (click)="savename()">SAVE</button>
            <button mat-raised-button color="primary" [mat-dialog-close]>CANCEL</button>
          </mat-card-actions>
    </mat-card>

</div>
</div>