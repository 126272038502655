

<mat-spinner *ngIf="saveWorkorder" [diameter]="100" style="margin: 0 auto;"></mat-spinner>  
<div *ngIf="!saveWorkorder">
  <h3 mat-dialog-title>Edit Address</h3>
<mat-divider></mat-divider>
<div mat-dialog-content style="width: 100vh;">




      <mat-tab-group color="accent" backgroundColor="primary" [selectedIndex]="tabIndex" >
        <mat-tab label="Mailing Address">
            <mat-card class="user-card">
              <mat-card-content>
  
                <mat-form-field appearance="outline" class="formfield-half">
                  <mat-label>Street Number</mat-label>
                  <input  matInput placeholder="Number" required [formControl]="mstNo">
                  <br/>
  
                  <mat-error *ngIf="stNo.invalid">Street number is required</mat-error>
                </mat-form-field>
                <br/>
  
                <mat-form-field appearance="outline" class="formfield-half">
                  <mat-label>Street Name</mat-label>
                  <input matInput placeholder="Street" required [formControl]="mstName">
                  <br/>
                  <mat-error *ngIf="stName.invalid">Street name is required</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="formfield-half">
                  <mat-label>Street Type</mat-label>
                  <input matInput placeholder="Type"  [formControl]="mstType" >
                  <!-- <mat-error *ngIf="stType.invalid">Required</mat-error> -->
                </mat-form-field>
                <br />
                <mat-form-field appearance="outline" class="formfield-half">
                  <mat-label>City</mat-label>
                  <input matInput placeholder="City" required [formControl]="mstCity">
                  <br/>
                  <mat-error *ngIf="stCity.invalid">Required</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="formfield-half">
                  <mat-label>State</mat-label>
                  <input matInput placeholder="State" required [formControl]="mstState" maxlength="2">
                  <br/>
                  <mat-error *ngIf="stState.invalid">A 2 letter state abbreviation is required</mat-error>
                </mat-form-field>
                <br />
                <mat-form-field appearance="outline" class="formfield-half">
                  <mat-label>Zip Code</mat-label>
                  <input matInput placeholder="Zip" required [formControl]="mstZip">
                  <br/>
                  <mat-error *ngIf="mstZip.invalid">A numeric ZIP is required</mat-error>
                </mat-form-field>
                <br/>
              </mat-card-content>
                <mat-card-actions>
                  <button mat-raised-button color="primary" (click)="savemailAddress()" [disabled]="badDatamail()">SAVE</button>
                  <button mat-raised-button color="primary" [mat-dialog-close]>CANCEL</button>
                </mat-card-actions>
              </mat-card>
            <br/>
          </mat-tab>
        <mat-tab label="Service Address">
        <mat-card class="user-card">
        <mat-card-content>


          <mat-form-field appearance="outline" class="formfield-half">
            <mat-label>Street Number</mat-label>
            <input #firstname="matInput" matInput placeholder="Number" required [formControl]="stNo">
            <br/>

            <mat-error *ngIf="stNo.invalid">Street  number is required</mat-error>
          </mat-form-field>
          <br/>

          <mat-form-field appearance="outline" class="formfield-half">
            <mat-label>Street Name</mat-label>
            <input matInput placeholder="Street" required [formControl]="stName">
            <br/>
            <mat-error *ngIf="stName.invalid">Street Name is Required</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="formfield-half">
            <mat-label>Street Type</mat-label>
            <input matInput placeholder="Type"  [formControl]="stType" >
            <!-- <mat-error *ngIf="stType.invalid">Required</mat-error> -->
          </mat-form-field>
          <br />
          <mat-form-field appearance="outline" class="formfield-half">
            <mat-label>City</mat-label>
            <input matInput placeholder="City" required [formControl]="stCity">
            <br/>
            <mat-error *ngIf="stCity.invalid">Required</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="formfield-half">
            <mat-label>State</mat-label>
            <input matInput placeholder="State" required [formControl]="stState" maxlength="2">
            <br/>
            <mat-error *ngIf="stState.invalid">A 2 letter state abbreviation is required</mat-error>
          </mat-form-field>
          <br />
          <mat-form-field appearance="outline" class="formfield-half">
            <mat-label>Zip Code</mat-label>
            <input matInput placeholder="Zip" required [formControl]="stZip">
            <br/>
            <mat-error *ngIf="stZip.invalid">A numeric ZIP is required</mat-error>
          </mat-form-field>
          <br/>
        </mat-card-content>
          <mat-card-actions>
            <button mat-raised-button color="primary" (click)="savesericeAddress()" [disabled]="badDataservice()">SAVE</button>
            <button mat-raised-button color="primary" [mat-dialog-close]>CANCEL</button>
          </mat-card-actions>
        </mat-card>
        </mat-tab>
       
      </mat-tab-group>





</div>
</div>
