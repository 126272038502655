import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { LocationsearchService } from '../services/locationsearch.service';
import { LocationSearch } from '../models/location';
// import { BBCSLocationSearch } from '../models/location';
// import { BBCSTerritoryStrings } from '../models/location';
// import { BBCSGrids } from '../models/location';
import { MatDialog } from '@angular/material/dialog';
import { BBCSLocationsearch_temp } from '../models/location';
// import { groupBy, mergeMap, toArray } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UtilityService } from '../services/utility.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { WorkorderService } from '../services/workorder.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss']
})

export class LocationSearchComponent implements OnInit {
  today = new Date();
  start: any;
  date: any;
  response: any = [];
  response_location: any = [];
  tabledata: any[] = [];
  tabledata_1: any[] = [];
  tabledata_2: any[] = [];
  response_terriotory: any[] = [];
  adddays: any = 0;
  mondays: any;
  Name: any;
  loadWorkorder: any = true;
  //i :number=1;
  subdays: any = 0;
  dayOfWeekStartingSundayZeroIndexBased: any;
  mondayOfWeek: any;
  tuesdayOfWeek: any;
  wednesdayOfWeek: any;
  second: any;
  third: any
  expiration: any;
  lg_one: any = 'false';
  lg_two: any = 'false';
  lg_three: any = 'false';
  lg_fourt: any = 'false';
  lg_fifth: any = 'false';
  lg_six: any = 'false';
  lg_seven: any = 'false';
  thursdayOfWeek: any;
  fridayOfWeek: any;
  satdayOfWeek: any;
  days: any;
  color: any;
  sundayOfWeek: any;
  selectedGrid = 'none';
  gridlist: any;
  stringifiedData: any;
  Organiztion_cd: any = 'ALL';
  loadWorkorder_monday: any = false;
  currentLocations: LocationSearch;
  gridfirst: any = '';
  countfirst: any = '';
  dayfirst: any = '';
  gridsecond: any = '';
  countsecond: any = '';
  gridthird: any = '';
  countthird: any = '';
  gridfourth: any = '';
  countfourth: any = '';
  gridfifth: any = '';
  Names: any = 'false';
  countfifth: any = '';
  gridsix: any = '';
  countsix: any = '';
  gridseven: any = '';
  countseven: any = '';
  colorfirst: any;
  colorsecond: any;
  colorthird: any;
  colorfourth: any;
  colorfifth: any;
  colorsix: any;
  colorseven: any;
  sub: any;
  GRIDS: any;
  currentDate: any;
  scheduleDate: any = new Date();
  latest_date: any;
  myData: any;
  schdate = new Date();
  personcompanyid: any;
  workorderID: any = this.workorderService.currentWorkorderID;
  stateCtrl: UntypedFormControl;
  filteredGrids: Observable<any[]>;
  myControl = new UntypedFormControl();
  options: any[] = [];
  filteredOptions: Observable<any[]>;


  constructor(
    private locationsearchService: LocationsearchService,
    private dialog: MatDialog, private activatedroute: ActivatedRoute,
    private datepipe: DatePipe,
    private router: Router, private route: ActivatedRoute, private utilityService: UtilityService,
    private workorderService: WorkorderService, public dialogRef: MatDialogRef<LocationSearchComponent>

  ) { }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit(): void {
    //console.log(this.myData);
    //this.scheduleDate = new Date(this.route.snapshot.paramMap.get('id'));
    // this.selectedGrid = this.route.snapshot.paramMap.get('id2');
    this.scheduleDate = this.locationsearchService.getlocationScheduledate();

    this.selectedGrid = this.locationsearchService.getlocationgrid();
    this.latest_date = this.datepipe.transform(new Date(this.scheduleDate), 'MM/dd/yyyy');
    // console.log('shreyas');

    //console.log(this.latest_date);
    if (this.selectedGrid === null || typeof this.selectedGrid === 'undefined') {
      this.selectedGrid = 'ALL'
    }
    // console.log(this.selectedGrid);




    this.mondayOfWeek = new Date(this.latest_date);
    // console.log(currentDate);

    this.mondayOfWeek = new Date(this.mondayOfWeek.getFullYear(), this.mondayOfWeek.getMonth(), this.mondayOfWeek.getDate());
    this.tuesdayOfWeek = new Date(this.mondayOfWeek.getFullYear(), this.mondayOfWeek.getMonth(), this.mondayOfWeek.getDate() + 1);
    this.wednesdayOfWeek = new Date(this.mondayOfWeek.getFullYear(), this.mondayOfWeek.getMonth(), this.mondayOfWeek.getDate() + 2);
    this.thursdayOfWeek = new Date(this.mondayOfWeek.getFullYear(), this.mondayOfWeek.getMonth(), this.mondayOfWeek.getDate() + 3);
    this.fridayOfWeek = new Date(this.mondayOfWeek.getFullYear(), this.mondayOfWeek.getMonth(), this.mondayOfWeek.getDate() + 4);
    this.satdayOfWeek = new Date(this.mondayOfWeek.getFullYear(), this.mondayOfWeek.getMonth(), this.mondayOfWeek.getDate() + 5);
    this.sundayOfWeek = new Date(this.mondayOfWeek.getFullYear(), this.mondayOfWeek.getMonth(), this.mondayOfWeek.getDate() + 6);

  }
  NextWeek() {

    //this.mondayOfWeek=this.mondayOfWeek.getDay+7
    this.mondayOfWeek = new Date(this.mondayOfWeek.getFullYear(), this.mondayOfWeek.getMonth(), this.mondayOfWeek.getDate() + 7);;
    this.tuesdayOfWeek = new Date(this.tuesdayOfWeek.getFullYear(), this.tuesdayOfWeek.getMonth(), this.tuesdayOfWeek.getDate() + 7);
    this.wednesdayOfWeek = new Date(this.wednesdayOfWeek.getFullYear(), this.wednesdayOfWeek.getMonth(), this.wednesdayOfWeek.getDate() + 7);

    this.thursdayOfWeek = new Date(this.thursdayOfWeek.getFullYear(), this.thursdayOfWeek.getMonth(), this.thursdayOfWeek.getDate() + 7);
    this.fridayOfWeek = new Date(this.fridayOfWeek.getFullYear(), this.fridayOfWeek.getMonth(), this.fridayOfWeek.getDate() + 7);
    this.satdayOfWeek = new Date(this.satdayOfWeek.getFullYear(), this.satdayOfWeek.getMonth(), this.satdayOfWeek.getDate() + 7);
    this.sundayOfWeek = new Date(this.sundayOfWeek.getFullYear(), this.sundayOfWeek.getMonth(), this.sundayOfWeek.getDate() + 7);

    this.Locationsearch(this.datepipe.transform(new Date(this.mondayOfWeek), 'MM/dd/yyyy'));



  }
  previousWeek() {
    this.mondayOfWeek = new Date(this.mondayOfWeek.getFullYear(), this.mondayOfWeek.getMonth(), this.mondayOfWeek.getDate() - 7);;
    this.tuesdayOfWeek = new Date(this.tuesdayOfWeek.getFullYear(), this.tuesdayOfWeek.getMonth(), this.tuesdayOfWeek.getDate() - 7);
    this.wednesdayOfWeek = new Date(this.wednesdayOfWeek.getFullYear(), this.wednesdayOfWeek.getMonth(), this.wednesdayOfWeek.getDate() - 7);

    this.thursdayOfWeek = new Date(this.thursdayOfWeek.getFullYear(), this.thursdayOfWeek.getMonth(), this.thursdayOfWeek.getDate() - 7);
    this.fridayOfWeek = new Date(this.fridayOfWeek.getFullYear(), this.fridayOfWeek.getMonth(), this.fridayOfWeek.getDate() - 7);
    this.satdayOfWeek = new Date(this.satdayOfWeek.getFullYear(), this.satdayOfWeek.getMonth(), this.satdayOfWeek.getDate() - 7);
    this.sundayOfWeek = new Date(this.sundayOfWeek.getFullYear(), this.sundayOfWeek.getMonth(), this.sundayOfWeek.getDate() - 7);
    this.Locationsearch(this.datepipe.transform(new Date(this.mondayOfWeek), 'MM/dd/yyyy'));



  }
  ngAfterViewInit() {

    this.Locationsearch(this.latest_date);
  }
  Gridselection(selectedGrid) {
    this.GRIDS = this.selectedGrid;
       console.log(this.selectedGrid);
    this.response_terriotory.forEach(element => {

      if (this.selectedGrid == element.grid) {
        this.second = element.second;
        this.third = element.third;

      }

    })
    for (var j = 0; j < this.tabledata_1.length; j++) {
      this.tabledata_1[j].colorfirst = null;
      this.tabledata_1[j].colorsecond = null;
      this.tabledata_1[j].colorthird = null;
      this.tabledata_1[j].colorfourth = null;
      this.tabledata_1[j].colorfifth = null;
      this.tabledata_1[j].colorsix = null;
      this.tabledata_1[j].colorseven = null;
      console.log(this.tabledata_1[j].gridfirst);


      if (this.tabledata_1[j].gridfirst !== null && typeof this.tabledata_1[j].gridfirst !== 'undefined') {
        if (this.second !== null && typeof this.second !== 'undefined') {
          if (this.second.search(this.tabledata_1[j].gridfirst) != -1) {
            this.tabledata_1[j].colorfirst = 'yellow';

          }
        }
        if (this.third !== null && typeof this.third !== 'undefined') {
          if (this.third.search(this.tabledata_1[j].gridfirst) != -1) {
            this.tabledata_1[j].colorfirst = 'red';
          }
        }
        if (this.selectedGrid.search(this.tabledata_1[j].gridfirst) != -1) {
          this.tabledata_1[j].colorfirst = 'green';
        }
      }
      if (this.tabledata_1[j].gridsecond !== null && typeof this.tabledata_1[j].gridsecond !== 'undefined') {
        if (this.second !== null && typeof this.second !== 'undefined') {
          if (this.second.search(this.tabledata_1[j].gridsecond) != -1) {
            this.tabledata_1[j].colorsecond = 'yellow';
          }
        }
        if (this.third !== null && typeof this.third !== 'undefined') {
          if (this.third.search(this.tabledata_1[j].gridsecond) != -1) {
            this.tabledata_1[j].colorsecond = 'red';
          }
        }

        if (this.selectedGrid.search(this.tabledata_1[j].gridsecond) != -1) {
          this.tabledata_1[j].colorsecond = 'green';
        }

      }
      if (this.tabledata_1[j].gridthird !== null && typeof this.tabledata_1[j].gridthird !== 'undefined') {
        if (this.second !== null && typeof this.second !== 'undefined') {
          if (this.second.search(this.tabledata_1[j].gridthird) != -1) {
            this.tabledata_1[j].colorthird = 'yellow';
          }
        }
        if (this.third !== null && typeof this.third !== 'undefined') {
          if (this.third.search(this.tabledata_1[j].gridthird) != -1) {
            this.tabledata_1[j].colorthird = 'red';
          }
        }
        if (this.selectedGrid.search(this.tabledata_1[j].gridthird) != -1) {
          this.tabledata_1[j].colorthird = 'green';
        }
      }
      if (this.tabledata_1[j].gridfourth !== null && typeof this.tabledata_1[j].gridfourth !== 'undefined') {
        if (this.second !== null && typeof this.second !== 'undefined') {
          if (this.second.search(this.tabledata_1[j].gridfourth) != -1) {
            this.tabledata_1[j].colorfourth = 'yellow';
          }
        }
        if (this.third !== null && typeof this.third !== 'undefined') {
          if (this.third.search(this.tabledata_1[j].gridfourth) != -1) {
            this.tabledata_1[j].colorfourth = 'red';
          }
        }
        if (this.selectedGrid.search(this.tabledata_1[j].gridfourth) != -1) {
          this.tabledata_1[j].colorfourth = 'green';
        }
      }
      if (this.tabledata_1[j].gridfifth !== null && typeof this.tabledata_1[j].gridfifth !== 'undefined') {
        if (this.second !== null && typeof this.second !== 'undefined') {
          if (this.second.search(this.tabledata_1[j].gridfifth) != -1) {
            this.tabledata_1[j].colorfifth = 'yellow';
          }
        }
        if (this.third !== null && typeof this.third !== 'undefined') {
          if (this.third.search(this.tabledata_1[j].gridfifth) != -1) {
            this.tabledata_1[j].colorfifth = 'red';
          }
        }
        if (this.selectedGrid.search(this.tabledata_1[j].gridfifth) != -1) {
          this.tabledata_1[j].colorfifth = 'green';
        }
      }
      if (this.tabledata_1[j].gridsix !== null && typeof this.tabledata_1[j].gridsix !== 'undefined') {
        if (this.second !== null && typeof this.second !== 'undefined') {
          if (this.second.search(this.tabledata_1[j].gridsix) != -1) {
            this.tabledata_1[j].colorsix = 'yellow';
          }
        }
        if (this.third !== null && typeof this.third !== 'undefined') {
          if (this.third.search(this.tabledata_1[j].gridsix) != -1) {
            this.tabledata_1[j].colorsix = 'red';
          }
        }
        if (this.selectedGrid.search(this.tabledata_1[j].gridsix) != -1) {
          this.tabledata_1[j].colorsix = 'green';
        }
      }
      if (this.tabledata_1[j].gridseven !== null && typeof this.tabledata_1[j].gridseven !== 'undefined') {
        if (this.second !== null && typeof this.second !== 'undefined') {
        if (this.second.search(this.tabledata_1[j].gridseven) != -1) {
          this.tabledata_1[j].colorseven = 'yellow';
        }
      }
        if (this.third !== null && typeof this.third !== 'undefined') {
          if (this.third.search(this.tabledata_1[j].gridseven) != -1) {
            this.tabledata_1[j].colorseven = 'red';
          }
        }
        if (this.selectedGrid.search(this.tabledata_1[j].gridseven) != -1) {
          this.tabledata_1[j].colorseven = 'green';
        }
      }

    }
  }
  Closeall() {
    this.router.navigate(['scheduling']);
  }
  public ScheduleNavigate(personcompany, grid, scheduledate) {
    console.log(scheduledate);
    this.locationsearchService.individualAuthorization(personcompany, this.workorderID, scheduledate, this.Organiztion_cd).subscribe(res => {

      if (res.Action == 0) {
        if (this.workorderID !== null && typeof this.workorderID !== 'undefined' && personcompany !== null && grid !== null && typeof personcompany !== 'undefined' && typeof grid !== 'undefined') {
          this.locationsearchService.setschedulingparameters(personcompany, grid, this.workorderID, scheduledate);
          if (this.workorderService.workorderStatusCode == 'schedule') {
            this.router.navigate(['scheduling', this.workorderService.currentWorkorder.Group, this.workorderService.currentWorkorder.SubGroup, this.workorderID, "schedule"]);
          }
          else {
            this.router.navigate(['scheduling', this.workorderService.currentWorkorder.Group, this.workorderService.currentWorkorder.SubGroup, this.workorderID, "reschedule"]);
          }

          this.dialogRef.close();
        }
      }
      else {
        Swal.fire({
          title: 'Error',
          text: res.Message,
          icon: 'error',
          confirmButtonText: 'OK',
        })
      }
    }, err => {
      Swal.fire({
        title: 'Error',
        text: err,
        icon: 'error',
        confirmButtonText: 'OK',
      })
    })

  }

  Locationsearch(scheduledate) {
    this.loadWorkorder = true;
    this.myData = {
      scheduledate: scheduledate,
      increment: "30",
      user: "All"
    };
    this.stringifiedData = JSON.stringify(this.myData);
    this.locationsearchService.getLocation(this.stringifiedData, this.Organiztion_cd).subscribe(data => {
      this.currentLocations = data;
      this.loadWorkorder = false;
      this.response = data.Grids;
      this.response_location = data.LocationSearch;
      this.tabledata_1 = [];
      this.tabledata = [];
      this.response_terriotory = data.TerritoryStrings;
      this.response_terriotory.forEach(element => {

        if (this.selectedGrid == element.grid) {
          this.second = element.second;
          this.third = element.third;

        }

      })
      //console.log(this.tabledata_2);
      this.options = [];
      this.response.forEach(element => {



        console.log(element.grid_cd);
        this.options.push(element.grid_cd);


      })

      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),

        map(value => this._filter(value))
      );

      this.tabledata_1 = [];
      this.tabledata = [];

      this.response_location.forEach(element => {


        this.tabledata.push(element);

        // console.log(element.apptdate);
        // this.latest_date =this.datepipe.transform(new Date(element.apptdate), 'MM/dd/yyyy');
        // console.log(this.latest_date);


      })




      var start = 0;
      this.tabledata_1 = [];

      for (var i = 0; i < this.tabledata.length; i++) {
        this.gridfirst = null;
        this.countfirst = null;
        this.gridsecond = null;
        this.countsecond = null;
        this.gridthird = null;
        this.countthird = null;
        this.gridfourth = null;
        this.countfourth = null;
        this.gridfifth = null;
        this.countfifth = null;
        this.gridsix = null;
        this.countsix = null;
        this.gridseven = null;
        this.countseven = null;
        this.Name = null;
        this.Names = 'false';
        this.colorfirst = null;
        this.colorsecond = null;
        this.colorthird = null;
        this.colorfourth = null;
        this.colorfifth = null;
        this.colorsix = null;
        this.colorseven = null;
        this.personcompanyid = null;
        //let newDate = new Date(this.tabledata[i].apptdate);
        let newDate = new Date(moment(this.tabledata[i].apptdate).utc().format("MM/DD/YYYY"));

        //console.log(newDate);
        //console.log(this.tabledata[i].grid);
        if (newDate.toDateString() === this.mondayOfWeek.toDateString()) {
          this.gridfirst = this.tabledata[i].grid;
          this.countfirst = this.tabledata[i].count;
          this.Name = this.tabledata[i].name;
          this.Names = 'TRUE'
          this.personcompanyid = this.tabledata[i].personcompanyid;



        }
        if (newDate.toDateString() === this.tuesdayOfWeek.toDateString()) {
          this.gridsecond = this.tabledata[i].grid;
          this.countsecond = this.tabledata[i].count;
          this.Name = this.tabledata[i].name;
          this.Names = 'TRUE'
          this.personcompanyid = this.tabledata[i].personcompanyid;

        }

        if (newDate.toDateString() === this.wednesdayOfWeek.toDateString()) {
          this.gridthird = this.tabledata[i].grid;
          this.countthird = this.tabledata[i].count;
          this.Name = this.tabledata[i].name;
          this.Names = 'TRUE'
          this.personcompanyid = this.tabledata[i].personcompanyid;

        }

        if (newDate.toDateString() === this.thursdayOfWeek.toDateString()) {
          this.gridfourth = this.tabledata[i].grid;
          this.countfourth = this.tabledata[i].count;
          this.Name = this.tabledata[i].name;
          this.personcompanyid = this.tabledata[i].personcompanyid;
          this.Names = 'TRUE'

        }
        if (newDate.toDateString() === this.fridayOfWeek.toDateString()) {
          this.gridfifth = this.tabledata[i].grid;
          this.countfifth = this.tabledata[i].count;
          this.Name = this.tabledata[i].name;
          //console.log(this.tabledata[i].name)
          this.Names = 'TRUE'
          this.personcompanyid = this.tabledata[i].personcompanyid;

        }
        if (newDate.toDateString() === this.satdayOfWeek.toDateString()) {
          this.gridsix = this.tabledata[i].grid;
          this.countsix = this.tabledata[i].count;
          this.Name = this.tabledata[i].name;
          this.personcompanyid = this.tabledata[i].personcompanyid;
          this.Names = 'TRUE'

        }
        if (newDate.toDateString() === this.sundayOfWeek.toDateString()) {
          this.gridseven = this.tabledata[i].grid;
          this.countseven = this.tabledata[i].count;
          this.Name = this.tabledata[i].name;
          this.Names = 'TRUE'
          this.personcompanyid = this.tabledata[i].personcompanyid;

        }
        this.lg_one = 'false';
        this.lg_two = 'false';
        this.lg_three = 'false';
        this.lg_fourt = 'false';
        this.lg_fifth = 'false';
        this.lg_six = 'false';
        this.lg_seven = 'false';

        for (var j = 0; j < this.tabledata_1.length; j++) {

          if (this.tabledata[i].name === this.tabledata_1[j].name && this.tabledata_1[j].gridfirst === null && this.lg_one === 'false') {

            if (this.gridfirst !== null && typeof this.gridfirst !== 'undefined') {

              this.tabledata_1[j].gridfirst = this.gridfirst;
              this.tabledata_1[j].countfirst = this.countfirst;
              this.tabledata_1[j].colorfirst = this.colorfirst;
              this.Names = 'FALSE';
              this.lg_one = 'true';
            }
          }
          if (this.tabledata[i].name === this.tabledata_1[j].name && this.tabledata_1[j].gridsecond === null && this.lg_two === 'false') {

            if (this.gridsecond !== null && typeof this.gridsecond !== 'undefined') {

              this.tabledata_1[j].gridsecond = this.gridsecond;
              this.tabledata_1[j].countsecond = this.countsecond;
              this.tabledata_1[j].colorsecond = this.colorsecond;
              this.Names = 'FALSE';
              this.lg_two = 'true';
            }
          }

          if (this.tabledata[i].name === this.tabledata_1[j].name && this.tabledata_1[j].gridthird == null && this.lg_three === 'false') {
            if (this.gridthird !== null && typeof this.gridthird !== 'undefined') {

              this.tabledata_1[j].gridthird = this.gridthird;
              this.tabledata_1[j].countthird = this.countthird;
              this.tabledata_1[j].colorthird = this.colorthird;
              this.Names = 'FALSE';
              this.lg_three = 'true';
            }
          }

          if (this.tabledata[i].name === this.tabledata_1[j].name && this.tabledata_1[j].gridfourth === null && this.lg_fourt === 'false') {
            if (this.gridfourth !== null && typeof this.gridfourth !== 'undefined') {


              this.tabledata_1[j].gridfourth = this.gridfourth;
              this.tabledata_1[j].countfourth = this.countfourth;
              this.tabledata_1[j].colorfourth = this.colorfourth;
              this.Names = 'FALSE';
              this.lg_fourt = 'true';
            }
          }
          if (this.tabledata[i].name === this.tabledata_1[j].name && this.tabledata_1[j].gridfifth === null && this.lg_fifth === 'false') {

            if (this.gridfifth !== null && typeof this.gridfifth !== 'undefined') {

              this.tabledata_1[j].gridfifth = this.gridfifth;
              this.tabledata_1[j].countfifth = this.countfifth;
              this.tabledata_1[j].colorfifth = this.colorfifth;
              this.Names = 'FALSE';
              this.lg_fifth = 'true';
            }
          }
          if (this.tabledata[i].name === this.tabledata_1[j].name && this.tabledata_1[j].gridsix === null && this.lg_six === 'false') {

            if (this.gridsix !== null && typeof this.gridsix !== 'undefined') {
              this.tabledata_1[j].gridsix = this.gridsix;
              this.tabledata_1[j].countsix = this.countsix;
              this.tabledata_1[j].colorsix = this.colorsix;
              this.Names = 'FALSE';
              this.lg_six = 'true';
            }
          }
          if (this.tabledata[i].name === this.tabledata_1[j].name && this.tabledata_1[j].gridseven === null && this.lg_seven === 'false') {

            if (this.gridseven !== null && typeof this.gridseven !== 'undefined') {
              this.tabledata_1[j].gridseven = this.gridseven;
              this.tabledata_1[j].countseven = this.countseven;
              this.tabledata_1[j].colorseven = this.colorseven;
              this.Names = 'FALSE';
              this.lg_seven = 'true';
            }

          }





        }











        if (this.Names === 'TRUE') {
          const c = new BBCSLocationsearch_temp();
          c.setFromWeb(this.Name,
            this.gridfirst,
            this.countfirst,
            this.gridsecond,
            this.countsecond,
            this.gridthird,
            this.countthird,
            this.gridfourth,
            this.countfourth,
            this.gridfifth,
            this.countfifth,
            this.gridsix,
            this.countsix,
            this.gridseven,
            this.countseven,
            this.colorfirst,
            this.colorsecond,
            this.colorthird,
            this.colorfourth,
            this.colorfifth,
            this.colorsix,
            this.colorseven, this.personcompanyid,
            this.mondayOfWeek,
            this.tuesdayOfWeek,
            this.wednesdayOfWeek,
            this.thursdayOfWeek,
            this.fridayOfWeek,
            this.satdayOfWeek,
            this.sundayOfWeek
          );
          this.tabledata_1.push(c);
        }

      }
      this.Gridselection(this.GRIDS);
    }, err => {

      Swal.fire({
        title: 'Error',
        text: err,
        icon: 'error',
        confirmButtonText: 'OK',
      })

    });

  }
}
