import { Component, OnInit } from '@angular/core';
import { WorkorderService } from '../services/workorder.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { UtilityService } from '../services/utility.service';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dractions',
  templateUrl: './dractions.component.html',
  styleUrls: ['./dractions.component.scss']
})
export class DractionsComponent implements OnInit {
 

  constructor(private datePipe: DatePipe,private workorderService: WorkorderService,private formBuilder: FormBuilder,public router:Router,private utilityService: UtilityService,private spinner: NgxSpinnerService) { }
  actionList: any;
  selectedAction:any;
  personcompanyid:any;
  devicesList:any;
  transactionsList:any;
  selectedOrgForm: FormGroup;
  formDetailedGroup: FormGroup;
  select_all = false;
  workOrderId:any;
  action:any;
  serialNo:any;
  user:any;
  officeid:any;
  date = new Date();
  actionDate:any;
  dateRequired:any;
  selectedValue:any;
  selectedOption:any;
  selected:any;
  checkbox:any;
  checkbox1:any;
  checkbox2:any;
  displayedColumns1: string[] = ['workorderid', 'programname', 'status', 'statusdate','createdate','action','errormessage','macaddress','serialno'];
  displayedColumns2: string[] = ['checkbox','serialno','devicename','devicestatus','statusdate','workorderid'];
  actions:any=true;
    ngOnInit(): void
    {
      this.selectedOrgForm = this.formBuilder.group({
        addons: this.formBuilder.array([])
      });
    this.workorderService.getActionsList().subscribe(res => {
    this.actionList = res.DRACTIONLIST;
  
    }, err => {
      console.log(err);
    });

    this.personcompanyid = localStorage.getItem('personcompanyid');
    this.officeid= localStorage.getItem('currentUtility');
    this.workorderService.getDevices(this.personcompanyid).subscribe(res1 => {
    this.devicesList = res1;
    console.log('deviceslist:',this.devicesList);

      }, err => {
        console.log(err);
      });

    this.workorderService.getTransactions(this.personcompanyid).subscribe(res2 => {
    this.transactionsList = res2;
    //console.log(this.transactionsList);
   
        }, err => {
         console.log(err);
        });
    }

    onSelectAll(e: any): void {
      for (let i = 0; i < this.devicesList.length; i++) {
        const item = this.devicesList[i];
        item.SELECTED = e;
        console.log('selected:',item.SELECTED);
      }
      
    }
    
    handleInteractionClick(){}
    handleDevicesClick(){}

  submitAction(){
    this.spinner.show();
    console.log("deviceList",this.devicesList);
    const elList = [];
      const deviceDetails =this.devicesList.map((val, index) => {
      val.SELECTED = val.SELECTED ? 1.0 : 0.0;
      if(val.SELECTED==1.0)
      {
    
      let bodyContent = {
        WorkorderId: this.devicesList[index].WORKORDER_ID,
        Action: this.selectedAction,
        SerialNo:this.devicesList[index].INSTALL_SERIALNO,
        User: this.utilityService.currentUtility.BBCSUserId,
        Date:this.datePipe.transform(this.actionDate, 'yyyy-MM-dd')

      };
      
      elList.push(bodyContent);
         
      }
      return val;

    })
    console.log("BodyContent",elList);
    if(elList.length==0)
    {
     
      Swal.fire({
        icon: 'info',
        html:'<div style="text-align:center"> '+"Please select atleast one device to submit for "+this.selected+'</div>',
        width:'800px',
        confirmButtonText: 'OK',
        //confirmButtonColor:'#00FF00'
      })
      this.spinner.hide();
      return;
    }
    
          this.workorderService.submitDrAction(this.personcompanyid, elList).subscribe((res : any) => {
          
            if(res.Action == 1)
            {
              this.spinner.hide();
              Swal.fire({
                icon: 'info',
                html:'<div style="text-align:center;color:primary"> '+res.Message+'</div>',
                width:'800px',
                //confirmButtonColor:'#00FF00',
                confirmButtonText: 'OK',
              })

              .then((result) => {
                if (result.value) {
                  
                  this.router.navigate(['/dractions']);  
                  //this.spinner.hide();
                }
            })

            }
            else if(res.Action==0)
            {
              this.spinner.hide();
                Swal.fire({    
                icon: 'success',
                html:'<div style="text-align:center;color:primary"> '+res.Message+'</div>',
                width:'800px',
                confirmButtonText: 'OK',
                //confirmButtonColor:'primary'
              })
              .then((result) => {
                if (result.value) {
                  this.router.navigate(['/dractions']);
                }
            })

            }


          })
        }

    onSelected(event: any) : void {
      console.log('event:', event);
      this.selected=event.value.ACTION_DESC;
      console.log('event:', this.selected);
      this.selectedAction=event.value.ACTION_CD;
      console.log(this.selectedAction);
      this.actions=true;
      if (this.selectedAction!='STC1OPTOUT'){
        this.actions=false;
      }
    //this.selectedValue= event.value.ACTION_DESC;
     // this.selectedAction= event.value.ACTION_CD;      
    }

    
}
