import { Injectable } from '@angular/core';
import { Observable, of, throwError, concat } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Office } from '../models/office';
import { Module } from '../models/module';
import { ProgramSponsor } from '../models/programsponsor';
import { Role } from '../models/role';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import { UtilityService } from './utility.service';
import { catchError, map, concatMap } from 'rxjs/operators';
import { ProgramSponsorUserRole } from '../user/user-detail/user-detail.component';
@Injectable({
    providedIn: 'root'
})
export class UserService {
    private userSearchWsUrl1 = environment.apiUrl + 'onering/admin';
    private userSearchWsUrl2 = environment.apiUrl;
    public orgCd;
    public orgDesc;
    public dbBBCSId;
    public resourceRole;
    constructor(private http: HttpClient, private utilityService: UtilityService) {

    }

    public getHeaders() {
        const email = localStorage.getItem('loggedInUserEmail');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-email': email,
                'x-programsponsorid': this.utilityService?.currentUtility?.ProgramsponsorId
            })
        };
        return httpOptions;
    }

    getOfficesForMenu(selectedMenu){
        const searchUrl = this.userSearchWsUrl1 + "/reportsconfig/nonconfiguredoffices/" + selectedMenu + '?code=' + environment.apiCode;
        return this.http.get<any[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }
    displayMenuOfficeList() {
        const searchUrl = this.userSearchWsUrl1 + "/reportsconfig/" + this.utilityService.currentUtility.OfficeId + "/menuofficeassociationlist" + '?code=' + environment.apiCode;
        return this.http.get<any[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    getMenuList(): Observable<any[]> {
      
        const searchUrl = this.userSearchWsUrl1 + "/reportsconfig/" + this.utilityService.currentUtility.OfficeId + "/menulist" + '?code=' + environment.apiCode;
        return this.http.get<any[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    getAllReportList(menuId): Observable<any[]> {
        const searchUrl = this.userSearchWsUrl1 + "/reportsconfig/allreportslist/" + menuId + '?code=' + environment.apiCode;
        return this.http.get<any[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    getAllMenuList(): Observable<any[]> {
       
        const searchUrl = this.userSearchWsUrl1 + "/reportsconfig/allmenulist/"+this.utilityService.currentUtility.OfficeId + '?code=' + environment.apiCode;
        return this.http.get<any[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    getOffices(): Observable<Office[]> {
        const searchUrl = this.userSearchWsUrl1 + "/offices" + '?code=' + environment.apiCode;
        return this.http.get<Office[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    associateMenuOffice(officeId, menuId, user, active) {
        const searchUrl = this.userSearchWsUrl1 + "/reportsconfig/" + officeId + "/associatemenuoffice/" + menuId + '?code=' + environment.apiCode;
        const body = {
            User: user,
            Active: active
        }
        return this.http.post<any>(searchUrl, body, this.getHeaders())

    }

    createReportConfig(id, name, description, path, externalPath, datacontrol, menu, active, user, officeId) {
        const searchUrl = this.userSearchWsUrl1 + "/reportsconfig/" + officeId + "/configurereportitem" + '?code=' + environment.apiCode;
        const body = {
            Id: id,
            Name: name,
            Description: description,
            Path: path,
            ExternalPath:externalPath,
            DataControl: datacontrol,
            Menu: menu,
            User: user,
            Active: active
        }

        console.log(body);
        return this.http.post<any>(searchUrl, body, this.getHeaders())
    }

    viewReportConfig(officeid, menuid, userRole) {
        const searchUrl = this.userSearchWsUrl1 + "/reportsconfig/" + officeid + "/reportslist/" + menuid + "/" + userRole + '?code=' + environment.apiCode;
        return this.http.get<Office[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    getSourceList() {
        const searchUrl = this.userSearchWsUrl1 + "/source" + '?code=' + environment.apiCode;
        return this.http.get<Office[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    createOffice(office: Office) {
        const searchUrl = this.userSearchWsUrl1 + "/office/create" + '?code=' + environment.apiCode;
        const body = {
            OfficeId: office.OfficeId,
            OfficeDescription: office.OfficeDescription,
            ProgramsponsorId: office.ProgramSponsorId,
            OfficeActive: office.OfficeActive,
            CreatedBy: office.CreatedBy,
            ModifiedBy: office.ModifiedBy
        }
        return this.http.post<any>(searchUrl, body, this.getHeaders());
    }


    createPSponsor(pSponsor: ProgramSponsor) {
        const searchUrl = this.userSearchWsUrl1 + "/programsponsor/create" + '?code=' + environment.apiCode;
        const body = {
            ProgramSponsorId: pSponsor.ProgramSponsorId,
            ProgramSponsorName: pSponsor.ProgramSponsorDescription,
            CompassUri: pSponsor.CompassUri,
            CompassAuthorization: pSponsor.CompassAuthorization,
            ProgramSponsorActive: pSponsor.ProgramSponsorActive,
            SalesforceUserName: pSponsor.SalesforceUsername,
            SalesforcePassword: pSponsor.SalesforcePassword,
            SourceId: pSponsor.SourceId,
            CreatedBy: pSponsor.CreatedBy,
            ModifiedBy: pSponsor.ModifiedBy
        }
        return this.http.post<any>(searchUrl, body, this.getHeaders())

    }


    officeExists(officeId, officeDescription): Observable<any> {
        const searchUrl = this.userSearchWsUrl1 + "/office/officeExists/" + officeId + "/" + officeDescription + '?code=' + environment.apiCode;
        return this.http.get<any>(searchUrl, this.getHeaders());
    }


    pSponsorExists(pSponsorId, pSponsorDescription): Observable<any> {
        const searchUrl = this.userSearchWsUrl1 + "/programsponsor/programsponsorexists/" + pSponsorId + "/" + pSponsorDescription + '?code=' + environment.apiCode;
        return this.http.get<any>(searchUrl, this.getHeaders());
    }

    getModules(): Observable<Module[]> {
        const searchUrl = this.userSearchWsUrl1 + "/modules" + '?code=' + environment.apiCode;
        return this.http.get<Module[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }


    userExists(userEmail, userName): Observable<any> {
        const searchUrl = this.userSearchWsUrl1 + "/user/userExists/" + userEmail + "/" + userName + '?code=' + environment.apiCode;
        return this.http.get<any>(searchUrl, this.getHeaders());
    }

    updateProgramSponsor(pSponsor: ProgramSponsor) {
        const searchUrl = this.userSearchWsUrl1 + "/programsponsor/update/" + pSponsor.ProgramSponsorId + '?code=' + environment.apiCode;

        const body = {
            ModifiedBy: pSponsor.ModifiedBy,
            CompassUri: pSponsor.CompassUri,
            CompassAuthorization: pSponsor.CompassAuthorization,
            ProgramSponsorName: pSponsor.ProgramSponsorDescription,
            ProgramSponsorActive: pSponsor.ProgramSponsorActive,
            SalesforceUserName: pSponsor.SalesforceUsername,
            SalesforcePassword: pSponsor.SalesforcePassword,
            SourceId: pSponsor.SourceId
        }

        return this.http.put<any>(searchUrl, body, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }


    updateUser(userId, user: User) {
        const searchUrl = this.userSearchWsUrl1 + "/user/update/" + userId + '?code=' + environment.apiCode;

        const body = {
            ModifiedBy: user.ModifiedBy,
            UserActive: user.UserActive
        }

        return this.http.put<any>(searchUrl, body, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    authorizeReports(reportName, officeId) {
        const searchUrl = environment.apiUrl + "reports/authorize/" + officeId + "/" + reportName + '?code=' + environment.apiCode;
        return this.http.get<any>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    addPSRole(psRole: ProgramSponsorUserRole, userId) {

        const searchUrl = this.userSearchWsUrl1 + "/user/programsponsor/update/" + userId + '?code=' + environment.apiCode;

        const body = {
            ModifiedBy: psRole.ModifiedBy,
            ProgramsponsorUserRole: psRole.ProgramsponsorUserRole
        }

        return this.http.put<any>(searchUrl, body, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    roleExists(roleId, roleName): Observable<any> {
        const searchUrl = this.userSearchWsUrl1 + "/role/roleexists/" + roleId + "/" + roleName + '?code=' + environment.apiCode;
        return this.http.get<any>(searchUrl, this.getHeaders());
    }

    showParentMenu(): Observable<any> {
        const searchUrl = this.userSearchWsUrl1 + "/module/menus" + '?code=' + environment.apiCode;
        return this.http.get<any>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    moduleExists(moduleId, moduleName): Observable<any> {
        const searchUrl = this.userSearchWsUrl1 + "/module/moduleexists/" + moduleId + "/" + moduleName + '?code=' + environment.apiCode;
        return this.http.get<any>(searchUrl, this.getHeaders());
    }

    deleteProgramSponsorUserRole(psRole: ProgramSponsorUserRole, userId) {

        const searchUrl = this.userSearchWsUrl1 + "/user/programsponsor/delete/" + userId + '?code=' + environment.apiCode;

        const body = {
            ModifiedBy: psRole.ModifiedBy,
            ProgramsponsorUserRole: psRole.ProgramsponsorUserRole
        }

        return this.http.put<any>(searchUrl, body, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    getProgramSponsorUserRole(userEmail): Observable<any> {
        const searchUrl = this.userSearchWsUrl1 + "/user/programsponsorlist/" + userEmail + '?code=' + environment.apiCode;
        return this.http.get<any>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    getProgramSponsors(): Observable<ProgramSponsor[]> {

        const searchUrl = this.userSearchWsUrl1 + "/programsponsors" + '?code=' + environment.apiCode;
        return this.http.get<ProgramSponsor[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    updateOffice(officeId, office: Office): Observable<any> {

        const searchUrl = this.userSearchWsUrl1 + "/office/update/" + officeId + "/" + office.ProgramSponsorId + '?code=' + environment.apiCode;

        const body = {
            OfficeDescription: office.OfficeDescription,
            OfficeActive: office.OfficeActive,
            ModifiedBy: office.ModifiedBy,
            Programsponsor: office.ProgramSponsorId
        }
        return this.http.put<any>(searchUrl, body, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    getRoles(): Observable<Role[]> {

        const searchUrl = this.userSearchWsUrl1 + "/roles" + '?code=' + environment.apiCode;
        return this.http.get<Role[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    updateRoles(updatedRole: Role): Observable<any> {
        const searchUrl = this.userSearchWsUrl1 + "/role/update/" + updatedRole.RoleId + '?code=' + environment.apiCode;
        const body = {
            RoleName: updatedRole.RoleName,
            RoleActive: updatedRole.RoleActive,
            ModifiedBy: updatedRole.ModifiedBy
        }
        return this.http.put<any>(searchUrl, body, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    createUser(user: User) {
        const searchUrl = this.userSearchWsUrl1 + "/user/create" + '?code=' + environment.apiCode;
        const body = {
            UserName: user.UserName,
            UserEmail: user.UserEmail,
            UserActive: user.UserActive,
            CreatedBy: user.CreatedBy,
            ModifiedBy: user.ModifiedBy
        }
        return this.http.post<any>(searchUrl, body, this.getHeaders())
    }


    getModuleItem(): Observable<any> {
        const searchUrl = this.userSearchWsUrl1 + "/module/controls" + '?code=' + environment.apiCode;
        return this.http.get<any[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    updateModules(updatedModule: Module): Observable<any> {

        const searchUrl = this.userSearchWsUrl1 + "/module/update/" + updatedModule.ModuleId + '?code=' + environment.apiCode;
        const body = {
            ModuleName: updatedModule.ModuleName,
            ModuleActive: updatedModule.IsActive,
            ModifiedBy: updatedModule.ModifiedBy,
            Rolemodules: updatedModule.Rolemodules,
            ModuleItem: updatedModule.ModuleItem,
            ModuleRef: updatedModule.ModuleRef
        }
        return this.http.put<any>(searchUrl, body, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    createRole(newRole: Role): Observable<any> {
        const searchUrl = this.userSearchWsUrl1 + "/role/create" + '?code=' + environment.apiCode;
        const body = {
            RoleId: newRole.RoleId,
            RoleName: newRole.RoleName,
            RoleActive: newRole.RoleActive,
            CreatedBy: newRole.CreatedBy,
            ModifiedBy: newRole.ModifiedBy
        }
        return this.http.post<any>(searchUrl, body, this.getHeaders());
    }


    createModule(newModule: Module): Observable<any> {
        const searchUrl = this.userSearchWsUrl1 + "/module/create" + '?code=' + environment.apiCode;
        const body = {
            ModuleId: newModule.ModuleId,
            ModuleName: newModule.ModuleName,
            ModuleActive: newModule.IsActive,
            CreatedBy: newModule.CreatedBy,
            ModifiedBy: newModule.ModifiedBy,
            ModuleItem: newModule.ModuleItem,
            Path: newModule.Path,
            ModuleRef: newModule.ModuleRef
        }

        return this.http.post<any>(searchUrl, body, this.getHeaders())
    }

    getUsers(): Observable<User[]> {
        const searchUrl = this.userSearchWsUrl1 + "/users" + '?code=' + environment.apiCode;
        return this.http.get<User[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }


    getAllUtilityList(): Observable<any> {
       
        const searchUrl = this.userSearchWsUrl2 + "selfschedule/utilitysettingsmaster/" + this.utilityService.currentUtility.OfficeId + '?utility=' + '&code=' + environment.apiCode;
        return this.http.get<any[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }


/*
    getAllUtilityList(): Observable<any> {
       
        const searchUrl = this.userSearchWsUrl2 + "selfschedule/utilitysettingsmaster"+ '?utility=' + '&code=' + environment.apiCode;
        return this.http.get<any[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

*/

    getAllUtilDetailList(utilid): Observable<any> {
        let selectedUtil = localStorage.getItem('utilityId');
        console.log('utilityId:',selectedUtil);
        const searchUrl = this.userSearchWsUrl2 + "selfschedule/utilitysettingsmaster/"+this.utilityService.currentUtility.OfficeId+'?utility=' + selectedUtil + '&code=' + environment.apiCode;
        return this.http.get<any[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }


    //------Dashboard related functions----------
    getOrganizationCode(officeId, email): Observable<any[]> {
        const searchUrl = environment.apiUrl + "distributordashboard/getorgcode/" + officeId + "/useremail/" + email + "?code=" + environment.apiCode;
        return this.http.get<any[]>(searchUrl, this.getHeaders()).pipe(
            map((response) => response),
            catchError((err) => this.utilityService.handleError(err, this, []))
        );
    }

    getAdminMatrix(officeId): Observable<any[]> {
        const searchUrl = environment.apiUrl + "dashboard/matrix/" + officeId + "/get/user/" + this.dbBBCSId + "/" + this.resourceRole + "?org_cd=" + this.orgCd + "&code=" + environment.apiCode;
        return this.http.get<any[]>(searchUrl, this.getHeaders()).pipe(
            map((response) => response),
            catchError((err) => this.utilityService.handleError(err, this, []))
        );
    }

    getDashboardBuckets(officeId): Observable<any[]> {
        const searchUrl = environment.apiUrl + "dashboard/" + officeId + "/get/user/" + this.dbBBCSId + "/" + this.resourceRole + "?org_cd=" + this.orgCd + "&code=" + environment.apiCode;
        return this.http.get<any[]>(searchUrl, this.getHeaders()).pipe(
            map((response) => response),
            catchError((err) => this.utilityService.handleError(err, this, []))
        );
    }

    getWorkorderAging(officeId): Observable<any[]> {
        const searchUrl = environment.apiUrl + "dashboard/workorderage/" + officeId + "/get/user/" + this.dbBBCSId + "?org_cd=" + this.orgCd + "&code=" + environment.apiCode;
        return this.http.get<any[]>(searchUrl, this.getHeaders()).pipe(
            map((response) => response),
            catchError((err) => this.utilityService.handleError(err, this, []))
        );
    }

    getWorkorderAgingDetail(officeId, programdetailid, statuscd): Observable<any[]> {
        const searchUrl = environment.apiUrl + "dashboard/workorderagedetail/" + officeId + "/get/user/" + this.dbBBCSId + "/programdetailid/" + programdetailid + "/status/" + statuscd + "?org_cd=" + this.orgCd + "&code=" + environment.apiCode;
        return this.http.get<any[]>(searchUrl, this.getHeaders()).pipe(
            map((response) => response),
            catchError((err) => this.utilityService.handleError(err, this, []))
        );
    }

    getWorkorderSummary(officeId): Observable<any[]> {
        const searchUrl = environment.apiUrl + "dashboard/WorkorderSummary/" + officeId + "/get/user/" + this.dbBBCSId + "?org_cd=" + this.orgCd + "&code=" + environment.apiCode;
        return this.http.get<any[]>(searchUrl, this.getHeaders()).pipe(
            map((response) => response),
            catchError((err) => this.utilityService.handleError(err, this, []))
        );
    }

    getWorkorderSummaryDetail(officeId, exceptioncd): Observable<any[]> {
        const searchUrl = environment.apiUrl + "dashboard/workordersummarydetail/" + officeId + "/get/user/" + this.dbBBCSId + "/exceptioncd/" + exceptioncd + "?org_cd=" + this.orgCd + "&code=" + environment.apiCode;
        return this.http.get<any[]>(searchUrl, this.getHeaders()).pipe(
            map((response) => response),
            catchError((err) => this.utilityService.handleError(err, this, []))
        );
    }

    getInstallerProduction(officeId): Observable<any[]> {
        const searchUrl = environment.apiUrl + "dashboard/installerproduction/" + officeId + "/get/user/" + this.dbBBCSId + "?org_cd=" + this.orgCd + "&code=" + environment.apiCode;
        return this.http.get<any[]>(searchUrl, this.getHeaders()).pipe(
            map((response) => response),
            catchError((err) => this.utilityService.handleError(err, this, []))
        );
    }

    getHoldReasonSummary(officeId): Observable<any[]> {
        const searchUrl = environment.apiUrl + "dashboard/holdsummaryreason/" + officeId + "/get/user/" + this.dbBBCSId + "?org_cd=" + this.orgCd + "&code=" + environment.apiCode;
        return this.http.get<any[]>(searchUrl, this.getHeaders()).pipe(
            map((response) => response),
            catchError((err) => this.utilityService.handleError(err, this, []))
        );
    }

    getHoldReasonDetail(officeId, reasonCd): Observable<any[]> {
        const searchUrl = environment.apiUrl + "dashboard/holddetailreason/" + officeId + "/get/user/" + this.dbBBCSId + "/reasoncd/" + reasonCd + "?org_cd=" + this.orgCd + "&code=" + environment.apiCode;
        return this.http.get<any[]>(searchUrl, this.getHeaders()).pipe(
            map((response) => response),
            catchError((err) => this.utilityService.handleError(err, this, []))
        );
    }
}
