<mat-spinner [diameter]="80" color="primary" *ngIf="searching"
  style="margin:0 auto; width:50% !important; margin-top: 2vw;"></mat-spinner>
<div [hidden]="searching">
  <mat-card class="user-card">
    <mat-card-header>
      <mat-card-title style="font-size: 16px">
        <span matBadge="{{ offices.length }}" matBadgeOverlap="false">Offices</span>
      </mat-card-title>
      <div style="width: 100% !important;">
        <button mat-button (click)="addNewOffice()"
          style="background: transparent;float:right !important; height: 100% !important">
          <mat-icon matTooltip="Add Office" aria-hidden="false" color="primary" 
          style="text-align: center;width: 98%;float:right;">
            add
          </mat-icon>
        </button>
      </div>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>
      <br>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
        <ng-container matColumnDef="OfficeId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Office ID</th>
          <td mat-cell *matCellDef="let off" matTooltip="{{off.OfficeId}}"> {{ off.OfficeId }} </td>
        </ng-container>

        <ng-container matColumnDef="OfficeDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
          <td mat-cell *matCellDef="let off" matTooltip="{{off.OfficeDescription}}"> {{off.OfficeDescription}} </td>
        </ng-container>

        <ng-container matColumnDef="ProgramSponsorId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>ProgramSponsor</th>
          <td mat-cell *matCellDef="let off" matTooltip="{{off.ProgramSponsor.ProgramSponsorDescription}}">
            {{off.ProgramSponsor.ProgramSponsorDescription}} </td>
        </ng-container>


        <ng-container matColumnDef="OfficeActive">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
          <td mat-cell *matCellDef="let off">
            <mat-icon matTooltip="Check" *ngIf="off.OfficeActive" color="primary">check_box</mat-icon>
            <mat-icon matTooltip="Cancel" *ngIf="!off.OfficeActive" style="color: red !important">cancel</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="CreatedDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</th>
          <td mat-cell *matCellDef="let off"> {{off.CreatedDate  | date:'shortDate' }} </td>
        </ng-container>


        <ng-container matColumnDef="ModifiedBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%">ModifiedBy</th>
          <td mat-cell *matCellDef="let off" matTooltip="{{off.ModifiedBy}}"> {{off.ModifiedBy }} </td>
        </ng-container>


        <ng-container matColumnDef="edit" stickyEnd>
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 6% !important"></th>
          <td mat-cell *matCellDef="let off">
            <button mat-button (click)="handleClick(off)" matTooltip="Create">
              <mat-icon matTooltip="Edit" color="primary">create</mat-icon>
            </button>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5,10,20]" showFirstLastButtons></mat-paginator>
    </mat-card-content>
  </mat-card>
</div>