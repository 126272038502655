import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as moment from 'moment';

import { IndividualService } from '../../../services/individual.service';

@Component({
    selector: 'app-individual-role',
    templateUrl: './individual-role.component.html',
    styleUrls: ['./individual-role.component.scss']
})
export class IndividualRoleComponent implements OnInit {

    workresource_id = null;
    roleType;
    endDate;
    inventory = false;
    active = true;
    certificate = false;

    clientkey1: string;
    clientkey2: string;
    clientkey3: string;
    clientkey4: string;
    clientkey5: string;
    clientkey6: string;

    constructor(
        public dialogRef: MatDialogRef<IndividualRoleComponent>,
        public indService: IndividualService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        if (this.data.editMode) {
            this.endDate = this.indService.indDetail.RolesTab.Roles[this.data.index].LASTDATE;
            this.inventory = this.indService.indDetail.RolesTab.Roles[this.data.index].INVENTORY;
            this.clientkey1 = this.indService.indDetail.RolesTab.Roles[this.data.index].CLIENTKEY1;
            this.clientkey2 = this.indService.indDetail.RolesTab.Roles[this.data.index].CLIENTKEY2;
            this.clientkey3 = this.indService.indDetail.RolesTab.Roles[this.data.index].CLIENTKEY3;
            this.clientkey4 = this.indService.indDetail.RolesTab.Roles[this.data.index].CLIENTKEY4;
            this.clientkey5 = this.indService.indDetail.RolesTab.Roles[this.data.index].CLIENTKEY5;
            this.clientkey6 =  this.indService.indDetail.RolesTab.Roles[this.data.index].CLIENTKEY6;
            this.active = this.indService.indDetail.RolesTab.Roles[this.data.index].WORKRESOURCE_ACTIVE;
            this.certificate = this.indService.indDetail.RolesTab.Roles[this.data.index].CERTIFICATION;
            this.workresource_id=this.indService.indDetail.RolesTab.Roles[this.data.index].WORKRESOURCE_ID;

            for (var i = 0; i < this.indService.indDetail.RolesTab.RoleTypeList.length; i++) {
                if (this.indService.indDetail.RolesTab.RoleTypeList[i].CODE
                    === this.indService.indDetail.RolesTab.Roles[this.data.index].ROLE_CD) {
                    this.roleType = this.indService.indDetail.RolesTab.RoleTypeList[i];
                    break;
                }
            }
        }
        else
        {   
            var today = new Date();
            var year = today.getFullYear();
            var month = today.getMonth();
            var day = today.getDate();
            this.endDate = new Date(year + 5, month, day);
        }

    }

    saveRole() {
        let datestr = null;
        if (this.endDate !== undefined) {
            datestr = moment(this.endDate).format('YYYY-MM-DDTHH:mm:ss');
        }

        if (this.data.editMode) {
            this.indService.indDetail.RolesTab.Roles[this.data.index].LASTDATE = datestr;
            this.indService.indDetail.RolesTab.Roles[this.data.index].ROLE_CD = this.roleType.CODE;
            this.indService.indDetail.RolesTab.Roles[this.data.index].INVENTORY = Number(this.inventory);
            this.indService.indDetail.RolesTab.Roles[this.data.index].CLIENTKEY1 = this.clientkey1;
            this.indService.indDetail.RolesTab.Roles[this.data.index].CLIENTKEY2 = this.clientkey2;
            this.indService.indDetail.RolesTab.Roles[this.data.index].CLIENTKEY3 = this.clientkey3;
            this.indService.indDetail.RolesTab.Roles[this.data.index].CLIENTKEY4 = this.clientkey4;
            this.indService.indDetail.RolesTab.Roles[this.data.index].CLIENTKEY5 = this.clientkey5;
            this.indService.indDetail.RolesTab.Roles[this.data.index].CLIENTKEY6 = this.clientkey6;
            this.indService.indDetail.RolesTab.Roles[this.data.index].WORKRESOURCE_ACTIVE = Number(this.active);
            this.indService.indDetail.RolesTab.Roles[this.data.index].CERTIFICATION = Number(this.certificate);
            this.indService.indDetail.RolesTab.Roles[this.data.index].SELECTEDROLEDESCRIPTION = this.roleType.DESCRIPTION;
            this.indService.indDetail.RolesTab.Roles[this.data.index].SELECTEDROLECODE = this.roleType.CODE;
        } else {
            const role = {
                WORKRESOURCE_ID: null,
                PERSONCOMPANY_ID: this.indService.personcompanyId,
                DESCRIPTION: '',
                LASTDATE: datestr,
                ROLE_CD: this.roleType.CODE,
                INVENTORY: Number(this.inventory),
                CLIENTKEY1: this.clientkey1,
                CLIENTKEY2: this.clientkey2,
                CLIENTKEY3: this.clientkey3,
                CLIENTKEY4: this.clientkey4,
                CLIENTKEY5: this.clientkey5,
                CLIENTKEY6: this.clientkey6,
                WORKRESOURCE_OFFICE: this.indService.officeId,
                WORKRESOURCE_ACTIVE: Number(this.active),
                CERTIFICATION: Number(this.certificate),
                ORGANIZATION_CD: this.indService.orgId,
                WORKLOAD: null,
                SELECTEDROLEDESCRIPTION: this.roleType.DESCRIPTION,
                SELECTEDROLECODE: this.roleType.CODE
            };

            this.indService.indDetail.RolesTab.Roles.push(role);
        }

        this.dialogRef.close('1');
    }

}
