<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#ff4081" type="timer" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>

<div *ngIf="userService.orgCd != null && userService.orgCd != undefined">
    <mat-card class="user-card">
        <mat-card-header>
            <mat-card-title style="font-size: 16px">
                Dashboard
            </mat-card-title>
        </mat-card-header>
        <mat-divider></mat-divider>

        <div class="flex-Div">
            <ng-container *ngFor="let item of buckets">
                <div [ngClass]="item.label == 'Total Workorders' ? 'two-flex-total' : 'two-flex-div20'" (click)="item.label !='Total Workorders' ? applyFilter(item.WorkorderStatus) : refrehPage()">
                    <h5 class="flex-to-the-center">{{ item.label }}</h5>
                    <h1 class="flex-to-the-center" [style.background-color]="item.BackgroundColor"
                        [style.color]="item.Color">{{ item.Count }}</h1>
                </div>
            </ng-container>
        </div>

        <br>

        <table *ngIf="dataMatrix.length > 0" class="mat-elevation-z8 all-border">
            <thead>
                <tr>
                    <th class="mat-header-cell" *ngFor="let head of dataMatrix[0] |  keyvalue : originalOrder">
                        {{head.key}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of dataMatrix">
                    <td style="cursor: pointer; font-size: 14px;" *ngFor="let list of item | keyvalue : originalOrder">
                        <span (click)="list.key != 'WorkorderType'? applyFilter(item, list.key ,true) : applyFilter(item, '',true)" matTooltip="{{item[list.key]}}"> {{item[list.key]}}</span>
                    </td>
                </tr>
            </tbody>
        </table>

        <mat-card class="user-card">
            <div style="display:flex">
                <div>
                    <mat-card-title style="font-size: 16px;">
                        <span>Workorders</span>
                    </mat-card-title>
                </div>
                <div style="flex-grow: 1"></div>
                <button mat-flat-button color="primary" type="submit" (click)="refrehPage()">Reset Filter</button>
            </div>
            <br />
            <mat-divider></mat-divider>
            <mat-card-content>
                <br />

                <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                    <ng-container matColumnDef="WorkorderID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Workorder ID
                            <button mat-button class="btn-toggle" [matMenuTriggerFor]="menu">
                                <mat-icon>filter_alt</mat-icon>
                            </button>
                            <mat-menu #menu>
                                <div mat-menu-item mat-filter-item [disableRipple]="true" class="menu-title">
                                    Workorder ID
                                </div>
                                <div mat-menu-item mat-filter-item [disableRipple]="true">
                                    <mat-form-field>
                                        <input matInput class="form-field" [formControl]="workorderIDFilter"
                                            placeholder="Value">
                                    </mat-form-field>
                                </div>
                                <div mat-menu-item mat-filter-item [disableRipple]="true">
                                    <button mat-raised-button (click)="clearColumn('workorderIDFilter')"
                                        color="primary">Clear</button>
                                </div>
                            </mat-menu>
                        </th>
                        <td mat-cell *matCellDef="let row"> {{row.WorkorderID}} </td>
                    </ng-container>

                    <ng-container matColumnDef="WorkorderType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Workorder Type
                            <button mat-button class="btn-toggle" [matMenuTriggerFor]="menu">
                                <mat-icon>filter_alt</mat-icon>
                            </button>
                            <mat-menu #menu>
                                <div mat-menu-item mat-filter-item [disableRipple]="true" class="menu-title">
                                    Workorder Type
                                </div>
                                <div mat-menu-item mat-filter-item [disableRipple]="true">
                                    <mat-form-field>
                                        <input matInput class="form-field" [formControl]="workorderTypeFilter"
                                            placeholder="Value">
                                    </mat-form-field>
                                </div>
                                <div mat-menu-item mat-filter-item [disableRipple]="true">
                                    <button mat-raised-button (click)="clearColumn('workorderTypeFilter')"
                                        color="primary">Clear</button>
                                </div>
                            </mat-menu>
                        </th>
                        <td mat-cell *matCellDef="let workorder" matTooltip="{{workorder.WorkorderType}}">
                            {{workorder.WorkorderType}} </td>
                    </ng-container>

                    <ng-container matColumnDef="AccountHolder">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Holder
                            <button mat-button class="btn-toggle" [matMenuTriggerFor]="menu">
                                <mat-icon>filter_alt</mat-icon>
                            </button>
                            <mat-menu #menu>
                                <div mat-menu-item mat-filter-item [disableRipple]="true" class="menu-title">
                                    Account Holder
                                </div>
                                <div mat-menu-item mat-filter-item [disableRipple]="true">
                                    <mat-form-field>
                                        <input matInput class="form-field" [formControl]="accountHolderFilter"
                                            placeholder="Value">
                                    </mat-form-field>
                                </div>
                                <div mat-menu-item mat-filter-item [disableRipple]="true">
                                    <button mat-raised-button (click)="clearColumn('accountHolderFilter')"
                                        color="primary">Clear</button>
                                </div>
                            </mat-menu>
                        </th>
                        <td mat-cell *matCellDef="let workorder" matTooltip="{{workorder.AccountHolder}}"> {{
                            workorder.AccountHolder }} </td>
                    </ng-container>

                    <ng-container matColumnDef="Distributor">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <span>Distributor Name</span>
                            <button mat-button class="btn-toggle" [matMenuTriggerFor]="menu">
                                <mat-icon>filter_alt</mat-icon>
                            </button>
                            <mat-menu #menu>
                                <div mat-menu-item mat-filter-item [disableRipple]="true" class="menu-title">
                                    <span>Distributor Name</span>
                                </div>
                                <div mat-menu-item mat-filter-item [disableRipple]="true">
                                    <mat-form-field>
                                        <input matInput class="form-field" [formControl]="distributorFilter"
                                            placeholder="Value">
                                    </mat-form-field>
                                </div>
                                <div mat-menu-item mat-filter-item [disableRipple]="true">
                                    <button mat-raised-button (click)="clearColumn('distributorFilter')"
                                        color="primary">Clear</button>
                                </div>
                            </mat-menu>

                        </th>
                        <td mat-cell *matCellDef="let workorder" matTooltip="{{workorder.Distributor}}"> {{
                            workorder.Distributor }} </td>
                    </ng-container>

                    <ng-container matColumnDef="Contractor">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <span>Contractor Name</span>
                            <button mat-button class="btn-toggle" [matMenuTriggerFor]="menu">
                                <mat-icon>filter_alt</mat-icon>
                            </button>
                            <mat-menu #menu>
                                <div mat-menu-item mat-filter-item [disableRipple]="true" class="menu-title">
                                    <span>Contractor Name</span>
                                </div>
                                <div mat-menu-item mat-filter-item [disableRipple]="true">
                                    <mat-form-field>
                                        <input matInput class="form-field" [formControl]="contractorFilter"
                                            placeholder="Value">
                                    </mat-form-field>
                                </div>
                                <div mat-menu-item mat-filter-item [disableRipple]="true">
                                    <button mat-raised-button (click)="clearColumn('contractorFilter')"
                                        color="primary">Clear</button>
                                </div>
                            </mat-menu>

                        </th>
                        <td mat-cell *matCellDef="let workorder" matTooltip="{{workorder.Contractor}}"> {{
                            workorder.Contractor }} </td>
                    </ng-container>

                    <ng-container matColumnDef="WorkorderStatus">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Workorder Status
                            <button mat-button class="btn-toggle" [matMenuTriggerFor]="menu">
                                <mat-icon>filter_alt</mat-icon>
                            </button>
                            <mat-menu #menu>
                                <div mat-menu-item mat-filter-item [disableRipple]="true" class="menu-title">
                                    Workorder Status
                                </div>
                                <div mat-menu-item mat-filter-item [disableRipple]="true">
                                    <mat-form-field>
                                        <input matInput class="form-field" [formControl]="workorderStatusFilter"
                                            placeholder="Value">
                                    </mat-form-field>
                                </div>
                                <div mat-menu-item mat-filter-item [disableRipple]="true">
                                    <button mat-raised-button (click)="clearColumn('workorderStatusFilter')"
                                        color="primary">Clear</button>
                                </div>
                            </mat-menu>
                        </th>
                        <td mat-cell *matCellDef="let workorder" matTooltip="{{workorder.WorkorderStatus}}"> {{
                            workorder.WorkorderStatus }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Updated">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated Date</th>
                        <td mat-cell *matCellDef="let workorder"> {{workorder.Updated | date:'shortDate' }} </td>
                    </ng-container>

                    <ng-container matColumnDef="Details">
                        <th mat-header-cell *matHeaderCellDef style="width: 6% !important">Details</th>
                        <td mat-cell *matCellDef="let workorder">
                            <button mat-button
                                (click)="handleEnrollmentWorkorderClick(workorder, workorder.EnrollmentId)">
                                <mat-icon style="visibility: visible;color:#3CB371;">label_important</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[10,20]" showFirstLastButtons></mat-paginator>
            </mat-card-content>
        </mat-card>
    </mat-card>
</div>